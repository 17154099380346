<template>
  <div :class="['flex flex-col', routeInfo.type === 'editor' ? 'h-full' : '']">
    <header class="z-10 px-6 py-4 sticky bg-white border-b" style="top: calc(4rem + 1px)">
      <div class="flex items-center justify-between md:space-x-5">
        <div class="flex items-center space-x-5">
          <div class="flex-shrink-0">
            <div class="relative bg-violet-600/70 rounded-xl p-1">
              <mdicon :name="kbStore.kbIcon" size="62" class="text-violet-100" aria-hidden="true"></mdicon>
            </div>
          </div>
          <div class="">
            <h1 class="text-xl font-bold text-gray-900 px-1 line-clamp-1">
              {{ currentTitle }}
            </h1>
            <div class="rounded mt-0.5 px-1 py-0.5 text-md line-clamp-1">
              {{ currentSubtitle }}
            </div>
          </div>
        </div>

        <!-- Control Groups -->

        <div
          v-if="rbac.canEditKb && routeInfo.type === 'settings'"
          class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3"
        >
          <button type="button" class="flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="onExportKb">
            <mdicon name="download-outline" class="h-6 w-6 mr-1 text-gray-500" aria-hidden="true"></mdicon>
            Export
          </button>

          <button type="button" class="mr-2 flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="onCloneKb">
            <mdicon name="content-duplicate" class="h-6 w-6 mr-1 text-gray-500" aria-hidden="true"></mdicon>
            Clone
          </button>

          <button
            type="button"
            class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="onSaveKb"
          >
            <CheckIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Save
          </button>
        </div>

        <div v-else-if="rbac.canEditKb && routeInfo.type === 'editor'" class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="onSaveKbDocument"
          >
            <CheckIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Save
          </button>
        </div>

        <div v-else-if="rbac.canEditKb && routeInfo.type === 'documents'" class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex items-center gap-2">
          <button
            v-if="showDelete"
            type="button"
            class="flex items-center gap-x-1 rounded-md bg-red-600 px-2.5 py-1.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            @click="onDeleteKbDocuments"
          >
            <mdicon name="minus-circle-outline" class="h-6 w-6" aria-hidden="true"></mdicon>
            Delete
          </button>
          <DropdownSelectButton class="" title="New Document" :items="newKbDocumentMenuItems" @select="onNewKbDocumentMenuSelect" />
        </div>

        <!--<InlineMessage v-else>You do not have privilege to edit KB</InlineMessage>-->
      </div>
    </header>

    <div :class="['', routeInfo.type === 'editor' ? 'flex-auto' : 'mx-6 my-4']">
      <router-view />
    </div>

    <ConfirmDialog group="cloneKb">
      <template #message="slotProps">
        <div class="flex flex-col gap-2 w-96">
          <label for="username">Name</label>
          <InputText id="username" class="" v-model="kbCloneName" aria-describedby="name-help" />
          <small id="name-help">Enter the name for the new Knowledge Base.</small>
        </div>
      </template>
    </ConfirmDialog>
    <ProgressDialog ref="saveDocumentProgress"></ProgressDialog>
    <KbDialogCreateNewDocument ref="dialogCreateNewDocument" @document-created="onKbDocumentCreated"></KbDialogCreateNewDocument>
    <KbDialogUploadNewDocument ref="dialogUploadNewDocument" @document-created="onKbDocumentCreated"></KbDialogUploadNewDocument>
    <KbDialogScrapeNewDocumentFromURL ref="dialogScrapeNewDocumentFromURL" @document-created="onKbDocumentCreated"></KbDialogScrapeNewDocumentFromURL>
    <KbDialogDeleteDocuments ref="dialogDeleteDocuments"></KbDialogDeleteDocuments>
  </div>
</template>
<script setup>
  import { useRoute, useRouter } from 'vue-router';
  import { useRbacStore } from '@/store/RBACStore';
  import { useKbStore } from '@/store/KbStore';
  import { useSidebarStore } from '@/store/SidebarStore';
  import { useProgress } from '@/hooks/useProgress';
  import { useToast } from 'vue-toastification';
  import { onMounted, onBeforeMount, watch, ref, computed } from 'vue';
  import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
  import ProgressDialog from '@/components/Dialogs/ProgressDialog.vue';
  import { CheckIcon } from '@heroicons/vue/20/solid';
  import { ConversationDetailsReport } from '@enegelai/core';
  import InputText from 'primevue/inputtext';
  import ConfirmDialog from 'primevue/confirmdialog';
  import { useConfirm } from 'primevue/useconfirm';
  import InlineMessage from 'primevue/inlinemessage';
  import DropdownSelectButton from '@/components/Buttons/DropdownSelectButton.vue';
  import { KB_TYPE_DEFS } from '@/spec/KbTypeDefs';
  import KbDialogCreateNewDocument from '@/components/KB/dialogs/KbDialogCreateNewDocument.vue';
  import KbDialogUploadNewDocument from '@/components/KB/dialogs/KbDialogUploadNewDocument.vue';
  import KbDialogDeleteDocuments from '@/components/KB/dialogs/KbDialogDeleteDocuments.vue';
  import KbDialogScrapeNewDocumentFromURL from '@/components/KB/dialogs/KbDialogScrapeNewDocumentFromURL.vue';
  import Button from 'primevue/button';
  import dayjs from 'dayjs';

  const router = useRouter();
  const route = useRoute();
  const rbac = useRbacStore();
  const progress = useProgress();

  const sidebarStore = useSidebarStore();
  const kbStore = useKbStore();

  const selectedKbDocuments = computed(() => kbStore.selectedKbDocuments);
  const showDelete = computed(() => Array.isArray(selectedKbDocuments.value) && selectedKbDocuments.value.length > 0);

  const toast = useToast();
  const confirm = useConfirm();
  const saveDocumentProgress = ref();

  const kbCloneName = ref('');

  const kbCurrentDocumentTitle = computed(() => (kbStore.currentKbDocumentTitle ? kbStore.currentKbDocumentTitle : kbStore.currentKbDocumentName));
  // Updated <time :datetime="kbStore.currentKbDocument?.updated">{{ dayjs(kbStore.currentKbDocument?.updated).format('LLL') }}</time> By {{ kbStore.currentKbDocument?.updatedBy }}
  const kbCurrentDocumentSubtitle = computed(() => {
    return kbStore.currentKbDocument ? 'Updated ' + dayjs(kbStore.currentKbDocument?.updated).format('LLL') + ' By ' + kbStore.currentKbDocument?.updatedBy : '';
  });

  const supportedRoutes = {
    'kb.editor.settings': { subtitle: 'Knowledge Base Settings', type: 'settings' },
    'kb.editor.documents': { subtitle: 'Knowledge Base Documents', type: 'documents' },
    'kb.editor.document.editor': { titleComputed: kbCurrentDocumentTitle, subtitleComputed: kbCurrentDocumentSubtitle, type: 'editor' },
    'kb.editor.search': { subtitle: 'Knowledge Base Search', type: 'search' },
    unknown: { subtitle: 'ERROR: Unknown route', type: 'unknown' },
  };
  const routeInfo = computed(() => {
    return supportedRoutes[route.name] || supportedRoutes['unknown'];
  });

  const currentTitle = computed(() => {
    return routeInfo.value?.titleComputed ? routeInfo.value.titleComputed.value : kbStore.kbSpec?.name + (routeInfo.value?.titleSuffix || '');
  });

  const currentSubtitle = computed(() => {
    return routeInfo.value?.subtitleComputed ? routeInfo.value.subtitleComputed.value : routeInfo.value?.subtitle || '';
  });

  watch(
    () => route.params.id,
    async (newId) => {
      await initKbEditor();
    },
  );

  const dialogCreateNewDocument = ref(null);
  const dialogUploadNewDocument = ref(null);
  const dialogScrapeNewDocumentFromURL = ref(null);
  const dialogDeleteDocuments = ref(null);

  const newKbDocumentMenuItems = [
    { type: 'create', title: 'Create', description: 'Create new text document', icon: 'file-document-plus-outline', defaultName: '' },
    { type: 'upload', title: 'Upload', description: 'Upload new document', icon: 'file-upload-outline', defaultName: '' },
    { type: 'scrape', title: 'Scrape Web Page', description: 'Create new document with the content from web page', icon: 'web-plus', defaultName: '' },
  ];

  async function onNewKbDocumentMenuSelect(item) {
    console.log(`Selected:`, item);
    switch (item.type) {
      case 'create': {
        dialogCreateNewDocument.value.start();
        break;
      }
      case 'upload': {
        dialogUploadNewDocument.value.start();
        break;
      }
      case 'scrape': {
        dialogScrapeNewDocumentFromURL.value.start();
        break;
      }
    }
  }

  function onSaveKb(showToast = true) {
    // Save the latest value
    kbStore.saveKb().then((saveResult) => {
      if (showToast) {
        if (saveResult) {
          toast.success('KB saved successfully');
        } else {
          toast.error(`Error saving KB`);
        }
      }
    });
  }

  function onExportKb() {
    const kbSpecContent = JSON.stringify(kbStore.kbSpec);
    downloadKbSpec(`kb_${kbStore.kbId}`, kbSpecContent, 'application/json');
  }

  function onCloneKb() {
    // Dialog - new name + confirm
    const suggestedName = `${kbStore.kbName} (Copy)`;
    kbCloneName.value = suggestedName;
    confirm.require({
      group: 'cloneKb',
      header: 'Clone Knowledge Base',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-outlined p-button-sm',
      acceptClass: 'p-button-sm',
      rejectLabel: 'Cancel',
      acceptLabel: 'Clone',
      accept: () => {
        //toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        const newKbId = kbStore.cloneKbInPlace(kbCloneName.value);
        router.push(`/kb/editor/${newKbId}`);
      },
      reject: () => {
        //toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        console.log(`KB clone canceled`);
      },
    });
  }

  function downloadKbSpec(filename, content, mimeType) {
    // credit: https://www.bitdegree.org/learn/javascript-download
    let text = content;
    let element = document.createElement('a');
    element.setAttribute('href', `data:${mimeType};charset=utf-8,` + encodeURIComponent(text));
    element.setAttribute('download', filename);
    //element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  async function initKbEditor() {
    let id = route.params?.id || null;
    if (!id) {
      console.log(`ERROR: KB ID must be specified`);
      toast.error(`KB ID must be specified`);
      return;
    }

    // TODO Make that computed, to use data from the kb spec when it loads
    const kbEditorRoutePrefix = `/kb/editor/${id}`; //route.path.split('/').slice(0, 4).join('/');
    const kbEditorSidebarSectionName = computed(() => kbStore.kbName || 'Knowledge Base');
    sidebarStore.setExtraItems(kbEditorRoutePrefix, [
      //{ title: 'KB Details', type: 'subtitle' },
      { title: kbEditorSidebarSectionName, type: 'sectionTitle' },
      { title: 'Documents', link: kbEditorRoutePrefix + '/documents', icon: 'file-document-multiple-outline', iconSize: 16, iconClass: 'bg-violet-800 text-white rounded' },
      { title: 'Search', link: kbEditorRoutePrefix + '/search', icon: 'database-search-outline', iconSize: 16, iconClass: 'bg-violet-800 text-white rounded' },
      { title: 'Settings', link: kbEditorRoutePrefix + '/settings', icon: 'cog-outline', iconSize: 16, iconClass: 'bg-violet-800 text-white rounded' },
    ]);

    //await kbStore.loadKb(id);
  }

  async function onSaveKbDocument() {
    await kbStore.beforeSaveKbDocument();
    progress.startProgress({ blockUI: true, spinner: true });
    //saveDocumentProgress.value.show({ title: 'Saving document', description: kbStore.currentKbDocument?.name || '' });
    const saveResult = await kbStore.saveCurrentKbDocument();
    progress.stopProgress();
    if (saveResult.success) {
      toast.success(`Document saved successfully`);
    } else {
      toast.error(`Error saving document: ${saveResult.error}`);
    }
  }

  function onKbDocumentCreated(documentInfo) {
    const newDocumentId = documentInfo?.id || null;
    if (!newDocumentId) {
      return;
    }
    console.log(`KB Document Created, id=${newDocumentId}`, documentInfo);
    router.push(`/kb/editor/${kbStore.kbId}/document/${newDocumentId}`);
  }

  function onDeleteKbDocuments() {
    dialogDeleteDocuments.value.start(selectedKbDocuments.value);
  }

  onBeforeMount(async () => {
    let id = route.params?.id || null;
    if (!id) {
      console.log(`ERROR: KB ID must be specified`);
      return;
    }
    await kbStore.loadKb(id);
  });

  onMounted(async () => {
    await initKbEditor();
  });
</script>
