<template>
  <div>
    <spec-script-action-render
      v-for="(item, idx) in props.steps"
      :key="idx"
      :action="item"
      :idx="idx"
      :menu-enabled="false"
      @action-form-active="onActionFormActive"
      @update:prop-value="
        (index, prop, value) => {
          emit('update:propValue', index, prop, value);
        }
      "
    ></spec-script-action-render>
  </div>
</template>

<script setup>
  // Restricted Script Editor: no drag and drop, no add / delete steps - only edit properties
  import { ref } from 'vue';
  import SpecScriptActionRender from '@/components/Tests/SpecScriptActionRender.vue';

  const props = defineProps({
    media: {
      type: String,
      default: 'voice',
    },
    steps: {
      type: Array,
      default() {
        return [];
      },
    },
  });

  const emit = defineEmits(['step-form-active', 'update:propValue']);

  function onActionFormActive(idx, active) {
    emit('step-form-active', idx, active);
  }
</script>
