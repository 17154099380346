<template>
  <div class="space-y-10 divide-y divide-gray-900/10">
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Job Parameters</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Choose Scenario to be executed and specify desired parameters</p>
      </div>

      <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-6 sm:p-8">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div class="col-span-full">
              <label for="scenario" class="block text-sm font-medium leading-6 text-gray-900">Scenario</label>
              <div class="mt-2 flex rounded-md shadow-sm">
                <div class="relative flex flex-grow items-stretch focus-within:z-10">
                  <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <mdicon name="playlist-check" size="24"></mdicon>
                  </div>
                  <input
                    id="scenario"
                    type="text"
                    name="scenario"
                    :value="specScenarioName"
                    readonly
                    class="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Scenario to execute"
                  />
                </div>
                <button
                  type="button"
                  class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  @click="onSelectScenario"
                >
                  <mdicon name="magnify" size="24"></mdicon>Find
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <ItemSelector :items="scenariosStore.scenarios" :open="scenarioSelectorOpen" @select="onScenarioSelected" @close="scenarioSelectorOpen = false"></ItemSelector>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useScenariosStore } from '@/store/ScenariosStore';
  import ItemSelector from '@/components/Selectors/ItemSelector.vue';

  const props = defineProps({
    specData: {
      type: Object,
      default() {
        return {};
      },
    },
  });
  const emit = defineEmits(['update:specProp']);

  const specScenario = computed({
    get() {
      return props.specData?.scenario;
    },
    set(newValue) {
      emit('update:specProp', 'scenario', newValue);
    },
  });

  const specScenarioName = computed(() => {
    if (!props.specData?.scenario) {
      return '';
    }
    const s = scenariosStore.scenarios.find((x) => x.id === props.specData.scenario);
    return s ? s.name : '';
  });

  const scenariosStore = useScenariosStore();

  const scenarioSelectorOpen = ref(false);

  function onSelectScenario() {
    console.log(`selecting scenario ...`);
    scenarioSelectorOpen.value = true;
  }
  function onScenarioSelected(scenario) {
    console.log(`Scenario selected: ${JSON.stringify(scenario)}`);
    scenarioSelectorOpen.value = false;
    specScenario.value = scenario.id;
  }
</script>
