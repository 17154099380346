<template>
  <div class="my-2 border rounded-lg">
    <div class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6 md:col-span-2 pb-2 my-2 p-2">
      <StdPropEditor
        v-for="prop in twilioIntegrationProps"
        :key="prop.name"
        :prop-def="prop"
        :class="[getPropLayoutClasses(prop)]"
        :model-value="configurationData[prop.name]"
        @update:modelValue="
          (newValue) => {
            onUpdateProp(prop, newValue);
          }
        "
      />
    </div>
    <Message v-if="errorMessage !== ''" class="m-2" severity="error" :sticky="false" :life="5000">{{ errorMessage }}</Message>
    <div class="m-2 flex items-center justify-end">
      <button
        type="button"
        class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        @click="onCancel"
      >
        Cancel
      </button>
      <button type="button" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 sm:ml-3 sm:w-auto" @click="onSave">Save</button>
    </div>
  </div>
</template>
<script setup>
  import { computed, ref, watch, reactive, onMounted } from 'vue';
  import StdPropEditor from '@/components/Editors/StdPropEditor.vue';
  //import { useCollection, useDocument } from 'vuefire';
  //import { doc, setDoc, getDoc, collection } from 'firebase/firestore';
  import { useAuthStore } from '@/store/AuthStore';
  import { useSettingsStore } from '@/store/SettingsStore';
  import { fbDb } from '@/firebaseApp';
  import Message from 'primevue/message';

  const emit = defineEmits(['canceled', 'saved']);

  const authStore = useAuthStore();
  const settingsStore = useSettingsStore();
  const tenantId = computed(() => authStore.tenantId);
  //const twilioConfigurationDocument = useDocument(doc(fbDb, `tenants/${tenantId.value}/integrations/twilio`));

  const errorMessage = ref('');

  let configurationData = reactive({
    account_sid: '',
    api_key: '',
    api_secret: '',
  });

  const twilioIntegrationProps = [
    { name: 'account_sid', type: 'string', title: 'account_sid', default: '' },
    { name: 'api_key', type: 'string', title: 'api_key', default: '' },
    { name: 'api_secret', type: 'string', title: 'api_secret', default: '' },
  ];

  const propLayoutClassesByType = {
    text: 'col-span-full',
    boolean: 'col-span-full',
    number: 'sm:col-span-full md:col-span-full lg:col-span-3 xl:col-span-2',
    string: 'sm:col-span-full md:col-span-full lg:col-span-3 xl:col-span-2',
    select: 'sm:col-span-full md:col-span-full lg:col-span-3 xl:col-span-2',
    stringLong: 'col-span-full',
    audioFile: 'col-span-full',
    transcriptsCollection: 'col-span-full',
    recordingsCollection: 'col-span-full',
    kpiCollection: 'col-span-full',
    temperature: 'col-span-full',
  };

  function getPropLayoutClasses(prop) {
    return propLayoutClassesByType[prop?.type] || '';
  }

  function onUpdateProp(prop, newValue) {
    console.log(`onUpdateProp: ${prop.name} = "${newValue}"`);
    configurationData[prop.name] = newValue;
    //emit('update:modelValue', arrayValue);
  }

  function validateConfiguration() {
    if (!configurationData.account_sid || configurationData.account_sid === '') {
      return {
        valid: false,
        message: 'Please provide value for account_sid',
      };
    }
    if (!configurationData.api_key || configurationData.api_key === '') {
      return {
        valid: false,
        message: 'Please provide value for api_key',
      };
    }
    if (!configurationData.api_secret || configurationData.api_secret === '') {
      return {
        valid: false,
        message: 'Please provide value for api_secret',
      };
    }

    return { valid: true };
  }

  async function onSave() {
    const validateResult = validateConfiguration();
    if (!validateResult.valid) {
      errorMessage.value = validateResult.message;
      return;
    }
    const updateResult = await settingsStore.setIntegrationSettings('twilio', configurationData);
    if (!updateResult) {
      errorMessage.value = `Error updating settings`;
      return;
    }
    await settingsStore.setIntegrationEnabled('twilio', true);
  }

  function onCancel() {
    emit('canceled');
  }

  onMounted(async () => {
    const config = await settingsStore.getIntegrationSettings('twilio');
    if (!config || typeof config !== 'object') {
      return;
    }
    Object.keys(config).map((x) => {
      configurationData[x] = config[x];
    });
  });
</script>
