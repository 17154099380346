<template>
  <!--<div class="sticky grid grid-rows-2 grid-cols-1" style="grid-template-rows: auto 1fr; top: calc(4rem + 1px); height: calc(100vh - 4rem - 1px)">-->
  <!--<div class="sticky flex flex-col" style="top: calc(4rem + 1px); height: calc(100vh - 4rem - 1px)">-->
  <div class="flex flex-col">
    <!-- Secondary navigation -->
    <header class="z-10 pb-8 pt-6 sm:pb-4 sticky bg-white border-b" style="top: calc(4rem + 1px)">
      <div class="mx-auto flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
        <h1 class="text-2xl font-semibold leading-7 text-gray-900">{{ specData.name }}</h1>
        <div class="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
          <div>
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Select a tab</label>
              <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
              <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
              </select>
            </div>
            <div class="hidden sm:block">
              <nav class="flex space-x-4" aria-label="Tabs">
                <button
                  v-for="tab in tabs"
                  :key="tab.name"
                  :href="tab.href"
                  :class="[tab.name === currentTab ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium']"
                  @click="onSelectTab(tab.name)"
                >
                  {{ tab.name }}
                </button>
              </nav>
            </div>
          </div>

          <!--<a v-for="item in secondaryNavigation" :key="item.name" :href="item.href" :class="item.current ? 'text-indigo-600' : 'text-gray-700'">{{ item.name }}</a>-->
        </div>
        <div class="ml-auto flex">
          <!--<button type="button" class="mr-2 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="onExecute">
            <PlayIcon class="-ml-0.5 mr-1.5 h-5 w-5 text-green-700" aria-hidden="true" />
            Execute
          </button>-->
          <button
            type="button"
            class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="onSave"
          >
            <CheckIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Save
          </button>
        </div>
      </div>
      <div class="ml-8 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
        <div class="mt-4 flex items-center text-sm text-gray-500">
          <HashtagIcon class="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
          {{ specData.id }}
        </div>
        <div class="mt-4 flex items-center text-sm text-gray-500">
          <mdicon name="motion-play-outline" size="18" class="mr-1.5"></mdicon>
          {{ specData.media }}
        </div>
        <!--<div class="mt-4 flex items-center text-sm text-gray-500">
            <PhoneArrowUpRightIcon class="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
            ANI:{{ testANI }}
          </div>
          <div class="mt-4 flex items-center text-sm text-gray-500">
            <PhoneArrowDownLeftIcon class="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
            DNIS:{{ testDNIS }}
          </div>-->
      </div>
    </header>

    <div class="flex-auto z-0 m-2 p-2">
      <div v-if="isTabVisible('Settings')" :class="isTabVisible('Settings') ? '' : 'md:hidden'" class="mx-4">
        <!--<SpecMediaSelector v-model="specData.media"></SpecMediaSelector>-->
        <SpecSettings :spec-data="specData" @update:spec-prop="onSpecPropUpdate"></SpecSettings>
        <div class="z-10 pb-8 pt-6 sm:pb-4 bg-white border-b">
          <div class="mx-auto flex flex-wrap items-center gap-6 sm:flex-nowrap">
            <div class="ml-auto flex">
              <button
                type="button"
                class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                @click="onSelectTab('Steps')"
              >
                <ChevronDoubleRightIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                Define Steps
              </button>
            </div>
          </div>
        </div>
      </div>
      <spec-script-editor-2
        v-if="isTabVisible('Steps')"
        :class="isTabVisible('Steps') ? '' : 'md:hidden'"
        :media="specMedia"
        :actions="specScriptActions"
        @select="onActionSelect"
        @add-action="onActionAdd"
        @move-action="onActionMove"
        @action-form-active="onActionFormActive"
        @delete-step="onDeleteStep"
        @update:prop-value="onActionUpdatePropValue"
      ></spec-script-editor-2>
    </div>
  </div>
</template>

<script setup>
  import { PlayIcon, CheckIcon, ChevronDoubleRightIcon, HashtagIcon } from '@heroicons/vue/20/solid';
  import { watch, ref, reactive, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useBreakpoint } from '@/hooks/useBreakpoint';
  import { pathOr } from 'ramda';
  import SpecScriptEditor2 from '@/components/Tests/SpecScriptEditor2.vue';
  import { getActionDef } from '@/spec/ActionDefs.js';
  import { useToast } from 'vue-toastification';
  import { nanoid } from 'nanoid';
  import api from '@/api/api';
  import SpecMediaSelector from '@/components/Tests/SpecMediaSelector.vue';
  import SpecSettings from '@/components/Tests/SpecSettings.vue';

  const props = defineProps({
    spec: {
      type: Object,
      default() {
        return {};
      },
    },
  });
  const emit = defineEmits(['save']);

  const layout = useLayoutStore();
  const { breakpoints } = useBreakpoint();

  // Navigation
  const tabs = [
    { name: 'Settings', href: '#', current: true },
    { name: 'Steps', href: '#', current: false },
  ];
  const currentTab = ref(tabs[0].name);
  function onSelectTab(tabName) {
    currentTab.value = tabName;
  }

  function isTabVisible(tabName) {
    if (layout.print || ['xs', 'sm', 'md'].includes(breakpoints.is)) {
      return true;
    }
    return currentTab.value === tabName;
  }

  // Make a full copy of the spec, and make it reactive
  const specData = reactive(JSON.parse(JSON.stringify(props.spec)));
  // TODO Move to function Normalize spec, i.e. add empty steps, etc
  if (!('id' in specData) || !specData.id) {
    specData.id = nanoid(10);
  }
  if (!('media' in specData)) {
    specData.media = 'recording';
  }
  if (!('script' in specData)) {
    specData.script = {
      type: 'sequence',
      version: 1,
      steps: [],
    };
  }
  if (!Array.isArray(specData.script.steps)) {
    specData.script.steps = [];
  }

  const router = useRouter();
  const toast = useToast();

  const specId = computed(() => specData?.id);

  const specMedia = computed(() => specData?.media);
  const specScriptActions = computed(() => specData?.script?.steps);

  const selectedActionIdx = ref(-1);
  //const selectedAction = computed(() => pathOr(undefined, ['script', 'steps', selectedActionIdx.value], specData));

  function onActionSelect(idx) {
    selectedActionIdx.value = idx;
    console.log(`Selected step: ${idx}`);
  }

  function onActionUpdate(idx, step) {
    specData.script.steps[idx] = step; // ???
    console.log(`Updated step: ${idx} -> ${JSON.stringify(step)}`);
  }

  function onActionAdd(actionData) {
    //specData.script.actions[idx] = step; // ???
    if (!actionData?.type) {
      console.log(`ERROR: Missing action type`);
      return;
    }
    const actionDef = getActionDef(actionData.type);
    const action = actionData;
    actionDef.props.map((p) => {
      action[p.name] = p.default;
    });
    if (!Array.isArray(specData.script.steps)) {
      specData.script.steps = [];
    }
    // Open editor by default on added actions
    action._active = true;
    const addIdx = actionData.idx;
    if (addIdx >= 0 && addIdx < specData.script.steps.length) {
      if (addIdx === 0) {
        specData.script.steps.unshift(action);
      } else {
        specData.script.steps.splice(addIdx, 0, action);
      }
    } else {
      specData.script.steps.push(action);
    }
    console.log(`onAddAction: Added action: ${JSON.stringify(action)}`);
  }

  function onDeleteStep({ idx }) {
    if (idx >= 0 && idx < specData.script.steps.length) {
      specData.script.steps.splice(idx, 1);
    }
  }

  function onActionFormActive(idx, active) {
    specData.script.steps[idx]._active = active;
  }

  function onActionUpdatePropValue(index, prop, value) {
    //console.log(`Got onUpdatePropValue: ${index}:${prop}=${value}`);
    specData.script.steps[index][prop] = value;
  }

  const moveItemInArray = (array, from, to) => {
    const item = array.splice(from, 1)[0];
    array.splice(to, 0, item);
  };

  function onActionMove(data) {
    const { fromIndex, toIndex } = data;
    console.log(`onActionMove: Moved action: ${fromIndex} -> ${toIndex}`);
    if (fromIndex >= 0 && toIndex >= 0) {
      moveItemInArray(specData.script.steps, fromIndex, toIndex);
    }
  }

  // TODO Support nested, i.e. specData.voice.ANI
  function onSpecPropUpdate(prop, value) {
    //console.log(`onSpecPropUpdate: ${prop} - ${value}`);
    specData[prop] = value;
  }

  async function onSave() {
    emit('save', specData);
    /*
    const testId = specId.value;
    console.log(`Saving test: ${testId}`);
    // TODO Move to API
    const postTestUrl = `/api/test/${testId}`;
    fetch(postTestUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(specData),
    })
      .then((response) => {
        toast.success('Test saved successfully');
      })
      .catch((e) => {
        toast.error(`Error saving test ${e.message}`);
      });
    */
  }

  async function onExecute() {
    // TODO Save if not saved
    const testId = specId.value;
    const res = await api.executeTest(testId);
    if (res.success) {
      toast.success('Test started successfully');
      // Move to testruns
      await router.push('/analysis/testruns');
    } else {
      toast.error(`Error starting test:\n${res.message}`);
    }
  }
</script>
