<template>
  <div class="">
    <div class="max-w-xl mx-auto text-center text-3xl font-medium text-gray-800">Search Knowledge Base</div>
    <div class="max-w-xl mx-auto my-2 text-center text-md tracking-tight text-gray-500">Type a question to see what data Bot will use for an answer</div>
    <div class="max-w-3xl mx-auto p-2">
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="mb-2 col-span-full">
        <StdTagInputEditor class="mt-2" v-model="searchQueryTags" :available-tags="kbStore.kbTags" :disableAddTag="true" placeholder="Filter by tags"></StdTagInputEditor>
      </div>
      <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          v-model="searchQuery"
          class="block w-full p-4 ps-10 font-source text-md text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Search knowledge base ..."
          required
          @keyup.enter="onSearch"
        />
        <button
          class="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          @click="onSearch"
        >
          Search
        </button>
      </div>
    </div>

    <div class="max-w-6xl mx-auto p-2">
      <ul role="list" class="divide-y divide-gray-100">
        <li v-for="(doc, idx) in searchResults" :key="idx" class="gap-x-6 p-4 rounded-xl hover:bg-slate-50">
          <div class="flex items-center justify-between">
            <div class="min-w-0">
              <div class="flex items-start gap-x-3">
                <p class="text-lg font-semibold leading-6 text-gray-900">{{ doc?.docInfo?.name || doc?.metadata?.source }}</p>
                <!--<p :class="[statuses[project.status], 'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset']">{{ project.status }}</p>-->
              </div>
              <div v-if="doc?.docInfo" class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                <p class="whitespace-nowrap">
                  Last updated on
                  <time :datetime="doc?.docInfo?.updated">{{ dayjs(doc?.docInfo?.updated).format('LLL') }}</time>
                </p>
                <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                  <circle cx="1" cy="1" r="1" />
                </svg>
                <p class="truncate">By {{ doc?.docInfo?.updatedBy }}</p>
              </div>
              <div v-if="doc?.similarityScore" class="mt-1">
                <p class="text-sm font-medium leading-6 text-gray-900">
                  Similarity score:
                  <span class="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">{{ doc?.similarityScore || 0 }}</span>
                </p>
              </div>
            </div>
            <div class="flex flex-none items-center gap-x-4">
              <!--<div class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Edit</div>-->
              <button
                class="flex items-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-2 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                @click="onEdit(doc)"
              >
                <mdicon name="text-box-edit-outline" size="20" class="text-gray-300 mr-2" aria-hidden="true"></mdicon>
                Edit
              </button>
            </div>
          </div>
          <!--<div class="prose font-source" v-html="md.mdToHtml(doc?.pageContent || '')"></div>-->
          <div class="mt-2">
            <Textarea
              :value="doc?.pageContent || ''"
              :unstyled="false"
              autoResize
              readonly
              :pt="{
                root: {
                  class: 'font-mono text-md w-full bg-slate-50',
                },
              }"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAuthStore } from '@/store/AuthStore';
  import { useKbStore } from '@/store/KbStore';
  import { useApi } from '@/hooks/useApi';
  import { useProgress } from '@/hooks/useProgress';
  import { useToast } from 'vue-toastification';
  import { useMd } from '@/hooks/useMd';
  import Textarea from 'primevue/textarea';
  import Button from 'primevue/button';
  import StdTagInputEditor from '@/components/Editors/StdTagInputEditor.vue';
  import dayjs from 'dayjs';
  import timezone from 'dayjs/plugin/timezone';
  import localizedFormat from 'dayjs/plugin/localizedFormat';

  dayjs.extend(localizedFormat);
  dayjs.extend(timezone);

  const router = useRouter();
  const authStore = useAuthStore();
  const kbStore = useKbStore();
  const api = useApi();
  const toast = useToast();
  const progress = useProgress();
  const md = useMd();

  const searchQuery = ref(null);
  const searchQueryTags = ref([]);
  const searchResults = ref([]);

  async function onSearch() {
    progress.startProgress({ blockUI: true, spinner: true });
    const searchResponse = await api.searchKb({ kbId: kbStore.kbId, query: searchQuery.value, tags: searchQueryTags.value });
    progress.stopProgress();
    if (!searchResponse.success) {
      toast.error(`Error searching KB: ${searchResponse.error}`);
      searchResults.value = [];
    }
    if (Array.isArray(searchResponse.data) && searchResponse.data.length > 0) {
      // Get document info for each result - by metadata
      searchResults.value = searchResponse.data.map((x) =>
        Object.assign({}, x, {
          docInfo: kbStore.getDocumentInfoByMetadata(x?.metadata || {}),
        }),
      );
    } else {
      searchResults.value = [];
    }
  }

  function onEdit(doc) {
    const id = doc?.docInfo?.id || null;
    if (!id) {
      return;
    }
    router.push(`/kb/editor/${kbStore.kbId}/document/${id}`);
  }
</script>
