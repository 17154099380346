<template>
  <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
    <div class="px-4 sm:px-0 relative">
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Collect Data</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Collect Data from User</p>
      </div>
    </div>
    <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
      <TabsResponsive class="mx-1 my-1" v-model:tabs="tabs" v-model:show-all="showAllTabsContent"></TabsResponsive>
      <div class="px-4 py-4">
        <h2 v-if="showAllTabsContent" class="text-xl font-semibold leading-7 text-gray-600 border-b border-gray-300 py-2">Data</h2>
        <div v-if="tabs[0].active" class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <InputTextarea
            class="col-span-full"
            name="startupFormTitle"
            v-model="contactFormTitle"
            :label="propsDefs['contactForm.title'].title"
            :description="propsDefs['contactForm.title'].description"
            :placeholder="propsDefs['contactForm.title'].placeholder"
            :rows="1"
          ></InputTextarea>
          <StdPropArrayEditor
            class="col-span-full"
            v-model="contactFormFields"
            :label="propsDefs['contactForm.fields'].title"
            :description="propsDefs['contactForm.fields'].description"
            :propertiesSpec="collectDataFieldProps"
          >
          </StdPropArrayEditor>

          <!--<ToggleGroup disabled class="col-span-full" v-model="collectContactMakeForm" :name="propsDefs['collectContactMakeForm'].title" :description="propsDefs['collectContactMakeForm'].description">
            <div class="mt-2 col-span-full">
              <ToggleGroup
                class="mt-4 col-span-full"
                v-model="collectContactShowOnStartup"
                :name="propsDefs['collectContactShowOnStartup'].title"
                :description="propsDefs['collectContactShowOnStartup'].description"
              ></ToggleGroup>
            </div>
          </ToggleGroup>-->
          <ToggleGroup class="col-span-full" v-model="collectContactShowOnStartup" :name="propsDefs['collectContactShowOnStartup'].title" :description="propsDefs['collectContactShowOnStartup'].description"></ToggleGroup>
          <ToggleGroup class="col-span-full" v-model="collectContactMakeForm" :name="propsDefs['collectContactMakeForm'].title" :description="propsDefs['collectContactMakeForm'].description"></ToggleGroup>
        </div>
        <h2 v-if="showAllTabsContent" class="text-xl font-semibold leading-7 text-gray-600 border-b border-gray-300 py-2">Guidelines</h2>
        <div v-if="tabs[1].active" class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <InputTextarea
            class="col-span-full"
            name="startupFormTitle"
            v-model="collectDataGuidelines"
            :label="propsDefs['collectDataGuidelines'].title"
            :description="propsDefs['collectDataGuidelines'].description"
            :rows="10"
          ></InputTextarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import InputTextarea from '@/components/Bots/editors/InputTextarea.vue';
  import ToggleGroup from '@/components/Toggles/ToggleGroup.vue';
  import TabsResponsive from '@/components/Tabs/TabsResponsive.vue';
  import { standardBotPropsByName } from '@/spec/BotTypeDefs';
  import { useBotsStore } from '@/store/BotsStore';
  import { computed, ref } from 'vue';
  import StdPropArrayEditor from '@/components/Editors/StdPropArrayEditor.vue';
  import BotConversationTranscript from '@/components/Bots/analytics/BotConversationTranscript.vue';
  import BotConversationSummary from '@/components/Bots/analytics/BotConversationSummary.vue';

  const botsStore = useBotsStore();

  const tabs = ref([
    { name: 'Data to Collect', href: '#', icon: 'format-list-text', active: true, hidden: false },
    { name: 'Guidelines', href: '#', icon: 'file-document-check-outline', active: false, hidden: false },
  ]);
  const tabsHidden = computed(() => [false, false]);
  const showAllTabsContent = ref(false);

  const collectDataFieldProps = [
    { name: 'name', type: 'string', title: 'Name', default: '' },
    { name: 'title', type: 'string', title: 'Title', default: '' },
    { name: 'type', type: 'select', title: 'Type', options: ['Text', 'Email Address', 'Phone Number', 'Select'], values: ['string', 'email', 'phone', 'select'], default: 'string' },
    { name: 'options', type: 'stringLong', title: 'Options', default: '', when: (i) => i.type === 'select' },
    { name: 'required', type: 'boolean', title: 'Required', default: 'false' },
    //{ name: 'description', type: 'stringLong', title: 'Description', default: '' },
  ];

  const propsDefs = standardBotPropsByName;

  const contactFormTitle = computed(botsStore.getPropGetSet('contactForm.title'));
  const contactFormFields = computed(botsStore.getPropGetSet('contactForm.fields'));
  const collectContactMakeForm = computed(botsStore.getPropGetSet('collectContactMakeForm'));
  const collectContactShowOnStartup = computed(botsStore.getPropGetSet('collectContactShowOnStartup'));
  const collectDataGuidelines = computed(botsStore.getPropGetSet('collectDataGuidelines'));
</script>
