/*
 * Filters Store
 *
 * TODO Consider making composable for different types of filters
 *  https://stackoverflow.com/questions/76928581/extending-or-composing-pinia-setup-stores-for-reusable-getters-setters-and-acti
 * */
import { defineStore } from 'pinia';
import { ref, reactive, computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useApi } from '@/hooks/useApi';
import { useAnalyticsStore } from '@/store/AnalyticsStore';
import { AnalyticsQuery } from '@/analytics/AnalyticsQuery';
import { useDateRangeStore } from '@/store/DaterangeStore';
import { FilterDef } from '@/analytics/Filters';

export const useFiltersStore = defineStore('filtersStore', () => {
  const authStore = useAuthStore();
  const tenantId = computed(() => authStore.tenantId);

  const analyticsStore = useAnalyticsStore();
  const dateRangeStore = useDateRangeStore();
  const api = useApi();

  // Scope is index prefix. Filter Store will work on scope which is currently set.
  // All filters are stored under scope.
  const currentScope = ref('conv');

  // All available filters
  const allFilters = reactive({});
  // All filters in current scope
  const activeFilters = computed(() => Object.values(allFilters[currentScope.value] || {}));
  // All filters in current scope with value set
  const setFilters = computed(() => Object.values(allFilters[currentScope.value] || {}).filter((f) => f.hasValue()));

  function initFilters(scope, filtersList = []) {
    if (!Array.isArray(filtersList)) {
      return;
    }
    if (!(scope in allFilters)) {
      allFilters[scope] = {};
    }
    currentScope.value = scope;
    const filtersInScope = allFilters[scope];
    filtersList.map((x) => {
      if (x.field in filtersInScope) {
        filtersInScope[x.field].update(x);
      } else {
        filtersInScope[x.field] = new FilterDef(x);
      }
    });
  }

  // TODO
  //  - set index prefix explicitly based on scope
  //  - consider: apply all other filters which are set
  //  - consider: sub-field, like Number and NumberName for better visual representation
  async function getFieldTerms(fieldName) {
    const q = AnalyticsQuery.getFieldTermsQuery(fieldName, dateRangeStore.timeInterval, null, 100, currentScope.value);
    await analyticsStore.executeAnalyticsQuery(q);
    let terms = [];
    if (q.success) {
      terms = (q?.result?.aggregations?.terms?.buckets || []).map((x) => x.key);
    }
    return terms;
  }

  function getFilter(fieldName) {
    return (allFilters[currentScope.value] || {})[fieldName] || null;
  }

  function setFilterValue(fieldName, value) {
    const f = getFilter(fieldName);
    if (f) {
      f.value = Array.isArray(value) ? value : [value];
    }
  }

  function setRangeFilterValue(fieldName, op, value) {
    const f = getFilter(fieldName);
    if (f) {
      f.op = op;
      f.value = value;
    }
  }

  function setFilterOp(fieldName, op) {
    const f = getFilter(fieldName);
    if (f) {
      f.op = op;
    }
  }

  function clearFilterValue(fieldName) {
    const f = getFilter(fieldName);
    if (f) {
      f.value = Array.isArray(f.value) ? [] : null;
    }
  }

  function setFilterEnabled(fieldName, enabled) {
    const f = getFilter(fieldName);
    if (f) {
      f.enabled = enabled;
    }
  }

  function toElasticFilters() {
    const elasticFilters = [];
    setFilters.value.map((f) => {
      if (f instanceof FilterDef) {
        const ef = f.toElastic();
        if (ef) {
          elasticFilters.push(ef);
        }
      }
    });
    return elasticFilters;
  }

  return {
    currentScope,
    allFilters,
    activeFilters,
    setFilters,
    initFilters,
    getFilter,
    getFieldTerms,
    setFilterValue,
    setRangeFilterValue,
    clearFilterValue,
    setFilterOp,
    setFilterEnabled,
    toElasticFilters,
  };
});
