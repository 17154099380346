<template>
  <div class="">
    <div class="relative isolate overflow-hidden">
      <dl class="grid grid-cols-1 gap-2 lg:grid-cols-2 xl:grid-cols-4 mt-2 py-2">
        <div v-for="stat in stats" :key="stat.name" :class="['bg-slate-50 shadow relative overflow-hidden rounded-xl px-4 pt-4 pb-2 m-1 min-h-[120px]', stat?.class || '']">
          <div class="z-20 relative">
            <dt class="truncate text-sm font-medium text-gray-500">{{ stat.name }}</dt>
            <dd class="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{{ stat.value }}</dd>
            <dd class="mt-2 text-sm text-gray-400 font-medium">{{ stat?.description || '' }}</dd>
            <dd v-if="stat?.extraDescription" class="mt-2 text-xs text-gray-400 font-medium">{{ stat?.extraDescription || '' }}</dd>
          </div>
          <db-easy-pie
            v-if="stat.gauge"
            class="z-10 absolute right-1 top-1 h-28 w-28 rounded-full text-lg font-semibold text-slate-500"
            :value="stat.value"
            :max="stat.max"
            :line-width="8"
            :animated="true"
            :percent-ranges="stat?.percentRanges || []"
            :show-percents="stat.percents || false"
          ></db-easy-pie>
          <mdicon v-else-if="stat.icon" :name="stat.icon" size="105" :class="['z-10 absolute right-1 top-1 rounded-full', stat.iconClass || '']" />
        </div>
      </dl>
    </div>

    <div v-if="conversationSummary && conversationSummary !== ''" class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded bg-violet-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Conversation Summary</h2>
      </div>
      <div class="my-2 p-4 bg-violet-50 rounded-md">
        <div class="prose font-source text-lg text-slate-600" v-html="conversationSummary"></div>
      </div>
    </div>

    <div v-if="sentimentExplanation && sentimentExplanation !== ''" class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded bg-violet-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Customer Sentiment: {{ customerSentiment }}</h2>
      </div>
      <div class="my-2 p-4 bg-violet-50 rounded-md">
        <div class="prose font-source text-lg text-slate-600" v-html="sentimentExplanation"></div>
      </div>
    </div>

    <div class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded bg-violet-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">CSat: {{ conversationCSat }}</h2>
      </div>
      <div class="my-2 p-4 bg-violet-50 rounded-md">
        <div class="prose font-source text-lg text-slate-600" v-html="csatExplanation"></div>
      </div>
    </div>

    <div class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded bg-violet-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Conversation Details</h2>
      </div>
      <div class="p-2">
        <ConversationMetadata :conversation="conversation"></ConversationMetadata>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useMd } from '@/hooks/useMd';
  import DbEasyPie from '@/components/Charts/DbEasyPie.vue';
  import ConversationMetadata from '@/components/Insights/ConversationMetadata.vue';
  import dayjs from 'dayjs';

  const props = defineProps({
    conversation: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  const md = useMd();

  //const conversationId = computed(() => props.conversation?.id || '');
  const conversationDuration = computed(() => props.conversation?.context?.transcript?.metadata?.duration || 0);
  const conversationDatetime = computed(() => props.conversation?.datetime || '');

  const hasKPI = computed(() => props.conversation.context?.analysis?.kpis !== undefined);
  const hasProblems = computed(() => props.conversation.context?.analysis?.problems !== undefined);
  const hasSentiment = computed(() => props.conversation.context?.analysis?.sentiment !== undefined);
  const hasScore = computed(() => hasKPI.value || hasProblems.value || hasSentiment.value);

  const conversationScore = computed(() => props.conversation?.score || 0);

  const conversationCSat = computed(() => props.conversation?.CSat || 0);

  const sumKPIsScore = computed(() => (props.conversation.context?.analysis?.kpis || []).reduce((sum, v) => sum + parseInt(v?.score || 0), 0));
  const totalKPIsScore = computed(() => (props.conversation.context?.analysis?.kpis || []).length * 10);
  const sumProblemsScore = computed(() => (props.conversation.context?.analysis?.problems || []).reduce((sum, v) => sum + parseInt(v?.severity || v?.importance || 0), 0));
  const totalProblemsScore = computed(() => (props.conversation.context?.analysis?.problems || []).length * 10);

  const conversationSummary = computed(() => md.mdToHtml(props.conversation.context?.analysis?.summary || ''));

  //const customerSentiment = computed(() => -10);
  const customerSentiment = computed(() => props.conversation.context?.analysis?.sentiment?.customerSentiment || 0);

  const sentimentExplanation = computed(() => md.mdToHtml(props.conversation.context?.analysis?.sentiment?.explanation || ''));

  const sentimentIcon = computed(() => (customerSentiment.value < 0 ? 'emoticon-angry-outline' : customerSentiment.value > 0 ? 'emoticon-happy-outline' : 'emoticon-neutral-outline'));

  const conversationTranscript = computed(() => props.conversation?.context?.transcript || {});
  const conversationTranscriptMetadata = computed(() => conversationTranscript.value?.metadata || {});

  const scenarioId = computed(() => props.conversation.scenario?.id || '');
  const scenarioName = computed(() => props.conversation.scenario?.name || '');
  const scenarioMedia = computed(() => props.conversation.scenario?.media || '');
  const analysisProblems = computed(() => props.conversation.context?.analysis?.problems || []);
  const analysisKPIs = computed(() => props.conversation.context?.analysis?.kpis || []);
  const analysisDetected = computed(() => props.conversation.context?.analysis?.detected || []);

  const csatExplanation = computed(() => md.mdToHtml(props.conversation?.CSatExplanation || ''));

  function formatSeconds(sec) {
    const diff = sec;
    const totalMinutes = Math.floor(diff / 60);
    const seconds = diff % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0
      ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      : `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  const allStats = computed(() => [
    {
      name: 'Date',
      value: dayjs(props.conversation?.datetime || 0).format('MM/DD/YYYY hh:mm:ss'),
      icon: 'clock-outline',
      description: `Duration: ${formatSeconds(props.conversation?.duration || 0)}`,
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
      show: true,
    },
    {
      name: 'Number',
      value: props.conversation?.Number || '',
      description: props.conversation?.NumberName || '',
      icon: 'phone-dial',
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
      show: true,
    },
    {
      name: 'Agent',
      value: props.conversation?.AgentName || props.conversation?.AgentId,
      description: `Agent ID: ${props.conversation?.AgentId}`,
      icon: 'face-agent',
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
      show: true,
    },
    {
      name: 'CSat',
      value: conversationCSat.value,
      max: 10,
      description: 'Out of 10',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 50, color: '#fde047' },
        { value: 75, color: '#bef264' },
      ],
      show: true,
    },
    {
      name: 'Score',
      value: conversationScore.value,
      icon: 'finance',
      iconClass: 'text-gray-200/60',
      description: 'Overall Conversation Score',
      extraDescription: `Higher is better`,
      show: hasScore.value,
    },
    {
      name: 'Sentiment',
      value: customerSentiment.value,
      icon: sentimentIcon.value,
      iconClass: customerSentiment.value < 0 ? 'text-red-500/70' : customerSentiment.value > 0 ? 'text-green-500/70' : 'text-gray-200/60',
      description: `From -100 to 100`,
      extraDescription: `-100 is highly negative, 100 is highly positive`,
      show: hasSentiment.value,
    },
    {
      name: 'Problems Severity',
      value: sumProblemsScore.value,
      max: 100, // totalProblemsScore.value,
      description: `Smaller is better`,
      extraDescription: '', //`Out of ${totalProblemsScore.value}`,
      gauge: true,
      percents: false,
      percentRanges: [
        { value: 0, color: '#bef264' },
        { value: 25, color: '#fde047' },
        { value: 50, color: '#fca5a5' },
      ],
      show: hasProblems.value,
    },
    {
      name: 'KPI Score',
      value: sumKPIsScore.value,
      max: totalKPIsScore.value,
      description: 'Higher is better',
      extraDescription: `Out of ${totalKPIsScore.value} (${totalKPIsScore.value / 10} KPIs)`,
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 50, color: '#fde047' },
        { value: 75, color: '#bef264' },
      ],
      show: hasKPI.value,
    },
    /*
    {
      name: 'Sentiment',
      value: customerSentiment.value,
      icon: sentimentIcon.value,
      iconClass: customerSentiment.value < 0 ? 'text-red-500/70' : customerSentiment.value > 0 ? 'text-green-500/70' : 'text-gray-200/60',
      description: `From -100 to 100`,
      extraDescription: `-100 is highly negative, 100 is highly positive`,
    },
    */
    /*
    {
      name: 'Problems Severity',
      value: sumProblemsScore.value,
      max: 100, // totalProblemsScore.value,
      description: `Smaller is better`,
      extraDescription: '', //`Out of ${totalProblemsScore.value}`,
      gauge: true,
      percents: false,
      percentRanges: [
        { value: 0, color: '#bef264' },
        { value: 25, color: '#fde047' },
        { value: 50, color: '#fca5a5' },
      ],
    },*/
  ]);
  const stats = computed(() => allStats.value.filter((x) => x.show));
</script>
