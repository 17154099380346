<template>
  <!-- See https://web.dev/one-line-layouts/ -->
  <div class="antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900">
    <page-header></page-header>
    <progress-bar :progress="layoutStore.progress"></progress-bar>
    <div class="grid grid-cols-2 grid-rows-1 min-h-0 min-w-0" style="grid-template-columns: auto 1fr">
      <!--<div v-if="true" class="bg-blue-300 sticky" style="top: 4rem; height: calc(100vh - 4rem)">-->
      <!-- 1px here is to account for border of navbar -->
      <!-- Sidebar -->
      <sidebar class="bg-green-200 h-full sticky" style="overflow-y: auto; top: 4rem; height: calc(100vh - 4rem - 1px)"></sidebar>

      <router-view class="h-full" />
    </div>
  </div>
</template>
<script setup>
  import PageHeader from '@/components/Navbars/PageHeader.vue';
  import Sidebar from '@/components/Sidebar/Sidebar.vue';
  import ProgressBar from '@/components/Progress.vue';
  import { useLayoutStore } from '@/store/LayoutStore';
  const layoutStore = useLayoutStore();
</script>
