<template>
  <div>
    <!--<label :for="propDef?.name" class="block text-sm font-medium leading-6 text-gray-900">{{ propDef?.title }}</label>
    <div class="text-sm" v-if="propDef?.description">{{ propDef?.description }}</div>-->

    <div v-for="(item, idx) in arrayValue" :key="idx" class="relative grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6 md:col-span-2 pb-2 border-b">
      <SpecScriptActionProperty2
        v-for="prop in entryProperties"
        :key="prop.name"
        :prop-def="prop"
        :class="[getPropLayoutClasses(prop)]"
        :model-value="item[prop.name]"
        @update:modelValue="
          (newValue) => {
            onUpdateProp(prop, idx, newValue);
          }
        "
      />
      <button
        v-if="idx > 0"
        type="button"
        @click="onDeleteItem(idx)"
        class="absolute top-2 right-0 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Delete
      </button>
    </div>
    <div class="flex justify-end mt-2">
      <button
        type="button"
        @click="onAddItem"
        class="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Add
      </button>
    </div>
  </div>
</template>

<script setup>
  import { computed, reactive } from 'vue';
  import SpecScriptActionProperty2 from '@/components/Tests/SpecScriptActionProperty2.vue';
  import { getActionDef } from '@/spec/ActionDefs.js';

  const props = defineProps({
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
    propDef: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  const emit = defineEmits(['update:modelValue']);

  const entryProperties = computed(() => props.propDef.properties);

  const arrayValue = reactive(JSON.parse(JSON.stringify(props.modelValue)));

  function onUpdateProp(prop, idx, newValue) {
    console.log(`onUpdateProp: ${prop.name} ${idx} ${newValue}`);
    let v = newValue;
    if (typeof prop.processValue === 'function') {
      v = prop.processValue(newValue);
      console.log(`Changed: "${newValue}" => "${v}"`);
    }
    arrayValue[idx][prop.name] = v;
    emit('update:modelValue', arrayValue);
  }

  function onAddItem() {
    const newItem = {};
    entryProperties.value.map((x) => {
      newItem[x.name] = x.default;
    });
    arrayValue.push(newItem);
    emit('update:modelValue', arrayValue);
  }

  function onDeleteItem(idx) {
    arrayValue.splice(idx, 1);
    emit('update:modelValue', arrayValue);
  }

  // TODO make common
  const propLayoutClassesByType = {
    text: 'col-span-full',
    boolean: 'col-span-full',
    number: 'sm:col-span-full md:col-span-full lg:col-span-3 xl:col-span-2',
    string: 'sm:col-span-full md:col-span-full lg:col-span-3 xl:col-span-2',
    stringLong: 'sm:col-span-full md:col-span-3',
    audioFile: 'col-span-full',
    transcriptsCollection: 'col-span-full',
    recordingsCollection: 'col-span-full',
    kpiCollection: 'col-span-full',
    temperature: 'col-span-full',
  };

  function getPropLayoutClasses(prop) {
    return propLayoutClassesByType[prop?.type] || '';
  }

  const value = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });
</script>
