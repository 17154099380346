<template>
  <div class="">
    <div class="relative bg-white">
      <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div class="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-28 xl:col-span-6">
          <div class="mx-auto max-w-2xl lg:mx-0">
            <div class="rounded-md flex items-center text-green-500">
              <div class="text-3xl text-gray-900 font-bold">Test your Assistant</div>
            </div>
            <div class="my-12 text-2xl tracking-tight text-gray-500">Use Clear Conversation button<br />to test a new conversation<br />as if with a different visitor</div>
            <button
              type="button"
              class="rounded-md bg-green-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-green-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500"
              @click="clearBotConversation"
            >
              Clear Conversation
            </button>
          </div>
        </div>
        <div class="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img class="aspect-[3/2] w-full opacity-60 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full" src="/img/whirl.svg" alt="" />
        </div>
      </div>
    </div>
    <enegelai-bot class="" v-if="botAvailable" ref="botWidget" :name="botsStore.botName" :url="botServiceURL" :org-id="botOrgId" :bot-id="botsStore.botId"></enegelai-bot>
  </div>
</template>

<script setup>
  import { ref, computed, watch, onMounted, nextTick } from 'vue';
  import { useAuthStore } from '@/store/AuthStore';
  import { useBotsStore } from '@/store/BotsStore';

  const botWidget = ref(null);

  const authStore = useAuthStore();
  const botsStore = useBotsStore();

  const botOrgId = computed(() => authStore.tenantId);

  const botServiceUrls = {
    'app.enegel.ai': 'bot-service.enegel.ai',
    'stage.enegel.ai': 'bots-stage.enegel.ai',
  };

  const botServiceURL = computed(() => {
    const host = window.location.host;
    if (host in botServiceUrls) {
      return botServiceUrls[host];
    }
    return 'localhost:3070';
  });

  //const botAvailable = computed(() => botsStore.botId && botsStore.botId !== null);
  const botAvailable = ref(false);

  watch(
    () => botsStore.botId,
    async () => {
      initBot();
    },
  );

  function clearBotConversation() {
    const chatBot = botWidget.value;
    if (chatBot) {
      chatBot.clearConversation();
    }
  }

  async function initBot() {
    if (botsStore.botId) {
      botAvailable.value = true;
      await nextTick();
      const storedBotId = localStorage.getItem('c7o.bot.test.botId');
      console.log(`Got c7o.bot.test.botId = ${storedBotId}`);
      if (!storedBotId) {
        localStorage.setItem('c7o.bot.test.botId', botsStore.botId);
      } else if (storedBotId !== botsStore.botId) {
        // Clear conversation if botId has changed
        localStorage.setItem('c7o.bot.test.botId', botsStore.botId);
        clearBotConversation();
        console.log(`Bot under test changed: c7o.bot.test.botId changed to ${botsStore.botId}`);
      }
    }
  }

  onMounted(() => {
    initBot();
  });
</script>
<style>
  enegelai-bot {
    z-index: 1000;
    --enegelai-bot-max-height: 700px;
    --enegelai-bot-height-top-margin: 220px;
  }
</style>
