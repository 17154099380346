<template>
  <div>
    <div class="border-b py-2 text-xl font-bold leading-7 text-gray-500 sm:truncate sm:text-xl sm:tracking-tight mb-4">Transcript</div>

    <test-result-recording v-if="!layout.print" :file="testResult.transcriptRecording"></test-result-recording>

    <div class="flow-root my-4 font-source">
      <ul role="list" class="-mb-8">
        <li v-for="(transcriptItem, transcriptItemIdx) in testResult.transcriptData" :key="transcriptItem.ts">
          <div class="relative pb-6">
            <span v-if="transcriptItemIdx !== testResult.transcript.length - 1" class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            <div class="relative flex items-start space-x-3">
              <mdicon
                :name="transcriptItem.actor === 'Bot' ? 'cog' : transcriptItem.actor === 'Agent' ? 'face-agent' : 'account'"
                size="26"
                class="flex h-10 w-10 items-center justify-center rounded-full bg-slate-100 ring-8 ring-white"
              />
              <div class="min-w-0 flex-1">
                <div>
                  <div class="text-sm">
                    {{ transcriptItem.actor }}
                  </div>
                  <!--<p class="mt-0.5 text-sm text-gray-500">{{ formatEntryTime(transcriptItem.ts) }}</p>-->
                </div>
                <div class="mt-2 text-md text-gray-700">
                  {{ transcriptItem.message }}
                </div>
              </div>
              <!--<template v-else-if="transcriptItem.actor === 'System'">
              <template v-else-if="false">
                <div>
                  <div class="relative px-1">
                    <div class="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                      <UserCircleIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
                    </div>
                  </div>
                </div>
                <div class="min-w-0 flex-1 py-1.5">
                  <div class="text-sm text-gray-500">
                    {{ transcriptItem.actor }}
                    {{ ' ' }}
                    {{ transcriptItem.type }}
                  </div>
                </div>
              </template>-->
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, computed } from 'vue';
  import TestResultRecording from '@/components/TestResult/TestResultRecording.vue';
  import { useTestResultsStore } from '@/store/TestResultsStore';
  import { useLayoutStore } from '@/store/LayoutStore';

  const testResult = useTestResultsStore();
  const layout = useLayoutStore();

  //const transcriptData = computed(() => testResult?.context?.transcript?.data || []);
  //const recording = computed(() => testResult?.context?.transcript?.recording || null);

  // TEMP
  const firstTimestamp = 1682191578905;

  function formatEntryTime(ts) {
    const diff = Math.floor((ts - firstTimestamp) / 1000); // it's in ms
    const totalMinutes = Math.floor(diff / 60);
    const seconds = diff % 60;
    //const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${minutes}:${seconds}`;
  }

  onMounted(() => {
    console.log(`Transcript mounted`);
  });
</script>
