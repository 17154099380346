<template>
  <!--<div class="h-[500px] w-full">
    <div class="h-full w-full">
      <DbDygraphsBar class="relative mt-4 h-[300px] bg-slate-800 rounded-xl p-4" :data="chartData.dthData2"></DbDygraphsBar>
    </div>
  </div>-->
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-start space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-purple-600/70 rounded-xl p-1">
            <mdicon name="finance" size="60" class="text-purple-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="pt-1.5">
          <h1 class="text-2xl font-bold text-gray-900">Dashboard</h1>
          <p class="text-sm font-medium text-gray-500">One</p>
        </div>
      </div>

      <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <button
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="loadData"
        >
          <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
          Load
        </button>
      </div>
    </div>

    <div class="pt-4 pb-4 border-b">
      <div class="sm:hidden">
        <label for="tabs" class="sr-only">Select a tab</label>
        <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
          <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
        </select>
      </div>
      <div class="hidden sm:block">
        <nav class="flex space-x-4" aria-label="Tabs">
          <button
            v-for="tab in tabs"
            :key="tab.name"
            :href="tab.href"
            :class="[tab.name === currentTab ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium']"
            @click="onSelectTab(tab.name)"
          >
            {{ tab.name }}
          </button>
        </nav>
      </div>
    </div>
    <div class="w-full">
      <div class="flex text-gray-400 font-source h-[300px] w-full">
        <DbDygraphsBar class="relative mt-4 bg-slate-800 rounded-xl p-4" :data="chartData" :options="chartOptions"></DbDygraphsBar>
      </div>
    </div>
    <div class="mt-10 w-full">
      <div class="w-[300px] h-[300px]">
        <DbChartjsPie :data="pieChartData" :width="300" :height="300"></DbChartjsPie>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { ref, reactive, onMounted } from 'vue';
  import DbDygraphsBar from '@/components/Charts/DbDygraphsBar.vue';
  import { DbChartjsPie, DbChartjsBar } from '@/components/Charts/DbChartjs';
  import { ArrowDownCircleIcon } from '@heroicons/vue/20/solid';
  import { useDateRangeStore } from '@/store/DaterangeStore';
  import { useAnalyticsStore } from '@/store/AnalyticsStore';
  import { AnalyticsQuery } from '@/analytics/AnalyticsQuery';

  const dateRangeStore = useDateRangeStore();
  const analyticsStore = useAnalyticsStore();

  const tabs = [];

  const chartData = ref([]);
  const chartOptions = reactive({
    stackedGraph: true,
    title: 'Problems severity',
    ylabel: 'Count',
    labels: ['Date'],
    //legend: 'follow',
  });

  const pieChartData = reactive({
    labels: [],
    datasets: [
      {
        label: 'Problems',
        data: [],
      },
    ],
  });

  // Load dashboard data
  async function loadData() {
    return loadDataPie();
    const q = AnalyticsQuery.dateHistogramQuery(dateRangeStore.timeInterval);
    await analyticsStore.executeAnalyticsQuery(q);
    if (q.success) {
      const problemsTable = q.toTable({
        'timeline.problems.problem.problemseverity': {},
      });
      if (problemsTable) {
        chartOptions.labels = problemsTable.tableHeader;

        // TEMP
        const dyTableData = problemsTable.tableData.map((row) => {
          row[0] = new Date(row[0]);
          return row;
        });

        chartData.value = dyTableData;
      }
    }
  }

  async function loadDataPie() {
    /*
    const first = pieChartData.labels.length === 0;
    pieChartData.labels = ['A', 'B']; //hh;
    pieChartData.datasets[0].data = [Math.random(), Math.random()]; //dd;
    if (!first) {
      if (pieChartData.datasets.length === 1) {
        pieChartData.datasets.push({ label: 'Test', data: [] });
      }
      pieChartData.datasets[1].data = [Math.random(), Math.random()]; //dd;
    }
    return;
    */
    const q = AnalyticsQuery.groupByQuery(dateRangeStore.timeInterval);
    await analyticsStore.executeAnalyticsQuery(q);
    const problemsTable = q.toTable({
      'problems.problem.problemseverity': {},
    });
    const pieTable = q.rotateTable(problemsTable.tableData);
    const hh = pieTable.tableHeader;
    pieChartData.labels = hh;
    const dd = pieTable.tableData[0];
    pieChartData.datasets[0].data = dd;
  }

  // TEST DATA

  const chartTestData = generateData();

  function generateData() {
    let dthData = [];
    let dthData2 = [];
    let dthData3 = [];
    let sTS = Date.now() - 100 * 3600 * 1000;

    for (let i = 0; i < 100; i++) {
      let cTs = sTS + i * 3600 * 1000;
      let d = new Date(cTs);
      let r = Math.random();
      dthData.push([d, r, r + 0.5]);
      dthData2.push([d, Math.random(), Math.random()]);
      dthData3.push(Math.random());
    }

    return {
      dthData: dthData,
      dthData2: dthData2,
      dthData3: dthData3,
    };
  }

  onMounted(() => {
    //loadData();
  });
</script>
