<template>
  <div class="h-full overflow-y-auto custom-scrollbar">
    <!--<banner-error message="ERROR: Empty script passed"></banner-error>-->
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="(item, idx) in props.actions" :key="idx" class="cursor-pointer py-1" @click="onSelectAction(idx)" :class="getClass(idx)">
        <div class="flex items-center">
          <div class="flex min-w-0 flex-1">
            <div class="shrink-0 p-2">
              <mdicon :name="actionDefs[idx].icon" size="24" class="text-slate-400" />
            </div>
            <div class="flex min-w-0 flex-1">
              <div class="w-24 flex-none bg-slate-100">
                <div class="truncate p-2 text-sm font-medium">{{ actionDefs[idx].title }}</div>
                <!--<p class="mt-2 flex items-center text-sm text-gray-500">
                  <EnvelopeIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                  <span class="truncate">OPA</span>
                </p>-->
              </div>
              <div class="ml-2 grow p-2 text-base font-semibold text-gray-700">
                {{ item.text }}
              </div>
            </div>
          </div>
          <div class="">
            <!--<ChevronRightIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />-->
            <spec-script-step-menu class="px-2"></spec-script-step-menu>
          </div>
        </div>
      </li>
      <li>
        <div class="border-b border-gray-200 bg-white px-2 py-2 sm:px-6">
          <div class="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div class="mt-4">
              <mdicon name="playlist-plus-" size="24" class="text-slate-400" />
            </div>
            <div class="ml-4 mt-4">
              <p class="mt-1 text-sm text-gray-500">Add actions to script to define the test scenario</p>
            </div>
            <div class="ml-4 mt-4 flex-shrink-0">
              <button
                type="button"
                class="relative inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                @click="onAddAction"
              >
                Add Action
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!--<banner-error v-else message="ERROR: Empty script passed"></banner-error>-->
  </div>
</template>

<script setup>
  //import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/vue/24/outline';
  import { ref, onMounted, computed } from 'vue';
  import { pathOr } from 'ramda';
  import SpecScriptStepMenu from '@/components/Spec/SpecScriptStepMenu.vue';
  import BannerError from '@/components/Banners/BannerError.vue';
  import { getActionDef } from '@/spec/ActionDefs.js';

  const props = defineProps({
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
  });

  const emit = defineEmits(['select', 'add-action']);

  const isEmpty = computed(() => !Array.isArray(props.actions) || props.actions.length <= 0);

  const actionDefs = computed(() => (isEmpty.value ? [] : props.actions.map((x) => getActionDef(x?.type))));

  // TODO Action classes array, to avoid getClass

  // Selected step
  const selectedIdx = ref(null);
  //const selectedAction = computed(() => pathOr(undefined, [selectedIdx.value], props.actions));

  function onSelectAction(idx) {
    selectedIdx.value = idx;
    console.log(`Action ${idx} selected`);
    emit('select', idx);
  }

  // todo reconsider, could be inefficient
  function getClass(idx) {
    return selectedIdx.value === idx ? ['bg-amber-100'] : [];
  }

  function onAddAction() {
    console.log(`ScriptEditor: onAddAction`);
    emit('add-action', { type: 'expect' });
  }

  onMounted(() => {
    console.log(`ScriptEditor: actions: ${JSON.stringify(props.actions)}`);
  });
</script>
