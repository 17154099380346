/*
 * Usage Store
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useToast } from 'vue-toastification';
import { useApi } from '@/hooks/useApi';
import { AnalyticsQuery } from '@/analytics/AnalyticsQuery';
import { useDateRangeStore } from '@/store/DaterangeStore';
import { useSettingsStore } from '@/store/SettingsStore';

export const useUsageStore = defineStore('usageStore', () => {
  const authStore = useAuthStore();
  const tenantId = computed(() => authStore.tenantId);
  const settingsStore = useSettingsStore();
  const toast = useToast();
  const dateRangeStore = useDateRangeStore();
  const api = useApi();

  // stored usage data
  const vocWordsData = ref([]);
  const vocWordsTotal = ref(0);
  const qaWordsData = ref([]);
  const qaWordsTotal = ref(0);

  // TODO Refine it to get usage for all products at once - sub-agg
  async function queryUsage() {
    const groupings = [
      {
        name: 'timeline',
        title: 'Date',
        type: 'date_histogram',
        interval: '1D', //dateRangeStore.timeInterval.interval,
        extended_bounds: { min: dateRangeStore.timeInterval.from, max: dateRangeStore.timeInterval.to },
        //groupings: [{ name: 'words', title: 'words', type: 'sum', field: 'words' }],
        groupings: [
          {
            name: 'product',
            type: 'terms',
            field: 'product',
            groupings: [
              { name: 'words', title: 'words', type: 'sum', field: 'words' }, // for voc & qa
              { name: 'messages', title: 'messages', type: 'sum', field: 'messages' }, // for assistants
              // TODO data usage size - for storage and analytics
            ],
          },
        ],
      },
    ];
    const filters = [
      { type: 'term', field: 'tenantId', value: tenantId.value },
      //{ type: 'term', field: 'product', value: 'voc' },
    ];
    const q = AnalyticsQuery.aggregateQuery(dateRangeStore.timeInterval, filters, groupings, 'usage', 'created');

    // [sv2] 07/06/2024 Transitioning to monthly-based indexes
    // Set indexSpan according to tenant settings todo remove when migrated to monthly indexes
    const indexSpan = await settingsStore.getElasticIndexSpan();
    q.request.indexSpan = indexSpan;

    await api.executeUsageQuery(q);
    if (!q.success) {
      vocWordsData.value = []; // reset VoC usage Data
      vocWordsTotal.value = 0;
      qaWordsData.value = []; // reset VoC usage Data
      qaWordsTotal.value = 0;
      const msg = `ERROR: Failed to get VoC Usage data, error:${q.error}`;
      console.log(msg);
      toast.error(msg);
      return;
    }
    q.normalize();
    const timeline = q?.normalized?.timeline || null;
    const vocWD = [[], []];
    let vocWT = 0;
    const qaWD = [[], []];
    let qaWT = 0;
    if (Array.isArray(timeline)) {
      for (let i = 0; i < timeline.length; i++) {
        let tb = timeline[i];
        vocWD[0].push(Math.floor(tb.key / 1000));
        qaWD[0].push(Math.floor(tb.key / 1000));
        let vocWords = 0;
        let qaWords = 0;
        if (Array.isArray(tb?.values)) {
          vocWords = (tb.values.find((x) => x.path === 'timeline.product.words' && x.key === 'voc') || { value: 0 })?.value || 0;
          qaWords = (tb.values.find((x) => x.path === 'timeline.product.words' && x.key === 'qa') || { value: 0 })?.value || 0;
        }
        vocWD[1].push(vocWords);
        qaWD[1].push(qaWords);
        vocWT += vocWords;
        qaWT += qaWords;
      }
    }

    /*
    const countsTable = q.toTable({ 'timeline.product.words': { title: 'Words' } }, ['timeline'], 'Date');
    const wordsData = [[], []];
    let wordsTotal = 0;
    for (let i = 0; i < countsTable.tableData.length; i++) {
      const row = countsTable.tableData[i];
      wordsData[0].push(Math.floor(row[0] / 1000));
      wordsData[1].push(row[1]);
      wordsTotal += row[1];
    }*/

    vocWordsData.value = vocWD;
    vocWordsTotal.value = vocWT;
    qaWordsData.value = qaWD;
    qaWordsTotal.value = qaWT;
    return q;
  }

  // Load usage data
  async function getUsage() {
    await queryUsage();
  }

  return {
    getUsage,
    vocWordsData,
    vocWordsTotal,
    qaWordsData,
    qaWordsTotal,
  };
});
