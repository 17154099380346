<template>
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-orange-600/70 rounded-xl p-1">
            <mdicon name="face-agent" size="62" class="text-orange-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-2xl font-bold text-gray-900">Agents CSat</h1>
          <div class="my-1 flex items-center">
            <div>Analyzing Agents CSat for the time period:</div>
            <TimeRangeSelector :inline="true" class="ml-2 font-semibold"></TimeRangeSelector>
          </div>
        </div>
      </div>

      <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <ProgressButton ref="analyzeProgressButton" title="Analyze" icon="motion-play-outline" @click="loadData"></ProgressButton>
      </div>
    </div>

    <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 xl:grid-cols-2 font-source">
      <div class="bg-gray-50 rounded-xl px-4 py-2">
        <div class="py-1 flex items-baseline justify-between gap-x-4 border-b">
          <div class="text-md font-semibold leading-6 text-gray-900">Agents</div>
          <div class="mr-4 text-sm flex-none font-semibold text-gray-900">{{ agentsInsightsStore.agents.length }}</div>
        </div>
        <div class="mt-2 py-1 flex items-baseline justify-between gap-x-4 border-b">
          <div class="text-md leading-6 text-gray-900">Conversations</div>
          <div class="mr-4 text-sm flex-none font-semibold text-gray-900">{{ agentsInsightsStore.conversationsCount }}</div>
        </div>
        <div class="mt-2 py-1 flex items-baseline justify-between gap-x-4">
          <div class="text-md leading-6 text-gray-900">Bottom Performers</div>
          <div class="mr-4 text-sm flex-none font-semibold text-gray-900">{{ agentsInsightsStore.agentsBottomPerformers.length }}</div>
        </div>
        <equalizer-value class="my-1" :value="agentsInsightsStore.agentsBottomPerformers.length" :max="agentsInsightsStore.agents.length" :height="10" color="#991b1b"></equalizer-value>
        <div class="mt-2 py-1 flex items-baseline justify-between gap-x-4">
          <div class="text-md leading-6 text-gray-900">Top Performers</div>
          <div class="mr-4 text-sm flex-none font-semibold text-gray-900">{{ agentsInsightsStore.agentsTopPerformers.length }}</div>
        </div>
        <equalizer-value class="my-1" :value="agentsInsightsStore.agentsTopPerformers.length" :max="agentsInsightsStore.agents.length" :height="10" color="#065f46"></equalizer-value>
      </div>
      <div class="bg-gray-50 rounded-xl px-4 py-2 min-h-[240px]">
        <DbDygraphsBar class="relative" :data="agentsInsightsStore.histogramData" :options="chartOptions"></DbDygraphsBar>
      </div>
    </div>

    <div class="mt-4 py-2 px-2 leading-7 text-gray-600 mb-4 flex items-center justify-between border-b border-gray-300">
      <div class="text-md font-semibold flex items-center">
        <mdicon name="account-eye-outline" size="18" class="rounded bg-red-600/90 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <div>Bottom Performers</div>
      </div>
      <div class="text-sm font-source">Agents who have CSat in the lowest range ({{ agentsInsightsStore.minScore }} - {{ agentsInsightsStore.minScore + agentsInsightsStore.scoreRange10pct }})</div>
    </div>
    <div class="mt-4">
      <AgentsListStats :agents="agentsInsightsStore.agentsBottomPerformers" conversationsPath="/csat/search" :fields="agentListStatsFields" :agentLink="false" sortField="score" :sortOrder="1"></AgentsListStats>
    </div>

    <div class="mt-6 py-2 px-2 leading-7 text-gray-600 mb-4 flex items-center justify-between border-b border-gray-300">
      <div class="text-md font-semibold flex items-center">
        <mdicon name="account-star" size="18" class="rounded bg-green-600/90 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <div>Top Performers</div>
      </div>
      <div class="text-sm font-source">Agents who have CSat in the highest range ({{ agentsInsightsStore.maxScore - agentsInsightsStore.scoreRange10pct }} - {{ agentsInsightsStore.maxScore }})</div>
    </div>
    <div class="mt-4">
      <AgentsListStats :agents="agentsInsightsStore.agentsTopPerformers" conversationsPath="/csat/search" :fields="agentListStatsFields" :agentLink="false" sortField="score"></AgentsListStats>
    </div>

    <div class="mt-6 py-2 px-2 leading-7 text-gray-600 mb-4 flex items-center justify-between border-b border-gray-300">
      <div class="text-md font-semibold flex items-center">
        <mdicon name="face-agent" size="18" class="rounded bg-orange-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <div>All Agents</div>
      </div>
    </div>
    <div class="mt-4">
      <AgentsListStats :agents="agentsInsightsStore.agents" conversationsPath="/csat/search" :fields="agentListStatsFields" :agentLink="false" sortField="score"></AgentsListStats>
    </div>
  </div>
</template>
<script setup>
  import { ref, computed } from 'vue';
  import { useAgentsInsightsStore } from '@/store/AgentsInsightsStore';
  import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
  import DbDygraphsBar from '@/components/Charts/DbDygraphsBar.vue';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';
  import AgentsListStats from '@/components/Insights/AgentsListStats.vue';
  import EqListCondensed from '@/components/Charts/EqListCondensed.vue';
  import EqualizerValue from '@/components/Charts/EqualizerValue.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';

  //const conversationSearchStore = useConversationSearchStore();
  const agentsInsightsStore = useAgentsInsightsStore();

  const analyzeProgressButton = ref(null);

  // Search Results Table
  //const dt = ref();
  const loading = ref(false);

  const highlight_start = 60;
  const highlight_end = 70;

  const chartOptions = computed(() => {
    return {
      stackedGraph: false,
      //title: 'Conversations',
      xlabel: 'CSat Range',
      ylabel: '# Agents',
      labels: ['Avg Score', 'Agents Count'],
      //legend: 'follow',
      underlayCallback: function (canvas, area, g) {
        const low = agentsInsightsStore?.histogramHighlightRanges?.low || null;
        if (low) {
          histogramHighlightRange(canvas, area, g, low, 'rgba(248, 113, 113,0.25)');
        }
        const high = agentsInsightsStore?.histogramHighlightRanges?.high || null;
        if (high) {
          histogramHighlightRange(canvas, area, g, high, 'rgba(74, 222, 128,0.25)');
        }
      },
    };
  });

  const agentListStatsFields = [{ field: 'score', title: 'CSat', formatter: (v) => v.toFixed(2) }];

  function histogramHighlightRange(canvas, area, g, range, color) {
    let bottom_left = g.toDomCoords(range.start, -20);
    let top_right = g.toDomCoords(range.end, +20);
    let left = bottom_left[0];
    let right = top_right[0];
    canvas.fillStyle = color;
    canvas.fillRect(left, area.y, right - left, area.h);
  }

  async function loadData() {
    loading.value = true;
    //await conversationSearchStore.search(searchQuery.value, 10, 0, [{ field: 'datetime', type: 'desc' }]);
    const csatCalculateScore = (stats) => {
      return stats.count > 0 ? (stats.sum / (stats.count * 10)) * 100 : 0;
    };
    await agentsInsightsStore.analyzeAgents('CSat', csatCalculateScore);
    loading.value = false;
    analyzeProgressButton.value.stopProgress();
  }
</script>
