<template>
  <div>
    <div class="relative isolate overflow-hidden pt-2">
      <!-- Secondary navigation -->
      <header class="pb-4 pt-6 sm:pb-1">
        <div class="mx-auto flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
          <h1 class="text-2xl font-semibold leading-7 text-gray-900">Scenario Result</h1>
          <div class="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
            <div>
              <div class="sm:hidden">
                <label for="tabs" class="sr-only">Select a tab</label>
                <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                  <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
                </select>
              </div>
              <div class="hidden sm:block">
                <nav class="flex space-x-4" aria-label="Tabs">
                  <button
                    v-for="tab in tabs"
                    :key="tab.name"
                    :href="tab.href"
                    :class="[tab.name === currentTab ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium']"
                    @click="onSelectTab(tab.name)"
                  >
                    {{ tab.name }}
                  </button>
                </nav>
              </div>
            </div>

            <!--<a v-for="item in secondaryNavigation" :key="item.name" :href="item.href" :class="item.current ? 'text-indigo-600' : 'text-gray-700'">{{ item.name }}</a>-->
          </div>
          <div class="ml-auto flex">
            <button
              class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="onExport"
            >
              <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
              Export
            </button>
          </div>
        </div>
        <div class="ml-8 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div class="mt-4 flex items-center text-sm text-gray-500">
            <HashtagIcon class="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {{ scenarioId }}
          </div>
          <div class="mt-4 flex items-center text-sm text-gray-500">
            <Bars4Icon class="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {{ scenarioName }}
          </div>
          <div class="mt-4 flex items-center text-sm text-gray-500">
            <mdicon name="motion-play-outline" size="18" class="mr-1.5"></mdicon>
            {{ scenarioMedia }}
          </div>
        </div>
      </header>
    </div>
    <div class="mx-8">
      <ScenarioResultsSummary v-if="isTabVisible('Summary')" :class="isTabVisible('Summary') ? '' : 'md:hidden'"></ScenarioResultsSummary>
      <ScenarioResultsProblems v-if="isTabVisible('Problems')" :class="isTabVisible('Problems') ? '' : 'md:hidden'" :problems="analysisProblems"></ScenarioResultsProblems>
      <ScenarioResultsKPIs v-if="isTabVisible('KPIs')" :class="isTabVisible('KPIs') ? '' : 'md:hidden'" :kpis="analysisKPIs"></ScenarioResultsKPIs>
      <ScenarioResultsTranscript v-if="isTabVisible('Transcript')" :class="isTabVisible('Transcript') ? '' : 'md:hidden'"></ScenarioResultsTranscript>
      <!--<test-result-transcript :class="isTabVisible('Transcript') ? '' : 'md:hidden'"></test-result-transcript>-->
      <!--<test-result-transcript :class="isTabVisible('Transcript') ? '' : 'md:hidden'" :visible="isTabVisible('Transcript')"></test-result-transcript>-->
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useScenarioResultsStore } from '@/store/ScenarioResultsStore';
  import { useBreakpoint } from '@/hooks/useBreakpoint';
  import { ArrowDownCircleIcon, HashtagIcon, Bars4Icon } from '@heroicons/vue/20/solid';
  import ScenarioResultsSummary from '@/components/Scenarios/ScenarioResultsSummary.vue';
  import ScenarioResultsProblems from '@/components/Scenarios/ScenarioResultsProblems.vue';
  import ScenarioResultsKPIs from '@/components/Scenarios/ScenarioResultsKPIs.vue';
  import ScenarioResultsTranscript from '@/components/Scenarios/ScenarioResultsTranscript.vue';
  import { utils, writeFileXLSX } from 'xlsx';

  const route = useRoute();
  const layout = useLayoutStore();
  const scenarioResultsStore = useScenarioResultsStore();

  const analysisKPIs = computed(() => scenarioResultsStore.context?.analysis?.kpis || []);
  const analysisProblems = computed(() => scenarioResultsStore.context?.analysis?.problems || []);

  const scenarioId = computed(() => scenarioResultsStore.scenario?.id || '');
  const scenarioName = computed(() => scenarioResultsStore.scenario?.name || '');
  const scenarioMedia = computed(() => scenarioResultsStore.scenario?.media || '');

  const tabs = [
    { name: 'Summary', href: '#', current: true },
    { name: 'Problems', href: '#', current: false },
    { name: 'KPIs', href: '#', current: false },
    { name: 'Transcript', href: '#', current: false },
    //{ name: 'Stats', href: '#', current: false },
  ];
  const currentTab = ref(tabs[0].name);

  const { breakpoints } = useBreakpoint();

  function onSelectTab(tabName) {
    currentTab.value = tabName;
  }

  function isTabVisible(tabName) {
    if (layout.print || ['xs', 'sm', 'md'].includes(breakpoints.is)) {
      return true;
    }
    return currentTab.value === tabName;
  }

  // Export test results to Excel ????
  function onExport() {
    // TODO Summary
    const wsProblems = utils.json_to_sheet(scenarioResultsStore.analysis?.problems || []);
    const wsKPIs = utils.json_to_sheet(scenarioResultsStore.analysis?.kpis || []);
    const transcriptData = scenarioResultsStore.transcriptMessages.map((x) => {
      return { actor: x?.actor, text: x?.message };
    });
    const wsTranscript = utils.json_to_sheet(transcriptData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, wsProblems, 'Problems');
    utils.book_append_sheet(wb, wsKPIs, 'KPIs');
    utils.book_append_sheet(wb, wsTranscript, 'Transcript');
    writeFileXLSX(wb, 'ScenarioResults.xlsx');
  }

  onMounted(() => {
    scenarioResultsStore.loadScenarioResult(route.params.id, route.params.scenarioId);
  });
</script>
