<template>
  <div>
    <div class="grid grid-cols-1 gap-x-8 gap-y-4 pt-1 pb-4 px-2 md:grid-cols-3">
      <div class="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6 md:col-span-2">
        <component
          :is="prop.type === 'array' ? SpecScriptArrayPropertyEditor : SpecScriptActionProperty2"
          v-for="prop in actionDefProps"
          :key="prop.name"
          :prop-def="prop"
          :class="getPropLayoutClasses(prop)"
          :model-value="props.action[prop.name]"
          @update:modelValue="
            (newValue) => {
              emit('update:propValue', props.idx, prop.name, newValue);
            }
          "
        >
        </component>
        <!--<spec-script-action-property2
            v-for="prop in actionDefProps"
            :key="prop.name"
            :prop-def="prop"
            :class="getPropLayoutClasses(prop)"
            :model-value="props.action[prop.name]"
            @update:modelValue="
              (newValue) => {
                emit('update:propValue', props.idx, prop.name, newValue);
              }
            "
          >
          </spec-script-action-property2>-->
      </div>

      <div class="border-l px-2">
        <h2 class="text-lg font-semibold text-gray-900">{{ actionDef?.title }}</h2>
        <p class="mt-1 text-md leading-6 text-gray-600">{{ actionDef?.description }}</p>
        <div class="mt-4 flex text-sm">
          <a target="_blank" :href="`${actionDef?.helpUrl}`" class="group inline-flex items-center text-gray-500 hover:text-gray-900">
            <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
            <span class="ml-2"
              >Learn more about <strong>{{ actionDef?.title }}</strong></span
            >
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
  import { XMarkIcon } from '@heroicons/vue/24/outline';
  import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid';
  import { reactive, watch, computed } from 'vue';
  import { getActionDef } from '@/spec/ActionDefs.js';
  import SpecScriptActionProperty2 from '@/components/Tests/SpecScriptActionProperty2.vue';
  import SpecScriptArrayPropertyEditor from '@/components/Tests/SpecScriptArrayPropertyEditor.vue';

  const props = defineProps({
    idx: {
      type: Number,
      default: -1,
    },
    action: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  const emit = defineEmits(['update:propValue']);

  // Clone action and make it reactive - we can make changes to it locally
  let actionData = reactive(JSON.parse(JSON.stringify(props.action)));

  const actionDef = computed(() => getActionDef(props.action?.type));
  const actionDefProps = computed(() => {
    if (!Array.isArray(actionDef.value.props) || actionDef.value.props.length <= 0) {
      return [];
    }
    return actionDef.value.props.filter((p) => (p.condition ? p.condition(props.action) : true));
  });

  watch(
    () => props.action,
    (newValue /*, oldValue*/) => {
      actionData = reactive(JSON.parse(JSON.stringify(newValue)));
      console.log(`Props: step changed: ${actionData.text}`);
    }
  );

  const propLayoutClassesByType = {
    text: 'col-span-full',
    boolean: 'col-span-full',
    number: 'sm:col-span-full md:col-span-full lg:col-span-3 xl:col-span-2',
    string: 'sm:col-span-full md:col-span-full lg:col-span-3 xl:col-span-2',
    audioFile: 'col-span-full',
    transcriptsCollection: 'col-span-full',
    recordingsCollection: 'col-span-full',
    kpiCollection: 'col-span-full',
    temperature: 'col-span-full',
    array: 'col-span-full',
  };

  function getPropLayoutClasses(prop) {
    return propLayoutClassesByType[prop?.type] || '';
  }
</script>
