<template>
  <div class="h-full flex flex-col">
    <!--<div class="relative isolate overflow-hidden pt-2">
      <header class="pb-4 pt-6 sm:pb-1">
        <div class="mx-auto flex flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
          <h1 class="text-2xl font-semibold leading-7 text-gray-900">{{ jobResult.name }} Result</h1>
          <div class="ml-auto flex">
            <button
              class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="onExport"
            >
              <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
              Export
            </button>
          </div>
        </div>
        <div class="ml-8 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div class="mt-4 flex items-center text-sm text-gray-500">
            <HashtagIcon class="mr-1.5 h-4 w-4 shrink-0 text-gray-400" aria-hidden="true" />
            {{ jobResult.id }}
          </div>
          <div class="mt-4 flex items-center text-sm text-gray-500">
            <Bars4Icon class="mr-1.5 h-4 w-4 shrink-0 text-gray-400" aria-hidden="true" />
            {{ jobResult.status }}
          </div>
          <div class="mt-4 flex items-center text-sm text-gray-500">
            <mdicon name="motion-play-outline" size="18" class="mr-1.5"></mdicon>
            {{ jobResult.started }}
          </div>
        </div>
      </header>
    </div>-->
    <div class="relative isolate overflow-hidden pt-2 mx-6 mt-6">
      <div class="md:flex md:items-center md:justify-between md:space-x-5">
        <div class="flex items-start space-x-5">
          <div class="flex-shrink-0">
            <div class="relative bg-teal-600/70 rounded-xl p-1">
              <mdicon name="play-box-outline" size="60" class="text-teal-100" aria-hidden="true"></mdicon>
            </div>
          </div>
          <!--
            Use vertical padding to simulate center alignment when both lines of text are one line,
            but preserve the same layout if the text wraps without making the image jump around.
          -->
          <div class="pt-1.5">
            <h1 class="text-2xl font-bold text-gray-900">{{ jobResult.name }} Results</h1>
            <p class="pt-1 text-sm font-medium text-gray-500">Executed: {{ dayjs(jobResult.started).format() }}</p>
          </div>
        </div>

        <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
          <button
            class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="onExport"
          >
            <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
            Export
          </button>
        </div>
      </div>

      <div class="pt-4 pb-4 border-b">
        <div class="sm:hidden">
          <label for="tabs" class="sr-only">Select a tab</label>
          <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
          <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
            <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
          </select>
        </div>
        <div class="hidden sm:block">
          <nav class="flex space-x-4" aria-label="Tabs">
            <button
              v-for="tab in tabs"
              :key="tab.id"
              :class="[tab.id === currentTab ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium']"
              @click="onSelectTab(tab.id)"
            >
              {{ tab.name }}
            </button>
          </nav>
        </div>
      </div>
    </div>
    <div class="flex-auto bg-orange-200 mx-2 my-1">
      <perspective-viewer ref="pvcAll" theme="Pro Light" :class="['eng-pvc-all h-full w-full', isTabVisible('all') ? '' : 'md:hidden']" />
      <perspective-viewer ref="pvcAnalyze" theme="Pro Light" :class="['h-full w-full', isTabVisible('analyze') ? '' : 'md:hidden']" />
    </div>
  </div>
</template>

<script setup>
  // Notes: customizing perspective:
  // https://github.com/finos/regular-table
  // https://github.com/finos/perspective/discussions/1510
  // Sample - custom plugin: https://gist.github.com/texodus/c42f3189699bd29cf20bbe7dce767b07
  import { onMounted, ref, computed, nextTick } from 'vue';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useJobsStore } from '@/store/JobsStore';
  import { ArrowDownCircleIcon } from '@heroicons/vue/20/solid';
  import { useRoute, useRouter } from 'vue-router';
  import { perspectiveTables } from '@/store/perspectivetables';
  import * as XLSX from 'xlsx';
  import { pathOr } from 'ramda';
  import dayjs from 'dayjs';
  import { useBreakpoint } from '@/hooks/useBreakpoint';

  const pvcAll = ref(null);
  const pvcAnalyze = ref(null);

  const layout = useLayoutStore();
  const jobsStore = useJobsStore();

  const route = useRoute();
  const router = useRouter();
  const jobExecutionId = route.params.id;

  const jobResult = computed(() => jobsStore.jobsResults.find((x) => x.id === jobExecutionId) || {});

  const tabs = [
    { id: 'all', name: 'All Results', href: '#', current: true },
    { id: 'analyze', name: 'Analyze', href: '#', current: false },
  ];
  const currentTab = ref(tabs[0].id);

  function onSelectTab(tabId) {
    if (tabId === 'analyze') {
      pvcAnalyze.value.toggleConfig(true);
    }
    currentTab.value = tabId;
  }

  function isTabVisible(tabId) {
    return currentTab.value === tabId;
  }

  let table = null;

  const TEMP_DATA = {
    id: 'eDYI2JqrZ-',
    started: false,
    name: 'New Scenario',
    media: 'transcript',
    step: 'analyzeProblems',
    status: 'PASS',
    error: null,
    startTs: 1687388180259,
    stopTs: 1687388183603,
    duration: 3,
  };

  /*
        const SCHEMA = {
        Title: 'string',
        'US Gross': 'float',
    * * */

  const TEMP_SCHEMA = {
    Id: 'string',
    Status: 'string',
    Error: 'string',
    Words: 'string',
    Sentiment: 'integer',
    Summary: 'string', // ???
    Problems: 'integer',
    'Problems.Severity': 'integer',
    KPIs: 'integer',
    'KPIs.Score': 'integer',
    'Transcript.customer': 'string',
    'Transcript.agent': 'string',
    'Transcript.start': 'datetime',
    'Transcript.duration': 'integer',
    'Transcript.id': 'string',
    'Transcript.conversationID': 'string',
    File: 'string',
  };

  /**
     {
    "Id": "hBrKDIgs90",
    "Status": "PASS",
    "Error": null,
    "File": "chat_14.json",
    "Transcript.id": "Chat #14",
    "Transcript.conversationID": "cc784c83-10a5-4ee1-b188-b8f01108108f",
    "Transcript.customer": "Visitor",
    "Transcript.agent": "Carl",
    "Transcript.start": "2023-03-03T10:09:00.000Z",
    "Transcript.duration": 1080,
    "KPI": 9,
    "KPI.Score": 87
  }
     */

  async function initialize() {
    const jobResultData = await jobsStore.getJobResultData(jobExecutionId);
    if (!jobResultData) {
      console.log(`ERROR: Failed to get Job ${jobExecutionId} result data`);
      return;
    }

    let jobResult = null;

    try {
      jobResult = JSON.parse(jobResultData);
    } catch (e) {
      console.log(`ERROR: Failed to process Job ${jobExecutionId} result data: ${e.message}`);
      return;
    }

    table = await perspectiveTables.getWorker().table(TEMP_SCHEMA);
    table.update(jobResult);

    await pvcAll.value.load(table);
    await pvcAnalyze.value.load(table);

    pvcAll.value.addEventListener('perspective-click', (event) => {
      onGridClick(event);
    });

    // This disables settings button
    pvcAll.value.shadowRoot.querySelector('div#settings_button').style.display = 'none';

    pvcAll.value.restore({
      plugin: 'Datagrid',
      plugin_config: {
        columns: {
          Id: { color: '#f1f5f9', string_color_mode: 'background' },
          'Problems.Severity': { neg_fg_color: '#ff471e', pos_fg_color: '#0c0d0d', neg_bg_color: '#000000', number_bg_mode: 'gradient', pos_bg_color: '#f87171', bg_gradient: 100 },
          'KPIs.Score': {
            bg_gradient: 100,
            neg_fg_color: '#ff471e',
            neg_bg_color: '#000000',
            pos_bg_color: '#5eead4',
            number_bg_mode: 'gradient',
            pos_fg_color: '#0c0d0d',
          },
        },
        editable: false,
        scroll_lock: false,
      },
      theme: 'Pro Light',
      title: 'Dataset', // Note: supplying title enables showing toolbar
      /*
      group_by: [],
      split_by: [],
      columns: ['Row', 'id', 'value'],
      filter: [],
      sort: [],
      expressions: [],
      aggregates: {},
      */
    });

    /*
      await nextTick();
      const regular_table = pvcAll.value.querySelector('regular-table');
      regular_table.addStyleListener(() => {
        console.log(`addStyleListener: !!!`);
        for (const th of regular_table.querySelectorAll('tbody td')) {
          const meta = regular_table.getMeta(th);
          console.log(`Meta: ${JSON.stringify(meta)}`);
          th.classList.toggle('text-white');
        }
      });*/
  }

  function onGridClick(event) {
    console.log(`Grid Click: ${JSON.stringify(event.detail)}`);
    /*
      const regular_table = pvc.value.querySelector('regular-table');
      regular_table.addStyleListener(() => {
        for (const td of regular_table.querySelectorAll('tbody td')) {
          const meta = regular_table.getMeta(td);
          console.log(`Meta: ${JSON.stringify(meta)}`);
          td.classList.toggle('text-white');
        }
      });
      return;
      */
    const scenarioExecutionId = pathOr(null, ['row', 'Id'], event.detail);
    if (scenarioExecutionId) {
      // Navigate to Scenario Results
      router.push(`/analysis/results/details/${jobExecutionId}/${scenarioExecutionId}`);
    }
  }

  async function onExport() {
    if (!table) {
      return;
    }
    const view = await table.view();
    const csv = await view.to_csv();
    // TODO from JSON, with more fine-tuned datatype processing ? I.e. ts to Date
    const wb = XLSX.read(csv, { type: 'binary' });
    XLSX.writeFileXLSX(wb, 'Results.xlsx');
  }

  onMounted(async () => {
    await initialize();
  });
</script>

<style>
  .eng-pvc-all tbody > tr > :nth-child(1) {
    color: #1b5dab;
  }
  .eng-pvc-all tbody > tr > :nth-child(1):hover {
    cursor: pointer;
    text-decoration: underline;
  }
</style>
