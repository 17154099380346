<template>
  <nav aria-label="Progress">
    <ol role="list" class="overflow-hidden">
      <li v-for="(step, stepIdx) in steps" :key="step.name" :class="[stepIdx !== steps.length - 1 ? 'pb-8' : '', 'relative']">
        <div v-if="stepIdx !== steps.length - 1" class="absolute left-9 top-4 -ml-px mt-0.5 h-full w-0.5 bg-orange-200" aria-hidden="true" />
        <div class="group relative flex items-start">
          <span class="flex h-20 items-start" aria-hidden="true">
            <span class="relative z-10 flex h-18 w-18 items-center justify-center rounded-full border-2 border-orange-300 bg-white group-hover:border-gray-400">
              <mdicon :name="step.icon" size="42" class="text-orange-600" />
            </span>
          </span>
          <span class="ml-4 flex min-w-0 flex-col">
            <span class="mt-2 text-md font-medium text-gray-700">{{ step.name }}</span>
            <span class="mt-1 text-sm text-gray-600">{{ step.description }}</span>
          </span>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script setup>
  import { CheckIcon } from '@heroicons/vue/20/solid';

  const steps = [
    { name: 'Create Scenario', description: 'Scenario describes what steps to perform, and how to check and analyze the result.', href: '#', status: 'complete', icon: 'playlist-check' },
    {
      name: 'Create Job',
      description: 'Job performs actual work by executing Scenario as many times as needed.',
      href: '#',
      status: 'current',
      icon: 'play-box-outline',
    },
    {
      name: 'Execute Job',
      description: 'Start the Job right away, or schedule it for execution at a later time. You may execute the same Job multiple times, for example, supplying it with new data.',
      href: '#',
      status: 'upcoming',
      icon: 'play-box-multiple-outline',
    },
    {
      name: 'Analyze Results',
      description: 'After Job completes, review and analyze results. You may drill down to the results of each individual Scenario.',
      href: '#',
      status: 'upcoming',
      icon: 'file-document-multiple-outline',
    },
    {
      name: 'Upload your data',
      description:
        'You may upload your data to be analyzed by Scenarios, such as Transcripts or Recordings. You may also create datasets to be used in your Scenarios and Jobs, for example lists of phone numbers to call.',
      href: '#',
      status: 'upcoming',
      icon: 'database-plus-outline',
    },
  ];
</script>
