export const JOB_TYPE_INFO = {
  analyzeTranscripts: {
    category: 'analyze',
    type: 'analyzeTranscripts',
    title: 'Analyze Transcripts',
    defaultName: 'New Analyze Transcripts Job',
    icon: 'text-account',
    description: 'Process Conversation Transcripts in JSON format. Summarize, Analyze Sentiment, Problems, KPIs, Agent Strengths, Weaknesses, and Opportunities.',
    collectionType: 'transcripts',
    itemsTitle: 'Transcripts',
    processStep: 'processTranscript',
    scenarioMedia: 'transcript',
  },
  analyzeRecordings: {
    category: 'analyze',
    type: 'analyzeRecordings',
    title: 'Analyze Recordings',
    defaultName: 'New Analyze Recordings Job',
    icon: 'account-voice',
    description: 'Process Audio Recordings. Transcribe, Summarize, Analyze Sentiment, Problems, KPIs, Agent Strengths, Weaknesses, and Opportunities.',
    collectionType: 'recordings',
    itemsTitle: 'Recordings',
    processStep: 'processRecording',
    scenarioMedia: 'recording',
  },
};

export const BOT_TYPE_INFO = {
  leadGeneration: {
    category: 'bot',
    type: 'leadGeneration',
    title: 'Lead Generation Assistant',
    defaultName: 'New Lead Generation Assistant',
    icon: 'lightbulb-on-20',
    description: 'Engage in conversation with web site visitors, and answer questions. Screen and Qualify leads. Escalate Hot leads to live person. Collect contact information from Lead.',
    collectionType: 'transcripts',
    itemsTitle: 'Transcripts',
    processStep: 'processTranscript',
    scenarioMedia: 'transcript',
  },
  questionAnswering: {
    category: 'bot',
    type: 'questionAnswering',
    title: 'Question Answering Assistant',
    defaultName: 'New Question Answering Assistant',
    icon: 'message-question-outline',
    description: 'Answer Questions and Escalate to live person as needed.',
    collectionType: 'recordings',
    itemsTitle: 'Recordings',
    processStep: 'processRecording',
    scenarioMedia: 'recording',
  },
};

export const KB_TYPE_INFO = {
  standard: {
    category: 'kb',
    type: 'standard',
    title: 'Knowledge Base',
    defaultName: 'New Knowledge Base',
    icon: 'database-outline',
    description: 'Stores information used by Assistants',
  },
};
