<template>
  <component :is="editorComponent"></component>
</template>
<script setup>
  import { computed } from 'vue';
  import { useBotsStore } from '@/store/BotsStore';
  import BotQAActionsEditor from '@/components/Bots/editors/BotQAActionsEditor.vue';
  import BotLeadGenActionsEditor from '@/components/Bots/editors/BotLeadGenActionsEditor.vue';

  const botsStore = useBotsStore();

  const supportedEditors = {
    questionAnswering: BotQAActionsEditor,
    leadGeneration: BotLeadGenActionsEditor,
    none: BotQAActionsEditor, // TODO
  };

  const editorComponent = computed(() => supportedEditors[botsStore.botSpec?.type || 'none']);
</script>
