<template>
  <div>
    <Autocomplete v-model="selectedAudio" :items="audioStore.audio" filter-prop="name"></Autocomplete>
    <audio :key="selectedAudio?.url" class="py-2 w-full" controls>
      <source :src="selectedAudio?.url" />
      Your browser does not support the audio element.
    </audio>
  </div>
</template>

<script setup>
  import { onMounted, computed, ref, watch, nextTick } from 'vue';
  import Autocomplete from '@/components/Autocomplete/Autocomplete.vue';
  import { useAudioStore } from '@/store/AudioStore';

  const audioStore = useAudioStore();

  const selectedAudio = ref(null);

  const props = defineProps(['modelValue']);
  const emit = defineEmits(['update:modelValue']);

  const selectedAudioName = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });

  selectedAudio.value = audioStore.audio.find((e) => e.name === selectedAudioName.value) || null;

  watch(
    () => selectedAudio.value,
    async () => {
      selectedAudioName.value = selectedAudio.value?.name;
    }
  );

  // Correctly reflect selection if we need to load audio collection first (i.e. it will be updated after load)
  watch(
    () => audioStore.audio,
    async () => {
      selectedAudio.value = audioStore.audio.find((e) => e.name === selectedAudioName.value) || null;
    }
  );

  //onMounted
  onMounted(() => {
    audioStore.loadAudio(false);
  });
</script>
