/*
 * Script Actions definitions and meta-information
 *
 * Script is a sequence of Actions
 * */

/*
 * Supported action capabilities. Specific Action may support combination of these.
 */
export const ACTION_CAPABILITIES = {
  PROCESS_INPUT: 1,
  PROCESS_ACTION_RESULT: 1 << 2, // TODO name ???
};

/*
 * Supported action Media. Specific Action may support combination of these.
 */
export const ACTION_MEDIA = {
  none: 0,
  voice: 1,
  recording: 1 << 1,
  transcript: 1 << 2,
  web: 1 << 3,
  // add more ...
};

/*
 * Base for all Actions definitions
 * */
export class ActionDef {
  constructor() {
    this.type = '';
    this.title = '';
    this.description = '';
    this.capabilities = 0;
    this.helpUrl = '';
    this.icon = ''; // MDI Icon name to use for this action in UI
    this.props = [];
    this.getDisplayData = (step) => {
      return {
        name: this.type,
        description: this.description,
        itemsName: null,
        items: [],
      };
    };
  }
  // todo methods, if any
}

export const emptyActionDef = new ActionDef();

/*
 * Action Definition: Expect
 * */
export class ActionDefExpect extends ActionDef {
  constructor() {
    super();
    this.type = 'expect';
    this.title = 'Expect';
    this.description = 'Expect Text within timeout';
    this.capabilities = ACTION_CAPABILITIES.PROCESS_INPUT;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.web;
    this.helpUrl = '/help/actions/expect';
    this.icon = 'headphones';
    this.props = [
      { name: 'text', type: 'text', title: 'Text', default: '' },
      { name: 'timeout', type: 'number', title: 'Timeout', default: 5, suffix: 'Seconds' },
      //{ name: 'text2', type: 'text', title: 'Really Long Title', default: '' },
    ];
  }
}

/*
 * Action Definition: Waitfor
 * */
export class ActionDefWaitfor extends ActionDef {
  constructor() {
    super();
    this.type = 'waitfor';
    this.title = 'Wait For';
    this.description = 'Wait for Text within timeout ignoring everything else';
    this.capabilities = ACTION_CAPABILITIES.PROCESS_INPUT;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.web;
    this.helpUrl = '/help/actions/waitfor';
    this.icon = 'headphones-settings';
    this.props = [
      { name: 'text', type: 'text', title: 'Text', default: '' },
      { name: 'timeout', type: 'number', title: 'Timeout', default: 5, suffix: 'Seconds' },
      //{ name: 'text2', type: 'text', title: 'Really Long Title', default: '' },
    ];
  }
}

export class ActionDefSpeak extends ActionDef {
  constructor() {
    super();
    this.type = 'speak';
    this.title = 'Speak';
    this.description = 'Speak provided Text';
    this.capabilities = ACTION_CAPABILITIES.PROCESS_ACTION_RESULT;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.web;
    this.helpUrl = '/help/actions/speak';
    this.icon = 'volume-high';
    this.props = [
      { name: 'name', type: 'string', title: 'Name', default: 'Speak' },
      { name: 'text', type: 'text', title: 'Text', default: '' },
    ];
    this.getDisplayData = (step) => {
      return {
        name: step?.name || this.title,
        description: step?.text || null,
        items: [],
      };
    };
  }
}

export class ActionDefAnswerQuestions extends ActionDef {
  constructor() {
    super();
    this.type = 'answerQuestions';
    this.title = 'Answer Questions';
    this.description = 'Answer Questions on the Collection of Documents';
    this.capabilities = ACTION_CAPABILITIES.PROCESS_ACTION_RESULT;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.web;
    this.helpUrl = '/help/actions/speak';
    this.icon = 'forum-outline';
    this.props = [
      // TODO Default: from intent parameters ?
      { name: 'collection', type: 'string', title: 'Collection', default: '' },
    ];
    this.getDisplayData = (step) => {
      return {
        name: this.title,
        description: this.description,
        itemsName: 'Collection',
        items: [
          {
            name: step?.collection || 'No name',
            icon: 'file-document-multiple-outline',
          },
        ],
      };
    };
  }
}

export class ActionDefSay extends ActionDef {
  constructor() {
    super();
    this.type = 'say';
    this.title = 'Say Text';
    this.description = 'Say specified Text';
    this.capabilities = ACTION_CAPABILITIES.PROCESS_ACTION_RESULT;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.web;
    this.helpUrl = '/help/actions/say';
    this.icon = 'volume-high';
    this.props = [{ name: 'text', type: 'text', title: 'Text', default: '' }];
  }
}

/*
 * Action Definition: Conversation
 * */
export class ActionDefConversation extends ActionDef {
  constructor() {
    super();
    this.type = 'conversation';
    this.title = 'Conversation';
    this.description = 'Multi-Turn Conversation to achieve a Goal';
    this.capabilities = ACTION_CAPABILITIES.PROCESS_INPUT | ACTION_CAPABILITIES.PROCESS_ACTION_RESULT;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.web;
    this.helpUrl = '/help/actions/conversation';
    this.icon = 'forum-outline';
    this.displayProp = 'goal';
    this.props = [
      { name: 'goal', type: 'text', title: 'Goal', default: '' },
      { name: 'parameters', type: 'text', title: 'Parameters', default: '' },
      { name: 'timeout', type: 'number', title: 'Timeout', default: 120, suffix: 'Seconds' },
      //{ name: 'text2', type: 'text', title: 'Really Long Title', default: '' },
    ];
  }
}

/*
 * Action Definition: Transcribe
 * TODO Remove
 * */
export class ActionDefTranscribe extends ActionDef {
  constructor() {
    super();
    this.type = 'transcribe';
    this.title = 'Transcribe Audio';
    this.description = 'Convert audio to text transcript with Speaker Diarisation';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.recording;
    this.helpUrl = '/help/actions/transcribe';
    this.icon = 'forum-outline';
    this.displayProp = 'file';
    this.props = [{ name: 'file', type: 'audioFile', title: 'Audio File', description: 'For best results, use stereo file with separate speaker in each channel', default: '' }];
  }
}

/*
 * Action Definition: Process transcript
 * */
export class ActionDefProcessTranscript extends ActionDef {
  constructor() {
    super();
    this.type = 'processTranscript';
    this.title = 'Process Transcript';
    this.description = 'Process Conversation Transcript in JSON format';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.transcript;
    this.helpUrl = 'https://docs.enegel.ai/docs/documentation/scenario-script/step-process-transcript';
    this.icon = 'forum-outline';
    this.displayProp = null; //'file';
    this.props = [
      { name: 'collection', type: 'transcriptsCollection', title: 'Transcripts Collection', description: 'Choose transcripts collection to process', default: '' },
      { name: 'file', type: 'string', title: 'Transcript File', description: 'Transcript file to process', default: '', hidden: true },
      //{ name: 'speaker1', type: 'string', title: 'Speaker 1 Name', default: 'Bot' },
      //{ name: 'speaker2', type: 'string', title: 'Speaker 2 Name', default: 'Caller' },
      //{ name: 'text2', type: 'text', title: 'Really Long Title', default: '' },
    ];
  }
}

/*
 * Action Definition: Process Recording
 * */
export class ActionDefProcessRecording extends ActionDef {
  constructor() {
    super();
    this.type = 'processRecording';
    this.title = 'Process Audio Recording';
    this.description = 'Transcribe Audio recording with Speaker Diarisation';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.recording;
    this.helpUrl = 'https://docs.enegel.ai/docs/documentation/scenario-script/step-process-recording';
    this.icon = 'forum-outline';
    this.displayProp = null;
    this.props = [
      { name: 'collection', type: 'recordingsCollection', title: 'Recordings Collection', description: 'Choose recordings collection to process', default: '' },
      { name: 'keywords', type: 'text', title: 'Keywords', default: '', description: 'Boost keywords when performing ASR', placeholder: 'keyword:INTENSIFIER\nfor example:"Hello:5". Separate keywords by new line.' },
      { name: 'replaces', type: 'text', title: 'Replaces', default: '', description: 'Replace keywords when performing ASR', placeholder: 'keyword:replacement\nfor example:"Hello:Hi". Separate keywords by new line.' },
    ];
  }
}

/*
 * Action Definition: Analyze Problems
 * */
export class ActionDefAnalyzeProblems extends ActionDef {
  constructor() {
    super();
    this.type = 'analyzeProblems';
    this.title = 'Analyze Problems';
    this.description = 'Analyze Transcript and detect problems';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.recording | ACTION_MEDIA.transcript;
    this.helpUrl = 'https://docs.enegel.ai/docs/documentation/scenario-script/step3-analyze-problems';
    this.icon = 'forum-outline';
    this.displayProp = null;
    this.props = [
      {
        name: 'policies',
        type: 'text',
        title: 'Policies',
        default: '',
        description: 'Specify corporate policies to take into account when detecting problems. Potential problems that are associated with the policies will not be reported.',
      },
      //{ name: 'speaker1', type: 'string', title: 'Speaker 1 Name', default: 'Bot' },
      //{ name: 'count', type: 'number', title: 'Number of problems', default: 5, description: 'How many problems to detect' },
      //{ name: 'customProblems', type: 'text', title: 'Custom Problem Definitions', default: '', description: 'You may add custom problem definitions as needed' },
    ];
  }
}

/*
 * Action Definition: Analyze KPIs
 * */
export class ActionDefAnalyzeKPI extends ActionDef {
  constructor() {
    super();
    this.type = 'analyzeKPI';
    this.title = 'Analyze KPI';
    this.description = 'Analyze Transcript and calculate Key Performance Indicators scores';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.recording | ACTION_MEDIA.transcript;
    this.helpUrl = 'https://docs.enegel.ai/docs/documentation/scenario-script/step4-analyze-kpis';
    this.icon = 'forum-outline';
    this.displayProp = null;
    this.props = [
      { name: 'collection.kpi', type: 'kpiCollection', title: 'KPI Set', description: 'Choose KPI Set to Analyze', default: '' },
      { name: 'policies', type: 'text', title: 'Policies', default: '', description: 'Specify corporate policies to take into account when analyzing KPIs.' },
    ];
  }
}

/*
 * Action Definition: Analyze KPIs
 * */
export class ActionDefAnalyzeSummarize extends ActionDef {
  constructor() {
    super();
    this.type = 'analyzeSummarize';
    this.title = 'Summarize';
    this.description = 'Analyze Transcript and Summarize Conversation';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.recording | ACTION_MEDIA.transcript;
    this.helpUrl = 'https://docs.enegel.ai/docs/documentation/scenario-script/step2-analyze-summarize';
    this.icon = 'forum-outline';
    this.displayProp = null;
    this.props = [
      //{ name: 'speaker1', type: 'string', title: 'Speaker 1 Name', default: 'Bot' },
      //{ name: 'customKPI', type: 'text', title: 'Additional KPI Definitions', default: '', description: 'You may add your specific KPI definitions as needed' },
    ];
  }
}

/*
 * Action Definition: Analyze Sentiment
 * */
export class ActionDefAnalyzeSentiment extends ActionDef {
  constructor() {
    super();
    this.type = 'analyzeSentiment';
    this.title = 'Analyze Sentiment';
    this.description = 'Analyze Transcript and Determine Customer Sentiment';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.recording | ACTION_MEDIA.transcript;
    this.helpUrl = '/help/actions/analyzeSentiment';
    this.icon = 'forum-outline';
    this.displayProp = null;
    this.props = [
      //{ name: 'speaker1', type: 'string', title: 'Speaker 1 Name', default: 'Bot' },
      //{ name: 'customKPI', type: 'text', title: 'Additional KPI Definitions', default: '', description: 'You may add your specific KPI definitions as needed' },
    ];
  }
}

/*
 * Action Definition: Analyze CSat
 * */
export class ActionDefAnalyzeCSat extends ActionDef {
  constructor() {
    super();
    this.type = 'analyzeCSat';
    this.title = 'Analyze CSat';
    this.description = 'Analyze Transcript and Determine CSat';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.recording | ACTION_MEDIA.transcript;
    this.helpUrl = '/help/actions/analyzeCSat';
    this.icon = 'forum-outline';
    this.displayProp = null;
    this.props = [
      {
        name: 'csatRelevantDomains',
        advanced: false,
        type: 'text',
        title: 'Relevant Domains',
        default: '',
        description: 'List relevant domains for the Customer Service. If provided, CSat will be analysed only if customer inquiry is within relevant domains.',
      },
      //{ name: 'speaker1', type: 'string', title: 'Speaker 1 Name', default: 'Bot' },
      //{ name: 'customKPI', type: 'text', title: 'Additional KPI Definitions', default: '', description: 'You may add your specific KPI definitions as needed' },
    ];
  }
}

/*
 * Action Definition: Analyze Agent
 * */
export class ActionDefAnalyzeAgent extends ActionDef {
  constructor() {
    super();
    this.type = 'analyzeAgent';
    this.title = 'Analyze Agent Traits';
    this.description = 'Analyze Agent Strengths, Weaknesses and Improvement Opportunities';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.recording | ACTION_MEDIA.transcript;
    this.helpUrl = '/help/actions/analyzeAgent';
    this.icon = 'forum-outline';
    this.displayProp = null;
    this.props = [
      {
        name: 'policies',
        type: 'text',
        title: 'Policies',
        default: '',
        description: 'Specify corporate policies to take into account when analyzing Agent. Agent behavior driven by the corporate policies will be accounted for in Agent Strengths, Weaknesses and Opportunities.',
      },
      /*
      { name: 'strengthsEnabled', type: 'boolean', title: 'Agent Strengths', default: true },
      {
        name: 'strengthsInstructions',
        type: 'text',
        title: '',
        default: '',
        description: 'You may provide additional instructions for Strengths analysis',
        hidden: true,
        condition: (s) => s.strengthsEnabled === true,
      },
      { name: 'weaknessesEnabled', type: 'boolean', title: 'Agent Weaknesses', default: true },
      {
        name: 'weaknessesInstructions',
        type: 'text',
        title: '',
        default: '',
        description: 'You may provide additional instructions for Weaknesses analysis',
        hidden: true,
        condition: (s) => s.weaknessesEnabled === true,
      },
      { name: 'opportunitiesEnabled', type: 'boolean', title: 'Agent Improvement Opportunities', default: true },
      {
        name: 'opportunitiesInstructions',
        type: 'text',
        title: '',
        default: '',
        description: 'You may provide additional instructions for Improvement Opportunities analysis',
        hidden: true,
        condition: (s) => s.opportunitiesEnabled === true,
      },
      { name: 'temperature', type: 'temperature', title: 'Temperature', default: 0.0 },
       */
    ];
  }
}

export class ActionDefAnalyzeAgent_Experimental extends ActionDef {
  constructor() {
    super();
    this.type = 'analyzeAgent';
    this.title = 'Analyze Agent Traits';
    this.description = 'Analyze Agent Strengths, Weaknesses and Improvement Opportunities';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.recording | ACTION_MEDIA.transcript;
    this.helpUrl = '/help/actions/analyzeAgent';
    this.icon = 'forum-outline';
    this.displayProp = null;
    this.props = [
      { name: 'strengthsEnabled', type: 'boolean', title: 'Agent Strengths', default: true },
      { name: 'strengthsTarget', type: 'string', title: '', description: 'You may adjust what to analyze', default: 'Strengths in Agent performance', condition: (s) => s.strengthEnabled === true },
      {
        name: 'strengthsInstructions',
        type: 'text',
        title: '',
        default: '',
        description: 'You may provide additional instructions for Strengths analysis',
        condition: (s) => s.strengthEnabled === true,
      },
      { name: 'weaknessesEnabled', type: 'boolean', title: 'Agent Weaknesses', default: true },
      { name: 'weaknessesTarget', type: 'string', title: '', description: 'You may adjust what to analyze', default: 'Weaknesses in Agent performance', condition: (s) => s.weaknessesEnabled === true },
      {
        name: 'weaknessesInstructions',
        type: 'text',
        title: '',
        default: '',
        description: 'You may provide additional instructions for Weaknesses analysis',
        condition: (s) => s.weaknessesEnabled === true,
      },
      { name: 'opportunitiesEnabled', type: 'boolean', title: 'Agent Improvement Opportunities', default: true },
      { name: 'opportunitiesTarget', type: 'string', title: '', description: 'You may adjust what to analyze', default: 'Opportunities how Agent can improve', condition: (s) => s.opportunitiesEnabled === true },
      {
        name: 'opportunitiesInstructions',
        type: 'text',
        title: '',
        default: '',
        description: 'You may provide additional instructions for Improvement Opportunities analysis',
        condition: (s) => s.opportunitiesEnabled === true,
      },
      { name: 'temperature', type: 'temperature', title: 'Temperature', default: 0 },
    ];
  }
}

export class ActionDefAnalyzeDetermine extends ActionDef {
  constructor() {
    super();
    this.type = 'analyzeDetermine';
    this.title = 'Determine';
    this.description = 'Analyze Transcript and Determine specified values';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice | ACTION_MEDIA.recording | ACTION_MEDIA.transcript;
    this.helpUrl = 'https://docs.enegel.ai/docs/documentation/scenario-script/analyze-determine';
    this.icon = 'forum-outline';
    this.displayProp = null;
    this.props = [
      { name: 'guidelines', type: 'text', title: 'Guidelines', description: 'Provide additional guidelines for analysis', default: '' },
      {
        name: 'entities',
        type: 'array',
        title: 'What to determine',
        description: 'You can specify multiple items to be determined',
        default: [{ name: 'opa', description: 'opa' }],
        properties: [
          { name: 'name', type: 'stringLong', title: 'Assign a Name', description: '', placeholder: 'Set unique name, i.e. "ReturnReason"', default: '', processValue: (v) => v.replace(/\s/g, '') },
          { name: 'description', type: 'text', rows: 1, title: 'Describe what to detect', description: '', placeholder: 'For example: "Reason for product return"', default: '' },
        ],
      },
    ];
  }
}

export class ActionDefDetectIntent extends ActionDef {
  constructor() {
    super();
    this.type = 'detectIntent';
    this.title = 'Detect Intent';
    this.description = 'Determine Intent from what Customer is saying. Accept only supported Intents.';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice;
    this.helpUrl = '/help/actions/detectIntent';
    this.icon = 'file-tree-outline';
    this.displayProp = null;
    this.props = [
      { name: 'name', type: 'string', title: 'Name', default: 'Detect Intent' },
      //{ name: 'speaker1', type: 'string', title: 'Speaker 1 Name', default: 'Bot' },
      //{ name: 'customKPI', type: 'text', title: 'Additional KPI Definitions', default: '', description: 'You may add your specific KPI definitions as needed' },
    ];
    this.getDisplayData = (step) => {
      return {
        name: step?.name || this.title,
        description: this.description,
        items: null,
      };
    };
  }
}

export class ActionDefProcessIntent extends ActionDef {
  constructor() {
    super();
    this.type = 'processIntent';
    this.title = 'Intent';
    this.description = 'Define Intent with Parameters';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice;
    this.helpUrl = '/help/actions/detectIntent';
    this.icon = 'checkbox-marked-circle-outline';
    this.displayProp = 'name';
    this.props = [
      { name: 'name', type: 'string', title: 'Name', default: '' },
      { name: 'definition', type: 'text', title: 'Definitions', default: '', description: 'Describe the intent' },
      { name: 'examples', type: 'text', title: 'Examples', default: '', description: 'Provide some examples of customer questions that match this intent' },
    ];
    this.getDisplayData = (step) => {
      return {
        name: step?.name || this.type,
        description: step?.definition || null,
        itemsName: 'Parameters',
        items: (step?.parameters || []).map((p) => {
          return {
            name: p.name,
            icon: 'account-arrow-right-outline',
          };
        }),
      };
    };
  }
}

export class ActionDefAPICall extends ActionDef {
  constructor() {
    super();
    this.type = 'apiCall';
    this.title = 'API Call';
    this.description = 'Make API Call';
    this.capabilities = 0;
    this.media = ACTION_MEDIA.voice;
    this.helpUrl = '/help/actions/detectIntent';
    this.icon = 'code-json';
    this.displayProp = 'name';
    this.props = [{ name: 'name', type: 'string', title: 'Name', default: '' }];
    this.getDisplayData = (step) => {
      return {
        name: step?.name || this.type,
        description: step?.definition || null,
        items: null,
      };
    };
  }
}

// All known Action Defs by type
export const ACTION_DEFS = {
  transcribe: new ActionDefTranscribe(),
  processTranscript: new ActionDefProcessTranscript(),
  processRecording: new ActionDefProcessRecording(),
  expect: new ActionDefExpect(),
  say: new ActionDefSay(),
  speak: new ActionDefSpeak(),
  answerQuestions: new ActionDefAnswerQuestions(),
  waitfor: new ActionDefWaitfor(),
  conversation: new ActionDefConversation(),
  analyzeProblems: new ActionDefAnalyzeProblems(),
  analyzeKPI: new ActionDefAnalyzeKPI(),
  analyzeSummarize: new ActionDefAnalyzeSummarize(),
  analyzeSentiment: new ActionDefAnalyzeSentiment(),
  analyzeCSat: new ActionDefAnalyzeCSat(),
  analyzeAgent: new ActionDefAnalyzeAgent(),
  analyzeDetermine: new ActionDefAnalyzeDetermine(),
  detectIntent: new ActionDefDetectIntent(),
  processIntent: new ActionDefProcessIntent(),
  apiCall: new ActionDefAPICall(),
};

// Meta-information about all known Actions TODO rename ?
export const ACTION_META = Object.values(ACTION_DEFS).map((x) => {
  return {
    type: x.type,
    title: x.title,
    description: x.description,
    helpUrl: x.helpUrl,
    icon: x.icon,
    media: x.media,
  };
});

export function getActionDef(actionType) {
  return actionType in ACTION_DEFS ? ACTION_DEFS[actionType] : emptyActionDef;
}

// To simplify migration to Step Defs from Core
export function getStepDef(stepType) {
  return stepType in ACTION_DEFS ? ACTION_DEFS[stepType] : emptyActionDef;
}
