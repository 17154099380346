<template>
  <div v-if="!showAllContent" class="flex space-x-1 rounded-lg bg-slate-100 p-1" aria-orientation="horizontal">
    <button
      v-for="tab in refinedTabs"
      :key="tab.name"
      :class="[tab.name === currentTab ? 'bg-white shadow' : 'hover:bg-slate-50 hover:shadow', 'flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3']"
      @click="onSelectTab(tab)"
    >
      <mdicon :name="tab.icon || 'forum-outline'" size="20" :class="[tab.name === currentTab ? 'text-sky-500' : 'text-slate-400', '']" aria-hidden="true"></mdicon>
      <span class="sr-only lg:not-sr-only lg:ml-2 text-slate-900">{{ tab.name }}</span>
    </button>
  </div>
</template>
<script setup>
  import { ref, computed, watch } from 'vue';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useBreakpoint } from '@/hooks/useBreakpoint';

  const tabs = defineModel('tabs');
  const showAll = defineModel('showAll');

  const layout = useLayoutStore();
  const { breakpoints } = useBreakpoint();

  const refinedTabs = computed(() => (Array.isArray(tabs.value) ? tabs.value.filter((x) => !x.hidden) : []));
  const currentTab = ref((refinedTabs.value.find((x) => x.active) || { name: refinedTabs.value.length > 0 ? refinedTabs.value[0].name : null }).name);

  const isPrintLayout = computed(() => layout.print);
  const isSmallScreen = computed(() => ['xs', 'sm', 'md'].includes(breakpoints.is));
  const showAllContent = computed(() => isPrintLayout.value || isSmallScreen.value);

  const updatedTabs = computed(() => {
    return tabs.value.map((x) => {
      return Object.assign({}, x, { active: isTabVisible(x) });
    });
  });

  function isTabVisible(tab) {
    return showAllContent.value || tab.name === currentTab.value;
  }

  watch(showAllContent, (newValue) => {
    tabs.value = updatedTabs.value;
    showAll.value = newValue;
  });

  function onSelectTab(tab) {
    currentTab.value = tab.name;
    tabs.value = updatedTabs.value;
  }
</script>
