<template>
  <div class="">
    <label v-if="label" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
    <div v-if="description" class="text-xs text-gray-500 mb-2">{{ description }}</div>
    <Button type="button" @click="toggle" outlined severity="secondary" size="small" raised text>
      <div class="flex flex-row items-center justify-between w-full">
        <mdicon name="calendar-clock-outline" size="22" class="text-gray-400 flex-none mr-2" aria-hidden="true"></mdicon>
        <div class="grow text-left text-sm font-medium leading-6 text-gray-800">{{ title }}</div>
        <mdicon name="chevron-down" size="18" class="text-gray-400 flex-none" aria-hidden="true"></mdicon>
      </div>
    </Button>

    <OverlayPanel ref="overlay">
      <div class="flex flex-col gap-2 w-screen max-w-xs overflow-hidden bg-white text-sm lg:max-w-xl">
        <div class="block text-sm font-medium leading-6 text-gray-900">Weekdays</div>
        <div class="flex flex-col gap-2 w-ful">
          <div v-for="[dayKey, dayValue] in Object.entries(businessHours.wd)" :key="dayKey" class="flex items-center gap-1 p-1 bg-slate-50 rounded-md">
            <div class="basis-1/4 text-md font-medium leading-6 text-gray-600">{{ dayjs.weekdaysShort()[dayKey] }}</div>
            <ToggleButton class="basis-1/4 w-full" v-model="dayValue.open" onLabel="Open" offLabel="Closed" />
            <div class="basis-1/4">
              <InputMask
                v-if="dayValue.open"
                class="w-full"
                v-model="dayValue.start"
                placeholder="hh:mm"
                mask="99:99"
                slotChar="hh:mm"
                :pt="{
                  root: { class: 'p-1' },
                }"
              />
            </div>
            <div class="basis-1/4">
              <InputMask
                v-if="dayValue.open"
                class="w-full"
                v-model="dayValue.end"
                placeholder="hh:mm"
                mask="99:99"
                slotChar="hh:mm"
                :pt="{
                  root: { class: 'p-1' },
                }"
              />
            </div>
          </div>
        </div>
        <div class="block text-sm font-medium leading-6 text-gray-900">Timezone</div>
        <Dropdown v-model="selectedTimezone" :options="timezoneOptions" optionLabel="name" placeholder="Select a Timezone" class="w-full md:w-14rem" />
        <div class="flex justify-end gap-2 mt-2 pt-2 border-t">
          <Button type="button" label="Cancel" severity="secondary" @click="overlay.hide()" size="small"></Button>
          <Button type="button" label="Save" @click="onSave" size="small"></Button>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup>
  import { ref, computed, reactive, watch, onMounted } from 'vue';
  import OverlayPanel from 'primevue/overlaypanel';
  import Button from 'primevue/button';
  import ToggleButton from 'primevue/togglebutton';
  import Calendar from 'primevue/calendar';
  import InputMask from 'primevue/inputmask';
  import Dropdown from 'primevue/dropdown';
  import dayjs from 'dayjs';
  import localeData from 'dayjs/plugin/localeData';
  import { defaultBusinessHours, supportedTimezones } from '@enegelai/core';

  dayjs.extend(localeData);

  const props = defineProps({
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
    label: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['update:modelValue']);

  const overlay = ref(null);

  const businessHours = reactive(Object.assign({}, defaultBusinessHours, JSON.parse(JSON.stringify(props.modelValue))));

  const title = computed(() => {
    let t = '';
    for (const [dayKey, dayValue] of Object.entries(props.modelValue?.wd || {})) {
      if (dayValue.open) {
        if (t !== '') {
          t += ',';
        }
        t += `${dayjs.weekdaysShort()[dayKey]}:${dayValue.start}-${dayValue.end}`;
      }
    }
    if (t.length > 60) {
      t = t.substring(0, 60) + '...';
    }
    return t;
  });

  const timezoneOptions = computed(() => {
    return Object.entries(supportedTimezones).map(([key, value]) => {
      return { name: key, value: value };
    });
  });
  const selectedTimezone = computed({
    get() {
      return timezoneOptions.value.find((x) => x.value === businessHours.tz);
    },
    set(timezoneEntry) {
      businessHours.tz = timezoneEntry.value;
    },
  });

  watch(
    () => props.modelValue,
    (newValue) => {
      const updated = Object.assign({}, defaultBusinessHours, JSON.parse(JSON.stringify(newValue)));
      Object.keys(updated).map((x) => {
        businessHours[x] = updated[x];
      });
    },
  );

  function onCancel(close) {
    close();
  }

  const toggle = (event) => {
    overlay.value.toggle(event);
  };

  function close() {
    overlay.value.hide();
  }
  function onSave() {
    emit('update:modelValue', JSON.parse(JSON.stringify(businessHours)));
    overlay.value.hide();
  }

  onMounted(() => {
    // if this is first time business hours are enabled, initialize with default
    if (!props.modelValue || !('wd' in props.modelValue)) {
      emit('update:modelValue', JSON.parse(JSON.stringify(businessHours)));
    }
  });
</script>
