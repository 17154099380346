/*
 * Test Result Store
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useTestResultsStore = defineStore('testResults', () => {
  // TODO Cache

  // Current test result
  const testResult = ref({});

  // Getters for special properties
  const status = computed(() => testResult.value?.test?.status || '');
  const mosScore = computed(() => (isNaN(testResult.value?.audio?.MOS) ? 0 : Number(Math.round(testResult.value.audio.MOS + 'e2') + 'e-2')));

  // Getters for general data blocks in test result
  const test = computed(() => testResult.value?.test || {});
  const context = computed(() => testResult.value?.context || {});

  // TODO Move to context
  const analysis = computed(() => testResult.value?.analysis || {});
  const call = computed(() => testResult.value?.call || {});
  const audio = computed(() => testResult.value?.audio || {});

  const transcript = computed(() => testResult.value?.context?.transcript || []);
  const transcriptData = computed(() => testResult.value?.context?.transcript?.data || []);
  const transcriptRecording = computed(() => testResult.value?.context?.transcript?.recording || null);

  // transcript of conversation between Caller and Bot
  const conversationTranscript = computed(() => transcript.value.filter((x) => x?.actor === 'Caller' || x?.actor === 'Bot'));

  // Number(Math.round(testResult.value.audio.MOS+'e2')+'e-2'); // 1.01

  function loadTestResult(context, testId) {
    // TODO Support cache
    const testResultUrl = `/store/DEMO/results/${context}/${testId}/result.json`;
    console.log(`Loading test result: ${testResultUrl}`);
    fetch(testResultUrl)
      .then((response) => response.json())
      .then((data) => (this.testResult = data))
      .catch((e) => {
        console.log(`Exception retrieving test results ${testResultUrl}: ${e.message}`);
      });
  }

  return {
    testResult,
    context,
    status,
    mosScore,
    test,
    analysis,
    call,
    audio,
    transcript,
    transcriptData,
    transcriptRecording,
    conversationTranscript,
    loadTestResult,
  };
});
