<template>
  <div>
    <button
      type="button"
      :class="[
        'inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
        hasValue ? '' : 'border-2 border-red-400',
      ]"
      @click="onOpen"
    >
      <mdicon name="pound" size="18" aria-hidden="true"></mdicon>
      {{ current }}
      <MagnifyingGlassIcon class="border-l pl-1 ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100" aria-hidden="true" />
    </button>
    <div class="block text-sm leading-6 text-gray-600">{{ currentDescription }}</div>
    <ItemSelector :items="allChannels" icon="pound" placeholder="Search Channels ..." :progress="progress" :open="open" @select="onSelect" @close="open = false"></ItemSelector>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import ItemSelector from '@/components/Selectors/ItemSelector.vue';
  import { useBotsStore } from '@/store/BotsStore';
  import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';

  const props = defineProps({
    modelValue: {
      type: Object,
      default() {
        return {};
      },
    },
  });
  const emit = defineEmits(['update:modelValue']);

  const botsStore = useBotsStore();
  const open = ref(false);
  const progress = ref(false);
  const allChannels = ref([]);
  const current = computed(() => props.modelValue?.name || 'select channel');
  const currentDescription = computed(() => props.modelValue?.description || '');
  const hasValue = computed(() => props.modelValue?.id);

  async function onOpen() {
    allChannels.value = [];
    open.value = true;
    progress.value = true;
    const channelsRes = await botsStore.slackGetChannels();
    progress.value = false;
    if (!channelsRes.success || !Array.isArray(channelsRes.data)) {
      console.log(`Error: failed to get channels`);
      return;
    }
    allChannels.value = channelsRes.data.map((x) => {
      return {
        id: x.id,
        name: x.name,
        description: x?.purpose?.value || '',
      };
    });
  }

  function onSelect(item) {
    open.value = false;
    emit('update:modelValue', item);
  }
</script>
