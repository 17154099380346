<template>
  <div class="space-y-5 divide-y divide-gray-900/10">
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">{{ botsStore.botTypeTitle }}</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Specify name and description for the Bot</p>
        </div>
      </div>

      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div class="sm:col-span-4">
              <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
              <div class="mt-2">
                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Specify Job Name"
                    v-model="specName"
                  />
                </div>
              </div>
            </div>

            <InputTextarea class="col-span-full" name="description" v-model="specDescription" label="Description" placeholder="Provide brief description of the bot"></InputTextarea>

            <!--<div class="col-span-full">
              <ToggleGroup v-model="stepSummarizeEnabled" :name="stepsDefs['analyzeSummarize'].title" :description="stepsDefs['analyzeSummarize'].description"></ToggleGroup>
              <ToggleGroup v-model="stepSentimentEnabled" :name="stepsDefs['analyzeSentiment'].title" :description="stepsDefs['analyzeSentiment'].description"></ToggleGroup>
              <ToggleGroup v-model="stepProblemsEnabled" :name="stepsDefs['analyzeProblems'].title" :description="stepsDefs['analyzeProblems'].description"></ToggleGroup>
              <ToggleGroup v-model="stepKPIEnabled" :name="stepsDefs['analyzeKPI'].title" :description="stepsDefs['analyzeKPI'].description">
                <label for="kpicollection" class="mt-2 block text-sm font-medium leading-6 text-gray-900">KPI Set to analyze</label>
                <CollectionSelector id="kpicollection" v-model="specScriptKpiCollection" type="kpi"></CollectionSelector>
              </ToggleGroup>
              <ToggleGroup v-model="stepAgentEnabled" :name="stepsDefs['analyzeAgent'].title" :description="stepsDefs['analyzeAgent'].description"></ToggleGroup>
            </div>-->
          </div>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Behavior</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Describe bot Personality and Behavior. Add key facts Bot should always know about to the Context.</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <InputTextarea
              class="col-span-full"
              name="personality"
              v-model="personality"
              label="Personality and Behavior"
              description="Describe Bot Personality and how it should behave."
              placeholder="For example:"
              :rows="2"
            ></InputTextarea>

            <InputTextarea
              class="col-span-full"
              name="globalContext"
              v-model="globalContext"
              label="Context"
              description="Describe facts Bot should always know about"
              placeholder="This could be a description of the services your provide, key facts, concise summary of your home page"
              :rows="2"
            ></InputTextarea>

            <InputTextarea
              class="col-span-full"
              name="engageMessage"
              v-model="engageMessage"
              :label="propsDefs['engageMessage'].title"
              :description="propsDefs['engageMessage'].description"
              :placeholder="propsDefs['engageMessage'].placeholder"
              :rows="1"
            ></InputTextarea>

            <div class="sm:col-span-4">
              <label for="name" class="block text-sm font-medium leading-6 text-gray-900">{{ propsDefs['engageTimeout'].title }}</label>
              <div class="text-xs text-gray-500">{{ propsDefs['engageTimeout'].description }}</div>
              <div class="mt-2">
                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="number"
                    name="engageTimeout"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    v-model="engageTimeout"
                  />
                </div>
              </div>
            </div>

            <InputTextarea
              class="col-span-full"
              name="welcomeMessage"
              v-model="welcomeMessage"
              :label="propsDefs['welcomeMessage'].title"
              :description="propsDefs['welcomeMessage'].description"
              :placeholder="propsDefs['welcomeMessage'].placeholder"
              :rows="2"
            ></InputTextarea>

            <InputTextarea
              class="col-span-full"
              name="declineMessage"
              v-model="declineMessage"
              :label="propsDefs['declineMessage'].title"
              :description="propsDefs['declineMessage'].description"
              :placeholder="propsDefs['declineMessage'].placeholder"
              :rows="2"
            ></InputTextarea>
          </div>
        </div>
      </div>
    </div>

    <BotSettingsEditorKB></BotSettingsEditorKB>

    <!-- TODO Remove
    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Collect Data</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Collect Contact Data</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <InputTextarea
              class="col-span-full"
              name="startupFormTitle"
              v-model="contactFormTitle"
              :label="propsDefs['contactForm.title'].title"
              :description="propsDefs['contactForm.title'].description"
              :placeholder="propsDefs['contactForm.title'].placeholder"
              :rows="1"
            ></InputTextarea>
            <StdPropArrayEditor
              class="col-span-full"
              v-model="contactFormFields"
              :label="propsDefs['contactForm.fields'].title"
              :description="propsDefs['contactForm.fields'].description"
              :propertiesSpec="startupFormSpecProps"
            >
            </StdPropArrayEditor>
            <ToggleGroup
              class="col-span-full"
              v-model="collectContactShowOnStartup"
              :name="propsDefs['collectContactShowOnStartup'].title"
              :description="propsDefs['collectContactShowOnStartup'].description"
            ></ToggleGroup>
          </div>
        </div>
      </div>
    </div>-->

    <BotSettingsEditorCollectData></BotSettingsEditorCollectData>

    <BotSettingsEditorCues></BotSettingsEditorCues>

    <BotSettingsEditorHandoff></BotSettingsEditorHandoff>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Business Hours</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Adjust Bot behavior depending on Business Hours</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <ToggleGroup class="col-span-full" v-model="businessHoursEnabled" :name="propsDefs['businessHoursEnabled'].title" :description="propsDefs['businessHoursEnabled'].description">
              <div class="mt-2 pl-4 col-span-full border-l-2 border-indigo-100 grid grid-cols-4 gap-x-2">
                <StdBusinessHoursEditor class="col-span-full" v-model="businessHours" :label="propsDefs['businessHours'].title" :description="propsDefs['businessHours'].description"> </StdBusinessHoursEditor>

                <div class="mt-2 col-span-2 text-base font-semibold leading-7 text-gray-900 p-2 border-b">Open</div>
                <div class="mt-2 col-span-2 text-base font-semibold leading-7 text-gray-900 p-2 border-b">Closed</div>

                <ToggleGroup
                  v-if="handoffEnableSlackEscalation"
                  class="col-span-2"
                  v-model="handoffSlackWhenOpen"
                  :name="propsDefs['handoffSlackWhenOpen'].title"
                  :description="propsDefs['handoffSlackWhenOpen'].description"
                ></ToggleGroup>
                <ToggleGroup
                  v-if="handoffEnableSlackEscalation"
                  class="col-span-2"
                  v-model="handoffSlackWhenClosed"
                  :name="propsDefs['handoffSlackWhenClosed'].title"
                  :description="propsDefs['handoffSlackWhenClosed'].description"
                ></ToggleGroup>

                <ToggleGroup
                  v-if="handoffEnableEmailEscalation"
                  class="col-span-2"
                  v-model="handoffEmailWhenOpen"
                  :name="propsDefs['handoffEmailWhenOpen'].title"
                  :description="propsDefs['handoffEmailWhenOpen'].description"
                ></ToggleGroup>
                <ToggleGroup
                  v-if="handoffEnableEmailEscalation"
                  class="col-span-2"
                  v-model="handoffEmailWhenClosed"
                  :name="propsDefs['handoffEmailWhenClosed'].title"
                  :description="propsDefs['handoffEmailWhenClosed'].description"
                ></ToggleGroup>

                <ToggleGroup
                  v-if="handoffEnableZendeskEscalation"
                  class="col-span-2"
                  v-model="handoffZendeskWhenOpen"
                  :name="propsDefs['handoffZendeskWhenOpen'].title"
                  :description="propsDefs['handoffZendeskWhenOpen'].description"
                ></ToggleGroup>
                <ToggleGroup
                  v-if="handoffEnableZendeskEscalation"
                  class="col-span-2"
                  v-model="handoffZendeskWhenClosed"
                  :name="propsDefs['handoffZendeskWhenClosed'].title"
                  :description="propsDefs['handoffZendeskWhenClosed'].description"
                ></ToggleGroup>

                <InputTextarea
                  class="mt-4 col-span-full"
                  name="engageMessage"
                  v-model="handoffMessageWhenClosed"
                  :label="propsDefs['handoffMessageWhenClosed'].title"
                  :description="propsDefs['handoffMessageWhenClosed'].description"
                  :placeholder="propsDefs['handoffMessageWhenClosed'].placeholder"
                  :rows="1"
                ></InputTextarea>
              </div>
            </ToggleGroup>
          </div>
        </div>
      </div>
    </div>

    <BotSettingsEditorCSat />

    <BotSettingsEditorVoice />

    <div v-if="showAdvanced" class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <p class="mt-1 text-sm leading-6 text-gray-600">Show advanced properties</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div class="col-span-full">
              <ToggleGroup v-model="advancedEnabled" name="Advanced" description="">
                <InputTextarea class="col-span-full" name="systemPrompt" v-model="systemPrompt" label="System Prompt" placeholder="" :rows="2"></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="globalInstructionsTemplate"
                  v-model="globalInstructionsTemplate"
                  label="Global Instructions template"
                  description="Give bot instructions what it should do and how it should act"
                  placeholder="For example:"
                  :rows="4"
                ></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="handoffInstructionsTemplate"
                  v-model="handoffInstructionsTemplate"
                  label="Handoff instructions template"
                  placeholder="For example:"
                  :rows="4"
                ></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="handoffCollectDataInstructionsTemplate"
                  v-model="handoffCollectDataInstructionsTemplate"
                  :label="propsDefs['handoffCollectDataInstructionsTemplate'].title"
                  :description="propsDefs['handoffCollectDataInstructionsTemplate'].description"
                  :placeholder="propsDefs['handoffCollectDataInstructionsTemplate'].placeholder"
                  :rows="4"
                ></InputTextarea>
                <ToggleGroup class="col-span-full" v-model="includeImages" :name="propsDefs['includeImages'].title" :description="propsDefs['includeImages'].description"></ToggleGroup>
                <ToggleGroup class="col-span-full" v-model="citeSources" :name="propsDefs['citeSources'].title" :description="propsDefs['citeSources'].description"></ToggleGroup>

                <InputString
                  class="mt-4 col-span-full"
                  name="overrideModel"
                  v-model="modelOverride"
                  :label="propsDefs['modelOverride'].title"
                  :description="propsDefs['modelOverride'].description"
                  :placeholder="propsDefs['modelOverride'].placeholder"
                  :rows="4"
                ></InputString>
                <InputString
                  class="mt-4 col-span-full"
                  name="implementationVersion"
                  v-model="implementationVersion"
                  :label="propsDefs['implementationVersion'].title"
                  :description="propsDefs['implementationVersion'].description"
                  :placeholder="propsDefs['implementationVersion'].placeholder"
                  :rows="4"
                ></InputString>
              </ToggleGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { pathOr } from 'ramda';
  import { useRoute } from 'vue-router';
  import { useBotsStore } from '@/store/BotsStore';
  import ToggleGroup from '@/components/Toggles/ToggleGroup.vue';
  import InputTextarea from '@/components/Bots/editors/InputTextarea.vue';
  import InputString from '@/components/Bots/editors/InputString.vue';
  import SlackChannelSelector from '@/components/Selectors/SlackChannelSelector.vue';
  import { BOT_TYPE_DEFS } from '@/spec/BotTypeDefs';
  import StdPropArrayEditor from '@/components/Editors/StdPropArrayEditor.vue';
  import StdBusinessHoursEditor from '@/components/Editors/StdBusinessHoursEditor.vue';
  import BotSettingsEditorCSat from '@/components/Bots/editors/BotSettingsEditorCSat.vue';
  import BotSettingsEditorVoice from '@/components/Bots/editors/BotSettingsEditorVoice.vue';
  import BotSettingsEditorKB from '@/components/Bots/editors/BotSettingsEditorKB.vue';
  import BotSettingsEditorHandoff from '@/components/Bots/editors/BotSettingsEditorHandoff.vue';
  import BotSettingsEditorCues from '@/components/Bots/editors/BotSettingsEditorCues.vue';
  import BotSettingsEditorCollectData from '@/components/Bots/editors/BotSettingsEditorCollectData.vue';
  import KBSelector from '@/components/Selectors/KBSelector.vue';

  const route = useRoute();
  const showAdvanced = computed(() => 'advanced' in (route?.query || {}));
  const botsStore = useBotsStore();
  // TEMP
  const advancedEnabled = ref(false);

  // TODO Refine this - move to BotTypeDefs
  const propsDefs = {};
  (BOT_TYPE_DEFS['questionAnswering']?.props || []).map((p) => {
    propsDefs[p.name] = {
      title: p?.title || '',
      description: p?.description || '',
      default: p?.default || '',
      placeholder: p?.placeholder || '',
    };
  });

  // Can pass array or dot notation path, like "spec.bot.prop"
  // TODO Generalize - move to hooks ? or store ?
  function getPropGetSet(propPath) {
    const pp = Array.isArray(propPath) ? propPath : propPath.split('.');
    return {
      get() {
        return pathOr(null, pp, botsStore.botSpec);
      },
      set(newValue) {
        botsStore.setBotSpecProp(pp, newValue);
      },
    };
  }

  const handoffCollectDataSpecProps = [
    { name: 'name', type: 'string', title: 'Name', default: '' },
    { name: 'type', type: 'select', title: 'Type', options: ['string', 'integer'], default: 'string' },
    { name: 'description', type: 'stringLong', title: 'Description', default: '' },
  ];

  const startupFormSpecProps = [
    { name: 'name', type: 'string', title: 'Name', default: '' },
    { name: 'title', type: 'string', title: 'Title', default: '' },
    { name: 'type', type: 'select', title: 'Type', options: ['Text', 'Email Address', 'Phone Number', 'Select'], values: ['string', 'email', 'phone', 'select'], default: 'string' },
    { name: 'options', type: 'stringLong', title: 'Options', default: '', when: (i) => i.type === 'select' },
    { name: 'required', type: 'boolean', title: 'Required', default: 'false' },
    //{ name: 'description', type: 'stringLong', title: 'Description', default: '' },
  ];

  const specName = computed(getPropGetSet('name'));
  const specDescription = computed(getPropGetSet('description'));

  const engageMessage = computed(getPropGetSet('engageMessage'));
  const engageTimeout = computed(getPropGetSet('engageTimeout'));
  const welcomeMessage = computed(getPropGetSet('welcomeMessage'));
  const declineMessage = computed(getPropGetSet('declineMessage'));

  const personality = computed(getPropGetSet('personality'));
  const globalContext = computed(getPropGetSet('globalContext'));

  const handoffCriteria = computed(getPropGetSet('handoffCriteria'));
  const handoffMessage = computed(getPropGetSet('handoffMessage'));
  const handoffInstructionsTemplate = computed(getPropGetSet('handoffInstructionsTemplate'));

  const handoffEnableSlackEscalation = computed(botsStore.getPropGetSet('handoffEnableSlackEscalation'));
  const handoffEnableEmailEscalation = computed(botsStore.getPropGetSet('handoffEnableEmailEscalation'));
  const handoffEnableZendeskEscalation = computed(botsStore.getPropGetSet('handoffEnableZendeskEscalation'));

  const contactFormTitle = computed(getPropGetSet('contactForm.title'));
  const contactFormFields = computed(getPropGetSet('contactForm.fields'));
  const collectContactMakeForm = computed(getPropGetSet('collectContactMakeForm'));
  const collectContactShowOnStartup = computed(getPropGetSet('collectContactShowOnStartup'));

  const handoffCollectDataSpec = computed(getPropGetSet('handoffCollectDataSpec'));
  const handoffCollectDataInstructionsTemplate = computed(getPropGetSet('handoffCollectDataInstructionsTemplate'));

  const systemPrompt = computed(getPropGetSet('systemPrompt'));
  const globalInstructionsTemplate = computed(getPropGetSet('globalInstructionsTemplate'));

  const includeImages = computed(getPropGetSet('includeImages'));
  const citeSources = computed(getPropGetSet('citeSources'));

  const modelOverride = computed(getPropGetSet('modelOverride'));
  const implementationVersion = computed(getPropGetSet('implementationVersion'));

  const businessHoursEnabled = computed(getPropGetSet('businessHoursEnabled'));
  const businessHours = computed(getPropGetSet('businessHours'));
  const handoffSlackWhenClosed = computed(getPropGetSet('handoffSlackWhenClosed'));
  const handoffSlackWhenOpen = computed(getPropGetSet('handoffSlackWhenOpen'));
  const handoffEmailWhenClosed = computed(getPropGetSet('handoffEmailWhenClosed'));
  const handoffEmailWhenOpen = computed(getPropGetSet('handoffEmailWhenOpen'));
  const handoffZendeskWhenClosed = computed(getPropGetSet('handoffZendeskWhenClosed'));
  const handoffZendeskWhenOpen = computed(getPropGetSet('handoffZendeskWhenOpen'));
  const handoffMessageWhenClosed = computed(getPropGetSet('handoffMessageWhenClosed'));

  async function getChannels() {
    const getChannelsResult = await botsStore.slackGetChannels();
    console.log(`Get channels result:`, getChannelsResult);
  }
</script>
