/*
 * Layout Store
 * */
import { defineStore } from 'pinia';
import { ref, reactive } from 'vue';

export const useDocumentationStore = defineStore('documentation', () => {
  const docMdFiles = import.meta.glob('@/docs/**/*.md', { as: 'raw', eager: true });
  for (const mdf in docMdFiles) {
    console.log(`MD File: ${mdf}`);
  }
  const docFiles = ref(docMdFiles);

  function setDark() {
    // +++
  }

  return {
    docFiles,
    setDark,
  };
});
