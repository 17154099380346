<template>
  <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
    <div class="px-4 sm:px-0 relative">
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Cues</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">
          Enable Cues. Configure bot which subset of Knowledge base to use depending on Bot placement or user input. You can use Cues for the case when Bot needs to handle several semi- or un-related topics.
        </p>
      </div>
    </div>
    <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
      <div class="px-4 py-2">
        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <ToggleGroup class="col-span-full" v-model="cuesEnabled" :name="propsDefs['cuesEnabled'].title" :description="propsDefs['cuesEnabled'].description">
            <div class="mt-2 pl-4 col-span-full border-l-2 border-indigo-100">
              <StdPropArrayEditor
                class="col-span-full"
                v-model="cuesWebPlacement"
                :label="propsDefs['cuesWebPlacement'].title"
                :description="propsDefs['cuesWebPlacement'].description"
                :propertiesSpec="cuesWebPlacementFormSpecProps"
              >
              </StdPropArrayEditor>
            </div>

            <div class="mt-2 pl-4 col-span-full border-l-2 border-indigo-100">
              <StdPropArrayEditor
                class="col-span-full"
                v-model="cuesContactInformation"
                :label="propsDefs['cuesContactInformation'].title"
                :description="propsDefs['cuesContactInformation'].description"
                :propertiesSpec="cuesContactInformationSpecProps"
              >
              </StdPropArrayEditor>
            </div>
          </ToggleGroup>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import InputTextarea from '@/components/Bots/editors/InputTextarea.vue';
  import ToggleGroup from '@/components/Toggles/ToggleGroup.vue';
  import StdBusinessHoursEditor from '@/components/Editors/StdBusinessHoursEditor.vue';
  import { BOT_TYPE_DEFS } from '@/spec/BotTypeDefs';
  import { useBotsStore } from '@/store/BotsStore';
  import { pathOr } from 'ramda';
  import { computed } from 'vue';
  import StdPropArrayEditor from '@/components/Editors/StdPropArrayEditor.vue';

  const botsStore = useBotsStore();

  const cuesWebPlacementFormSpecProps = [
    { name: 'urlPattern', type: 'string', title: 'URL Pattern', default: '' },
    { name: 'type', type: 'select', title: 'Cue Type', options: ['KB Document'], values: ['kbDoc'], default: 'kbDoc' },
    { name: 'value', type: 'string', title: 'Value', default: '' },
    //{ name: 'required', type: 'boolean', title: 'Required', default: 'false' },
    //{ name: 'description', type: 'stringLong', title: 'Description', default: '' },
  ];

  const cuesContactInformationSpecProps = [
    { name: 'rule', type: 'stringLong', title: 'Rule', default: '', description: 'define rule based on contact information fields' },
    { name: 'type', type: 'select', title: 'Cue Type', options: ['KB Document', 'KB Tags'], values: ['kbDoc', 'kbTags'], default: 'kbDoc' },
    { name: 'value', type: 'string', title: 'Value', default: '' },
  ];

  // TODO Refine this - move to BotTypeDefs
  const propsDefs = {};
  (BOT_TYPE_DEFS['questionAnswering']?.props || []).map((p) => {
    propsDefs[p.name] = {
      title: p?.title || '',
      description: p?.description || '',
      default: p?.default || '',
      placeholder: p?.placeholder || '',
    };
  });

  // Can pass array or dot notation path, like "spec.bot.prop"
  // TODO Generalize - move to hooks ? or store ?
  function getPropGetSet(propPath) {
    const pp = Array.isArray(propPath) ? propPath : propPath.split('.');
    return {
      get() {
        return pathOr(null, pp, botsStore.botSpec);
      },
      set(newValue) {
        botsStore.setBotSpecProp(pp, newValue);
      },
    };
  }

  const cuesEnabled = computed(getPropGetSet('cuesEnabled'));
  const cuesWebPlacement = computed(getPropGetSet('cuesWebPlacement'));
  const cuesContactInformation = computed(getPropGetSet('cuesContactInformation'));
</script>
