/*
 * Scenarios Store
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useCollection, useDocument } from 'vuefire';
import { collection } from 'firebase/firestore';
import { doc, setDoc, getDoc, deleteDoc } from 'firebase/firestore';
import { fbDb } from '@/firebaseApp';

const EMPTY_SCENARIO_SPEC = {
  name: 'New Scenario',
  media: 'recording',
};

export const useScenariosStore = defineStore('scenarios', () => {
  const authStore = useAuthStore();

  const tenantId = computed(() => authStore.tenantId);

  // List of all scenarios, with subscription - will be updated if any document is changed in the collection
  const scenarios = useCollection(collection(fbDb, `tenants/${tenantId.value}/scenarios`));

  // TODO Reconsider - are those really needed ?
  let currentScenarioDoc = ref({}); // Scenario document data
  let currentScenarioSpec = ref({}); // Scenario spec

  // TODO Pass flag new: true | false, so we can do extra check - if doc was supposed to exist but does not
  // Load existing or create new empty scenario document
  async function loadScenario(scenarioId) {
    const emptySpec = Object.assign({ id: scenarioId }, EMPTY_SCENARIO_SPEC);
    const scenarioDocRef = doc(fbDb, `tenants/${tenantId.value}/scenarios`, scenarioId);
    const scenarioDoc = await getDoc(scenarioDocRef);
    if (scenarioDoc.exists()) {
      console.log('Document data:', scenarioDoc.data());
      currentScenarioDoc.value = scenarioDoc.data();
      const scenarioSpecDoc = await getDoc(doc(scenarioDocRef, 'versions', 'current'));
      if (!scenarioSpecDoc.exists()) {
        currentScenarioSpec.value = emptySpec;
      } else {
        const specData = scenarioSpecDoc.data();
        let spec = null;
        try {
          spec = JSON.parse(specData.spec);
        } catch (e) {
          console.log(`Scenario[${scenarioId}]: Failed to parse spec JSON: ${e.message}`);
          spec = emptySpec;
        }
        currentScenarioSpec.value = spec;
      }
    } else {
      console.log(`Scenario[${scenarioId}]: Does not exist, assuming new`);
      currentScenarioSpec.value = emptySpec;
      // TODO Doc
      //  - created
      //  - by
    }
    return currentScenarioSpec.value;
  }
  // TODO params

  // Update or Add scenario (upsert)
  async function setScenario(scenario) {
    const scenarioId = scenario?.id || null;
    if (!scenarioId) {
      return false; // TODO error
    }

    try {
      await setDoc(doc(fbDb, `tenants/${tenantId.value}/scenarios`, scenarioId), {
        name: scenario?.name || '',
        description: scenario?.description || '',
        media: scenario?.media || '',
        updated: Date.now(),
        // TODO updated by
      });
    } catch (e) {
      console.log(`Scenario[${scenarioId}]: Error writing document: ${e.message}`);
      return false;
    }

    const scenarioSpec = JSON.stringify(scenario);
    // Now write spec
    try {
      await setDoc(doc(fbDb, `tenants/${tenantId.value}/scenarios/${scenarioId}/versions`, 'current'), {
        spec: scenarioSpec,
      });
    } catch (e) {
      console.log(`Scenario[${scenarioId}]: Error writing spec: ${e.message}`);
      return false;
    }
    return true;
  }

  async function deleteScenario(scenarioId) {
    try {
      // TODO Delete versions collection ?
      await deleteDoc(doc(fbDb, `tenants/${tenantId.value}/scenarios`, scenarioId));
    } catch (e) {
      console.log(`Scenario[${scenarioId}]: Error deleting scenario: ${e.message}`);
      return false;
    }
    return true;
  }

  return {
    scenarios,
    currentScenarioDoc,
    currentScenarioSpec,
    loadScenario,
    setScenario,
    deleteScenario,
  };
});
