/*
 * Date range Store for analytics
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export const useDateRangeStore = defineStore('dateRange', () => {
  const from = ref('P30D'); // ref('P14D');
  const to = ref('now');
  const title = ref('Last 30 Days'); //ref('Last 14 Days');
  // TODO Revisit default: this conflicts with groupingInterval.value
  const interval = ref('1H');

  const timeIntervalChanged = ref(0);

  // TODO Resolve from in timeInterval using local time zone, known in browser, i.e.:
  /*
      if (fromStr.startsWith('P')) {
        // Attempt to process as ISO_8601 time interval
        let d = moment.duration(fromStr);
        if (d.isValid()) {
          dms = d.asMilliseconds();
          this.from = this.to - dms; // time interval or 5 min in the past
        }
      } else if (fromStr.startsWith('StartOf')) {
        // Attempt to process as "Start Of", i.e. "StartOfDay", "StartOfWeek"
        let st = fromStr.replace('StartOf', '').toLowerCase();
        let d = moment().startOf(st);
        if (d.isValid()) {
          this.from = d.valueOf();
        }
      }
  */

  const timeInterval = computed(() => {
    return {
      from: from.value,
      to: to.value,
      interval: groupingInterval.value,
    };
  });

  const groupingInterval = computed(() => {
    const d = calculateDuration();
    if (d < 2 * 60 * 60 * 1000) {
      return '1m';
    } else if (d < 6 * 60 * 60 * 1000) {
      return '30m';
    } else if (d < 2 * 24 * 60 * 60 * 1000) {
      return '1H';
    } else if (d < 8 * 24 * 60 * 60 * 1000) {
      return '6H';
    } else if (d < 15 * 24 * 60 * 60 * 1000) {
      return '12H';
    } else {
      return '1D';
    }
  });

  function isSameTimeInterval(timeInterval = {}) {
    return (timeInterval?.from || null) === from.value && (timeInterval?.to || null) === to.value && (timeInterval?.interval || null) === interval.value;
  }

  function isChangedSince(ts) {
    return ts !== timeIntervalChanged.value;
  }

  function isSameFromTo(timeInterval = {}) {
    return (timeInterval?.from || null) === from.value && (timeInterval?.to || null) === to.value;
  }

  // Set dark mode true or false
  function setDateRange(fromDate, toDate, rangeTitle = null) {
    from.value = fromDate;
    to.value = toDate;
    if (rangeTitle) {
      title.value = rangeTitle;
    } else {
      // Generate title
      title.value = `${dayjs(from.value).format('MM/DD/YYYY hh:mm:ss')} - ${dayjs(to.value).format('MM/DD/YYYY hh:mm:ss')}`;
    }
    interval.value = groupingInterval.value;
    timeIntervalChanged.value = Date.now();
  }

  function calculateDuration() {
    let currTo = 0;
    let currFrom = 0;

    let currentTimeMs = Date.now();
    currTo = currentTimeMs; // Current time by default
    let toStr = to.value;
    if (toStr !== 'now') {
      let parsedTo = parseInt(toStr); // This will take either int or string
      if (!Number.isNaN(parsedTo)) {
        currTo = parsedTo;
      }
    }

    let fromStr = from.value;
    let dms = 5 * 60 * 1000; // 5 mins in the past by default
    let parsedFrom = parseInt(fromStr); // This will take either int or string
    if (!Number.isNaN(parsedFrom)) {
      currFrom = parsedFrom;
    } else {
      if (fromStr.startsWith('P')) {
        // Attempt to process as ISO_8601 time interval
        let d = dayjs.duration(fromStr);
        if (dayjs.isDuration(d)) {
          dms = d.asMilliseconds();
          currFrom = currTo - dms; // time interval or 5 min in the past
        }
      } else if (fromStr.startsWith('StartOf')) {
        // Attempt to process as "Start Of", i.e. "StartOfDay", "StartOfWeek"
        let st = fromStr.replace('StartOf', '').toLowerCase();
        let d = dayjs().startOf(st);
        if (d.isValid()) {
          currFrom = d.valueOf();
        }
      }
    }

    let duration = currTo - currFrom;
    return duration;
  }

  return {
    from,
    to,
    title,
    timeInterval,
    timeIntervalChanged,
    setDateRange,
    isSameTimeInterval,
    isSameFromTo,
    isChangedSince,
  };
});
