<template>
  <div>
    <Dialog v-model:visible="visible" modal :closable="false" header="Scrape Document from Web Page" :style="{ width: '60vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="flex flex-col gap-1">
        <label for="docname">URL</label>
        <small id="docname-help">Enter URL to scrape document from</small>
        <InputText id="docname" class="" v-model="kbDocumentName" aria-describedby="docname-help" />
      </div>
      <div class="mt-2 flex flex-col gap-1">
        <label for="doctitle">Title</label>
        <small id="doctitle-help">Title for the new Document</small>
        <InputText id="doctitle" class="" v-model="kbDocumentTitle" aria-describedby="doctitle-help" />
      </div>
      <div v-if="contentReady" class="mt-4">
        <div class="my-2">Preview</div>
        <TuiViewer :initial-value="kbDocumentContent" class="h-[300px] overflow-auto border px-2 py-1 rounded bg-slate-50" />
      </div>
      <div class="mt-4">
        <small class="min-h-6 block">{{ progressText }} </small>
        <ProgressBar :mode="progressMode" style="height: 6px"></ProgressBar>
      </div>
      <div v-if="errorMessage" class="w-full my-4">
        <Message :closable="false" severity="error">{{ errorMessage }}</Message>
      </div>
      <template #footer>
        <Button label="Cancel" :disabled="showProgress" severity="secondary" @click="visible = false" autofocus />
        <Button v-if="!scraped" label="Scrape" :disabled="showProgress" severity="primary" @click="scrapeDocument" autofocus />
        <Button v-else label="Create" :disabled="!contentReady || showProgress" severity="primary" @click="createDocument" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
  import { useRbacStore } from '@/store/RBACStore';
  import { useKbStore } from '@/store/KbStore';
  import { useApi } from '@/hooks/useApi';
  import { useToast } from 'vue-toastification';
  import { ref, nextTick, watch, computed } from 'vue';
  import InputText from 'primevue/inputtext';
  import Dialog from 'primevue/dialog';
  import Button from 'primevue/button';
  import ProgressBar from 'primevue/progressbar';
  import Message from 'primevue/message';
  import TuiViewer from '@/components/Editors/TuiViewer.vue';

  const kbStore = useKbStore();
  const api = useApi();

  const toast = useToast();

  const visible = ref(false);
  const scraped = ref(false);
  const showProgress = ref(false);
  const progressMode = computed(() => (showProgress.value ? 'indeterminate' : ''));
  const progressText = ref('');
  const contentReady = ref(false);
  const errorMessage = ref(null);

  const kbDocumentName = ref('');
  const kbDocumentTitle = ref('');
  //watch()
  watch(kbDocumentName, (newValue) => {
    scraped.value = false;
  });

  const kbDocumentContent = ref('');

  const emit = defineEmits(['documentCreated']);

  function start() {
    kbDocumentName.value = '';
    kbDocumentContent.value = '';
    contentReady.value = false;
    progressText.value = '';
    showProgress.value = false;
    errorMessage.value = null;
    visible.value = true;
  }

  async function scrapeDocument() {
    contentReady.value = false;

    progressText.value = 'Scraping URL (this may take a minute) ...';
    showProgress.value = true;

    const scrapeResult = await api.scrapeKbDocument({
      kbId: kbStore.kbId,
      url: kbDocumentName.value,
    });
    progressText.value = '';
    showProgress.value = false;
    if (!scrapeResult.success) {
      errorMessage.value = `Error scraping document: ${scrapeResult.error}`;
      toast.error(errorMessage.value);
      return;
    }

    kbDocumentContent.value = scrapeResult.data?.markdown || scrapeResult.data?.content || 'No data retrieved';
    contentReady.value = true;
    scraped.value = true;
  }

  async function createDocument() {
    progressText.value = 'Creating document ...';
    showProgress.value = true;
    const meta = {};
    if (kbDocumentTitle.value && kbDocumentTitle.value.length > 0) {
      meta.title = kbDocumentTitle.value;
    }
    const createRes = await kbStore.createKbDocument(kbDocumentName.value, kbDocumentContent.value, null, null, meta);
    progressText.value = '';
    showProgress.value = false;
    visible.value = false;
    if (!createRes.success) {
      toast.error(`Error creating document: ${createRes.error}`);
      return;
    }
    await nextTick();
    emit('documentCreated', createRes.data);
  }

  defineExpose({ start });
</script>
