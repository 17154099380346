<template>
  <div>
    <label v-if="label" :for="name" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
    <div v-if="description" class="text-xs text-gray-500">{{ description }}</div>
    <button
      type="button"
      :class="[
        'mt-2 inline-flex items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
        hasValue ? '' : 'border-2 border-red-400',
      ]"
      @click="onOpen"
    >
      <mdicon name="database" size="18" class="mr-2" aria-hidden="true"></mdicon>
      {{ current?.name || '' }}
      <MagnifyingGlassIcon class="border-l pl-2 ml-3 -mr-1 h-7 w-7 text-violet-200 hover:text-violet-100" aria-hidden="true" />
    </button>
    <ItemSelector :items="allKbs" icon="database" placeholder="Search Knowledge Bases ..." :progress="progress" :open="open" @select="onSelect" @close="open = false"></ItemSelector>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import ItemSelector from '@/components/Selectors/ItemSelector.vue';
  import { useKbStore } from '@/store/KbStore';
  import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';

  const props = defineProps({
    modelValue: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  });
  const emit = defineEmits(['update:modelValue']);

  const kbStore = useKbStore();
  const open = ref(false);
  const progress = ref(false);
  const allKbs = computed(() => kbStore.knowledgeBases);
  const current = computed(() => (props.modelValue ? kbStore.knowledgeBases.find((x) => x.id === props.modelValue) : { name: 'default' }));
  const currentDescription = computed(() => props.modelValue || 'no description');
  const hasValue = computed(() => props.modelValue !== null);

  async function onOpen() {
    //allChannels.value = [];
    open.value = true;
    /*
    progress.value = true;
    const channelsRes = await botsStore.slackGetChannels();
    progress.value = false;
    if (!channelsRes.success || !Array.isArray(channelsRes.data)) {
      console.log(`Error: failed to get channels`);
      return;
    }
    allChannels.value = channelsRes.data.map((x) => {
      return {
        id: x.id,
        name: x.name,
        description: x?.purpose?.value || '',
      };
    });*/
  }

  function onSelect(item) {
    open.value = false;
    emit('update:modelValue', item.id);
  }
</script>
