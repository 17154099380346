<template>
  <div class="w-full relative" ref="ruleEditorContainer">
    <Button
      id="editorButton"
      type="button"
      @click="toggle"
      outlined
      severity="secondary"
      :pt="{
        root: {
          class: 'px-2 py-2 w-full',
        },
      }"
    >
      <div class="flex flex-row items-center justify-between w-full">
        <mdicon :name="getRangeOp().icon" size="22" class="flex-none text-slate-400 mr-2" aria-hidden="true"></mdicon>
        <div class="grow text-left">{{ label }}</div>
        <mdicon name="chevron-down" size="26" class="flex-none text-slate-400" aria-hidden="true"></mdicon>
      </div>
    </Button>

    <OverlayPanel ref="overlay">
      <div class="flex flex-col gap-2">
        <Dropdown v-model="selectedRangeOp" :options="rangeOps" optionLabel="label" placeholder="Select Op" :highlightOnSelect="false" class="">
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex items-center">
              <mdicon :name="slotProps.value.icon" size="18" class="text-slate-400 mr-2" aria-hidden="true"></mdicon>
              <div>{{ slotProps.value.label }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="w-full flex items-center">
              <mdicon :name="slotProps.option.icon" size="18" class="text-slate-400 mr-2" aria-hidden="true"></mdicon>
              <div>{{ slotProps.option.label }}</div>
            </div>
          </template>
        </Dropdown>
        <div class="flex align-items-center gap-3">
          <InputNumber v-model="updatedValue1" :useGrouping="false" showButtons />
        </div>
        <div v-if="selectedRangeOp.key === 'btw'" class="flex align-items-center gap-3 mb-5">
          <InputNumber v-model="updatedValue2" :useGrouping="false" showButtons />
        </div>
        <div class="flex justify-end gap-2">
          <Button type="button" label="Cancel" severity="secondary" @click="overlay.hide()" size="small"></Button>
          <Button type="button" label="Clear" severity="secondary" @click="onClear" size="small"></Button>
          <Button type="button" label="Apply" @click="onApply" size="small"></Button>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import InputText from 'primevue/inputtext';
  import SplitButton from 'primevue/splitbutton';
  import InputGroup from 'primevue/inputgroup';
  import InputGroupAddon from 'primevue/inputgroupaddon';
  import InputNumber from 'primevue/inputnumber';
  import OverlayPanel from 'primevue/overlaypanel';
  import Button from 'primevue/button';
  import Chips from 'primevue/chips';
  import Dropdown from 'primevue/dropdown';

  const props = defineProps({
    op: {
      type: String,
      default: 'eq',
    },
    value: {
      type: [Number, Array],
      default: null,
    },
  });
  const emit = defineEmits(['change']);

  const ruleEditorContainer = ref();
  const overlay = ref();

  const rangeOps = [
    { key: 'eq', label: 'Equal', icon: 'equal' },
    { key: 'gte', label: 'Greater', icon: 'greater-than-or-equal' },
    { key: 'lte', label: 'Smaller', icon: 'less-than-or-equal' },
    { key: 'btw', label: 'Between', icon: 'unfold-more-vertical' },
  ];

  const getRangeOp = () => rangeOps.find((x) => x.key === props.op) || rangeOps[0];
  const getValue1 = () => (Array.isArray(props.value) ? (props.value.length > 0 ? props.value[0] : null) : props.value);
  const getValue2 = () => (Array.isArray(props.value) ? (props.value.length > 1 ? props.value[1] : null) : null);

  const selectedRangeOp = ref(getRangeOp());
  const updatedValue1 = ref(getValue1());
  const updatedValue2 = ref(getValue2());

  const toggle = (event) => {
    selectedRangeOp.value = getRangeOp();
    updatedValue1.value = getValue1();
    updatedValue2.value = getValue2();
    overlay.value.toggle(event);
  };

  const label = computed(() => {
    const currOp = getRangeOp();
    const currV1 = getValue1();
    const currV2 = getValue2();
    if (currV1 === null) {
      return 'Set value';
    }
    switch (currOp.key) {
      case 'eq': {
        return `Equal to ${currV1}`;
      }
      case 'gte': {
        return `Greater than ${currV1}`;
      }
      case 'lte': {
        return `Less than ${currV1}`;
      }
      case 'btw': {
        return `Between ${currV1} and ${currV2}`;
      }
    }
    return '';
  });

  function onApply() {
    emit('change', {
      op: selectedRangeOp.value?.key || 'eq',
      value: selectedRangeOp.value?.key === 'btw' ? [updatedValue1.value, updatedValue2.value] : [updatedValue1.value],
    });
    overlay.value.hide();
  }
  function onClear() {
    emit('change', {
      op: 'eq',
      value: null,
    });
    overlay.value.hide();
  }
</script>
