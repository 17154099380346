<template>
  <SpecEditor2 v-if="specAvailable" :spec="testSpec" @save="onScenarioSave"></SpecEditor2>
</template>
<script setup>
  import { useRoute } from 'vue-router';
  import { useScenariosStore } from '@/store/ScenariosStore';
  import { useToast } from 'vue-toastification';
  import { onMounted, ref, computed } from 'vue';
  //import { parse } from 'yaml';
  import SpecEditor2 from '@/components/Tests/SpecEditor2.vue';
  //import specYaml from '@/data/book_ticket.yaml?raw';

  const route = useRoute();
  //const testsStore = useTestsStore();

  const scenariosStore = useScenariosStore();

  let testSpec = null; //parse(specYaml);
  const specAvailable = ref(false);

  const toast = useToast();

  function onScenarioSave(scenario) {
    const saveResult = scenariosStore.setScenario(scenario);
    if (saveResult) {
      toast.success('Scenario saved successfully');
    } else {
      toast.error(`Error saving scenario`);
    }
  }

  onMounted(async () => {
    let id = route.params?.id || null;
    if (!id) {
      console.log(`ERROR: scenario ID must be specified`);
      return;
    }

    testSpec = await scenariosStore.loadScenario(id);
    if (testSpec) {
      specAvailable.value = true;
    }
  });
</script>
