<template>
  <div>
    <Dialog v-model:visible="visible" modal :closable="false" header="Create Document" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="flex flex-col gap-1">
        <label for="docname">Name</label>
        <small id="docname-help">Enter the name for the new Document. Name must be unique in Knowledge Base.</small>
        <InputText id="docname" class="" v-model="kbDocumentName" aria-describedby="docname-help" />
      </div>
      <div class="mt-2 flex flex-col gap-1">
        <label for="doctitle">Title</label>
        <small id="doctitle-help">Title for the new Document</small>
        <InputText id="doctitle" class="" v-model="kbDocumentTitle" aria-describedby="doctitle-help" />
      </div>
      <div class="mt-4">
        <small class="min-h-6 block">{{ progressText }} </small>
        <ProgressBar :mode="progressMode" style="height: 6px"></ProgressBar>
      </div>
      <template #footer>
        <Button label="Cancel" :disabled="showProgress" severity="secondary" @click="visible = false" autofocus />
        <Button label="Create" :disabled="showProgress" severity="primary" @click="createDocument" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
  import { useRbacStore } from '@/store/RBACStore';
  import { useKbStore } from '@/store/KbStore';
  import { useToast } from 'vue-toastification';
  import { ref, nextTick, computed } from 'vue';
  import InputText from 'primevue/inputtext';
  import Dialog from 'primevue/dialog';
  import Button from 'primevue/button';
  import ProgressBar from 'primevue/progressbar';

  const kbStore = useKbStore();

  const toast = useToast();

  const visible = ref(false);
  const showProgress = ref(false);
  const progressMode = computed(() => (showProgress.value ? 'indeterminate' : ''));
  const progressText = ref('');

  const kbDocumentName = ref('');
  const kbDocumentTitle = ref('');

  const emit = defineEmits(['documentCreated']);

  function start() {
    const suggestedName = 'New document'; //`${botsStore.botName} (Copy)`;
    kbDocumentName.value = suggestedName;
    visible.value = true;
  }

  const pause = (x) => new Promise((res) => setTimeout(res, x));

  async function createDocument() {
    progressText.value = 'Creating document...';
    showProgress.value = true;
    const meta = {};
    if (kbDocumentTitle.value && kbDocumentTitle.value.length > 0) {
      meta.title = kbDocumentTitle.value;
    }
    const createRes = await kbStore.createKbDocument(kbDocumentName.value, 'REPLACE WITH YOUR CONTENT\n', null, null, meta);
    progressText.value = '';
    showProgress.value = false;
    visible.value = false;
    if (!createRes.success) {
      toast.error(`Error creating document: ${createRes.error}`);
      return;
    }
    await nextTick();
    emit('documentCreated', createRes.data);
  }

  defineExpose({ start });
</script>
