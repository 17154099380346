<template>
  <div class="m-4 p-4">
    <button
      type="button"
      class="mr-2 flex items-center gap-x-1 rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
      @click="onStartJob"
    >
      <mdicon name="plus-circle-outline" class="h-6 w-6" aria-hidden="true"></mdicon>
      Start Job
    </button>
    <ProgressDialog ref="jobStartProgress"></ProgressDialog>
  </div>
</template>
<script setup>
  import ProgressDialog from '@/components/Dialogs/ProgressDialog.vue';
  import { ref } from 'vue';
  import { useJobsStore } from '@/store/JobsStore';
  import { useToast } from 'vue-toastification';

  const jobsStore = useJobsStore();
  const toast = useToast();

  const jobStartProgress = ref(null);

  const progressOpen = ref(false);
  const progressState = ref('progress');
  const progressJobName = ref('The Job Name');
  const progressError = ref('');

  async function onStartJob() {
    console.log(`Starting Job`);
    jobStartProgress.value.show({ title: 'Starting Job', description: 'This is the test job name' });
    const startResult = await jobsStore.startJob('xRouslvUbz');
    if (startResult.success) {
      console.log(`Job started successfully`);
      //jobStartProgress.value.showSuccess({ message: 'Job started successfully' });
      jobStartProgress.value.hide();
      toast.success('Job Started successfully');
      return;
    }
    jobStartProgress.value.showError({ message: `ERROR: ${startResult.error}` });
  }

  async function onStartJobOld() {
    console.log(`Starting Job`);
    progressState.value = 'progress';
    progressError.value = '';
    progressOpen.value = true;
    const startResult = await jobsStore.startJob('xRouslvUbz');
    if (startResult.success) {
      progressOpen.value = false;
      return;
    }
    progressError.value = `ERROR: ` + startResult.error;
    progressState.value = 'error';
  }
</script>
