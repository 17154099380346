<template>
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5 pb-2 border-b">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-violet-600/70 rounded-xl p-1">
            <mdicon name="forum-outline" size="62" class="text-violet-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="mb-1 text-2xl font-bold text-gray-900">{{ conversationTitle }}</h1>
          <div class="flex items-center">
            <div>Id: {{ conversationId }}</div>
            <div class="ml-2">Bot: {{ getBotName(conversation) }}</div>
          </div>
        </div>
      </div>

      <!--<div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <button
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="onExport"
        >
          <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
          Report
        </button>
      </div>-->
    </div>

    <TabsResponsive v-model:tabs="tabs" v-model:show-all="showAllTabsContent"></TabsResponsive>

    <h2 v-if="showAllTabsContent" class="text-xl font-semibold leading-7 text-gray-600 border-b border-gray-300 py-2">Summary</h2>
    <BotConversationSummary v-if="tabs[0].active" :conversation="conversation"></BotConversationSummary>
    <h2 v-if="showAllTabsContent" class="text-xl font-semibold leading-7 text-gray-600 border-b border-gray-300 py-2">Transcript</h2>
    <BotConversationTranscript :class="[tabs[1].active ? '' : 'hidden']" :conversation="conversation" :transcriptMessagesPath="['messages']" actorProp="role" textProp="content"></BotConversationTranscript>
  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useBotsStore } from '@/store/BotsStore';
  import { useBotConversationSearchStore } from '@/store/BotConversationSearchStore';
  import { useBreakpoint } from '@/hooks/useBreakpoint';
  import { ArrowDownCircleIcon, HashtagIcon } from '@heroicons/vue/20/solid';
  import TabsResponsive from '@/components/Tabs/TabsResponsive.vue';
  import BotConversationSummary from '@/components/Bots/analytics/BotConversationSummary.vue';
  import BotConversationTranscript from '@/components/Bots/analytics/BotConversationTranscript.vue';
  import BotConversationRecordingPlayer from '@/components/Bots/analytics/BotConversationRecordingPlayer.vue';
  import { ConversationDetailsReport } from '@enegelai/core';
  import DbEasyPie from '@/components/Charts/DbEasyPie.vue';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import { pathOr } from 'ramda';
  import { formatSeconds } from '@/utils/formatSeconds';
  import { useMd } from '@/hooks/useMd';
  import ConversationKPIs from '@/components/Insights/ConversationKPIs.vue';
  import ConversationTranscriptPlayer from '@/components/Insights/ConversationTranscriptPlayer.vue';
  import CSatConversationSummary from '@/components/CSat/CSatConversationSummary.vue';
  import ConversationProblems from '@/components/Insights/ConversationProblems.vue';
  import ConversationAgentSummary from '@/components/Insights/ConversationAgentSummary.vue';

  //dayjs.extend(utc);

  const route = useRoute();
  const layout = useLayoutStore();
  const botsStore = useBotsStore();
  const conversationSearchStore = useBotConversationSearchStore();
  const md = useMd();

  const conversation = ref({});

  const conversationId = computed(() => conversation.value?.id || '');
  const conversationDatetime = computed(() => conversation.value?.datetime || '');
  const conversationMedia = computed(() => conversation.value?.channelAttributes?.media || 'chat');

  const conversationHasCSat = computed(() => (conversation.value?.CSat || 0) > 0);
  const conversationCSat = computed(() => conversation.value?.CSat || 0);
  const conversationCSatText = computed(() => ((conversation.value?.CSat || 0) > 0 ? conversation.value.CSat : 'N/A'));
  const conversationCSatExplanation = computed(() => md.mdToHtml(conversation.value?.CSatExplanation || ''));

  const tabs = ref([
    { name: 'Summary', href: '#', icon: 'chart-box-outline', active: true, hidden: false },
    { name: 'Transcript', href: '#', icon: 'play-box-outline', active: false, hidden: false },
  ]);
  const tabsHidden = computed(() => [false, false]);
  const showAllTabsContent = ref(false);

  const clientProps = [
    { name: 'IP', prop: ['clientAttributes', 'remoteIp'] },
    { name: 'User-Agent', prop: ['clientAttributes', 'userAgent'] },
  ];

  // Create user-friendly title for the conversation, by using first user or greeting message
  const conversationTitle = computed(() => {
    const defaultConverstationTitle = `Bot conversation ${conversation.value?.id || ''}`;
    if (!Array.isArray(conversation.value.messages) || conversation.value.messages.length <= 0) {
      return defaultConverstationTitle;
    }
    const firstMsg = conversation.value.messages.find((x) => x?.role === 'user') || conversation.value.messages[0];
    if (firstMsg && firstMsg.content && firstMsg.content !== '') {
      return firstMsg.content.substring(0, 45) + ' ...';
    }
    return defaultConverstationTitle;
  });

  const collectedData = computed(() => {
    let allData = Object.keys(conversation.value?.state?.contactData || {}).map((x) => {
      return {
        name: x,
        value: conversation.value?.state?.contactData[x] || '',
      };
    });
    const leadData = Object.keys(conversation.value?.state?.leadData || {})
      .map((x) => {
        return {
          name: x,
          value: conversation.value?.state?.leadData[x] || '',
        };
      })
      .filter((x) => x.name !== 'allDataProvidedByUser');
    allData = allData.concat(leadData);
    return allData;
  });

  const collectedDataExists = computed(() => {
    return collectedData.value.length > 0;
  });

  function getBotName(conv) {
    // TODO Store bot name in botconv
    const botId = conv?.botId || null;
    if (!botId) {
      return '-';
    }
    const bot = botsStore.getBot(botId);
    if (!bot) {
      return '-';
    }
    return bot?.name || '-';
  }

  // Export report
  function onExport() {
    const reports = ConversationDetailsReport.generateResultReports(conversation.value);
    downloadReport(`Conversation_${conversationId.value}`, reports.reportHtml, 'text/html');
  }

  function downloadReport(filename, report, mimeType) {
    // credit: https://www.bitdegree.org/learn/javascript-download
    let text = report;
    let element = document.createElement('a');
    element.setAttribute('href', `data:${mimeType};charset=utf-8,` + encodeURIComponent(text));
    element.setAttribute('download', filename);
    //element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  onMounted(async () => {
    conversation.value = await conversationSearchStore.getConversation(route.params.conversationId);
  });
</script>
