<template>
  <TransitionRoot as="template" :show="props.open">
    <Dialog as="div" class="relative z-10" @close="emit('cancel')">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
                <form class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div class="flex-1">
                    <!-- Header -->
                    <div class="bg-slate-200/50 px-4 py-6 sm:px-6">
                      <div class="flex items-start justify-between space-x-3">
                        <div class="space-y-1">
                          <DialogTitle class="text-base font-semibold leading-6 text-gray-900">Collection</DialogTitle>
                          <p class="text-sm text-gray-500">Fill in the information below</p>
                        </div>
                        <div class="flex h-7 items-center">
                          <button type="button" class="text-gray-400 hover:text-gray-500" @click="emit('cancel')">
                            <span class="sr-only">Close panel</span>
                            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="mx-6 mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div class="sm:col-span-4">
                        <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
                        <div class="mt-2">
                          <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                              id="name"
                              type="text"
                              name="name"
                              v-model="name"
                              class="block flex-1 pl-3 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              placeholder="Collection Name"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="col-span-full">
                        <label for="about" class="block text-sm font-medium leading-6 text-gray-900">Description</label>
                        <div class="mt-2">
                          <textarea
                            id="description"
                            name="description"
                            rows="3"
                            v-model="description"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Collection Description"
                          />
                        </div>
                      </div>

                      <div class="col-span-full">
                        <div for="about" class="block text-sm font-medium leading-6 text-gray-500">Created</div>
                        <div for="about" class="block mt-2 text-sm font-medium leading-6 text-gray-700">{{ dayjs(created) }}</div>
                      </div>

                      <!--<div class="col-span-full">
                        <div for="about" class="block text-sm font-medium leading-6 text-gray-500">Created By</div>
                        <div for="about" class="block mt-2 text-sm font-medium leading-6 text-gray-700">my@email</div>
                      </div>-->

                      <!--<div class="col-span-full sm:divide-y sm:divide-gray-200 sm:py-0"></div>-->
                    </div>
                  </div>

                  <!-- Action buttons -->
                  <div class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div class="flex justify-end space-x-3">
                      <button type="button" class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="emit('cancel')">Cancel</button>
                      <button
                        type="button"
                        class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        @click="onSave"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  // Sample of form validation: https://github.com/deyemiobaa/tailwindcss_custom_form_validation/blob/main/index.html
  import { ref, watch, onMounted } from 'vue';
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
  import { XMarkIcon } from '@heroicons/vue/24/outline';
  import dayjs from 'dayjs';

  const props = defineProps({
    open: {
      type: Boolean,
      default: false,
    },
    new: {
      type: Boolean,
      default: false,
    },
    collection: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  const emit = defineEmits(['cancel', 'save']);

  const name = ref('');
  const description = ref('');
  const created = ref(null);
  const createdBy = ref(null);
  const files = ref(null);

  watch(
    () => props.collection,
    () => {
      init();
    }
  );

  function init() {
    name.value = props.collection?.name || '';
    description.value = props.collection?.description || '';
    created.value = props.collection?.created || null;
    createdBy.value = props.collection?.createdBy || null;
    files.value = props.collection?.files || null;
  }

  function onSave() {
    const updatedCollection = Object.assign(JSON.parse(JSON.stringify(props.collection)), {
      name: name.value,
      description: description.value,
    });
    emit('save', updatedCollection);
  }

  onMounted(() => {
    init();
  });
</script>
