<template>
  <div class="relative">
    <div class="flex items-center w-full h-full">
      <DbSparkLine class="flex-auto h-full" :data="data"></DbSparkLine>
      <mdicon :name="trendIcon" size="34" :class="['ml-1', trendIconClass]" aria-hidden="true"></mdicon>
    </div>
    <div class="absolute top-1/4 font-source text-md text-gray-500">{{ trendText }}</div>
  </div>
</template>
<script setup>
  import { ref, computed } from 'vue';
  import DbSparkLine from '@/components/Charts/DbSparkLine.vue';
  import { detectTrend } from '@/analytics/LinearRegression';
  const props = defineProps({
    max: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: null,
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [1, 5, 4, 2, 7, 6, 1, 3, 9, 3, 8, 7, 6, 5, 4, 3, 2, 1, 0, 0, 14];
        //return [1, 0, 1, 0, 1, 0, 1, 0, 3];
      },
    },
  });

  const trend = computed(() => detectTrend(props.data));

  const trendIcon = computed(() => {
    return trend.value > 0 ? 'arrow-up-bold-circle-outline' : trend.value < 0 ? 'arrow-down-bold-circle-outline' : 'dots-horizontal-circle-outline';
  });

  const trendIconClass = computed(() => {
    if (props.reverse) {
      return trend.value > 0 ? 'text-orange-600/70' : trend.value < 0 ? 'text-teal-600/70' : 'text-sky-600/70';
    } else {
      return trend.value > 0 ? 'text-teal-600/70' : trend.value < 0 ? 'text-orange-600/70' : 'text-neutral-300/70';
    }
  });

  const trendText = computed(() => {
    return trend.value > 0 ? 'Up' : trend.value < 0 ? 'Down' : 'Same';
    /*
    if (props.reverse) {
      return trend.value > 0 ? 'Worse' : trend.value < 0 ? 'Better' : 'Same';
    } else {
      return trend.value > 0 ? 'Better' : trend.value < 0 ? 'Worse' : 'Same';
    }*/
  });
</script>
