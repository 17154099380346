/*
 * Job executions & jobs results store
 * Access data in storage buckets
 *
 * References:
 *
 * Cache control - metadata: https://firebase.google.com/docs/storage/web/file-metadata#update_file_metadata
 * https://firebase.google.com/docs/storage/web/download-files
 * */
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
//import { useCollection, useDocument } from 'vuefire';
import { useFirebaseStorage, useStorageFile, useStorageFileUrl } from 'vuefire';
import { getStorage, ref as storageRef, listAll, getDownloadURL, getBlob } from 'firebase/storage';

export const useJobsExecutionsStore = defineStore('jobsExecutions', () => {
  const authStore = useAuthStore();
  // ???
  const tenantId = computed(() => authStore.tenantId);

  const storage = getStorage();

  // TEST - List all "folders" in bucket/path
  async function loadJobExecutions() {
    // Create a reference under which you want to list
    const listRef = storageRef(storage, 'T1/results/run1');

    const result = [];

    let listResult = null;
    try {
      listResult = await listAll(listRef);
    } catch (e) {
      console.log(`Exception listing entries: ${e.message}`);
      return result;
    }

    listResult.prefixes.forEach((folderRef) => {
      // All the prefixes under listRef.
      //console.log(`Got ${JSON.stringify(folderRef)}`);
      result.push({
        name: folderRef.name,
        bucket: folderRef.bucket,
      });
    });

    /*
    // Find all the prefixes and items.
    listAll(listRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
        });
        res.items.forEach((itemRef) => {
          // All the items under listRef.
        });
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });
     */

    return result;
  }

  // TEST - Load file from store
  async function loadJobExecutionResult(executionName) {
    const resultFileRef = storageRef(storage, `T1/results/run1/${executionName}/result.json`);

    let resultFile = await getBlob(resultFileRef);
    console.log(`Got blob!`);

    return;
    let downloadUrl = null;
    try {
      downloadUrl = await getDownloadURL(resultFileRef);
    } catch (e) {
      console.log(`Exception getting URL: ${e.message}`);
    }

    // https://firebase.google.com/docs/storage/web/download-files
    /* Sample
    getDownloadURL(ref(storage, 'images/stars.jpg'))
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'

          // This can be downloaded directly:
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
          };
          xhr.open('GET', url);
          xhr.send();

          // Or inserted into an <img> element
          const img = document.getElementById('myimg');
          img.setAttribute('src', url);
        })
        .catch((error) => {
          // Handle any errors
        });
    */
    /*
    const {
      url,
      // refresh the url if the file changes
      refresh,
    } = useStorageFile(resultFileRef);
    */
    console.log(`Got file url: ${downloadUrl}`);
  }

  /*
  async function loadScenario(scenarioId) {
    const emptySpec = Object.assign({ id: scenarioId }, EMPTY_SCENARIO_SPEC);
    const scenarioDocRef = doc(fbDb, `tenants/${tenantId.value}/scenarios`, scenarioId);
    const scenarioDoc = await getDoc(scenarioDocRef);
    if (scenarioDoc.exists()) {
      console.log('Document data:', scenarioDoc.data());
      currentScenarioDoc.value = scenarioDoc.data();
      const scenarioSpecDoc = await getDoc(doc(scenarioDocRef, 'versions', 'current'));
      if (!scenarioSpecDoc.exists()) {
        currentScenarioSpec.value = emptySpec;
      } else {
        const specData = scenarioSpecDoc.data();
        let spec = null;
        try {
          spec = JSON.parse(specData.spec);
        } catch (e) {
          console.log(`Scenario[${scenarioId}]: Failed to parse spec JSON: ${e.message}`);
          spec = emptySpec;
        }
        currentScenarioSpec.value = spec;
      }
    } else {
      console.log(`Scenario[${scenarioId}]: Does not exist, assuming new`);
      currentScenarioSpec.value = emptySpec;
      // TODO Doc
      //  - created
      //  - by
    }
    return currentScenarioSpec.value;
  }
  */

  return {
    loadJobExecutions,
    loadJobExecutionResult,
  };
});
