<template>
  <FlowSpecEditor v-if="specAvailable" :spec="flowSpec" @save="onScenarioSave"></FlowSpecEditor>
</template>
<script setup>
  import { useRoute } from 'vue-router';
  import { useToast } from 'vue-toastification';
  import { onMounted, ref, computed } from 'vue';
  import FlowSpecEditor from '@/components/Flows/FlowSpecEditor.vue';
  import testFlowSpec from '@/data/testflowspec.json?raw';

  const route = useRoute();

  let flowSpec = null; //parse(specYaml);
  const specAvailable = ref(false);
  const specName = ref('');

  const toast = useToast();

  function onScenarioSave(scenario) {
    const saveResult = false; //scenariosStore.setScenario(scenario);
    if (saveResult) {
      toast.success('Scenario saved successfully');
    } else {
      toast.error(`Error saving scenario`);
    }
  }

  onMounted(async () => {
    /*
    let id = route.params?.id || null;
    if (!id) {
      console.log(`ERROR: scenario ID must be specified`);
      return;
    }*/

    flowSpec = JSON.parse(testFlowSpec); //await scenariosStore.loadScenario(id);
    if (flowSpec) {
      specName.value = flowSpec.name;
      specAvailable.value = true;
    }
  });
</script>
