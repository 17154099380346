<template>
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-emerald-600/70 rounded-xl p-1">
            <mdicon name="finance" size="62" class="text-emerald-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="mb-1 text-2xl font-bold text-gray-900">Voice Of Customer</h1>
          <div class="my-1 flex items-center">
            <div>CSat for:</div>
            <TimeRangeSelector :inline="true" class="ml-2 font-semibold"></TimeRangeSelector>
          </div>
        </div>
      </div>
      <div class="flex flex-col justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <Button icon="pi pi-upload" label="Upload" severity="secondary" @click="onUploadRecording" />
        <ProgressButton ref="analyzeProgressButton" title="Analyze" icon="motion-play-outline" @click="loadData"></ProgressButton>
      </div>
      <!--<div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <button
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="onExport"
        >
          <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
          Report
        </button>
      </div>-->
    </div>

    <FiltersList class="mt-2" @select-filter="onSelectFilter" @show-filters="showFilters"></FiltersList>

    <div class="relative isolate overflow-hidden">
      <div class="">
        <dl class="mt-1 grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-4 relative">
          <div v-for="stat in stats" :key="stat.name" :class="['bg-slate-50 relative overflow-hidden rounded-xl my-2 px-4 pt-4 pb-2 min-h-[120px]', stat?.class || '']" @click="onStatClick(stat)">
            <div class="z-20 relative">
              <dt class="truncate text-sm font-medium text-gray-500">{{ stat.name }}</dt>
              <dd class="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{{ stat.value }}</dd>
              <dd class="mt-2 text-sm text-gray-400 font-medium">{{ stat?.description || '' }}</dd>
              <dd v-if="stat?.extraDescription" class="mt-2 text-xs text-gray-400 font-medium">{{ stat?.extraDescription || '' }}</dd>
            </div>
            <db-easy-pie
              v-if="stat.gauge"
              class="z-10 absolute right-1 top-1 h-28 w-28 rounded-full text-lg font-semibold text-slate-500"
              :value="stat.value"
              :max="stat.max"
              :line-width="8"
              :animated="true"
              :percent-ranges="stat?.percentRanges || []"
              :show-percents="stat.percents || false"
            ></db-easy-pie>
            <mdicon v-else-if="stat.icon" :name="stat.icon" size="105" :class="['z-10 absolute right-1 top-1 rounded-full', stat.iconClass || '']" />
          </div>
        </dl>
      </div>
    </div>

    <div v-if="false" class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-emerald-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Customer Satisfaction over Time</h2>
      </div>
      <div class="">
        <div class="mt-1 w-full rounded-xl py-4">
          <div class="flex text-gray-400 font-source h-[120px] w-full">
            <DbDygraphsLine class="relative" :data="chartData" :options="chartOptions" :bottom-legend="true" @db-event="handleDbEvent"></DbDygraphsLine>
          </div>
        </div>
      </div>
    </div>

    <div class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-emerald-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Customer Satisfaction over Time</h2>
      </div>
      <DbUplot class="mt-4 w-full h-[400px]" :data="uPlotData" :options="uPlotOptions" @db-event="handleDbEvent"></DbUplot>
    </div>

    <div v-if="false" class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-emerald-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">CSat by Number</h2>
      </div>
      <div class="">
        <div class="mt-1 w-full rounded-xl py-4">
          <div class="flex text-gray-400 font-source h-[120px] w-full">
            <DbDygraphsLine class="relative" :data="chartGroupedData" :options="chartGroupedOptions" :bottom-legend="true" @db-event="handleDbEvent"></DbDygraphsLine>
          </div>
        </div>
      </div>
    </div>
    <CSatDialogUploadRecording ref="dialogUploadRecording" @recordingAnalyzed="onRecordingAnalyzed"></CSatDialogUploadRecording>
    <EditFiltersSidebar v-model:visible="filtersOpen"></EditFiltersSidebar>
  </div>
</template>

<script setup>
  import { onMounted, ref, reactive, computed, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useConversationSearchStore } from '@/store/ConversationSearchStore';
  import { useFiltersStore } from '@/store/FiltersStore';
  import { ArrowDownCircleIcon, HashtagIcon } from '@heroicons/vue/20/solid';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';
  import dayjs from 'dayjs';
  import DbEasyPie from '@/components/Charts/DbEasyPie.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';
  import { useDateRangeStore } from '@/store/DaterangeStore';
  import DbDygraphsBar from '@/components/Charts/DbDygraphsBar.vue';
  import DbDygraphsLine from '@/components/Charts/DbDygraphsLine.vue';
  import DbUplot from '@/components/Charts/DbUplot.vue';
  import EditFiltersSidebar from '@/components/Filters/EditFiltersSidebar.vue';
  import FiltersList from '@/components/Filters/FiltersList.vue';
  import { stdConversationFilters } from '@/analytics/filtersDefs';
  import Button from 'primevue/button';
  import CSatDialogUploadRecording from '@/components/CSat/dialogs/CSatDialogUploadRecording.vue';

  const route = useRoute();
  const router = useRouter();
  const layout = useLayoutStore();
  const conversationSearchStore = useConversationSearchStore();
  const dateRangeStore = useDateRangeStore();
  const filtersStore = useFiltersStore();

  const loading = ref(false);
  const conversationsTotal = ref(0);
  const csatPctAvg = ref(0);
  const csatPctMin = ref(0);

  const filtersOpen = ref(false);

  const analyzeProgressButton = ref(null);

  const dialogUploadRecording = ref(null);

  const stats = computed(() => [
    {
      id: 'time',
      name: 'Time period',
      value: dateRangeStore.title,
      icon: 'clock-outline',
      //description: `Duration: ${formatSeconds(props.conversation?.duration || 0)}`,
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
    },
    {
      id: 'conv',
      name: 'Conversations',
      value: conversationsTotal.value,
      description: 'Total conversations',
      icon: 'phone-dial',
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
      class: 'cursor-pointer hover:bg-slate-100 ring-2 ring-violet-200/50 hover:ring-violet-700/50',
    },
    /*
    {
      name: 'Agent',
      value: 'None',
      description: `None`,
      icon: 'face-agent',
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
    },*/
    {
      id: 'csat',
      name: 'CSat',
      value: parseFloat(csatPctAvg.value.toFixed(2)),
      max: 100,
      description: 'Average CSat %',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 40, color: '#fde047' },
        { value: 60, color: '#bef264' },
      ],
    },
    {
      id: 'csatMin',
      name: 'Min CSat',
      value: parseFloat(csatPctMin.value.toFixed(2)),
      max: 100,
      description: 'Smallest CSat %',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 40, color: '#fde047' },
        { value: 60, color: '#bef264' },
      ],
    },
  ]);

  watch(filtersStore.allFilters, (newValue) => {
    if (loading.value) {
      return;
    }
    loadData();
  });

  watch(
    () => dateRangeStore.timeIntervalChanged,
    (newValue) => {
      loadData();
    },
  );

  function onStatClick(stat) {
    console.log(`On stat click:`, stat);
    switch (stat.id) {
      case 'conv': {
        // move to conversations
        router.push('/csat/search');
        break;
      }
    }
  }

  const chartData = ref([]);
  const chartOptions = {
    stackedGraph: true,
    //title: 'Conversations',
    ylabel: 'CSat %',
    labels: ['Date', 'CSat'],
    legend: 'follow',
    connectSeparatedPoints: true,
    drawPoints: true,
  };

  const chartGroupedData = ref([]);
  const chartGroupedOptions = reactive({
    stackedGraph: false,
    //title: 'Conversations',
    legend: 'follow',
    ylabel: 'CSat %',
    labels: ['Date'],
    fillGraph: true,
    fillAlpha: 0.2,
    connectSeparatedPoints: true,
    drawPoints: true,
  });

  const uPlotData = ref([]);
  /*
  const uPlotDataTest = [
    [
      1566453600, 1566453660, 1566453720, 1566453780, 1566453840, 1566453900, 1566453960, 1566454020, 1566454080, 1566454140, 1566454200, 1566454260, 1566454320, 1566454380, 1566454440, 1566454500, 1566454560,
      1566454620, 1566454680, 1566454740, 1566454800, 1566454860, 1566454920, 1566454980, 1566455040, 1566455100, 1566455160, 1566455220, 1566455280, 1566455340, 1566455400, 1566455460, 1566455520, 1566455580,
      1566455640, 1566455700, 1566455760, 1566455820, 1566455880, 1566455940, 1566456000, 1566456060, 1566456120, 1566456180, 1566456240, 1566456300, 1566456360, 1566456420, 1566456480, 1566456540, 1566456600,
      1566456660, 1566456720, 1566456780, 1566456840, 1566456900, 1566456960, 1566457020, 1566457080, 1566457140, 1566457200, 1566457260, 1566457320, 1566457380, 1566457440, 1566457500, 1566457560, 1566457620,
      1566457680, 1566457740, 1566457800, 1566457860, 1566457920, 1566457980, 1566458040, 1566458100, 1566458160, 1566458220, 1566458280, 1566458340, 1566458400, 1566458460, 1566458520, 1566458580, 1566458640,
      1566458700, 1566458760, 1566458820, 1566458880, 1566458940, 1566459000, 1566459060, 1566459120, 1566459180, 1566459240, 1566459300, 1566459360, 1566459420, 1566459480, 1566459540, 1566459600, 1566459660,
      1566459720, 1566459780, 1566459840, 1566459900, 1566459960, 1566460020, 1566460080, 1566460140, 1566460200, 1566460260, 1566460320, 1566460380, 1566460440, 1566460500, 1566460560, 1566460620, 1566460680,
      1566460740, 1566460800, 1566460860, 1566460920, 1566460980, 1566461040, 1566461100, 1566461160, 1566461220, 1566461280, 1566461340, 1566461400, 1566461460, 1566461520, 1566461580, 1566461640, 1566461700,
      1566461760, 1566461820, 1566461880, 1566461940, 1566462000, 1566462060, 1566462120, 1566462180, 1566462240, 1566462300, 1566462360, 1566462420, 1566462480, 1566462540, 1566462600, 1566462660, 1566462720,
      1566462780, 1566462840, 1566462900, 1566462960, 1566463020, 1566463080, 1566463140, 1566463200, 1566463260, 1566463320, 1566463380, 1566463440, 1566463500, 1566463560, 1566463620, 1566463680, 1566463740,
      1566463800, 1566463860, 1566463920, 1566463980, 1566464040, 1566464100, 1566464160, 1566464220, 1566464280, 1566464340, 1566464400, 1566464460, 1566464520, 1566464580, 1566464640, 1566464700, 1566464760,
      1566464820, 1566464880, 1566464940, 1566465000, 1566465060, 1566465120, 1566465180, 1566465240, 1566465300, 1566465360, 1566465420, 1566465480, 1566465540,
    ],
    [
      0.54, 0.15, 0.16, 0.15, 0.19, 0.26, 0.32, 0.15, 0.15, 0.28, 0.29, 0.33, 0.18, 0.17, 0.17, 0.33, 0.32, 0.23, 0.15, 0.15, 0.24, 0.29, 0.16, 0.17, 0.17, 0.45, 0.28, 0.16, 0.17, 0.17, 0.24, 0.3, 0.19, 0.19, 0.17, 0.24,
      0.29, 0.22, 0.18, 0.28, 0.26, 0.17, 0.3, 0.16, 0.21, 0.24, 0.16, 0.29, 0.17, 0.18, 0.27, 0.16, 0.33, 0.16, 0.19, 0.24, 0.18, 1.7, 0.18, 0.16, 0.34, 0.18, 0.28, 0.18, 0.17, 0.34, 0.17, 0.28, 0.17, 0.22, 0.23, 0.14,
      0.29, 0.2, 0.16, 0.24, 0.16, 0.17, 0.29, 0.17, 0.38, 0.2, 0.16, 0.3, 0.18, 0.31, 0.16, 0.16, 0.29, 0.15, 0.31, 0.17, 0.17, 0.3, 0.2, 0.27, 0.16, 0.15, 0.3, 0.41, 0.28, 0.16, 0.15, 0.28, 0.15, 0.33, 0.18, 0.18,
      0.17, 5.2, 0.23, 0.17, 0.16, 0.16, 0.33, 0.3, 0.19, 0.16, 0.92, 0.28, 0.33, 0.17, 0.21, 0.15, 0.32, 0.61, 1.48, 0.15, 0.17, 0.44, 0.23, 0.19, 0.17, 0.24, 0.33, 0.26, 0.17, 0.17, 0.17, 0.27, 0.25, 0.11, 0.1, 0.07,
      0.09, 0.37, 0.08, 0.09, 0.08, 0.07, 0.34, 0.07, 0.08, 0.07, 0.07, 0.37, 0.07, 0.07, 0.07, 0.25, 0.26, 0.06, 0.1, 0.07, 0.09, 0.31, 0.08, 0.11, 0.11, 0.12, 0.26, 0.08, 0.09, 0.07, 0.1, 0.25, 0.06, 0.06, 0.07, 0.07,
      0.36, 0.26, 0.08, 0.08, 0.09, 0.16, 0.23, 0.07, 0.09, 0.21, 0.22, 0.21, 0.09, 0.07, 0.09, 0.22, 0.3, 0.12, 0.07, 0.11,
    ],
  ];
  const uPlotDataTest2 = [
    [1566453600000, 1566453660000, 1566453720000],
    [0.54, 0.15, 0.16],
  ];
  */
  const uPlotOptions = {
    /* To disable legend
    legend: {
      show: false,
    },*/
    scales: {
      x: { time: true },
      y: { range: { min: { soft: 0, mode: 2 }, max: { soft: 100, mode: 2 } } },
    },
    series: [
      {},
      {
        label: 'CSat',
        value: (u, v, sidx, didx) => (didx == null ? null : v == null ? '' : v.toFixed(1) + '%'),
        stroke: '#4e79a7',
        fill: 'rgba(78, 121, 167,0.1)',
      },
    ],
    axes: [
      {},
      {
        values: (u, vals, space) => vals.map((v) => +v.toFixed(1) + '%'),
      },
    ],
  };

  async function loadData() {
    analyzeProgressButton.value.startProgress();
    loading.value = true;
    //await conversationSearchStore.search('*', 25, 0, [{ field: 'datetime', type: 'desc' }]);
    const groupings = [
      {
        name: 'timeline',
        title: 'Date',
        type: 'date_histogram',
        interval: dateRangeStore.timeInterval.interval,
        extended_bounds: { min: dateRangeStore.timeInterval.from, max: dateRangeStore.timeInterval.to },
        groupings: [
          { name: 'stats', title: 'Stats', type: 'stats', field: 'CSat' },
          {
            name: 'number',
            title: 'Number',
            type: 'terms',
            size: 50,
            field: 'Number',
            groupings: [{ name: 'stats', title: 'Stats', type: 'stats', field: 'CSat' }],
          },
        ],
        // TODO nested - CSAT stats
      },
    ];
    const aggregateResult = await conversationSearchStore.aggregate(groupings);
    processAggregateResult(aggregateResult);
    loading.value = false;
    analyzeProgressButton.value.stopProgress();
  }

  function calculateCSat(csatStats) {
    if (!csatStats || typeof csatStats !== 'object') {
      return 0;
    }
    let csat = 0;
    if (csatStats?.count > 0) {
      csat = ((csatStats?.sum || 0) / ((csatStats?.count || 0) * 10)) * 100;
    }
    return csat;
  }

  function processAggregateResult(aggregateResult) {
    if (!aggregateResult.success) {
      conversationsTotal.value = 0;
      chartData.value = [];
      csatPctAvg.value = 0;
      // TODO Toast
      console.log(`Error aggregating data: ${aggregateResult.error}`);
      return;
    }

    conversationsTotal.value = aggregateResult?.result?.total || 0;

    const countsTable = aggregateResult.toTable(
      {
        //timeline: { title: 'Count' }, // TODO Check this by some reason is added as second value in array, after timeline.stats
        'timeline.stats': { title: 'CSat Stats' },
        'timeline.number.stats': { title: 'Number Stats' },
      },
      ['timeline'],
      'Date',
    );

    // TODO Average
    let csatNum = 0;
    let csatPctSum = 0;
    let minCsat = 100;

    const csatData = [];
    const csatGroupedData = [];
    const uPlotCsatData = [[], []];

    //console.log('Hdr:', countsTable.tableHeader);
    countsTable.tableData.map((row) => {
      const csatRow = [];
      const csatGroupedRow = [];
      csatRow.push(new Date(row[0])); // Date
      csatGroupedRow.push(new Date(row[0])); // Date
      uPlotCsatData[0].push(Math.floor(row[0] / 1000));
      let csat = calculateCSat(row[1]);
      if (csat > 0) {
        csatNum++;
        csatPctSum += csat;
        if (csat < minCsat) {
          minCsat = csat;
        }
      }
      csatRow.push(csat === 0 ? null : csat); // CSat %%
      uPlotCsatData[1].push(csat === 0 ? null : csat);

      // Grouped
      if (row.length > 2) {
        for (let i = 2; i < row.length; i++) {
          let gcsat = calculateCSat(row[i]);
          csatGroupedRow.push(gcsat === 0 ? null : gcsat);
        }
      }
      csatData.push(csatRow);
      csatGroupedData.push(csatGroupedRow);
    });
    //console.log('Hdr 2:', countsTable.tableHeader);

    csatPctAvg.value = Math.floor(csatPctSum / csatNum);
    csatPctMin.value = minCsat;

    chartData.value = csatData;
    // Remove col 1 (overall CSat) and keep the rest of the columns (grouped values) for the grouped chart
    countsTable.tableHeader.splice(1, 1);
    chartGroupedOptions.labels = countsTable.tableHeader;
    chartGroupedData.value = csatGroupedData;
    uPlotData.value = uPlotCsatData; //uPlotDataTest2;
    //uPlotData.value = uPlotDataTest2;
  }

  function handleDbEvent(event) {
    switch (event.type) {
      case 'zoom': {
        console.log(`Handle Zoom`, event);
        let from = event?.minDate || null;
        let to = event?.maxDate || null;
        if (from && to) {
          dateRangeStore.setDateRange(from, to);
          loadData();
        }
        break;
      }
    }
  }

  function onSelectFilter(filter) {
    // TODO pass which filter to make active
    showFilters();
  }

  function showFilters() {
    filtersOpen.value = true;
  }

  function onSetFilter(field, value) {
    filtersStore.setFilterValue(field, value);
  }

  function onUploadRecording() {
    console.log(`Upload recording`);
    dialogUploadRecording.value.start();
  }

  function onRecordingAnalyzed() {
    console.log(`Recording analyzed`);
    loadData();
  }

  onMounted(() => {
    filtersStore.initFilters('conv', stdConversationFilters);
    loadData();
  });
</script>
