import MarkdownIt from 'markdown-it';
import mila from 'markdown-it-link-attributes';
import hljs from 'highlight.js/lib/common';
import 'highlight.js/styles/atom-one-dark.min.css';

function highlightBlock(str, lang) {
  return `<pre class="theme-atom-one-dark not-prose"><code class="rounded hljs language-${lang}">${str}</code></pre>`;
}

export const useMd = () => {
  const mdIt = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
    highlight(code, language) {
      const validLang = !!(language && hljs.getLanguage(language));
      if (validLang) {
        const lang = language ?? '';
        return highlightBlock(hljs.highlight(code, { language: lang }).value, lang);
      }
      return highlightBlock(hljs.highlightAuto(code).value, '');
    },
  });

  mdIt.use(mila, { attrs: { target: '_blank', rel: 'noopener' } });

  const mdToHtml = (md) => {
    const html = mdIt.render(md);
    return html;
  };

  return {
    mdToHtml,
  };
};
