<template>
  <div class="flex items-center justify-between md:space-x-5">
    <div class="flex items-center space-x-5">
      <div class="flex-shrink-0">
        <div class="relative bg-orange-600/70 rounded-xl p-1">
          <mdicon name="face-agent" size="62" class="text-orange-100" aria-hidden="true"></mdicon>
        </div>
      </div>
      <div class="">
        <h1 class="text-2xl font-bold text-gray-900">
          {{ agentDataStore.agentName }}<span v-if="agentDataStore.agentId" class="ml-2 font-semibold text-slate-400">( {{ agentDataStore.agentId }} )</span>
        </h1>
        <div class="my-1 flex items-center">
          <div>Analyzing time period:</div>
          <TimeRangeSelector :inline="true" class="ml-1 font-semibold"></TimeRangeSelector>
          <div v-if="rbac.canChangeAgent" class="flex items-center">
            <div class="ml-1">For the agent:</div>
            <AgentSelector :current="agentDataStore.agentName" :inline="true" class="ml-1 font-semibold" @agent-selected="onAgentSelected"></AgentSelector>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
      <ProgressButton ref="analyzeProgressButton" title="Analyze" icon="motion-play-outline" @click="analyzeAgent"></ProgressButton>
      <!--<button
        class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
        TBD
      </button>-->
    </div>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';
  import { useAuthStore } from '@/store/AuthStore';
  import { useRbacStore } from '@/store/RBACStore';
  import { useAgentDataStore } from '@/store/AgentDataStore';
  import AgentSelector from '@/components/Selectors/AgentSelector.vue';

  const authStore = useAuthStore();
  const rbac = useRbacStore();
  const agentDataStore = useAgentDataStore();
  const analyzeProgressButton = ref(null);

  async function onAgentSelected(agent) {
    console.log(`Selected:`, agent);
    const newAgentId = agent?.agentId || null;
    if (newAgentId) {
      console.log(`agentId=${newAgentId} selected, setting agentId in agentData store`);
      await agentDataStore.setAgent(newAgentId);
      await analyzeAgent();
    }
  }

  async function analyzeAgent() {
    await agentDataStore.analyzeAgentData();
    analyzeProgressButton.value.stopProgress();
  }
</script>
