<template>
  <spec-editor :spec="testSpec"></spec-editor>
</template>
<script setup>
  import { parse } from 'yaml';
  import SpecEditor from '@/components/Spec/SpecEditor.vue';
  import specYaml from '@/data/msft_freepbx.yaml?raw';

  const testSpec = parse(specYaml);
  const a = 1;
</script>
