ace.define('ace/mode/kbdoc_highlight_rules', ['require', 'exports', 'module', 'ace/lib/oop', 'ace/mode/text_highlight_rules'], function (acequire, exports, module) {
  'use strict';

  var oop = acequire('../lib/oop');
  var TextHighlightRules = acequire('./text_highlight_rules').TextHighlightRules;

  var KbDocHighlightRules = function () {
    var keywords = 'MESSAGE|ACCEPT|MERGE|SUM|ADD||MESSAGE|TABLE|ADVANCING|MODE|TAPE|';

    var builtinConstants = 'true|false|null';

    var builtinFunctions = 'count|min|max|avg|sum|rank|now|coalesce|main';

    var keywordMapper = this.createKeywordMapper(
      {
        'support.function': builtinFunctions,
        keyword: keywords,
        'constant.language': builtinConstants,
      },
      'identifier',
      true,
    );

    this.$rules = {
      start: [
        {
          token: 'comment',
          regex: '\\*.*$',
        },
        {
          token: 'string', // " string
          regex: '".*?"',
        },
        {
          token: 'string', // ' string
          regex: "'.*?'",
        },
        {
          token: 'url',
          regex:
            '^(?:(?:(?:https?|ftp):)?\\/\\/)(?:\\S+(?::\\S*)?@)?(?:(?!(?:10|127)(?:\\.\\d{1,3}){3})(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\\.)+(?:[a-z\u00a1-\uffff]{2,}\\.?))(?::\\d{2,5})?(?:[/?#]\\S*)?$',
        },
        {
          token: 'constant.numeric', // float
          regex: '[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b',
        },
        {
          token: keywordMapper,
          regex: '[a-zA-Z_$][a-zA-Z0-9_$]*\\b',
        },
        {
          token: 'keyword.operator',
          regex: '\\+|\\-|\\/|\\/\\/|%|<@>|@>|<@|&|\\^|~|<|>|<=|=>|==|!=|<>|=',
        },
        {
          token: 'paren.lparen',
          regex: '[\\(]',
        },
        {
          token: 'paren.rparen',
          regex: '[\\)]',
        },
        {
          token: 'text',
          regex: '\\s+',
        },
      ],
    };
  };

  oop.inherits(KbDocHighlightRules, TextHighlightRules);

  exports.KbDocHighlightRules = KbDocHighlightRules;
});

ace.define(
  'ace/mode/kbdoc',
  ['require', 'exports', 'module', 'ace/lib/oop', 'ace/mode/markdown', 'ace/mode/markdown_highlight_rules', 'ace/mode/kbdoc_highlight_rules', 'ace/range'],
  function (acequire, exports, module) {
    'use strict';

    var oop = acequire('../lib/oop');
    var MarkdownMode = acequire('./markdown').Mode;
    var MarkdownHighlightRules = require('./markdown_highlight_rules').MarkdownHighlightRules;
    var KbDocHighlightRules = acequire('./kbdoc_highlight_rules').KbDocHighlightRules;
    var Range = acequire('../range').Range;

    var Mode = function () {
      this.HighlightRules = MarkdownHighlightRules;
    };
    oop.inherits(Mode, MarkdownMode);

    (function () {
      this.lineCommentStart = '*';
      this.$id = 'ace/mode/kbdoc';
    }).call(Mode.prototype);

    exports.Mode = Mode;
  },
);
