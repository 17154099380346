<template>
  <div>
    <div v-if="conversationMedia === 'voice'" class="overflow-hidden mt-6">
      <BotConversationRecordingPlayer :conversation="conversation"></BotConversationRecordingPlayer>
    </div>

    <div class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-400 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-gray-300/50 p-1 mr-2 text-gray-500" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Transcript</h2>
      </div>
    </div>

    <div class="flow-root my-4 font-source pt-2 pb-6">
      <ul role="list" class="-mb-8">
        <li v-for="(transcriptItem, transcriptItemIdx) in conversationTranscriptMessages" :key="transcriptItem.ts">
          <div class="relative pb-4">
            <span v-if="transcriptItemIdx !== conversationTranscriptMessages.length - 1" class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            <div class="relative flex items-start space-x-3">
              <div v-if="actorIsCSat(transcriptItem)" :class="['flex h-10 w-10 items-center justify-center', getTranscriptIconClass(transcriptItem)]">{{ transcriptItem.CSat }}</div>
              <mdicon v-else :name="getIcon(transcriptItem)" size="26" :class="['flex h-10 w-10 items-center justify-center', getTranscriptIconClass(transcriptItem)]" />
              <div class="min-w-0 flex-1">
                <div>
                  <div :class="[getTranscriptTitleClass(transcriptItem)]">{{ getAt(transcriptItem) }} {{ getRole(transcriptItem) }}</div>
                  <!--<p class="mt-0.5 text-sm text-gray-500">{{ formatEntryTime(transcriptItem.ts) }}</p>-->
                </div>
                <div v-if="actorIsBot(transcriptItem)" :class="['mt-2', getTranscriptTextClass(transcriptItem)]" v-html="getBotMessageHtml(transcriptItem)"></div>
                <div
                  v-else-if="actorIsSystem(transcriptItem) && transcriptItem[textProp].length > 150"
                  :class="['mt-2 hover:bg-slate-100 cursor-pointer px-2 py-1 -ml-2 rounded-md', transcriptItem.notruncate ? '' : 'max-w-[60vw] truncate', getTranscriptTextClass(transcriptItem)]"
                  @click="transcriptItem.notruncate = !(transcriptItem.notruncate || false)"
                >
                  {{ transcriptItem[textProp] }}
                </div>
                <div
                  v-else-if="actorIsCSat(transcriptItem) && transcriptItem[textProp].length > 150"
                  :class="['mt-2 hover:bg-slate-100 cursor-pointer px-2 py-1 -ml-2 rounded-md', transcriptItem.notruncate ? '' : 'max-w-[60vw] truncate', getTranscriptTextClass(transcriptItem)]"
                  @click="transcriptItem.notruncate = !(transcriptItem.notruncate || false)"
                  v-html="getCSatMessageHtml(transcriptItem)"
                ></div>
                <!--
                <div v-else-if="actorIsCSat(transcriptItem)" :class="['mt-2', getTranscriptTextClass(transcriptItem)]" v-html="getCSatMessageHtml(transcriptItem)"></div>
                -->
                <div v-else :class="['mt-2', getTranscriptTextClass(transcriptItem)]">
                  {{ transcriptItem[textProp] }}
                </div>
                <div v-if="messageHasSources(transcriptItem)">
                  <Accordion>
                    <AccordionTab>
                      <template #header>
                        <span class="flex items-center gap-2 w-full">
                          <mdicon name="text-box-search-outline" size="20" />
                          <span class="font-source font-medium">Sources</span>
                        </span>
                      </template>
                      <ul class="m-0">
                        <li v-for="(item, idx) in transcriptItem.sources" class="font-source text-xs mb-2 hover:text-violet-700">
                          <a v-if="item.startsWith('http')" :href="item" target="_blank">{{ item }}</a>
                          <span v-else>{{ item }}</span>
                        </li>
                      </ul>
                    </AccordionTab>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { pathOr } from 'ramda';
  import { useMd } from '@/hooks/useMd';
  import { formatSeconds } from '@/utils/formatSeconds';
  import Panel from 'primevue/panel';
  import Accordion from 'primevue/accordion';
  import AccordionTab from 'primevue/accordiontab';
  import BotConversationRecordingPlayer from '@/components/Bots/analytics/BotConversationRecordingPlayer.vue';

  const props = defineProps({
    conversation: {
      type: Object,
      default() {
        return {};
      },
    },
    transcriptMessagesPath: {
      type: Array,
      default() {
        return ['context', 'transcript', 'messages'];
      },
    },
    actorProp: {
      type: String,
      default: 'actor',
    },
    textProp: {
      type: String,
      default: 'message',
    },
  });

  const md = useMd();

  const conversationId = computed(() => props.conversation?.id || '');
  const conversationMedia = computed(() => props.conversation?.channelAttributes?.media || 'chat');

  const conversationDatetime = computed(() => props.conversation.datetime);
  //const conversationTranscriptMetadata = computed(() => conversationTranscript.value?.metadata || {});
  const conversationTranscriptMessages = computed(() => {
    //conversationTranscript.value?.messages || conversationTranscript.value?.data ||
    return pathOr([], props.transcriptMessagesPath, props.conversation);
  });

  function getAt(msg) {
    return formatSeconds(Math.floor(((msg?.datetime || 0) - conversationDatetime.value) / 1000));
  }

  // === 'User' ? 'font-medium' : ''

  const actorsInfo = {
    user: {
      name: 'User',
      type: 'user',
      icon: 'account',
      textClass: 'text-md text-gray-700 font-medium bg-slate-50 rounded-md px-2 py-1 -ml-2',
      titleClass: 'text-sm',
      iconClass: 'rounded-full bg-slate-100',
    },
    bot: {
      name: 'Bot',
      type: 'bot',
      icon: 'account-multiple',
      textClass: 'prose font-source text-md bg-violet-50 rounded-md px-2 py-1 -ml-2 text-slate-600',
      titleClass: 'text-sm',
      iconClass: 'rounded-full bg-violet-100',
    },
    assistant: {
      name: 'Bot',
      type: 'bot',
      icon: 'account-multiple',
      textClass: 'prose font-source text-md bg-violet-50 rounded-md px-2 py-1 -ml-2 text-slate-600',
      titleClass: 'text-sm',
      iconClass: 'rounded-full bg-violet-100',
    },
    agent: { name: 'Agent', type: 'agent', icon: 'face-agent', textClass: 'text-md text-gray-700 font-medium bg-orange-50 rounded-md px-2 py-1 -ml-2', titleClass: 'text-sm', iconClass: 'rounded-full bg-orange-100' },
    system: {
      name: 'System',
      type: 'system',
      icon: 'cog',
      textClass: 'text-sm text-gray-500',
      titleClass: 'text-xs text-gray-500',
      iconClass: 'rounded-full bg-white text-slate-300 ring-8 ring-white',
    },
    csat: {
      name: 'CSat',
      type: 'csat',
      icon: 'cog',
      textClass: 'prose font-source text-xs text-slate-600',
      titleClass: 'text-xs text-gray-600',
      iconClass: 'rounded-full text-slate-700 font-medium',
    },
    tool: {
      name: 'System',
      type: 'system',
      icon: 'cog',
      textClass: 'text-sm text-gray-500',
      titleClass: 'text-xs text-gray-500',
      iconClass: 'rounded-full bg-white text-slate-300 ring-8 ring-white',
    },
  };

  function getActorInfo(msg) {
    const actor = (msg[props.actorProp] || 'system').toLowerCase();
    return actorsInfo[actor] || actorsInfo['system'];
  }

  function getRole(msg) {
    const actor = (msg[props.actorProp] || 'system').toLowerCase();
    const actorInfo = actorsInfo[actor];
    if (actorInfo.type === 'agent') {
      return msg.name + ` ( ${actorInfo?.name} )`;
    }
    return actorInfo?.name || 'System';
    //return actor === 'user' ? 'User' : actor === 'bot' || actor === 'assistant' ? 'Bot' : 'System';
  }

  function getIcon(msg) {
    const actor = (msg[props.actorProp] || 'system').toLowerCase();
    return actorsInfo[actor]?.icon || 'cog';
    //const role = getRole(msg);
    //return role === 'User' ? 'account' : role === 'Bot' ? 'account-multiple' : 'cog';
    //transcriptItem[actorProp].toLowerCase() === 'assistant' ? 'cog' : transcriptItem[actorProp].toLowerCase() === 'agent' ? 'face-agent' : 'account'
  }

  function messageHasSources(transcriptItem) {
    return Array.isArray(transcriptItem.sources) && transcriptItem.sources.length > 0;
  }

  function actorIsBot(transcriptItem) {
    return getActorInfo(transcriptItem)?.type === 'bot';
  }

  function actorIsSystem(transcriptItem) {
    return getActorInfo(transcriptItem)?.type === 'system';
  }

  function actorIsCSat(transcriptItem) {
    return getActorInfo(transcriptItem)?.type === 'csat';
  }

  function getBotMessageHtml(transcriptItem) {
    return md.mdToHtml(transcriptItem[props.textProp] || '');
  }

  function getCSatMessageHtml(transcriptItem) {
    if (!transcriptItem.notruncate) {
      return (transcriptItem[props.textProp] || '').substring(0, 50) + ' ...';
    }
    return md.mdToHtml(transcriptItem[props.textProp] || '');
  }

  function getTranscriptTextClass(transcriptItem) {
    return getActorInfo(transcriptItem)?.textClass || 'text-md text-gray-700';
  }

  function getTranscriptTitleClass(transcriptItem) {
    return getActorInfo(transcriptItem)?.titleClass || 'text-sm';
  }

  function getTranscriptIconClass(transcriptItem) {
    const actorInfo = getActorInfo(transcriptItem);
    if (actorInfo.type === 'csat') {
      const bg = transcriptItem?.CSat < 4 ? 'bg-red-100' : transcriptItem?.CSat < 7 ? 'bg-yellow-100' : 'bg-green-100';
      return actorInfo?.iconClass + ' ' + bg;
    }
    return actorInfo?.iconClass || 'rounded-full bg-slate-100 ring-8 ring-white';
  }
</script>
