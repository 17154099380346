<template>
  <TestResult></TestResult>
</template>
<script setup>
  import { useRoute } from 'vue-router';
  import { useTestResultsStore } from '@/store/TestResultsStore';
  import { onMounted } from 'vue';
  import TestResult from '@/components/TestResult/TestResult.vue';

  const route = useRoute();
  const testsResultsStore = useTestResultsStore();

  onMounted(async () => {
    let id = route.params?.id || null;
    if (!id) {
      // TODO show something
      return;
    }
    testsResultsStore.loadTestResult('ctx-demo', id);
  });
</script>
