<template>
  <div ref="tuiViewerElement"></div>
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue';
  import Viewer from '@toast-ui/editor/dist/toastui-editor-viewer';
  //import '@toast-ui/editor/dist/toastui-editor.css';
  //import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';
  import '@toast-ui/editor/dist/toastui-editor-viewer.css';

  const props = defineProps({
    height: {
      type: String,
      default: '300px',
    },
    initialValue: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: {},
    },
  });

  const tuiViewerElement = ref(null);
  let tuiViewer = null;

  const editorEvents = ['load', 'change', 'caretChange', 'focus', 'blur', 'keydown', 'keyup', 'beforePreviewRender', 'beforeConvertWysiwygToMarkdown'];
  const emit = defineEmits(['load', 'change', 'caretChange', 'focus', 'blur', 'keydown', 'keyup', 'beforePreviewRender', 'beforeConvertWysiwygToMarkdown']);

  const defaultValueMap = {
    initialValue: '',
    height: '300px',
  };

  const viewerOptions = computed(() => {
    const eventOptions = {};

    editorEvents.forEach((event) => {
      eventOptions[event] = (...args) => {
        emit(event, ...args);
      };
    });

    const options = {
      ...props.options,
      initialValue: props.initialValue,
      height: props.height,
      usageStatistics: false,
      events: eventOptions,
    };

    Object.keys(defaultValueMap).forEach((key) => {
      if (!options[key]) {
        options[key] = defaultValueMap[key];
      }
    });

    return options;
  });

  function setMarkdown(markdown, cursorToEnd) {
    if (tuiViewer) {
      tuiViewer.setMarkdown(markdown, cursorToEnd);
    }
  }

  function getMarkdown() {
    return tuiViewer ? tuiViewer.getMarkdown() : '';
  }

  onMounted(() => {
    const options = { ...viewerOptions.value, el: tuiViewerElement.value };
    tuiViewer = new Viewer(options);
  });

  defineExpose({ setMarkdown, getMarkdown });
</script>

<style>
  /* Custom CSS to change the font size in TOAST UI Editor */
  .toastui-editor-contents {
    font-size: 16px; /* Change this to your desired font size */
    font-family:
      Source Sans Pro,
      ui-sans-serif,
      system-ui,
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
  }

  .ProseMirror {
    font-size: 16px; /* Change this to your desired font size */
    font-family:
      Source Sans Pro,
      ui-sans-serif,
      system-ui,
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
  }

  .toastui-editor-contents h1 {
    font-size: 2em; /* Custom font size for headers */
    line-height: 120%;
  }

  .toastui-editor-contents h2 {
    font-size: 1.75em;
    line-height: 120%;
  }

  .toastui-editor-contents p {
    font-size: 1em; /* Custom font size for paragraphs */
  }
</style>
