<template>
  <!--<div class="sticky grid grid-rows-2 grid-cols-1" style="grid-template-rows: auto 1fr; top: calc(4rem + 1px); height: calc(100vh - 4rem - 1px)">-->
  <!--<div class="sticky flex flex-col" style="top: calc(4rem + 1px); height: calc(100vh - 4rem - 1px)">-->
  <div class="grid grid-cols-1 grid-rows-2" style="grid-template-rows: auto 1fr; min-height: 0; min-width: 0">
    <div class="lg:flex lg:items-center lg:justify-between p-2 my-1">
      <background-glow></background-glow>
      <div class="z-10 min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-slate-400 sm:truncate sm:text-3xl sm:tracking-tight">{{ specData.name }}</h2>
        <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {{ specData.media }}
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <MapPinIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            {{ specData.voice.dnis }}
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Modified on March 28, 2023
          </div>
        </div>
      </div>
      <div class="z-10 mt-5 flex lg:mt-0 lg:ml-4">
        <span class="hidden sm:block">
          <button type="button" class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <PencilIcon class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Settings
          </button>
        </span>

        <span class="sm:ml-3">
          <button
            type="button"
            class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <CheckIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Save
          </button>
        </span>
      </div>
    </div>
    <div class="z-10 grid grid-rows-1 grid-cols-2" style="grid-template-columns: 1fr auto; min-height: 0; min-width: 0">
      <div class="m-1 bg-white rounded">
        <spec-script-editor :actions="specScriptActions" @select="onActionSelect" @add-action="onActionAdd"></spec-script-editor>
      </div>
      <!-- 1px here is to account for border of navbar
          <div v-if="true" class="sticky" style="top: calc(4rem + 1px); height: calc(100vh - 4rem - 1px)">
      -->
      <div class="m-1 bg-white rounded border-l dark:border-0">
        <!-- Right Sidebar - Properties view
        <div class="h-full bg-slate-50" style="overflow-y: auto">Selected element props</div>-->
        <spec-script-action-editor :idx="selectedActionIdx" :action="selectedAction" @update-step="onActionUpdate"></spec-script-action-editor>
      </div>
    </div>
  </div>
</template>

<script>
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  import { BriefcaseIcon, CalendarIcon, CheckIcon, ChevronDownIcon, ChevronRightIcon, CurrencyDollarIcon, LinkIcon, MapPinIcon, PencilIcon } from '@heroicons/vue/20/solid';
  import { watch, ref, reactive, computed } from 'vue';
  import { pathOr } from 'ramda';
  import SpecScriptEditor from '@/components/Spec/SpecScriptEditor.vue';
  import SpecScriptActionEditor from '@/components/Spec/SpecScriptActionEditor.vue';
  import BackgroundGlow from '@/components/Backgrounds/BackgroundGlow.vue';
  import { getActionDef } from '@/spec/ActionDefs.js';

  export default {
    components: {
      SpecScriptEditor,
      SpecScriptActionEditor,
      BackgroundGlow,
      Menu,
      MenuButton,
      MenuItem,
      MenuItems,
      BriefcaseIcon,
      CalendarIcon,
      CheckIcon,
      ChevronDownIcon,
      ChevronRightIcon,
      CurrencyDollarIcon,
      LinkIcon,
      MapPinIcon,
      PencilIcon,
    },
    props: {
      spec: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    setup(props, context) {
      // Make a full copy of the spec, and make it reactive
      // TODO Normalize spec, i.e. add empty steps, etc
      const specData = reactive(JSON.parse(JSON.stringify(props.spec)));

      const specScriptActions = computed(() => specData?.script?.actions);

      const selectedActionIdx = ref(-1);
      const selectedAction = computed(() => pathOr(undefined, ['script', 'actions', selectedActionIdx.value], specData));

      function onActionSelect(idx) {
        selectedActionIdx.value = idx;
        console.log(`Selected step: ${idx}`);
      }

      function onActionUpdate(idx, step) {
        specData.script.actions[idx] = step; // ???
        console.log(`Updated step: ${idx} -> ${JSON.stringify(step)}`);
      }

      function onActionAdd(actionData) {
        //specData.script.actions[idx] = step; // ???
        if (!actionData?.type) {
          console.log(`ERROR: Missing action type`);
          return;
        }
        const actionDef = getActionDef(actionData.type);
        const action = actionData;
        actionDef.props.map((p) => {
          action[p.name] = p.default;
        });
        specData.script.actions.push(action);
        console.log(`onAddAction: Added action: ${JSON.stringify(action)}`);
      }

      return {
        specData,
        specScriptActions,
        onActionSelect,
        onActionUpdate,
        onActionAdd,
        selectedActionIdx,
        selectedAction,
      };
    },
  };
</script>

<style scoped></style>
