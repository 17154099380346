<template>
  <div class="my-2">
    <div v-if="isLoading">
      <Skeleton class="w-full mb-2" height="80px" borderRadius="16px"></Skeleton>
      <Skeleton height="3rem" class="w-full" borderRadius="16px"></Skeleton>
    </div>
    <div :class="['w-full', audioAvailable ? '' : 'hidden']" ref="wavesurferElement" id="waveform"></div>
    <Message v-if="!isLoading && !audioAvailable" severity="warn" :closable="false">Recording is not available</Message>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, watch, computed } from 'vue';
  import { useApi } from '@/hooks/useApi';
  import Skeleton from 'primevue/skeleton';
  import Message from 'primevue/message';
  import WaveSurfer from 'wavesurfer.js';
  import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.js';
  import Hover from 'wavesurfer.js/dist/plugins/hover.js';
  import dayjs from 'dayjs';

  //const delay = (x) => new Promise((res) => setTimeout(res, x));

  const props = defineProps({
    conversation: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  const emit = defineEmits(['timeupdate', 'play', 'pause']);

  // audioAvailable exposed via v-model for parent to react on
  const audioAvailable = defineModel('audioAvailable');

  const api = useApi();

  const wavesurferElement = ref(null);
  let wavesurfer = null;

  const isLoading = ref(false);

  const conversationId = computed(() => props.conversation?.id || null);

  const options = {
    container: '#waveform',
    height: 80,
    waveColor: 'violet',
    progressColor: 'purple',
    mediaControls: true,
    fillParent: true,
    //responsive: true,
    //scrollParent: true,
    backend: 'MediaElement',
    splitChannels: false,
    hideScrollbar: true,
    plugins: [
      TimelinePlugin.create(),
      Hover.create({
        lineColor: 'purple',
        lineWidth: 2,
        labelBackground: '#555',
        labelColor: '#fff',
        labelSize: '11px',
      }),
    ],
  };

  watch(
    () => props.conversation,
    (newValue /*, oldValue*/) => {
      loadAudio();
    },
  );

  // Function to convert Base64 to Blob
  function base64ToBlob(base64String, contentType = '') {
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    return new Blob([byteArray], { type: contentType });
  }

  async function loadAudio() {
    if (!conversationId.value) {
      return;
    }
    isLoading.value = true;
    audioAvailable.value = false;
    const audioRes = await api.loadBotConversationRecording({
      conversationId: conversationId.value,
    });
    isLoading.value = false;
    if (!audioRes.success || !audioRes?.data) {
      console.log(`Error loading audio: ${audioRes.error}`);
      audioAvailable.value = false;
      return;
    }
    const audioBlob = base64ToBlob(audioRes.data, audioRes?.mimeType || 'audio/mp3');
    audioAvailable.value = true;
    if (!wavesurfer) {
      wavesurfer = WaveSurfer.create(options);
      wavesurfer.on('timeupdate', (currentTime) => {
        emit('timeupdate', currentTime);
      });
      wavesurfer.on('play', () => {
        emit('play');
      });
      wavesurfer.on('pause', () => {
        emit('pause');
      });
    }
    wavesurfer.loadBlob(audioBlob);
  }

  function setTime(time) {
    if (wavesurfer) {
      wavesurfer.setTime(time);
    }
  }

  function play(time) {
    if (wavesurfer) {
      wavesurfer.play();
    }
  }

  function isPlaying(time) {
    return wavesurfer ? wavesurfer.isPlaying() : false;
  }

  function pause(time) {
    if (wavesurfer) {
      wavesurfer.pause();
    }
  }

  onMounted(() => {
    loadAudio();
  });

  defineExpose({
    setTime,
    play,
    pause,
    isPlaying,
  });
</script>
<style>
  #waveform ::part(wrapper) {
    margin-bottom: 2px;
    margin-left: 1px;
  }
</style>
