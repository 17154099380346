/*
 * Store for Agents CSat Scores data
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useAgentsCSatScoresStore = defineStore('AgentsCSatScoresStore', () => {
  // Agents KPI Scores data
  const agentsData = ref([]);
  const sortField = ref('CSat');
  const sortOrder = ref(-1);
  const analyzedTimeInterval = ref(null);

  return {
    agentsData,
    sortField,
    sortOrder,
    analyzedTimeInterval,
  };
});
