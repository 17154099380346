<template>
  <Popover class="relative">
    <PopoverButton v-if="inline" class="inline-flex items-center hover:underline cursor-pointer">
      <span>{{ dateRangeStore.title }}</span>
    </PopoverButton>

    <PopoverButton v-else class="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-200 border border-gray-50/20 rounded-md py-1 px-2">
      <mdicon name="calendar-clock-outline" size="20" class="text-slate-400" aria-hidden="true"></mdicon>
      <span>{{ dateRangeStore.title }}</span>
      <ChevronDownIcon class="h-5 w-5" aria-hidden="true" />
    </PopoverButton>

    <PopoverPanel v-slot="{ close }" class="absolute left-1/2 z-10 mt-5 flex w-screen max-w-lg -translate-x-3/4 px-4">
      <div class="w-screen max-w-xs p-4 flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5 lg:max-w-3xl">
        <TabGroup>
          <TabList class="flex space-x-1 rounded-xl bg-gray-200 p-1">
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-slate-300 focus:outline-none focus:ring-2',
                  selected ? 'bg-white shadow' : 'text-slate-500 hover:bg-white/[0.12] hover:text-slate-800',
                ]"
              >
                Quick
              </button>
            </Tab>
            <Tab as="template" v-slot="{ selected }">
              <button
                :class="[
                  'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                  'ring-white ring-opacity-60 ring-offset-2 ring-offset-slate-300 focus:outline-none focus:ring-2',
                  selected ? 'bg-white shadow' : 'text-slate-500 hover:bg-white/[0.12] hover:text-slate-800',
                ]"
              >
                Custom
              </button>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <div class="grid grid-cols-1 gap-x-6 gap-y-1 p-4 lg:grid-cols-2">
                <div v-for="item in options" :key="item.key" class="group relative flex gap-x-2 rounded-lg p-4 items-center hover:bg-gray-50">
                  <div class="relative bg-purple-600/70 rounded-md p-1 h-8 w-8 flex items-center justify-center">
                    <mdicon name="calendar-clock-outline" size="20" class="text-purple-100" aria-hidden="true"></mdicon>
                  </div>
                  <div class="font-semibold text-gray-900 cursor-pointer" @click="setQuickRange(item, close)">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div class="mt-4 flex flex-col items-center justify-center">
                <div class="w-full p-4">
                  <div class="flex m-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <span class="flex w-14 select-none items-center justify-end pr-2 text-gray-500 sm:text-sm">From:</span>
                    <input type="text" readonly :value="customFrom" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
                  </div>
                  <div class="flex m-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                    <span class="flex w-14 select-none items-center justify-end pr-2 text-gray-500 sm:text-sm">To:</span>
                    <input type="text" readonly :value="customTo" class="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6" />
                  </div>
                </div>
                <Calendar v-model="customDates" inline selectionMode="range" :manualInput="true" class="text-xs font-source" />
                <div class="mt-5 mr-10 sm:mt-4 sm:flex justify-end w-full">
                  <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    ref="cancelButtonRef"
                    @click="onCancel(close)"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    :disabled="!customRangeSet"
                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white hover:bg-indigo-500 shadow-sm sm:ml-3 sm:w-auto disabled:opacity-50"
                    @click="setCustomRange(close)"
                  >
                    OK
                  </button>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </PopoverPanel>
  </Popover>
</template>

<script setup>
  import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
  import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue';
  import { ChevronDownIcon } from '@heroicons/vue/20/solid';
  import Calendar from 'primevue/calendar';
  import { ref, computed } from 'vue';
  import { useDateRangeStore } from '@/store/DaterangeStore';
  import dayjs from 'dayjs';

  const props = defineProps({
    inline: {
      type: Boolean,
      default: false,
    },
  });

  const dateRangeStore = useDateRangeStore();

  const customDates = ref();

  const customFrom = computed(() => {
    return customDates.value?.length > 0 && customDates.value[0] ? dayjs(customDates.value[0]).format('MM/DD/YYYY') : '';
  });

  const customTo = computed(() => {
    return customDates.value?.length > 1 && customDates.value[1] ? dayjs(customDates.value[1]).format('MM/DD/YYYY') : '';
  });

  const customRangeSet = computed(() => {
    return customDates.value?.length === 2 && customDates.value[0] && customDates.value[1];
  });

  const options = [
    { key: '32', title: 'Today', from: 'StartOfDay', to: 'now' },
    { key: '33', title: 'This Week', from: 'StartOfWeek', to: 'now' },
    { key: '34', title: 'This Month', from: 'StartOfMonth', to: 'now' },
    { key: '27', title: 'Last 14 days', from: 'P14D', to: 'now' },
    { key: '28', title: 'Last 30 days', from: 'P30D', to: 'now' },
    { key: '29', title: 'Last 60 days', from: 'P60D', to: 'now' },
  ];

  function setQuickRange(spec, close) {
    dateRangeStore.setDateRange(spec.from, spec.to, spec.title);
    close();
  }
  function setCustomRange(close) {
    if (customRangeSet.value) {
      const title = `${customFrom.value} - ${customTo.value}`;
      dateRangeStore.setDateRange(dayjs(customDates.value[0]).valueOf(), dayjs(customDates.value[1]).valueOf(), title);
      close();
    }
  }

  function onCancel(close) {
    close();
  }
</script>
