<template>
  <div>
    <Dialog v-model:visible="visible" modal :closable="false" header="Upload and Analyze Recording" :style="{ width: '60vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div v-if="!uploaded">
        <small>You can upload mp3 or wav file with call recording</small>
        <FileUpload
          name="uploader"
          customUpload
          @uploader="onFileUpload($event)"
          :multiple="false"
          accept="audio/mpeg,audio/wav"
          :maxFileSize="20000000"
          :pt="{
            thumbnail: { src: '/img/icon-file.svg' },
          }"
        >
          <template #empty>
            <p>Drag and drop file here to upload.</p>
          </template>
        </FileUpload>
      </div>
      <div v-if="uploaded" class="flex flex-col gap-2">
        <div class="flex gap-2 items-center font-source text-sm">
          <div>
            Name: <strong>{{ recordingFileName }}</strong>
          </div>
          <div>
            Type: <strong>{{ recordingFileType }}</strong>
          </div>
          <div>
            Size: <strong>{{ formatBytes(recordingFileSize) }}</strong>
          </div>
        </div>
      </div>
      <div v-if="uploaded" class="mt-4 flex flex-col gap-2">
        <h2 class="font-semibold text-lg">Please provide some details about this call</h2>
        <label class="text-sm">Call Date</label>
        <Calendar v-model="recordingDate" showIcon showTime />
        <label class="text-sm">Agent Name</label>
        <InputText v-model="recordingAgentName" class="" aria-describedby="docname-help" />
        <label class="text-sm">Phone Number</label>
        <InputText v-model="recordingPhoneNumber" class="" aria-describedby="docname-help" />
        <label class="text-sm">Tags</label>
        <StdTagInputEditor v-model="recordingTags" :available-tags="availableTags" @add-tag="onAddTag"></StdTagInputEditor>
      </div>
      <div class="mt-4">
        <small class="min-h-6 block">{{ progressText }} </small>
        <ProgressBar :mode="progressMode" style="height: 6px"></ProgressBar>
      </div>
      <div v-if="errorMessage" class="w-full my-4">
        <Message :closable="false" severity="error">{{ errorMessage }}</Message>
      </div>
      <template #footer>
        <Button label="Cancel" :disabled="showProgress" severity="secondary" @click="visible = false" autofocus />
        <Button label="Create" :disabled="!uploaded || showProgress" severity="primary" @click="analyzeRecording" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
  import { useRbacStore } from '@/store/RBACStore';
  import { useSettingsStore } from '@/store/SettingsStore';
  import { useApi } from '@/hooks/useApi';
  import { useToast } from 'vue-toastification';
  import { ref, nextTick, computed } from 'vue';
  import InputText from 'primevue/inputtext';
  import Dialog from 'primevue/dialog';
  import Button from 'primevue/button';
  import Calendar from 'primevue/calendar';
  import ProgressBar from 'primevue/progressbar';
  import FileUpload from 'primevue/fileupload';
  import Message from 'primevue/message';
  import { formatBytes } from '@/utils/formatBytes';
  import StdTagInputEditor from '@/components/Editors/StdTagInputEditor.vue';

  const settingsStore = useSettingsStore();
  const api = useApi();

  const toast = useToast();

  const visible = ref(false);
  const uploaded = ref(false);
  const showProgress = ref(false);
  const progressMode = computed(() => (showProgress.value ? 'indeterminate' : ''));
  const progressText = ref('');
  const contentReady = ref(false);
  const errorMessage = ref(null);

  const recordingFileName = ref('');
  const recordingFileType = ref('');
  const recordingFileSize = ref(0);
  let recordingDataBase64 = null;

  const recordingDate = ref(new Date());
  const recordingTags = ref([]);
  const availableTags = ref([]);
  const recordingAgentName = ref('John Smith');
  const recordingPhoneNumber = ref('+1-800-111-2233');

  const emit = defineEmits(['recordingAnalyzed']);

  function start() {
    recordingFileName.value = '';
    uploaded.value = false;
    progressText.value = '';
    showProgress.value = false;
    errorMessage.value = null;
    visible.value = true;
  }

  const pause = (x) => new Promise((res) => setTimeout(res, x));

  async function fileToBase64DataUrl(file) {
    return await new Promise((resolve, reject) => {
      const reader = Object.assign(new FileReader(), {
        onload: () => {
          let rr = reader.result;
          const idx = rr.indexOf('base64,');
          if (idx === -1) {
            resolve(null);
          }
          rr = rr.substring(idx + 7);
          resolve(rr);
        },
        onerror: () => {
          console.log(`Error converting to base64`, reader.error);
          resolve(null);
        },
      });
      reader.readAsDataURL(file);
    });
  }

  async function onFileUpload(event) {
    const file = event.files[0];
    recordingFileName.value = file.name;
    recordingFileType.value = file.type;
    recordingFileSize.value = file.size;
    //const fab = await file.arrayBuffer();
    recordingDataBase64 = await fileToBase64DataUrl(file);
    console.log(`File uploaded`, event);
    uploaded.value = true;
    if (!recordingDataBase64) {
      errorMessage.value = `Error reading file`;
      return;
    }
  }

  // Create fake aircall metadata, so analytics will show details about call in a nice way
  function generateMetadata() {
    let callId = recordingFileName.value;
    // remove extension
    callId = callId.replace('.mp3', '');
    callId = callId.replace('.wav', '');
    const meta = {
      id: callId,
      direct_link: `https://api.aircall.io/v1/calls/${recordingFileName.value}`,
      direction: 'inbound',
      started_at: Math.floor(recordingDate.value.getTime() / 1000),
      duration: 1000,
      raw_digits: `${recordingPhoneNumber.value}`,
      user: {
        id: 1,
        name: `${recordingAgentName.value}`,
      },
      number: {
        name: 'Inbound',
        digits: `${recordingPhoneNumber.value}`,
      },
      tags: recordingTags.value.map((t) => {
        return { name: t };
      }),
    };
    return meta;
  }

  async function uploadFiles() {
    const recId = recordingFileName.value;
    const metadata = generateMetadata();
    const metadataBase64 = btoa(JSON.stringify(metadata));
    const metadataPath = `${settingsStore.tenantId}/ingest/queue/uploads/${recId}.metadata.json`;
    const metaUploadRes = await api.storeUploadFile({
      path: metadataPath,
      data: metadataBase64,
      contentType: 'application/json',
    });
    if (!metaUploadRes.success) {
      errorMessage.value = `Error uploading file: ${metaUploadRes.error}`;
      return null;
    }

    const path = `${settingsStore.tenantId}/ingest/queue/uploads/${recId}`;
    const uploadRes = await api.storeUploadFile({
      path: path,
      data: recordingDataBase64,
      contentType: recordingFileType.value,
    });
    if (!uploadRes.success) {
      errorMessage.value = `Error uploading file: ${uploadRes.error}`;
      return null;
    }
    return path;
  }

  function getDateFolderUTC(date) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() returns month from 0-11, so we add 1
    const day = String(date.getUTCDate()).padStart(2, '0');

    return `${year}.${month}.${day}`;
  }

  async function moveFiles() {
    const recId = recordingFileName.value;
    const metadataPath = `${settingsStore.tenantId}/ingest/queue/uploads/${recId}.metadata.json`;
    const recordingPath = `${settingsStore.tenantId}/ingest/queue/uploads/${recId}`;
    const transcriptPath = `${settingsStore.tenantId}/ingest/queue/uploads/${recId}.transcript.json`;
    // date
    const dateFolder = getDateFolderUTC(recordingDate.value);
    const newMetadataPath = `${settingsStore.tenantId}/calls/${dateFolder}/${recId}.metadata.json`;
    const newRecordingPath = `${settingsStore.tenantId}/calls/${dateFolder}/${recId}`;
    const newTranscriptPath = `${settingsStore.tenantId}/calls/${dateFolder}/${recId}.transcript.json`;
    await api.storeMoveFile({ path: metadataPath, newPath: newMetadataPath });
    await api.storeMoveFile({ path: recordingPath, newPath: newRecordingPath });
    await api.storeMoveFile({ path: transcriptPath, newPath: newTranscriptPath });
  }

  // tenant-9b4df02d0ea0303a/9b4df02d0ea0303a/ingest/queue/uploads/KRIS_CALL_4.mp3
  async function analyzeRecording() {
    const jobId = settingsStore.ingestJobId;
    if (!jobId) {
      errorMessage.value = `Ingest job not configured`;
      return;
    }

    errorMessage.value = null;
    progressText.value = 'Analyzing recording ...';
    showProgress.value = true;

    const recId = recordingFileName.value;
    const recPath = await uploadFiles();
    if (!recPath) {
      progressText.value = '';
      showProgress.value = false;
      return;
    }

    const jobParams = {
      jobId: jobId,
      jobParameters: {
        files: [recPath],
      },
    };
    const jobResult = await api.executeJobOnDemand(jobParams);
    if (!jobResult.success) {
      errorMessage.value = `Error executing job: ${jobResult.error}`;
      progressText.value = '';
      showProgress.value = false;
      return;
    }

    await moveFiles();

    progressText.value = '';
    showProgress.value = false;
    visible.value = false;
    toast.success(`Recording analyzed`);
    emit('recordingAnalyzed', jobResult);
  }

  function onAddTag(tag) {
    availableTags.value.push(tag);
  }

  defineExpose({ start });
</script>
