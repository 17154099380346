/*
 * Analytics Store
 *
 * TODO
 *  - Time Interval
 *  - Filters
 * */
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useSettingsStore } from '@/store/SettingsStore';
import { useApi } from '@/hooks/useApi';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useToast } from 'vue-toastification';

export const useAnalyticsStore = defineStore('analytics', () => {
  const authStore = useAuthStore();
  const tenantId = computed(() => authStore.tenantId);
  const settingsStore = useSettingsStore();
  const api = useApi();

  const toast = useToast();

  const functions = getFunctions();

  // Conversations cache TODO Consider moving out to separate class
  const conversationsCache = {};

  // Add / update conversations to cache
  // When searched and found some conversations, we can add them to cache
  // so that we don't need to reload conversation from ES when opening details view
  function cacheConversations(conversations) {
    if (!Array.isArray(conversations) || conversations.length <= 0) {
      return;
    }
    for (let c of conversations) {
      const id = c?.id || null;
      if (id) {
        conversationsCache[id] = c;
      }
    }
  }

  async function getConversation(id) {
    return conversationsCache[id] || {};
    // TODO Load this specific conversation from ES, if not found in cache
  }

  // Execute Analytics Query
  async function executeAnalyticsQuery(analyticsQuery) {
    const queryRequest = analyticsQuery?.request || null;
    if (!queryRequest) {
      console.log(`ERROR: request must be provided in analyticsQuery`);
      return false;
    }

    // [sv2] 07/06/2024 Transitioning to monthly-based indexes
    // Set indexSpan according to tenant settings todo remove when migrated to monthly indexes
    const indexSpan = await settingsStore.getElasticIndexSpan();
    analyticsQuery.request.indexSpan = indexSpan;

    // [sv2] 07/06/2024 Switch to api function
    await api.executeAnalyticsQuery(analyticsQuery);

    /* The below is done in api.executeAnalyticsQuery
    const executeAnalyticsQueryFunction = httpsCallable(functions, 'executeAnalyticsQuery', { timeout: 120000 });
    let result = null;
    try {
      result = await executeAnalyticsQueryFunction(queryRequest);
    } catch (e) {
      const msg = `ERROR: Query execution failed: ${e.message}`;
      analyticsQuery.setError(msg);
      console.log(msg);
      toast.error(msg);
      return false;
    }

    // Function call returns everything inder "data"
    const resultData = result?.data || {};
    const resultSuccess = resultData?.success || false;
    if (resultData && resultSuccess) {
      analyticsQuery.setResult(resultData.data);
    } else {
      analyticsQuery.setError(resultData?.message || 'Request failed', resultData?.data);
    }
    */

    const resultSuccess = analyticsQuery?.success || false;
    console.log(`Execute query result: SUCCESS:${resultSuccess}`);
    return resultSuccess;
  }

  return {
    executeAnalyticsQuery,
    cacheConversations,
    getConversation,
  };
});
