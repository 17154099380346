<template>
  <MultiSelect v-model="value" display="chip" :options="allTags" filter @filter="onFilterEvent" :placeholder="placeholder || 'Set tags'" class="w-full font-source">
    <template #emptyfilter="slotProps">
      <div v-if="!addTagDisabled" class="flex items-center gap-x-2 cursor-pointer text-gray-400 hover:text-sky-800" @click.stop="onAddTag">
        <div>Add tag</div>
        <Tag severity="secondary" :value="currentFilterValue"></Tag>
      </div>
    </template>
  </MultiSelect>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import MultiSelect from 'primevue/multiselect';
  import Tag from 'primevue/tag';

  const props = defineProps(['modelValue', 'availableTags', 'disableAddTag', 'placeholder']);
  const emit = defineEmits(['update:modelValue', 'addTag']);

  const currentFilterValue = ref('');

  const addTagDisabled = computed(() => {
    if (props.disableAddTag === true) {
      return true;
    }
    return false;
  });

  const value = computed({
    get() {
      return Array.isArray(props.modelValue) ? props.modelValue : [];
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });

  const allTags = computed(() => {
    const all = Array.isArray(props.availableTags) ? props.availableTags.concat() : [];
    for (let i = 0; i < value.value.length; i++) {
      if (!all.includes(value.value[i])) {
        all.push(value.value[i]);
      }
    }
    return all;
  });

  function onFilterEvent(filterEvent) {
    currentFilterValue.value = filterEvent?.value || '';
  }

  function onAddTag() {
    emit('addTag', currentFilterValue.value);
  }
</script>
