<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8">
    <div class="sm:flex sm:items-center">
      <div class="flex-auto flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-violet-600/70 rounded-xl p-1">
            <mdicon :name="icon" size="62" class="text-violet-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-2xl font-bold text-gray-900">{{ collectionDoc?.name || '' }}</h1>
          <p class="text-sm font-medium text-gray-500">{{ collectionDescription }}</p>
        </div>
      </div>

      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <div class="flex items-center">
          <button
            v-if="showDelete"
            type="button"
            class="mr-2 flex items-center gap-x-1 rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            @click="onDeleteDocuments"
          >
            <MinusCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
            Delete
          </button>
          <button
            type="button"
            class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="onNewDocument"
          >
            <PlusCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
            Add
          </button>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <DataTable v-model:selection="selectedDocuments" :value="collectionDocsList" paginator :rows="25" :rowsPerPageOptions="[25, 50, 100]" removableSort tableStyle="min-width: 50rem" class="p-datatable-sm">
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column v-for="(field, idx) in tableFields" :key="idx" :field="field.name" :header="field.title" sortable>
          <template v-if="field.key" #body="slotProps">
            <div class="flex items-start cursor-pointer" @click="onEditDocument(slotProps.data)">
              <div class="flex mr-2 h-7 w-7 flex-none items-center justify-center rounded-md text-violet-500/70 bg-violet-50">
                <mdicon :name="icon" size="18"></mdicon>
              </div>
              <div class="font-medium hover:underline">{{ slotProps.data[field.name] }}</div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
    <CollectionDocEditDialog :open="editOpen" :document="editDocument" :fields="fields" :icon="icon" :collectionItemsName="collectionItemsName" @cancel="editOpen = false" @save="onSaveDocument"></CollectionDocEditDialog>
    <ConfirmationDialog :open="deleteConfirm" title="Delete" :description="`Are you sure you want to delete ${collectionItemsName} ?`" @cancel="deleteConfirm = false" @confirm="onDeleteConfirmed"></ConfirmationDialog>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useAuthStore } from '@/store/AuthStore';
  import { useCollectionsStore } from '@/store/CollectionsStore';
  import { useCollection, useDocument } from 'vuefire';
  import { collection } from 'firebase/firestore';
  import { PlusCircleIcon, MinusCircleIcon } from '@heroicons/vue/20/solid';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import CollectionDocEditDialog from '@/components/Collections/CollectionDocEditDialog.vue';
  import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
  import { fbDb } from '@/firebaseApp';
  import { nanoid } from 'nanoid';
  import { useToast } from 'vue-toastification';

  const props = defineProps({
    collectionType: {
      type: String,
      default: null,
    },
    collectionSubtitle: {
      type: String,
      default: null,
    },
    collectionItemsName: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
  });

  const route = useRoute();
  const authStore = useAuthStore();
  const collectionsStore = useCollectionsStore();
  const toast = useToast();

  const tableFields = computed(() => {
    return props.fields.filter((x) => !x.tableHide);
  });
  const selectedDocuments = ref();
  const showDelete = computed(() => Array.isArray(selectedDocuments.value) && selectedDocuments.value.length > 0);

  const collectionId = ref(route.params?.id || null);
  const collectionType = ref(route.meta?.collectionType || null);
  // This should be pre-loaded already
  const allCollections = useCollection(collectionsStore.getCollection(collectionType.value));
  //const collectionDoc = computed(() => collectionsStore.getCollectionDoc(collectionType.value, collectionId.value));
  const collectionDoc = computed(() => allCollections.value.find((x) => x.id === collectionId.value));

  const collectionDescription = computed(() => collectionDoc.value?.description || props.collectionSubtitle);

  const collectionDocsListPath = `tenants/${authStore.tenantId}/${collectionType.value}/${collectionId.value}/docs`;
  const collectionDocsList = useCollection(collection(fbDb, collectionDocsListPath));

  const editOpen = ref(false);
  const editDocument = ref(null);
  const editNew = ref(false); // ??

  function onNewDocument() {
    editDocument.value = {
      id: nanoid(10),
      // The rest will be defined by fields
    };
    editNew.value = true;
    editOpen.value = true;
  }

  function onEditDocument(document) {
    editDocument.value = document;
    editNew.value = false;
    editOpen.value = true;
  }

  async function onSaveDocument(doc) {
    console.log(`onSaveDocument: `, doc);
    editOpen.value = false;
    const saveResult = await collectionsStore.setCollectionContentDoc(props.collectionType, collectionId.value, doc);
    if (saveResult) {
      toast.success('Saved successfully');
    } else {
      toast.error(`Error when saving`);
    }
  }

  const deleteConfirm = ref(false);

  async function onDeleteConfirmed() {
    deleteConfirm.value = false;
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`No documents selected to delete`);
      return;
    }
    console.log(`Deleting documents: ${JSON.stringify(selectedDocuments.value)}`);
    let overallResult = true;
    for (let i = 0; i < selectedDocuments.value.length; i++) {
      const doc = selectedDocuments.value[i];
      const deleteResult = await collectionsStore.deleteCollectionContentDoc(props.collectionType, collectionId.value, doc);
      overallResult &= deleteResult;
    }
    if (overallResult) {
      toast.success('Deleted successfully');
    } else {
      toast.error(`Error when deleting`);
    }
  }

  async function onDeleteDocuments() {
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`No documents selected to delete`);
      return;
    }
    deleteConfirm.value = true;
  }
</script>

<style>
  .p-datatable tbody {
    vertical-align: top;
  }
</style>
