<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold leading-7 text-gray-900">Jobs Executions</h1>
        <p class="mt-2 text-sm text-gray-700">A list of all the Jobs executions</p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          type="button"
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <mdicon name="playlist-plus" class="h-6 w-6" aria-hidden="true"></mdicon>
          <router-link :to="{ path: `/analysis/tests/edit/new` }">TODO</router-link>
        </button>
      </div>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0"></th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">ID</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Created</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="result in jobExecutions" :key="result.name">
                <td class="w-[12px] whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-0 hover:text-sky-700">
                  <router-link :to="{ path: `/analysis/testruns/result/${result.name}` }">
                    <mdicon name="playlist-edit" class="h-6 w-6" aria-hidden="true"></mdicon>
                  </router-link>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-gray-500 text-md font-semibold" @click="testGetResult(result.name)">
                  {{ result.name }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-gray-500 text-md font-semibold">-</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">-</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">-</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue';
  //import { useTestsStore } from '@/store/TestsStore';
  import { useJobsExecutionsStore } from '@/store/JobsExecutionsStore';

  //const testsStore = useTestsStore();
  const jobsExecutionsStore = useJobsExecutionsStore();

  const jobExecutions = ref([]);

  async function testGetResult(executionName) {
    console.log(`Getting results for: ${executionName}`);
    await jobsExecutionsStore.loadJobExecutionResult(executionName);
  }

  onMounted(async () => {
    jobExecutions.value = await jobsExecutionsStore.loadJobExecutions();
  });
</script>
