<template>
  <div class="mx-6 my-4">
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-center space-x-5 w-full">
        <div class="flex-shrink-0">
          <div class="relative bg-purple-600/70 rounded-xl p-1">
            <mdicon name="text-search" size="62" class="text-purple-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="w-full">
          <div class="w-full">
            <div class="relative">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                v-model="searchQuery"
                class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-purple-500/50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:text-md sm:leading-6"
                placeholder='Enter keywords or "quoted phrase" to Search Conversations'
                type="search"
                @keyup.enter="loadData"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <ProgressButton ref="searchProgressButton" title="Search" icon="text-search" @click="loadData"></ProgressButton>
        <!--<button
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="loadData"
        >
          <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
          Export
        </button>-->
      </div>
    </div>

    <FiltersList class="mt-2" @select-filter="onSelectFilter" @show-filters="showFilters"></FiltersList>

    <DbUplot
      class="w-full h-[160px] mt-2 bg-gray-100/50 rounded-xl"
      :data="uPlotData"
      :options="uPlotOptions"
      @db-event="handleDbEvent"
      :tooltip="true"
      :title="`${conversationSearchStore.conversationsTotal} Conversations`"
    ></DbUplot>

    <div class="mt-6">
      <DataTable
        :value="conversations"
        v-model:expanded-rows="expandedRows"
        lazy
        paginator
        :first="conversationSearchStore.searchOffset"
        :rows="25"
        dataKey="id"
        :totalRecords="totalRecords"
        :loading="loading"
        sortField="datetime"
        :sortOrder="-1"
        removableSort
        @page="onPage($event)"
        @sort="onSort($event)"
        size="small"
        scrollable
        tableStyle="min-width: 50rem"
        filterDisplay="menu"
        v-model:filters="tableFiltersSpec"
      >
        <Column
          expander
          :pt="{
            rowToggler: { class: 'p-0 w-3 h-3' },
          }"
        >
        </Column>
        <Column field="id" header="Conversation">
          <template #body="slotProps">
            <router-link :to="{ path: `/bots/conversation/${slotProps.data.id}` }">
              <div class="flex items-center">
                <div class="cursor-pointer font-semibold hover:underline">{{ getConversationTitle(slotProps.data) }}</div>
                <mdicon name="open-in-new" size="18" class="ml-1 text-slate-300"></mdicon>
              </div>
            </router-link>
          </template>
        </Column>
        <Column field="botName" header="Bot" sortable filterField="botName" :showFilterMenu="true" :showFilterMatchModes="false" :showClearButton="false" :showApplyButton="false">
          <template #filter="{ filterModel }">
            <EditFilter :filter="filtersStore.getFilter('botName')" class="p-2 m-2"></EditFilter>
          </template>
          <template #filterfooter="">
            <div class="flex justify-end">
              <div class="flex-0"><Button label="Edit Filters" icon="pi pi-filter" @click="showFilters" outlined size="small"></Button></div>
            </div>
          </template>
          <template #body="slotProps">
            <router-link :to="{ path: `/bots/editor/${slotProps.data.botId}` }">
              <div class="flex items-center">
                <div class="cursor-pointer hover:underline">{{ getBotName(slotProps.data) }}</div>
                <mdicon name="open-in-new" size="18" class="ml-1 text-slate-300"></mdicon>
              </div>
            </router-link>
          </template>
        </Column>
        <Column field="state.escalated" header="Escalated" sortable>
          <template #body="slotProps">
            {{ slotProps.data?.state?.escalated ? 'Yes' : 'No' }}
          </template>
        </Column>
        <Column field="state.isLead" header="Lead" sortable>
          <template #body="slotProps">
            {{ getLeadText(slotProps.data) }}
          </template>
        </Column>
        <Column field="AgentName" header="Agent" sortable filterField="AgentName" :showFilterMenu="true" :showFilterMatchModes="false" :showClearButton="false" :showApplyButton="false">
          <template #filter="{ filterModel }">
            <EditFilter :filter="filtersStore.getFilter('AgentName')" class="p-2 m-2"></EditFilter>
          </template>
          <template #filterfooter="">
            <div class="flex justify-end">
              <div class="flex-0"><Button label="Edit Filters" icon="pi pi-filter" @click="showFilters" outlined size="small"></Button></div>
            </div>
          </template>
          <template #body="slotProps">
            <div v-if="slotProps.data?.AgentName" class="flex items-center cursor-pointer hover:underline" @click="onSetFilter('AgentName', [slotProps.data?.AgentName || null])">
              <div class="cursor-pointer font-medium">{{ slotProps.data?.AgentName || slotProps.data?.AgentId || '-' }}</div>
              <mdicon name="filter" size="20" class="ml-2 text-gray-300"></mdicon>
            </div>
            <div v-else>-</div>
          </template>
        </Column>
        <Column field="state.timeToAgent" sortable>
          <template #header="slotProps">
            <span title="Time To Agent">TTA</span>
          </template>
          <template #body="slotProps">
            {{ getTTAText(slotProps.data) }}
          </template>
        </Column>
        <!--
        <Column header="Contact">
          <template #body="slotProps">
            <div class="flex items-center font-source">
              {{ getConversationContact(slotProps.data) }}
            </div>
          </template>
        </Column>
        -->
        <Column field="CSat" header="CSat" sortable>
          <template #body="slotProps">
            <span :class="['px-2 py-1.5 font-source text-md rounded-sm', getCSatClass(slotProps.data)]">
              {{ 'CSat' in slotProps.data ? (slotProps.data.CSat !== null ? slotProps.data.CSat : '-') : '-' }}
            </span>
          </template>
        </Column>
        <Column field="datetime" header="Date" sortable>
          <template #body="slotProps">
            <div class="">{{ dayjs(slotProps.data?.datetime).format('MM/DD/YY HH:mm:ss') }}</div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="ml-4 py-1 px-4 font-source text-sm bg-slate-50 rounded-xl" v-html="getConversationText(slotProps.data)"></div>
        </template>
      </DataTable>
    </div>
    <EditFiltersSidebar v-model:visible="filtersOpen"></EditFiltersSidebar>
  </div>
</template>
<script setup>
  import { ref, computed, onMounted, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useBotsStore } from '@/store/BotsStore';
  import { useBotConversationSearchStore } from '@/store/BotConversationSearchStore';
  import { useDateRangeStore } from '@/store/DaterangeStore';
  import { useFiltersStore } from '@/store/FiltersStore';
  import { stdBotConversationFilters } from '@/analytics/filtersDefs';
  import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import dayjs from 'dayjs';
  import DbDygraphsBar from '@/components/Charts/DbDygraphsBar.vue';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';
  import EditFiltersSidebar from '@/components/Filters/EditFiltersSidebar.vue';
  import FiltersList from '@/components/Filters/FiltersList.vue';
  import EditFilter from '@/components/Filters/EditFilter.vue';
  import Button from 'primevue/button';
  import DbUplot from '@/components/Charts/DbUplot.vue';
  import uPlot from 'uplot';
  import { formatSeconds } from '@/utils/formatSeconds';

  const route = useRoute();
  const botsStore = useBotsStore();
  const conversationSearchStore = useBotConversationSearchStore();
  const dateRangeStore = useDateRangeStore();
  const filtersStore = useFiltersStore();
  const q = decodeURIComponent(route?.query?.query || '');
  const searchQuery = ref(q);

  const searchProgressButton = ref(null);

  const filtersOpen = ref(false);
  const tableFiltersSpec = ref({
    botName: { value: null },
    AgentName: { value: null },
  });

  // Search Results Table
  //const dt = ref();
  const loading = ref(false);
  const totalRecords = computed(() => conversationSearchStore.conversationsTotal);
  const conversations = computed(() => conversationSearchStore.conversations);

  const expandedRows = ref([]);

  const uPlotData = computed(() => conversationSearchStore.uPlotTimeline);
  const uPlotOptions = computed(() => {
    return {
      //height: 200,
      legend: {
        show: false,
      },
      tzDate: (ts) => uPlot.tzDate(new Date(ts * 1e3), 'Etc/UTC'),
      scales: {
        x: { time: true },
      },
      series: [
        {},
        {
          label: 'Conversations',
          paths: uPlot.paths.bars({ align: 0 }),
          stroke: 'rgba(78, 121, 167, 0.1 )',
          fill: 'rgba(78, 121, 167, 0.4)',
        },
      ],
    };
  });

  watch(filtersStore.allFilters, (newValue) => {
    if (loading.value) {
      return;
    }
    console.log(`Filters changed: ${JSON.stringify(newValue)}`);
    loadData();
  });

  watch(
    () => dateRangeStore.timeIntervalChanged,
    (newValue) => {
      console.log(`Date range changed: ${JSON.stringify(newValue)}`);
      loadData();
    },
  );

  const onPage = async (event) => {
    console.log(`onPage: ${JSON.stringify(event)}`);
    if ('first' in event && 'rows' in event) {
      loading.value = true;
      await conversationSearchStore.page(event.first, event.rows);
      loading.value = false;
    }
  };

  const onSort = async (event) => {
    console.log(`onSort: ${JSON.stringify(event)}`);
    // [{field:'name',type:'asc|desc'},...]
    if ('sortField' in event && 'sortOrder' in event) {
      const sortFields = [
        {
          field: event?.sortField || 'datetime',
          type: event?.sortOrder === 1 ? 'asc' : 'desc',
        },
      ];
      loading.value = true;
      await conversationSearchStore.sort(sortFields);
      loading.value = false;
    }
  };

  async function loadData() {
    searchProgressButton.value.startProgress();
    loading.value = true;
    //const filters = [{ type: 'match_phrase', field: 'state.leadType', value: 'hot' }];
    await conversationSearchStore.search(searchQuery.value, 25, 0, [{ field: 'datetime', type: 'desc' }], true);
    loading.value = false;
    if (searchProgressButton.value) {
      searchProgressButton.value.stopProgress();
    }
  }

  function onSetFilter(field, value) {
    filtersStore.setFilterValue(field, value);
  }

  // Create user-friendly title for the conversation, by using first message
  function getConversationTitle(conv) {
    if (!Array.isArray(conv.messages) || conv.messages.length <= 0) {
      return conv.id;
    }
    const firstMsg = conv.messages.find((x) => x?.role === 'user');
    if (!firstMsg || !firstMsg.content || firstMsg.content === '') {
      return conv.id;
    }
    return firstMsg.content.substring(0, 45) + '...';
  }

  function getConversationText(conv) {
    if (!Array.isArray(conv.messages) || conv.messages.length <= 0) {
      return '';
    }
    let convText = '';
    conv.messages.map((m) => {
      const role = (m?.role || '').toLowerCase();
      if (role === 'user' || role === 'assistant') {
        if (role === 'user') {
          convText += `<div class="text-indigo-700 font-medium pt-1">User: ${m.content}</div>`; //m.content + '<br>';
        } else {
          convText += `<div class="text-gray-600">Bot: ${m.content}</div>`; //m.content + '<br>';
        }
      }
    });
    return convText;
  }

  function getConversationContact(conv) {
    const contactData = conv?.state?.contactData || null;
    if (!contactData || typeof contactData !== 'object') {
      return '-';
    }
    let name = '';
    let email = '';
    let phone = '';
    Object.keys(contactData).map((p) => {
      const pl = p.toLowerCase();
      if (pl === 'name' || pl === 'user name') {
        name = contactData[p];
      } else if (pl === 'email' || pl === 'email address') {
        email = contactData[p];
      } else if (pl === 'phone' || pl === 'phone number') {
        phone = contactData[p];
      }
    });
    let contactText = `${name} ${email} ${phone}`.trim();
    contactText = contactText === '' ? '-' : contactText;
    return contactText;
  }

  function getTTAText(conv) {
    const tta = conv?.stats?.timeToAgent || null;
    if (!tta || isNaN(tta)) {
      return '-';
    }
    return formatSeconds(tta);
  }

  function getLeadText(conv) {
    if (!conv?.state?.isLead) {
      return 'No';
    }
    if (conv.state?.leadType === 'hot') {
      return 'Hot';
    }
    return 'Yes';
  }

  function getBotName(conv) {
    // TODO Store bot name in botconv
    const botId = conv?.botId || null;
    if (!botId) {
      return '-';
    }
    const bot = botsStore.getBot(botId);
    if (!bot) {
      return '-';
    }
    return bot?.name || '-';
  }

  function getCSatClass(conv) {
    if (!('CSat' in conv) || conv.CSat === null) {
      return '';
    }
    let s = conv.CSat;
    return s < 5 ? `bg-red-400/10` : `bg-green-400/30`;
  }

  function onSelectFilter(filter) {
    // TODO pass which filter to make active
    showFilters();
  }

  function showFilters() {
    filtersOpen.value = true;
  }

  function handleDbEvent(event) {
    switch (event.type) {
      case 'zoom': {
        let from = event?.minDate || null;
        let to = event?.maxDate || null;
        if (from && to) {
          dateRangeStore.setDateRange(from, to);
          loadData();
        }
        break;
      }
    }
  }

  onMounted(() => {
    filtersStore.initFilters('botconv', stdBotConversationFilters);
    // [re-]Load conditionally
    if (dateRangeStore.isChangedSince(conversationSearchStore.searchTimeIntervalTs)) {
      searchProgressButton.value.startProgress();
      loadData();
    }
  });
</script>
