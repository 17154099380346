<template>
  <!-- See https://web.dev/one-line-layouts/ -->
  <div class="antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900">
    <page-header></page-header>
    <progress-bar :progress="false"></progress-bar>
    <div class="grid grid-cols-2 grid-rows-1 min-h-0 min-w-0" style="grid-template-columns: auto 1fr; height: calc(100vh - 4rem - 1px)">
      <!--<div v-if="true" class="bg-blue-300 sticky" style="top: 4rem; height: calc(100vh - 4rem)">-->
      <!-- 1px here is to account for border of navbar -->
      <!-- Sidebar -->
      <div class="overflow-y-auto">
        <sidebar></sidebar>
      </div>
      <router-view />
    </div>
  </div>
</template>
<script>
  //import Navbar from '@/components/Navbars/Navbar.vue';
  //import NavbarExt from '@/components/Navbars/NavbarExt.vue';
  import PageHeader from '@/components/Navbars/PageHeader.vue';
  import Sidebar from '@/components/Sidebar/Sidebar.vue';
  import ProgressBar from '@/components/Progress.vue';
  //import wsConnection from '../store/wsconnection';
  import { defineComponent } from 'vue';

  export default defineComponent({
    name: 'TestLayout',
    components: { PageHeader, ProgressBar, Sidebar },
    data() {
      return {};
    },
    mounted() {},
    methods: {},
  });
</script>
