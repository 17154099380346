<template>
  <div ref="uPlotWrapperRef" class="">
    <div class="absolute z-0" ref="uPlotTargetRef"></div>
    <div v-if="title" class="z-10 text-right flex justify-end">
      <div class="z-20 mx-2 px-2 mt-1 py-1 rounded-md bg-slate-800/70 text-slate-100 font-source text-sm font-medium">{{ title }}</div>
    </div>
  </div>
</template>
<script setup>
  import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
  import { placement } from './placement.js';

  import uPlot from 'uplot';
  import 'uplot/dist/uPlot.min.css';

  //import { optionsUpdateState, dataMatch } from 'uplot-wrappers-common';
  //type OptionsUpdateState = 'keep' | 'update' | 'create';
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is
  if (!Object.is) {
    // eslint-disable-next-line
    Object.defineProperty(Object, 'is', { value: (x, y) => (x === y && (x !== 0 || 1 / x === 1 / y)) || (x !== x && y !== y) });
  }

  const props = defineProps({
    options: { type: Object, required: true },
    data: { type: Array, required: true },
    resetScales: { type: Boolean, required: false, default: true },
    tooltip: { type: Boolean, required: false, default: false },
    title: { type: String, required: false, default: null },
  });
  const emit = defineEmits(['db-event']);

  const uPlotWrapperRef = ref(null);
  const uPlotTargetRef = ref(null);
  const _chart = ref(null);

  const defaultOptions = {
    hooks: {
      setSelect: [
        (u) => {
          if (u.select.width > 0) {
            let min = u.posToVal(u.select.left, 'x');
            let max = u.posToVal(u.select.left + u.select.width, 'x');
            emit('db-event', {
              type: 'zoom',
              src: 'uplot',
              minDate: Math.floor(min * 1000), // ts in ms
              maxDate: Math.floor(max * 1000), // ts in ms
              g: _chart.value,
            });
          }
        },
      ],
    },
    plugins: [],
  };

  if (props.tooltip) {
    //defaultOptions.plugins.push(legendAsTooltipPlugin());
    defaultOptions.plugins.push(tooltipPlugin());
  }

  const uPlotOptions = computed(() => {
    // TODO merge plugins, if supplied in options
    return Object.assign({}, defaultOptions, props.options);
  });

  /*
  watch(props.options, (options, prevOptions) => {
    //console.log(`Watch:uPlotOptions`);
    const optionsState = optionsUpdateState(prevOptions, options);
    if (!_chart.value || optionsState === 'create') {
      _destroy();
      _create();
    } else if (optionsState === 'update') {
      //_chart.value.updateOptions(options);
    }
  });
  */

  watch(
    () => props.data,
    (data, prevData) => {
      //console.log(`uPlot: data changed`);
      if (!_chart.value) {
        _create();
      } else if (!dataMatch(prevData, data)) {
        if (props.resetScales) {
          _chart.value.setData(data);
        } else {
          _chart.value.setData(data, false);
          _chart.value.redraw();
        }
      }
    },
  );

  onMounted(() => {
    window.addEventListener('resize', handleResize);
    _create();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
    _destroy();
  });

  // ???
  //beforeDestroy() {
  //  this._destroy();
  //},

  function getSize() {
    const size = {
      width: uPlotWrapperRef.value.clientWidth,
      height: uPlotWrapperRef.value.clientHeight,
    };
    console.log(`uPlot: getSize`, size);
    return size;
  }

  function handleResize() {
    const size = getSize();
    _chart.value.setSize(size);
  }

  function _destroy() {
    if (_chart.value) {
      //this.$emit('delete', _chart.value);
      _chart.value.destroy();
      _chart.value = null;
    }
  }

  function _create() {
    const size = getSize();
    //console.log(`uPlot:_create:options`, uPlotOptions.value);
    _chart.value = new uPlot(uPlotOptions.value, props.data, uPlotTargetRef.value);
    _chart.value.setSize(size);
    //$emit('create', _chart.value);
  }

  function optionsUpdateState(_lhs, _rhs) {
    const { width: lhsWidth, height: lhsHeight, ...lhs } = _lhs;
    const { width: rhsWidth, height: rhsHeight, ...rhs } = _rhs;

    let state = 'keep';
    if (lhsHeight !== rhsHeight || lhsWidth !== rhsWidth) {
      state = 'update';
    }
    if (Object.keys(lhs).length !== Object.keys(rhs).length) {
      return 'create';
    }
    for (const k of Object.keys(lhs)) {
      if (!Object.is(lhs[k], rhs[k])) {
        state = 'create';
        break;
      }
    }
    return state;
  }

  function dataMatch(lhs, rhs) {
    if (lhs.length !== rhs.length) {
      return false;
    }
    return lhs.every((lhsOneSeries, seriesIdx) => {
      const rhsOneSeries = rhs[seriesIdx];
      if (lhsOneSeries.length !== rhsOneSeries.length) {
        return false;
      }
      return lhsOneSeries.every((value, valueIdx) => value === rhsOneSeries[valueIdx]);
    });
  }

  // converts the legend into a simple tooltip
  function legendAsTooltipPlugin({ className, style = { backgroundColor: 'rgba(226, 232, 240, 0.8)', color: 'black' } } = {}) {
    let legendEl;
    let over, bound, bLeft, bTop;

    function syncBounds() {
      let bbox = over.getBoundingClientRect();
      bLeft = bbox.left;
      bTop = bbox.top;
    }

    function init(u, opts) {
      over = u.over;
      bound = over;

      legendEl = u.root.querySelector('.u-legend');

      legendEl.classList.remove('u-inline');
      className && legendEl.classList.add(className);

      uPlot.assign(legendEl.style, {
        textAlign: 'left',
        pointerEvents: 'none',
        display: 'none',
        /*
        position: 'absolute',
        left: 0,
        top: 0,
        marginLeft: '10px',
        zIndex: 100,
        */
        boxShadow: '2px 2px 10px rgba(0,0,0,0.5)',
        ...style,
      });

      // hide series color markers
      const idents = legendEl.querySelectorAll('.u-marker');

      for (let i = 0; i < idents.length; i++) idents[i].style.display = 'none';

      const overEl = u.over;
      overEl.style.overflow = 'visible';

      // move legend into plot bounds
      overEl.appendChild(legendEl);

      // show/hide tooltip on enter/exit
      overEl.addEventListener('mouseenter', () => {
        legendEl.style.display = null;
      });
      overEl.addEventListener('mouseleave', () => {
        legendEl.style.display = 'none';
      });

      // let tooltip exit plot
      //	overEl.style.overflow = "visible";
    }
    function update(u) {
      const { left, top } = u.cursor;
      const anchor = { left: left + bLeft, top: top + bTop };
      //legendEl.style.transform = 'translate(' + left + 'px, ' + top + 'px)';
      placement(legendEl, anchor, 'right', 'start', { bound });
    }

    return {
      hooks: {
        init: init,
        setSize: (u) => {
          syncBounds();
        },
        setCursor: update,
      },
    };
  }

  function tooltipPlugin() {
    let over, bound, bLeft, bTop;
    let tooltipVisible = false;
    const fmtDate = uPlot.fmtDate('{M}/{D}/{YY} {h}:{mm}:{ss} {AA}');

    const overlay = document.createElement('div');
    overlay.id = 'overlay';
    overlay.style.display = 'none';
    overlay.style.position = 'absolute';
    overlay.style.zIndex = '100';
    overlay.className = 'absolute font-source bg-slate-800/80 border border-slate-400 text-slate-100 rounded-md mx-4 p-2';
    document.body.appendChild(overlay);

    function syncBounds() {
      let bbox = over.getBoundingClientRect();
      bLeft = bbox.left;
      bTop = bbox.top;
    }

    function showTooltip() {
      if (!tooltipVisible) {
        overlay.style.display = 'block';
        tooltipVisible = true;
      }
    }

    function hideTooltip() {
      if (tooltipVisible) {
        overlay.style.display = 'none';
        tooltipVisible = false;
      }
    }

    return {
      hooks: {
        init: (u) => {
          over = u.over;
          bound = over;
        },
        setSize: (u) => {
          syncBounds();
        },
        setCursor: (u) => {
          const { left, top, idx } = u.cursor;
          if (!u.data || !Array.isArray(u.data) || u.data.length <= 0) {
            hideTooltip();
            return;
          }
          const x = u.data[0][idx] || null;
          if (!x) {
            hideTooltip();
            return;
          }
          if (u.data.length <= 1) {
            hideTooltip();
            return;
          }
          const yVals = [];
          for (let i = 1; i < u.data.length; i++) {
            yVals.push({ label: u.series[i]?.label || '-', fill: u.series[i].fill(), value: u.data[i][idx] || 0, formatter: u.series[i]?.tooltipFormatter || null });
          }
          let yValsHtml = '';
          yVals.map((yv) => {
            yValsHtml += '<div class="text-lg flex items-center text-sm">';
            yValsHtml += `<div class="h-4 w-4 rounded border mr-1" style="background-color:${yv.fill};border-color:white;"></div>`;
            yValsHtml += `<div class="mr-1">${yv.label}:</div>`;
            yValsHtml += `<div class="font-semibold">${yv.formatter ? yv.formatter(yv.value) : yv.value}</div>`;
            yValsHtml += '</div>';
          });

          //console.log(`Tooltip: x=${x}, y=${y}, date: ${fmtDate(new Date(x * 1e3))}`);
          const anchor = { left: left + bLeft, top: top + bTop };
          //overlay.innerHTML = `<div class="text-sm">${new Date(x * 1e3).toLocaleString()}</div><div class="text-lg font-semibold">${y}</div>`;
          overlay.innerHTML = `<div class="text-sm">${fmtDate(new Date(x * 1e3))}</div>${yValsHtml}`;
          placement(overlay, anchor, 'right', 'start', { bound });
          //overlay.style.display = 'block';
          showTooltip();
        },
      },
    };
  }
</script>
