<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold leading-7 text-gray-900">Jobs</h1>
        <p class="mt-2 text-sm text-gray-700">A list of executed scenarios</p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          type="button"
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="onRefresh"
        >
          <mdicon name="refresh" class="h-6 w-6" aria-hidden="true"></mdicon>
          Update
        </button>
      </div>
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm text-gray-900 sm:pl-0"></th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">ID</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Name</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Status</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Step</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Media</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Started</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Duration</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr v-for="testRun in testsStore.testRuns" :key="testRun.id">
                <td class="w-[12px] whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-0 hover:text-sky-700">
                  <svg v-if="testRun.started" class="animate-spin -ml-1 mr-3 h-5 w-5 text-indigo-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <router-link v-else :to="{ path: `/analysis/testruns/result/${testRun.id}` }">
                    <mdicon :name="testRun.status === 'PASS' ? 'check-circle-outline' : 'close-circle-outline'" class="h-6 w-6" aria-hidden="true"></mdicon>
                  </router-link>
                </td>
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium sm:pl-0">
                  <div v-if="testRun.started">{{ testRun.id }}</div>
                  <router-link v-else class="hover:text-sky-700 hover:underline" :to="{ path: `/analysis/testruns/result/${testRun.id}` }">{{ testRun.id }}</router-link>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-gray-500 text-md font-semibold">{{ testRun.name }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-gray-500 text-md font-semibold">{{ testRun.status }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-gray-500 text-md font-semibold">{{ testRun.step }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ testRun.media }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ formatDate(testRun.startTs) }}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ formatSeconds(testRun.duration) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { onMounted } from 'vue';
  import { useTestsStore } from '@/store/TestsStore';

  const testsStore = useTestsStore();

  function onRefresh() {
    testsStore.loadTestRuns();
  }

  function formatDate(ts) {
    const d = new Date(ts);
    return d.toISOString();
  }

  function formatSeconds(sec) {
    if (!sec) {
      return '';
    }
    const totalMinutes = Math.floor(sec / 60);
    const seconds = sec % 60;
    //const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  onMounted(() => {
    testsStore.loadTestRuns();
  });
</script>
