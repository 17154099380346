<template>
  <div class="relative isolate overflow-hidden bg-white">
    <div class="-z-10 absolute inset-0 bottom-10 bg-bottom bg-no-repeat bg-slate-50 dark:bg-[#0B1120] beams-5"></div>
    <div class="mx-auto max-w-7xl px-6 pb-24 pt-10 sm:pb-32 lg:flex lg:px-8 lg:py-40">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8">
        <logo class="w-auto h-6 lg:h-12"></logo>
        <h1 class="mt-10 text-4xl font-bold tracking-tight text-gray-900 lg:text-6xl">AI Powered<br />CX Improvement</h1>
        <p class="hidden lg:block mt-6 text-lg text-slate-600 max-w-3xl mx-auto dark:text-slate-400">
          In today's digital world, <span class="font-medium text-teal-700 dark:text-teal-400">Customer Experience </span>is a crucial factor for businesses to succeed.
          <span class="font-medium text-teal-700 dark:text-teal-400">A Positive CX, </span>can lead to increased customer <span class="font-medium text-teal-700 dark:text-teal-400">Satisfaction, Loyalty </span> and
          ultimately drive
          <span class="font-medium text-teal-700 dark:text-teal-400">Revenue Growth.</span>
        </p>
        <div class="hidden lg:flex mt-10 items-center gap-x-6">
          <a href="https://enegel.com" target="_blank" class="text-sm font-semibold leading-6 text-gray-900">Learn more <span aria-hidden="true">→</span></a>
        </div>
      </div>
      <div class="mx-auto lg:mt-12 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32">
        <div class="mt-20 max-w-md flex-none sm:max-w-5xl lg:max-w-none">
          <div class="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div class="sm:flex sm:items-start mb-5">
              <div class="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10">
                <EnvelopeIcon class="h-12 w-12 text-slate-400" aria-hidden="true" />
              </div>
              <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <div as="h3" class="mt-2 text-xl font-semibold leading-6 text-gray-900">Verify your email</div>
                <div class="mt-4">
                  <p class="text-sm text-gray-500">
                    Email with verification link has been sent to your address <strong>{{ authStore.currentUser.email }}</strong
                    >. <br /><br />Please confirm your email address by clicking the verification link.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button type="button" class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto" @click="onResend">
                Send Again
              </button>
              <button
                type="button"
                class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                ref="cancelButtonRef"
                @click="onLogout"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import Logo from '@/components/Navbars/Logo.vue';
  import { onMounted } from 'vue';
  import { useAuthStore } from '@/store/AuthStore';
  import firebase from 'firebase/compat/app';
  import 'firebaseui/dist/firebaseui.css';
  import { fbAuth } from '@/firebaseApp';
  import { useRouter, useRoute } from 'vue-router';
  import { sendEmailVerification } from 'firebase/auth';
  import { ExclamationTriangleIcon, EnvelopeIcon } from '@heroicons/vue/24/outline';

  const route = useRoute();
  const router = useRouter();
  const authStore = useAuthStore();

  let checkInterval = null;
  let checkCounter = 0;

  function sendVerificationEmail() {
    const redirectUrl = `${window.location.protocol}//${window.location.host}/`;
    console.log(`Redirect URL: ${redirectUrl}`);

    const actionCodeSettings = {
      url: redirectUrl, //'http://localhost:5173',
    };

    sendEmailVerification(fbAuth.currentUser, actionCodeSettings)
      .then(function () {
        console.log(`Sent email verification, awaiting for confirmation ...`);
        if (!checkInterval) {
          checkInterval = setInterval(async () => {
            if (checkCounter++ > 2) {
              console.log(`Stopping checks ...`);
              clearInterval(checkInterval);
              return;
            }
            await fbAuth.currentUser.reload();
            if (fbAuth.currentUser.emailVerified) {
              console.log('Email Verified');
              clearInterval(checkInterval);
              const dest = route?.query?.redirect || '/';
              await router.push(dest);
            }
          }, 10000);
        }
      })
      .catch(function (error) {
        console.log(`ERROR Sending email verification: ${error.message}`);
      });
  }

  function onLogout() {
    authStore.logout();
  }

  function onResend() {
    sendVerificationEmail();
  }

  onMounted(() => {
    sendVerificationEmail();
  });
</script>
