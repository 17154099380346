/*
 * Collections Store
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useCollection } from 'vuefire';
import { collection } from 'firebase/firestore';
import { doc, setDoc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref as storageRef, list, listAll } from 'firebase/storage';
import { fbDb } from '@/firebaseApp';

export const useCollectionsStore = defineStore('collections', () => {
  const authStore = useAuthStore();
  const tenantId = computed(() => authStore.tenantId);

  const storage = getStorage();

  const collectionsByType = {};

  // TODO Reconsider this: these will always be loaded and subscribed to, no matter if they are not used in UI
  //  Consider only providing proper path to collection from the store, and loading collection itself
  //  in the component that actually needs it. Vuefire - supposedly - will cache it (check!)

  // List of all transcript collections, with subscription - will be updated if any document is changed in the collection
  const transcriptsCollections = useCollection(collection(fbDb, `tenants/${tenantId.value}/transcripts`));

  // List of all recordings collections, with subscription - will be updated if any document is changed in the collection
  const recordingsCollections = useCollection(collection(fbDb, `tenants/${tenantId.value}/recordings`));

  const kpiCollections = useCollection(collection(fbDb, `tenants/${tenantId.value}/kpi`));

  // Cache of collections files lists indexed by path
  const collectionsFilesListsByPath = {};

  // Get and cache collection by type, if requested
  function getCollection(type) {
    if (!(type in collectionsByType)) {
      collectionsByType[type] = collection(fbDb, `tenants/${tenantId.value}/${type}`);
    }
    return collectionsByType[type];
  }
  function getCollectionPath(type) {
    return `tenants/${tenantId.value}/${type}`;
  }

  function getCollectionDoc(type, collectionId) {
    return doc(fbDb, `tenants/${tenantId.value}/${type}`, collectionId);
    /*
    let collectionDoc = {};
    try {
      collectionDoc = await getDoc(doc(fbDb, `tenants/${tenantId.value}/${type}`, collectionId));
    } catch (e) {
      console.log(`Collection[${type}/${collectionId}]: Error getting collection document: ${e.message}`);
      return {};
    }
    return collectionDoc;
    */

    /*
    let c = null;
    switch (type) {
      case 'transcripts': {
        c = transcriptsCollections.value.find((x) => x.id === collectionId);
        break;
      }
      case 'recordings': {
        c = recordingsCollections.value.find((x) => x.id === collectionId);
        break;
      }
      default: {
        if (type in collectionsByType) {
          c = collectionsByType[type].value.find((x) => x.id === collectionId);
        }
      }
    }
    return c ? c : null;
    */
  }

  // Update or Add collection doc (upsert)
  async function setCollectionDoc(type, collectionDoc) {
    const cId = collectionDoc?.id || null;
    if (!cId) {
      return false; // TODO error
    }

    try {
      await setDoc(doc(fbDb, `tenants/${tenantId.value}/${type}`, cId), collectionDoc);
    } catch (e) {
      console.log(`Collection[${type}/${cId}]: Error writing document: ${e.message}`);
      return false;
    }

    return true;
  }

  async function updateCollectionDoc(type, collectionId, props) {
    if (!collectionId) {
      return false; // TODO error
    }
    try {
      await updateDoc(doc(fbDb, `tenants/${tenantId.value}/${type}`, collectionId), props);
    } catch (e) {
      console.log(`Collection[${type}/${collectionId}]: Error updating document: ${e.message}`);
      return false;
    }
    return true;
  }

  async function getCollectionFilesList(type, collectionId = null) {
    const path = `tenants/${tenantId.value}/${type}/${collectionId}/files`;
    return useCollection(collection(fbDb, path));
    /*
    // Cache does not work - collection ref gets destroyed when component is unmounted ?
    if (!['transcripts', 'recordings'].includes(type) || !collectionId) {
      return ref([]);
    }
    const path = `tenants/${tenantId.value}/${type}/${collectionId}/files`;
    if (!(path in collectionsFilesListsByPath)) {
      // List of all files in the collections, with subscription
      console.log(`Getting collection: ${path}`);
      collectionsFilesListsByPath[path] = useCollection(collection(fbDb, path));
    }
    return collectionsFilesListsByPath[path];
    */
  }

  async function setCollectionFileDoc(type, collectionId, fileDoc) {
    try {
      await setDoc(doc(fbDb, `tenants/${tenantId.value}/${type}/${collectionId}/files`, fileDoc.name), fileDoc);
    } catch (e) {
      console.log(`Collection[${type}/${collectionId}]: Error writing file document: ${e.message}`);
      return false;
    }
    return true;
  }

  async function setCollectionContentDoc(type, collectionId, document) {
    const path = 'docs';
    try {
      await setDoc(doc(fbDb, `tenants/${tenantId.value}/${type}/${collectionId}/${path}`, document.id), document);
    } catch (e) {
      console.log(`Collection[${type}/${collectionId}]: Error writing document: ${e.message}`);
      return false;
    }
    return true;
  }

  async function deleteCollectionContentDoc(type, collectionId, document) {
    const path = 'docs';
    try {
      await deleteDoc(doc(fbDb, `tenants/${tenantId.value}/${type}/${collectionId}/${path}`, document.id));
    } catch (e) {
      console.log(`Collection[${type}/${collectionId}]: Error deleting document: ${e.message}`);
      return false;
    }
    return true;
  }

  function getCollectionStorageRef(type, collectionId) {
    return storageRef(storage, `${tenantId.value}/${type}/${collectionId}`);
  }

  return {
    transcriptsCollections,
    recordingsCollections,
    kpiCollections,
    collectionsByType,
    getCollection,
    getCollectionPath,
    getCollectionDoc,
    setCollectionDoc,
    updateCollectionDoc,
    getCollectionStorageRef,
    getCollectionFilesList,
    setCollectionFileDoc,
    setCollectionContentDoc,
    deleteCollectionContentDoc,
  };
});
