<template></template>
<script setup>
import {useRouter} from "vue-router";
import {useSettingsStore} from "@/store/SettingsStore";

const router = useRouter();
const settingsStore = useSettingsStore();

if(settingsStore.productEnabledQA){
  router.push(`/analysis/quickstart`);
}

if(settingsStore.productEnabledBot){
  router.push(`/bots/quickstart`);
}

router.push(`/bots/quickstart`);

</script>