<template>
  <div>
    <label :for="propDef?.name" class="block text-sm font-medium leading-6 text-gray-900">{{ propDef?.title }}</label>
    <div class="mt-2">
      <textarea
        v-if="propDef?.type === 'text'"
        :id="propDef?.name"
        v-model="value"
        rows="4"
        class="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:py-1.5 sm:text-sm sm:leading-6"
      />
      <div v-else-if="propDef?.type === 'number'" class="relative rounded-md shadow-sm">
        <div v-if="propDef?.prefix" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
          :id="propDef?.name"
          v-model="value"
          type="number"
          class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          :class="{ 'pl-7': propDef?.prefix, 'pr-20': propDef?.suffix }"
        />
        <div v-if="propDef?.suffix" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span class="text-gray-500 sm:text-sm" id="price-currency">{{ propDef?.suffix }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';

  const props = defineProps(['modelValue', 'propDef']);
  const emit = defineEmits(['update:modelValue']);

  const value = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });
</script>
