<template>
  <SpecEditor2 v-if="specAvailable" :spec="testSpec"></SpecEditor2>
</template>
<script setup>
  import { useRoute } from 'vue-router';
  import { useTestsStore } from '@/store/TestsStore';
  import { onMounted, ref, computed } from 'vue';
  //import { parse } from 'yaml';
  import SpecEditor2 from '@/components/Tests/SpecEditor2.vue';
  //import specYaml from '@/data/book_ticket.yaml?raw';

  const route = useRoute();
  const testsStore = useTestsStore();

  let testSpec = null; //parse(specYaml);
  const specAvailable = ref(false);

  onMounted(async () => {
    let id = route.params?.id || 'new';
    if (id === 'new') {
      testSpec = {
        name: 'New Scenario',
        media: 'recording',
      };
      specAvailable.value = true;
    } else {
      testSpec = await testsStore.loadTest(id);
      if (testSpec) {
        specAvailable.value = true;
      }
    }
  });
</script>
