<template>
  <div class="ml-6 h-full flex flex-col">
    <div class="py-1 sm:flex sm:items-center sm:justify-between border-b">
      <div class="w-full">
        <div class="flex items-center">
          <div class="grow">
            <!--
            <div v-if="kbDocumentTitle" class="leading-6 font-source text-xl font-medium text-gray-700">{{ kbDocumentTitle }}</div>
            -->
            <div class="flex items-center">
              <mdicon name="file-document-outline" size="20" class="mr-1 text-gray-400 rounded" aria-hidden="true"></mdicon>
              <div :class="['text-md text-slate-600', 'font-source font-medium leading-6']">
                <a v-if="kbDocumentName.startsWith('http')" class="hover:underline cursor-pointer" :href="kbDocumentName" target="_blank">{{ kbDocumentName }}</a>
                <h1 v-else>{{ kbDocumentName }}</h1>
              </div>
              <ClipboardCopyButton @click="onCopyName"></ClipboardCopyButton>
            </div>
            <!--
            <div class="mb-1 text-xs">
              Updated <time :datetime="kbStore.currentKbDocument?.updated">{{ dayjs(kbStore.currentKbDocument?.updated).format('LLL') }}</time> By {{ kbStore.currentKbDocument?.updatedBy }}
            </div>
            -->
          </div>
          <div class="flex-col text-xs font-medium text-gray-500 text-right mr-1">
            <p>Do Not Split</p>
            <InputSwitch v-model="kbDocumentDoNotSplitValue" />
          </div>
        </div>
        <div v-if="kbStore.currentKbDocument" class="mr-1 max-w-[800px]">
          <StdTagInputEditor v-model="kbDocumentTagsValue" :available-tags="kbStore.kbTags" @add-tag="onKbAddTag"></StdTagInputEditor>
        </div>
      </div>
      <div v-if="false" class="mr-8">
        <ButtonGroup>
          <Button label="Find" severity="secondary" icon="pi pi-search" outlined size="small" @click="onShowFind" />
          <Button label="Replace" severity="secondary" icon="pi pi-search-plus" outlined size="small" @click="onShowReplace" />
        </ButtonGroup>
      </div>
    </div>

    <div v-if="false" class="flex-auto">
      <v-ace-editor ref="editorElement" v-model:value="kbDocumentContentValue" lang="markdown" theme="crimson_editor" :options="editorOptions" style="height: 100%" />
    </div>

    <div class="flex-auto">
      <TuiEditor ref="mdEditorElement" :initial-value="kbDocumentContentValue" height="100%" />
    </div>
    <div v-if="false" class="flex-auto">
      <TuiViewer ref="mdEditorElement" :initial-value="kbDocumentContentValue" class="h-[400px] overflow-auto" />
    </div>

    <!--
    <ConfirmationDialog :open="deleteConfirm" title="Delete" :description="`Are you sure you want to delete selected Knowledge Bases ?`" @cancel="deleteConfirm = false" @confirm="onDeleteConfirmed"></ConfirmationDialog>
    <ImportFileDialog ref="importSpecDialog" @cancel="onImportCancel" @import="onSpecImport"></ImportFileDialog>
    -->
  </div>
</template>

<script setup>
  import { useRouter, useRoute } from 'vue-router';
  import { useKbStore } from '@/store/KbStore';
  import { useProgress } from '@/hooks/useProgress';
  import { nanoid } from 'nanoid';
  import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
  import { useToast } from 'vue-toastification';
  import { computed, onMounted, ref, watch, onBeforeUnmount } from 'vue';
  import { KB_TYPE_DEFS } from '@/spec/KbTypeDefs';
  import DropdownSelectButton from '@/components/Buttons/DropdownSelectButton.vue';
  import ClipboardCopyButton from '@/components/Buttons/ClipboardCopyButton.vue';
  import ImportFileDialog from '@/components/Dialogs/ImportFileDialog.vue';
  import { CheckIcon } from '@heroicons/vue/20/solid';
  import StdTagInputEditor from '@/components/Editors/StdTagInputEditor.vue';
  import ButtonGroup from 'primevue/buttongroup';
  import InputSwitch from 'primevue/inputswitch';
  import Button from 'primevue/button';
  import TuiEditor from '@/components/Editors/TuiEditor.vue';
  import TuiViewer from '@/components/Editors/TuiViewer.vue';
  import { VAceEditor } from 'vue3-ace-editor';
  import 'ace-builds/src-noconflict/mode-markdown';
  import 'ace-builds/src-noconflict/theme-crimson_editor';
  import 'ace-builds/src-noconflict/theme-dreamweaver';
  import 'ace-builds/src-noconflict/ext-searchbox';
  import './acemodelog.js';
  import './acemodekbdoc.js';
  import dayjs from 'dayjs';
  import timezone from 'dayjs/plugin/timezone';
  import localizedFormat from 'dayjs/plugin/localizedFormat';

  dayjs.extend(localizedFormat);
  dayjs.extend(timezone);

  const router = useRouter();
  const route = useRoute();
  const kbStore = useKbStore();
  const toast = useToast();
  const progress = useProgress();

  const mdEditorElement = ref(null);

  const editorElement = ref(null);
  let editor = null;
  const editorOptions = {
    fontSize: 18,
    wrap: true,
  };

  const kbId = ref();
  const kbDocumentId = ref();

  const kbDocumentName = computed(() => kbStore.currentKbDocument?.name || kbDocumentId.value || '');
  const kbDocumentTitle = computed(() => kbStore.currentKbDocument?.metadata?.title || null);

  const kbDocumentContentValue = computed({
    get() {
      return kbStore.currentKbDocumentContent || '';
    },
    set(value) {
      kbStore.currentKbDocumentContent = value;
    },
  });

  const kbDocumentTagsValue = computed({
    get() {
      return kbStore.currentKbDocumentTags || [];
    },
    set(value) {
      kbStore.setTagsForCurrentKbDocument(value);
    },
  });

  const kbDocumentDoNotSplitValue = computed({
    get() {
      return kbStore.currentKbDocumentDoNotSplit;
    },
    set(value) {
      kbStore.setDoNotSplitForCurrentKbDocument(value);
    },
  });

  watch(
    () => kbDocumentContentValue.value,
    function (mdValue) {
      console.log('value changes detected');
      mdEditorElement.value.setMarkdown(mdValue, false);
    },
  );

  async function initKbDocumentEditor() {
    kbId.value = route.params?.id || null;
    kbDocumentId.value = route.params?.documentId || null;
    if (!kbId.value || !kbDocumentId.value) {
      console.log(`ERROR: KB ID and KB Document ID must be specified`);
      toast.error(`Missing URL parameters`);
      return;
    }
    progress.startProgress({ blockUI: true, spinner: true });
    await kbStore.loadKbDocument(kbId.value, kbDocumentId.value);
    progress.stopProgress();
  }

  function initEditor() {
    //const aceRef = ref(null);
    editor = editorElement.value.getAceInstance();
    //editor.setTheme('ace/theme/dreamweaver');
    //editor.getSession().setMode('ace/mode/markdown');
    //editor.getSession().setMode('ace/mode/kbdoc');
    //editorElement.value.style.fontSize = '18px';
    //editor.setOption('fontSize', 18);

    // +++
  }

  function onCopyName() {
    navigator.clipboard.writeText(kbDocumentName.value);
  }

  function onShowFind() {
    if (editor) {
      editor.execCommand('find');
    }
  }

  function onShowReplace() {
    if (editor) {
      editor.execCommand('replace');
    }
  }

  // Subscribe to actions in kbStore
  kbStore.$onAction(
    ({
      name, // name of the action
    }) => {
      if (name === 'beforeSaveKbDocument') {
        // Need to update store with the most recent document content
        const md = mdEditorElement.value.getMarkdown();
        kbStore.currentKbDocumentContent = md;
        console.log(`KbDocumentEditor: kbStore.beforeSaveKbDocument - updated document content`);
      }
    },
  );

  function onKbAddTag(tag) {
    kbStore.addTagToKb(tag);
  }

  onMounted(async () => {
    await initKbDocumentEditor();
    //initEditor();
  });

  onBeforeUnmount(() => {
    kbStore.unloadKbDocument();
  });
</script>
