<template>
  <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
    <div class="px-4 sm:px-0 relative">
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Voice</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Channel-specific parameters for Voice. When talking to bot via Voice, these parameters will take effect.</p>
      </div>
    </div>
    <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
      <div class="px-4 py-2">
        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <InputTextarea
            class="mt-2 col-span-full"
            name="voiceInstructions"
            v-model="voiceInstructions"
            :label="propsDefs['voiceInstructions'].title"
            :description="propsDefs['voiceInstructions'].description"
            :placeholder="propsDefs['voiceInstructions'].placeholder"
            :rows="1"
          ></InputTextarea>
          <InputTextarea
            class="mt-2 col-span-full"
            name="voiceEngageMessages"
            v-model="voiceEngageMessages"
            :label="propsDefs['voiceEngageMessages'].title"
            :description="propsDefs['voiceEngageMessages'].description"
            :placeholder="propsDefs['voiceEngageMessages'].placeholder"
            :rows="1"
          ></InputTextarea>
          <StdPropEditor class="sm:col-span-4" :prop-def="propsDefs['voiceEngageTimeout']" v-model="voiceEngageTimeout" />
          <StdPropEditor class="sm:col-span-4" :prop-def="propsDefs['voiceEndOfSpeechSensitivity']" v-model="voiceEndOfSpeechSensitivity" />
          <!--
          <StdPropEditor class="sm:col-span-4" :prop-def="propsDefs['voiceNoiseSuppression']" v-model="voiceNoiseSuppression" />
          -->
          <StdPropEditor class="sm:col-span-4" :prop-def="propsDefs['ttsVoice']" v-model="ttsVoice" />
          <StdPropEditor class="sm:col-span-4" :prop-def="propsDefs['asrLanguages']" v-model="asrLanguages" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import InputTextarea from '@/components/Bots/editors/InputTextarea.vue';
  import ToggleGroup from '@/components/Toggles/ToggleGroup.vue';
  import StdBusinessHoursEditor from '@/components/Editors/StdBusinessHoursEditor.vue';
  import StdPropEditor from '@/components/Editors/StdPropEditor.vue';
  import { BOT_TYPE_DEFS, standardBotPropsByName } from '@/spec/BotTypeDefs';
  import { useBotsStore } from '@/store/BotsStore';
  import { computed } from 'vue';

  const botsStore = useBotsStore();

  const propsDefs = standardBotPropsByName;

  const voiceEndOfSpeechSensitivity = computed(botsStore.getPropGetSet('voiceEndOfSpeechSensitivity'));
  // This seems not needed so far - affects quality of voice recognition too much
  const voiceNoiseSuppression = computed(botsStore.getPropGetSet('voiceNoiseSuppression'));
  const voiceInstructions = computed(botsStore.getPropGetSet('voiceInstructions'));
  const voiceEngageMessages = computed(botsStore.getPropGetSet('voiceEngageMessages'));
  const voiceEngageTimeout = computed(botsStore.getPropGetSet('voiceEngageTimeout'));
  const ttsVoice = computed(botsStore.getPropGetSet('ttsVoice'));
  const asrLanguages = computed(botsStore.getPropGetSet('asrLanguages'));
</script>
