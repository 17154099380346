<template>
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5 pb-2 border-b">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-violet-600/70 rounded-xl p-1">
            <mdicon name="forum-outline" size="62" class="text-violet-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="mb-1 text-2xl font-bold text-gray-900">Conversation</h1>
          <div class="hidden lg:block">
            <nav class="flex space-x-4" aria-label="Tabs">
              <button
                v-for="tab in tabs"
                :key="tab.name"
                :href="tab.href"
                :class="[tab.name === currentTab ? 'bg-gray-100 text-gray-700' : 'text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium']"
                @click="onSelectTab(tab.name)"
              >
                {{ tab.name }}
              </button>
            </nav>
          </div>
        </div>
      </div>

      <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <button
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="onExport"
        >
          <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
          Report
        </button>
      </div>
    </div>
    <div class="">
      <ConversationSummary v-if="isTabVisible('Summary')" :conversation="conversation" :class="isTabVisible('Summary') ? '' : 'md:hidden'"></ConversationSummary>
      <ScenarioResultsProblems v-if="isTabVisible('Problems')" :class="isTabVisible('Problems') ? '' : 'md:hidden'" :problems="analysisProblems"></ScenarioResultsProblems>
      <ScenarioResultsKPIs v-if="isTabVisible('KPIs')" :class="isTabVisible('KPIs') ? '' : 'md:hidden'" :kpis="analysisKPIs"></ScenarioResultsKPIs>
      <ConversationAgentSummary v-if="isTabVisible('Agent')" :conversation="conversation" :class="isTabVisible('Agent') ? '' : 'md:hidden'"></ConversationAgentSummary>
      <ConversationTranscript
        v-if="isTabVisible('Transcript')"
        :conversation="conversation"
        :class="isTabVisible('Transcript') ? '' : 'md:hidden'"
        :transcript-messages-path="['context', 'transcript', 'data']"
      ></ConversationTranscript>
      <!--<test-result-transcript :class="isTabVisible('Transcript') ? '' : 'md:hidden'"></test-result-transcript>-->
      <!--<test-result-transcript :class="isTabVisible('Transcript') ? '' : 'md:hidden'" :visible="isTabVisible('Transcript')"></test-result-transcript>-->
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useConversationSearchStore } from '@/store/ConversationSearchStore';
  import { useBreakpoint } from '@/hooks/useBreakpoint';
  import { ArrowDownCircleIcon, HashtagIcon } from '@heroicons/vue/20/solid';
  import ConversationSummary from '@/components/Insights/ConversationSummary.vue';
  import ConversationTranscript from '@/components/Insights/ConversationTranscript.vue';
  import ScenarioResultsProblems from '@/components/Scenarios/ScenarioResultsProblems.vue';
  import ScenarioResultsKPIs from '@/components/Scenarios/ScenarioResultsKPIs.vue';
  import ConversationAgentSummary from '@/components/Insights/ConversationAgentSummary.vue';
  import { ConversationDetailsReport } from '@enegelai/core';

  const route = useRoute();
  const layout = useLayoutStore();
  const conversationSearchStore = useConversationSearchStore();

  const conversation = ref({});

  const conversationId = computed(() => conversation.value?.id || '');
  const conversationDatetime = computed(() => conversation.value?.datetime || '');
  const analysisKPIs = computed(() => conversation.value.context?.analysis?.kpis || []);
  const analysisProblems = computed(() => conversation.value.context?.analysis?.problems || []);

  const scenarioId = computed(() => conversation.value.scenario?.id || '');
  const scenarioName = computed(() => conversation.value.scenario?.name || '');
  const scenarioMedia = computed(() => conversation.value.scenario?.media || '');

  const tabs = [
    { name: 'Summary', href: '#', current: true },
    { name: 'Problems', href: '#', current: false },
    { name: 'KPIs', href: '#', current: false },
    { name: 'Agent', href: '#', current: false },
    { name: 'Transcript', href: '#', current: false },
    //{ name: 'Stats', href: '#', current: false },
  ];
  const currentTab = ref(tabs[0].name);

  const { breakpoints } = useBreakpoint();

  function onSelectTab(tabName) {
    currentTab.value = tabName;
  }

  function isTabVisible(tabName) {
    if (layout.print || ['xs', 'sm', 'md'].includes(breakpoints.is)) {
      return true;
    }
    return currentTab.value === tabName;
  }

  // Export report
  function onExport() {
    const reports = ConversationDetailsReport.generateResultReports(conversation.value);
    downloadReport(`Conversation_${conversationId.value}`, reports.reportHtml, 'text/html');
  }

  function downloadReport(filename, report, mimeType) {
    // credit: https://www.bitdegree.org/learn/javascript-download
    let text = report;
    let element = document.createElement('a');
    element.setAttribute('href', `data:${mimeType};charset=utf-8,` + encodeURIComponent(text));
    element.setAttribute('download', filename);
    //element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  onMounted(async () => {
    conversation.value = await conversationSearchStore.getConversation(route.params.conversationId);
  });
</script>
