<template>
  <div class="mx-6 my-6">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <div class="flex-auto flex items-center space-x-5">
          <div class="flex-shrink-0">
            <div class="relative bg-purple-600/70 rounded-xl p-1">
              <mdicon name="swap-horizontal" size="62" class="text-purple-100" aria-hidden="true"></mdicon>
            </div>
          </div>
          <div class="">
            <h1 class="text-2xl font-bold text-gray-900">Integrations</h1>
            <p class="text-sm font-medium text-gray-500">All integrations in your account</p>
          </div>
        </div>

        <div class="mt-4 relative isolate">
          <ul role="list" class="divide-y divide-gray-100">
            <li v-for="integration in integrations" :key="integration.name" :class="['']">
              <div :class="['px-2 my-2 py-2 rounded-lg flex justify-between items-center gap-x-2', integration.enabled ? 'bg-green-50 hover:bg-green-100' : 'hover:bg-gray-50']">
                <div class="flex min-w-0 gap-x-4 items-center">
                  <img class="h-18 w-18 flex-none" :src="integration.imageUrl" alt="" />
                  <div class="min-w-0 flex-auto">
                    <p class="text-md font-semibold leading-6 text-gray-900">{{ integration.name }}</p>
                    <p class="mt-1 truncate text-xs leading-5 text-gray-500">{{ integration.description }}</p>
                  </div>
                </div>
                <div class="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                  <div v-if="integration.enabled" class="">
                    <div class="mt-1 flex items-center justify-end gap-x-1.5">
                      <div class="flex-none rounded-full bg-emerald-500/20 p-1">
                        <div class="h-4 w-4 rounded-full bg-emerald-500" />
                      </div>
                      <p class="text-md font-semibold leading-5 text-gray-900">Enabled</p>
                    </div>
                    <div v-if="integration.configureComponent" class="mt-1">
                      <button
                        type="button"
                        class="flex items-center gap-x-1 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        @click="toggleIntegrationConfigure(integration.type)"
                      >
                        <img class="h-4 w-4 flex-none" :src="integration.imageUrl" alt="" />
                        Configure {{ integration.name }}
                      </button>
                    </div>
                  </div>
                  <div v-else>
                    <button
                      type="button"
                      class="flex items-center gap-x-1 rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-green-100"
                      @click="onEnableIntegration(integration)"
                    >
                      <img class="h-8 w-8 flex-none" :src="integration.imageUrl" alt="" />
                      Enable {{ integration.name }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="integration.configureComponent && integrationsConfigureEnabled[integration.type] === 'enabled'" class="">
                <component :is="integration.configureComponent" @canceled="toggleIntegrationConfigure(integration.type)"></component>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ProgressDialog ref="integrationsOpProgress"></ProgressDialog>
  </div>
</template>

<script setup>
  import { ref, reactive, computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useSettingsStore } from '@/store/SettingsStore';
  import { useRbacStore } from '@/store/RBACStore';
  import { useToast } from 'vue-toastification';
  import ProgressDialog from '@/components/Dialogs/ProgressDialog.vue';
  import TwilioIntegrationConfigure from '@/components/Settings/integrations/TwilioIntegrationConfigure.vue';

  // Here is what we are getting back
  // https://localhost:5173/settings/integrations/?type=slack&code=5964653648581.6037896825587.7bdef5ab41af5b64fc56eb602774e2f9d1c0688d1be76b91e8b196dfdfad6461&state=

  const settingsStore = useSettingsStore();
  const rbacStore = useRbacStore();
  const toast = useToast();
  const route = useRoute();

  const integrationsOpProgress = ref(null);

  const integrationsConfigureEnabled = reactive({});

  function toggleIntegrationConfigure(type) {
    if (integrationsConfigureEnabled[type] === 'enabled') {
      integrationsConfigureEnabled[type] = 'disabled';
    } else {
      integrationsConfigureEnabled[type] = 'enabled';
    }
  }

  function onEnableIntegration(integration, target = '_blank') {
    // TODO handle config-only integrations
    if (integration.configureComponent && integration.configureComponent !== '') {
      toggleIntegrationConfigure(integration.type);
      return;
    }

    // State, to check validness of redirect
    const oauthState = Date.now();
    localStorage.setItem('enegel-oauth-state', `${oauthState}`);
    if (integration.oAuthURL) {
      // Create redirect URI
      let hn = window.location.hostname;
      let redirect_uri = `https://${hn}`;
      if (window.location.port) {
        redirect_uri += `:${window.location.port}`;
      }

      if (integration === 'aircall') {
        // cannot use localhost redirect uri for aircall
        redirect_uri = `https://stage.enegel.ai`;
      }

      redirect_uri += `/settings/integrations?type=${integration.type}`;
      localStorage.setItem('enegel-oauth-redirect-uri', `${redirect_uri}`);
      // Navigate to oAuth URL to start oAuth process
      const oAuthURL = `${integration.oAuthURL}&redirect_uri=${redirect_uri}&state=${oauthState}`;
      console.log(`Navigating to ${oAuthURL}`);
      window.open(oAuthURL, target);
    }
    // TODO Anything else
  }

  onMounted(async () => {
    const qType = route?.query?.type || null;
    const qCode = route?.query?.code || null;
    const qState = route?.query?.state || null;
    const qAction = route?.query?.action || null;
    const localState = localStorage.getItem('enegel-oauth-state');
    const localRedirectURI = localStorage.getItem('enegel-oauth-redirect-uri');
    localStorage.removeItem('enegel-oauth-state');
    localStorage.removeItem('enegel-oauth-redirect-uri');

    // Support parameter action=enable
    //   so type=aircal&action=enable will redirect to Aircall to start oauth enablement
    if (qType && qAction === 'enable') {
      const integration = integrations.value.find((x) => x.type === qType);
      if (integration === undefined) {
        console.log(`oAuth integration: unable to find integration "${qType}" - ignored`);
        return;
      }
      if (integration.enabled) {
        console.log(`oAuth integration: "${qType}" already enabled - ignored`);
        return;
      }
      setTimeout(() => {
        onEnableIntegration(integration, '_self'); //'_self'
      }, 500);
      return;
    }

    if (!qType || !qCode) {
      console.log(`oAuth integration redirect: no type or code - ignored`);
      return;
    }

    if (qState !== localState) {
      console.log(`oAuth integration redirect: state ${qState} does not match local state ${localState} - ignored`);
      return;
    }
    const integration = integrations.value.find((x) => x.type === qType);
    if (integration === undefined) {
      console.log(`oAuth integration redirect: unable to find integration "${qType}" - ignored`);
      return;
    }
    console.log(`oAuth integration redirect: type=${qType} code=${qCode} state=${qState}`);
    integrationsOpProgress.value.show({ title: 'Integration', description: `Enabling integration: ${integration.name}` });

    // Invoke function to complete oAuth
    let res = await settingsStore.integrationsOAuthComplete(qType, qCode, integration.subdomain, localRedirectURI);
    console.log(`oAuth integration result: ${res}`);
    if (res?.success) {
      integrationsOpProgress.value.hide();
      toast.success(`Integration Enabled`);
      return;
    }

    integrationsOpProgress.value.showError({ message: `ERROR: ${res?.error}` });
  });

  const aircallClientId = computed(() => {
    return window.location.hostname.startsWith('app.enegel.ai') ? 'wzlUackIlrRyDE8x1sngx98n_JVOJL-GNXPvzszQXSo' : 'dZoIgfd3xgmzFylPntAT8F5r5oSexwS0U4hCbOCpvP4';
  });

  const integrations = computed(() => {
    const integrationsList = [
      {
        name: 'Slack',
        type: 'slack',
        description: 'Slack integration',
        subdomain: null,
        oAuthURL:
          'https://slack.com/oauth/v2/authorize?client_id=6028161877778.6013668656983&scope=channels:history,channels:read,chat:write,chat:write.customize,chat:write.public,groups:read,groups:history,usergroups:read,users.profile:read&user_scope=',
        enabled: settingsStore.slackIntegrationEnabled,
        imageUrl: '/img/Slack-mark.svg',
      },
      {
        name: 'Aircall',
        type: 'aircall',
        description: 'Aircall integration',
        subdomain: null,
        oAuthURL: `https://dashboard.aircall.io/oauth/authorize?response_type=code&client_id=${aircallClientId.value}&scope=public_api`,
        imageUrl: '/img/aircall.png',
        enabled: settingsStore.aircallIntegrationEnabled,
      },
      {
        name: 'Twilio',
        type: 'twilio',
        description: 'Twilio integration',
        configureComponent: TwilioIntegrationConfigure,
        imageUrl: '/img/twilio.svg',
        enabled: settingsStore.twilioIntegrationEnabled,
      },
    ];

    if (!rbacStore.isSuperAdmin) {
      return integrationsList;
    }

    // Only superadmin can manage zendesk

    if (window.location.hostname === 'app.enegel.ai') {
      integrationsList.push({
        name: 'Zendesk (crescendosupport)',
        type: 'zendesk',
        description: 'Zendesk integration',
        subdomain: 'crescendosupport',
        oAuthURL: 'https://crescendosupport.zendesk.com/oauth/authorizations/new?response_type=code&client_id=zdg-c7oai&scope=read%20write',
        imageUrl: '/img/Zendesk-Logo.svg',
        enabled: settingsStore.zendeskIntegrationEnabled,
      });
    } else {
      integrationsList.push({
        name: 'Staging Zendesk (d3v-enegelai)',
        type: 'zendesk',
        description: 'Zendesk integration',
        subdomain: 'd3v-enegelai',
        oAuthURL: 'https://d3v-enegelai.zendesk.com/oauth/authorizations/new?response_type=code&client_id=zdg-enegel.ai&scope=read%20write',
        imageUrl: '/img/Zendesk-Logo.svg',
        enabled: settingsStore.zendeskIntegrationEnabled,
      });
    }

    return integrationsList;
  });
</script>
