<template>
  <div class="mx-6 my-6">
    <AgentHeader class="border-b pb-2 mb-5"></AgentHeader>
    <router-view />
  </div>
</template>

<script setup>
  import { onMounted } from 'vue';
  import AgentHeader from '@/components/Agents/AgentHeader.vue';
  import { useRoute } from 'vue-router';

  import { useAgentDataStore } from '@/store/AgentDataStore';

  const agentDataStore = useAgentDataStore();
  const route = useRoute();

  onMounted(async () => {
    // Test TODO refine
    const agentId = route?.query?.agentId || null;
    if (agentId) {
      console.log(`agentId=${agentId} passed, setting agentId in agentData store`);
      await agentDataStore.setAgent(agentId);
    }
    await agentDataStore.init();
  });
</script>
