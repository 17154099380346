/*
 * API Client
 * */
import ApiOp from '@/api/apiop';

const baseURL = '/api';

class API {
  constructor() {
    // TODO
  }

  async executeTest(testId) {
    const url = `${baseURL}/executetest/${testId}`;
    return new ApiOp({
      url: url,
      method: 'post',
    }).execute();
  }

  async getTestRuns() {
    const url = `${baseURL}/testruns`;
    return new ApiOp({
      url: url,
      method: 'get',
    }).execute();
  }

  async getJobResults(jobId) {
    const url = `${baseURL}/results/${jobId}`;
    return new ApiOp({
      url: url,
      method: 'get',
    }).execute();
  }

  async getCollections(type) {
    const url = `${baseURL}/collections/${type}`;
    return new ApiOp({
      url: url,
      method: 'get',
    }).execute();
  }

  // TODO API Implementation using Firebase
}

const api = new API();
export default api;

/*
const api = {
  getData({ type = 'stats' }) {
    let opts = {
      method: 'get',
      params: {
        format: 'json',
      },
    };
    switch (type) {
      case 'stats': {
        opts.url = `${baseURL}/stats`;
        break;
      }
    }
    return new ApiOp(opts).execute();
  },

  async getNodes(){
    // TODO
    return false;
  }
};
*/
