<template>
  <div class="">
    <div class="relative isolate overflow-hidden">
      <div class="">
        <dl class="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-4">
          <div v-for="stat in statsToShow" :key="stat.name" class="-z-20 min-h-[110px] relative overflow-hidden rounded-xl bg-slate-50 px-4 pt-4 pb-2">
            <dt class="truncate text-sm font-medium text-gray-500">{{ stat.name }}</dt>
            <dd :class="['mt-1 font-semibold tracking-tight text-gray-900', stat?.valueClass || 'text-xl']">{{ stat.value }}</dd>
            <dd class="mt-2 text-sm text-gray-400 font-medium">{{ stat?.description || '' }}</dd>
            <dd v-if="stat?.extraDescription" class="mt-2 text-xs text-gray-400 font-medium">{{ stat?.extraDescription || '' }}</dd>
            <db-easy-pie
              v-if="stat.gauge"
              class="-z-10 absolute right-1 top-1 h-28 w-28 rounded-full text-lg font-semibold text-slate-500"
              :value="isNaN(stat.value) ? 0 : stat.value"
              :max="stat.max"
              :line-width="8"
              :animated="true"
              :percent-ranges="stat?.percentRanges || []"
              :show-percents="stat.percents || false"
            ></db-easy-pie>
            <mdicon v-else-if="stat.icon" :name="stat.icon" :size="stat.iconSize || 105" :class="['-z-10 absolute right-1 top-1 rounded-full', stat.iconClass || '']" />
          </div>
        </dl>
      </div>
    </div>

    <div v-if="conversationSummary !== ''" class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-gray-300/50 p-1 mr-2 text-gray-500" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Summary</h2>
      </div>
      <div class="my-2 p-4 bg-violet-50 rounded-md">
        <div class="prose font-source text-lg text-slate-600" v-html="conversationSummary"></div>
      </div>
    </div>

    <div class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-gray-300/50 p-1 mr-2 text-gray-500" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">CSat: {{ conversationCSatText }}</h2>
      </div>
      <div v-if="conversationCSatExplanation !== ''" class="my-2 p-4 bg-violet-50 rounded-md">
        <div class="prose font-source text-lg text-slate-600" v-html="conversationCSatExplanation"></div>
      </div>
    </div>

    <div v-if="collectedDataExists" class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-400 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-gray-300/50 p-1 mr-2 text-gray-500" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Collected Data</h2>
      </div>
      <div class="">
        <div class="my-2 grid grid-cols-1 gap-x-8 gap-y-4 xl:grid-cols-2 lg:gap-x-8">
          <div v-for="(entry, idx) in collectedData" :key="idx" class="border-b">
            <div class="flex justify-between">
              <dt class="text-gray-500">{{ entry.name }}</dt>
              <dd class="text-gray-700 font-semibold">
                {{ entry.value }}
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>

    <StdPropSetViewer v-if="isConversationEscalated" class="overflow-hidden mt-6" title="Escalation" :src="conversation" :propSet="handoffProps"></StdPropSetViewer>

    <StdPropSetViewer class="overflow-hidden mt-6" title="Client" :src="conversation" :propSet="clientProps"></StdPropSetViewer>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { ArrowDownCircleIcon, HashtagIcon } from '@heroicons/vue/20/solid';
  import StdPropSetViewer from '@/components/Viewers/StdPropSetViewer.vue';
  import { ConversationDetailsReport } from '@enegelai/core';
  import DbEasyPie from '@/components/Charts/DbEasyPie.vue';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  import { pathOr } from 'ramda';
  import { formatSeconds } from '@/utils/formatSeconds';
  import { useMd } from '@/hooks/useMd';

  //dayjs.extend(utc);

  const props = defineProps({
    conversation: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  const md = useMd();

  const conversationId = computed(() => props.conversation?.id || '');
  const conversationDatetime = computed(() => props.conversation?.datetime || '');
  const conversationMedia = computed(() => props.conversation?.channelAttributes?.media || 'chat');

  const conversationSummary = computed(() => ((props.conversation?.state?.summary || '') !== '' ? md.mdToHtml(props.conversation?.state?.summary) : ''));
  const conversationHasCSat = computed(() => (props.conversation?.CSat || 0) > 0);
  const conversationCSat = computed(() => props.conversation?.CSat || 0);
  const conversationCSatText = computed(() => ((props.conversation?.CSat || 0) > 0 ? props.conversation.CSat : 'N/A'));
  const conversationCSatExplanation = computed(() => md.mdToHtml(props.conversation?.CSatExplanation || ''));

  const handoffProps = [
    { name: 'Reason', prop: ['state', 'handoffReason'] },
    { name: 'User message', prop: ['state', 'handoffUserMessage'] },
  ];

  const clientProps = computed(() => {
    const cp = [{ name: 'Channel', prop: ['channelAttributes', 'media'] }];
    Object.keys(props.conversation?.clientAttributes || {}).map((x) => {
      cp.push({ name: x, prop: ['clientAttributes', x] });
    });
    return cp;
  });

  // Create user-friendly title for the conversation, by using first user or greeting message
  const conversationTitle = computed(() => {
    const defaultConverstationTitle = `Bot conversation ${props.conversation?.id || ''}`;
    if (!Array.isArray(props.conversation.messages) || props.conversation.messages.length <= 0) {
      return defaultConverstationTitle;
    }
    const firstMsg = props.conversation.messages.find((x) => x?.role === 'user') || props.conversation.messages[0];
    if (firstMsg && firstMsg.content && firstMsg.content !== '') {
      return firstMsg.content.substring(0, 45) + ' ...';
    }
    return defaultConverstationTitle;
  });

  const isConversationEscalated = computed(() => {
    if ('escalated' in (props.conversation?.state || {})) {
      return props.conversation.state.escalated;
    }
    if (props.conversation?.state?.slackEngaged || props.conversation?.state?.emailSent || props.conversation?.state?.emailEngaged) {
      return true;
    }
    return false;
  });

  const isAbandoned = computed(() => {
    return props.conversation?.state?.abandoned || false;
  });

  const isSlackEngaged = computed(() => {
    return props.conversation?.state?.slackEngaged || false;
  });

  const conversationEscalatedTo = computed(() => {
    let res = '';
    if (props.conversation?.state?.emailSent || props.conversation?.state?.emailEngaged) {
      res += 'Email';
    }
    if (props.conversation?.state?.slackEngaged) {
      res += res === '' ? 'Slack' : ', Slack';
    }
    return res;
  });

  const conversationHasAgent = computed(() => {
    return props.conversation?.state?.agentConnected || props.conversation?.state?.agentId || props.conversation?.state?.agentName;
  });

  const collectedData = computed(() => {
    let allData = Object.keys(props.conversation?.state?.contactData || {}).map((x) => {
      return {
        name: x,
        value: props.conversation?.state?.contactData[x] || '',
      };
    });
    const leadData = Object.keys(props.conversation?.state?.leadData || {})
      .map((x) => {
        return {
          name: x,
          value: props.conversation?.state?.leadData[x] || '',
        };
      })
      .filter((x) => x.name !== 'allDataProvidedByUser');
    allData = allData.concat(leadData);
    return allData;
  });

  const collectedDataExists = computed(() => {
    return collectedData.value.length > 0;
  });

  const contactDataExists = computed(() => {
    return Object.keys(props.conversation?.state?.contactData || {}).length > 0;
  });

  const contactData = computed(() =>
    Object.keys(props.conversation?.state?.contactData || {}).map((x) => {
      return {
        name: x,
        value: props.conversation?.state?.contactData[x] || '',
      };
    }),
  );

  const leadDataExists = computed(() => {
    return Object.keys(props.conversation?.state?.leadData || {}).length > 0;
  });

  const excludedLeadDataFields = ['allLeadDataProvidedByUser'];
  const leadData = computed(() =>
    Object.keys(props.conversation?.state?.leadData || {})
      .filter((p) => !excludedLeadDataFields.includes(p))
      .map((x) => {
        return {
          name: x,
          value: props.conversation?.state?.leadData[x] || '',
        };
      }),
  );

  const stats = computed(() => [
    {
      name: 'Date',
      value: dayjs(props.conversation?.datetime || 0).format('MM/DD/YYYY hh:mm:ss'),
      description: `Channel: ${conversationMedia.value}`,
      icon: 'clock-outline',
      valueClass: 'text-lg',
      iconClass: 'text-gray-200/60',
      show: true,
    },
    {
      name: 'Bot Messages',
      value: props.conversation?.stats?.botMessages || 0,
      icon: 'finance',
      iconClass: 'text-gray-200/60',
      description: 'Number of Bot Messages',
      //extraDescription: `Higher is better`,
      show: true,
    },
    {
      name: 'Lead',
      value: props.conversation?.state?.isLead || false ? (props.conversation?.state?.leadType === 'hot' ? 'Hot Lead' : 'Yes') : 'No',
      icon: 'lightbulb-on-20',
      iconClass: props.conversation?.state?.isLead || false ? 'text-green-500/50' : 'text-gray-200/60',
      iconSize: 90,
      description: 'Is it a Lead or not',
      //extraDescription: `Higher is better`,
      show: true,
    },
    {
      name: 'CSat',
      value: conversationHasCSat.value ? conversationCSat.value : 'N/A',
      max: 10,
      description: 'Out of 10',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 50, color: '#fde047' },
        { value: 75, color: '#bef264' },
      ],
      show: true,
    },
    {
      name: 'Escalated',
      value: isConversationEscalated.value ? 'Yes' : 'No',
      icon: 'arrow-up-bold-circle-outline',
      iconClass: isConversationEscalated.value ? 'text-green-500/50' : 'text-gray-200/60',
      iconSize: 100,
      description: isConversationEscalated.value ? `To: ${conversationEscalatedTo.value}` : '',
      //extraDescription: `Higher is better`,
      show: true,
    },
    {
      name: 'Agent',
      value: props.conversation?.AgentName || 'N/A',
      description: props.conversation?.state?.agentConnectedAt ? dayjs(props.conversation?.state?.agentConnectedAt).format('MM/DD/YYYY hh:mm:ss') : '',
      //extraDescription: `When Agent joined`,
      icon: 'face-agent',
      iconClass: props.conversation?.AgentName || false ? 'text-green-500/50' : 'text-gray-200/60',
      show: true, // isSlackEngaged.value,
    },
    {
      name: 'Time to Agent',
      value: props.conversation?.state?.timeToAgent ? formatSeconds(props.conversation?.state?.timeToAgent) : 'N/A',
      description: 'From Escalate to Agent answer',
      icon: 'timer-outline',
      iconClass: 'text-gray-200/60',
      show: true, // isSlackEngaged.value,
    },
    {
      name: 'Abandoned',
      value: isAbandoned.value ? 'Yes' : 'No',
      description: 'User left before talking to Agent',
      icon: 'account-arrow-right-outline',
      iconClass: isAbandoned.value ? 'text-red-700/40' : 'text-gray-200/60',
      show: true, //isSlackEngaged.value,
    },
  ]);

  const statsToShow = computed(() => stats.value.filter((x) => x.show));

  function getBotName(conv) {
    // TODO Store bot name in botconv
    const botId = conv?.botId || null;
    if (!botId) {
      return '-';
    }
    const bot = botsStore.getBot(botId);
    if (!bot) {
      return '-';
    }
    return bot?.name || '-';
  }

  // Export report
  function onExport() {
    const reports = ConversationDetailsReport.generateResultReports(props.conversation);
    downloadReport(`Conversation_${conversationId.value}`, reports.reportHtml, 'text/html');
  }

  function downloadReport(filename, report, mimeType) {
    // credit: https://www.bitdegree.org/learn/javascript-download
    let text = report;
    let element = document.createElement('a');
    element.setAttribute('href', `data:${mimeType};charset=utf-8,` + encodeURIComponent(text));
    element.setAttribute('download', filename);
    //element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }
</script>
