<template>
  <div class="space-y-5 divide-y divide-gray-900/10">
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Actions</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Define Actions to show in Bot depending on if visitor is a Lead, and if the Lead is Hot</p>
        </div>
      </div>

      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <StdPropArrayEditor class="col-span-full" v-model="actions" :label="propsDefs['actions'].title" :description="propsDefs['actions'].description" :propertiesSpec="actionsSpecProps"></StdPropArrayEditor>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { computed, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { pathOr } from 'ramda';
  import { useBotsStore } from '@/store/BotsStore';
  import InputTextarea from '@/components/Bots/editors/InputTextarea.vue';
  import StdPropArrayEditor from '@/components/Editors/StdPropArrayEditor.vue';
  import { BOT_TYPE_DEFS } from '@/spec/BotTypeDefs';

  const route = useRoute();
  const showAdvanced = computed(() => 'advanced' in (route?.query || {}));
  const botsStore = useBotsStore();

  const propsDefs = {};
  (BOT_TYPE_DEFS['leadGeneration']?.props || []).map((p) => {
    propsDefs[p.name] = {
      title: p?.title || '',
      description: p?.description || '',
      default: p?.default || '',
      placeholder: p?.placeholder || '',
    };
  });

  const actionsSpecProps = [
    { name: 'type', type: 'select', title: 'Type', options: ['Link', 'Calendly Meeting', 'Hubspot Meeting'], values: ['Link', 'Meeting', 'hubspotMeeting'], default: 'Link' },
    { name: 'leadCondition', type: 'select', title: 'Show to Lead', options: ['Any', 'Hot'], default: 'Any' },
    { name: 'name', type: 'string', title: 'Name', default: '' },
    { name: 'value', type: 'stringLong', title: 'URL', default: '' },
    { name: 'hubspotMeetingAuto', type: 'boolean', title: 'Open Booking Widget Automatically', default: false, when: (i) => i.type === 'hubspotMeeting' },
  ];

  // TODO Generalize
  function getPropGetSet(propPath) {
    const pp = Array.isArray(propPath) ? propPath : propPath.split('.');
    return {
      get() {
        return pathOr(null, pp, botsStore.botSpec);
      },
      set(newValue) {
        botsStore.setBotSpecProp(pp, newValue);
      },
    };
  }

  const actions = computed(getPropGetSet('actions'));
</script>
