export function getStorePropGetSet(store, prop) {
  return {
    get() {
      return store[prop];
    },
    set(newValue) {
      store[prop] = newValue;
    },
  };
}
