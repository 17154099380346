<template>
  <div>
    <div ref="wavesurferElement" id="waveform" class="w-full"></div>
  </div>
</template>

<script setup>
  import { onMounted, reactive, ref, watch } from 'vue';
  import WaveSurfer from 'wavesurfer.js';

  const props = defineProps({
    file: {
      type: String,
      default: null,
    },
  });

  const wavesurferElement = ref(null);

  let wavesurfer = null;

  const options = {
    container: '#waveform',
    height: 60,
    waveColor: 'violet',
    progressColor: 'purple',
    mediaControls: true,
    fillParent: true,
    //responsive: true,
    //scrollParent: true,
    backend: 'MediaElement',
    splitChannels: false,
    hideScrollbar: true,
  };

  watch(
    () => props.file,
    (newValue /*, oldValue*/) => {
      if (newValue) {
        wavesurfer.load(`/store/DEMO/${props.file}`);
      }
    }
  );

  onMounted(() => {
    wavesurfer = WaveSurfer.create(options);
    if (props.file) {
      wavesurfer.load(`/store/DEMO/${props.file}`);
    }
  });
</script>
