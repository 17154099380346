<template>
  <div class="space-y-5 divide-y divide-gray-900/10">
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <mdicon name="rotate-orbit" size="450" class="absolute -z-10 top-0 -left-10 text-slate-50" aria-hidden="true"></mdicon>
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">{{ jobTypeTitle }}</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Specify name and description for the Job, choose data to process, and enable desired analysis</p>
        </div>
      </div>

      <form class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div class="sm:col-span-4">
              <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
              <div class="mt-2">
                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Specify Job Name"
                    v-model="specName"
                  />
                </div>
              </div>
            </div>

            <div class="col-span-full">
              <label for="description" class="block text-sm font-medium leading-6 text-gray-900">Description</label>
              <div class="mt-2">
                <textarea
                  id="description"
                  name="description"
                  rows="2"
                  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  v-model="specDescription"
                />
              </div>
            </div>

            <div class="sm:col-span-4">
              <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Product</label>
              <div class="mt-2">
                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Specify Product Name"
                    v-model="specProduct"
                  />
                </div>
              </div>
            </div>

            <div class="col-span-full">
              <label for="collection" class="block text-sm font-medium leading-6 text-gray-900">{{ jobItemsTitle }} to process</label>
              <CollectionSelector id="collection" v-model="specScriptCollection" :type="jobCollectionType"></CollectionSelector>
            </div>

            <div class="col-span-full">
              <ToggleGroup v-model="stepSummarizeEnabled" :name="stepsDefs['analyzeSummarize'].title" :description="stepsDefs['analyzeSummarize'].description"></ToggleGroup>
              <ToggleGroup v-model="stepSentimentEnabled" :name="stepsDefs['analyzeSentiment'].title" :description="stepsDefs['analyzeSentiment'].description"></ToggleGroup>
              <ToggleGroup v-model="stepCSatEnabled" :name="stepsDefs['analyzeCSat'].title" :description="stepsDefs['analyzeCSat'].description"></ToggleGroup>
              <ToggleGroup v-model="stepProblemsEnabled" :name="stepsDefs['analyzeProblems'].title" :description="stepsDefs['analyzeProblems'].description"></ToggleGroup>
              <ToggleGroup v-model="stepKPIEnabled" :name="stepsDefs['analyzeKPI'].title" :description="stepsDefs['analyzeKPI'].description">
                <label for="kpicollection" class="mt-2 block text-sm font-medium leading-6 text-gray-900">KPI Set to analyze</label>
                <CollectionSelector id="kpicollection" v-model="specScriptKpiCollection" type="kpi"></CollectionSelector>
              </ToggleGroup>
              <ToggleGroup v-model="stepAgentEnabled" :name="stepsDefs['analyzeAgent'].title" :description="stepsDefs['analyzeAgent'].description"></ToggleGroup>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <p class="mt-1 text-sm leading-6 text-gray-600">You can tune up each analysis step in advanced properties</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div class="col-span-full">
              <ToggleGroup v-model="advancedEnabled" name="Advanced" description="Show advanced properties"></ToggleGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import ToggleGroup from '@/components/Toggles/ToggleGroup.vue';
  import { getStepDef } from '@/spec/ActionDefs.js';
  import CollectionSelector from '@/components/Collections/CollectionSelector.vue';
  import { JOB_TYPE_INFO } from '@/const';

  const props = defineProps({
    specData: {
      type: Object,
      default() {
        return {};
      },
    },
    advanced: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(['update:advanced', 'update:specProp', 'update:specScriptStepProp']);

  const advancedEnabled = computed({
    get() {
      return props.advanced;
    },
    set(newValue) {
      emit('update:advanced', newValue);
    },
  });

  // We need definitions for all possible steps of embedded scenario
  const stepsDefs = {
    processTranscript: getStepDef('processTranscript'),
    processTRecording: getStepDef('processRecording'),
    analyzeSummarize: getStepDef('analyzeSummarize'),
    analyzeSentiment: getStepDef('analyzeSentiment'),
    analyzeCSat: getStepDef('analyzeCSat'),
    analyzeProblems: getStepDef('analyzeProblems'),
    analyzeKPI: getStepDef('analyzeKPI'),
    analyzeAgent: getStepDef('analyzeAgent'),
  };

  const jobTypeTitle = JOB_TYPE_INFO[props.specData.type]?.title || 'UNKNOWN';
  const jobCollectionType = JOB_TYPE_INFO[props.specData.type]?.collectionType || 'NONE';
  const jobItemsTitle = JOB_TYPE_INFO[props.specData.type]?.itemsTitle || 'NONE';
  const processStepType = JOB_TYPE_INFO[props.specData.type]?.processStep || 'UNKNOWN';

  const specName = computed({
    get() {
      return props.specData?.name;
    },
    set(newValue) {
      emit('update:specProp', 'name', newValue);
    },
  });

  const specDescription = computed({
    get() {
      return props.specData?.description;
    },
    set(newValue) {
      emit('update:specProp', 'description', newValue);
    },
  });

  const specProduct = computed({
    get() {
      return props.specData?.product;
    },
    set(newValue) {
      emit('update:specProp', 'product', newValue);
    },
  });

  const specScriptCollection = computed({
    get() {
      const idx = stepIdx(processStepType);
      return idx !== -1 ? props.specData.embeddedScenario.script.steps[idx]?.collection || '' : '';
    },
    set(newValue) {
      const idx = stepIdx(processStepType);
      if (idx !== -1) {
        emit('update:specScriptStepProp', idx, 'collection', newValue);
      }
    },
  });

  const specScriptKpiCollection = computed({
    get() {
      const idx = stepIdx('analyzeKPI');
      return idx !== -1 ? props.specData.embeddedScenario.script.steps[idx]['collection.kpi'] || '' : '';
    },
    set(newValue) {
      const idx = stepIdx('analyzeKPI');
      if (idx !== -1) {
        emit('update:specScriptStepProp', idx, 'collection.kpi', newValue);
      }
    },
  });

  function updateStepEnabled(stepEnabled, stepType, stepIdx) {
    console.log(`Updating Step: ${stepType}: ${stepEnabled} ${stepIdx}`);
    const embeddedScenario = JSON.parse(JSON.stringify(props.specData.embeddedScenario));
    if (stepEnabled) {
      const sDef = stepsDefs[stepType] || null;
      if (sDef) {
        const step = {
          type: stepType,
          _active: true,
        };
        sDef.props.map((p) => {
          step[p.name] = p.default;
        });
        embeddedScenario.script.steps.push(step);
        console.log(`Added Step: ${stepType}`, step);
      }
    } else {
      console.log(`Deleting Step: ${stepType} ${stepIdx}`);
      embeddedScenario.script.steps.splice(stepIdx, 1);
    }
    emit('update:specProp', 'embeddedScenario', embeddedScenario);
  }

  function stepIdx(stepType) {
    const res = (props.specData?.embeddedScenario?.script?.steps || []).findIndex((x) => x?.type === stepType);
    console.log(`stepIdx called: ${stepType} = ${res}`);
    return res;
  }

  function getStepGetSet(stepType) {
    return {
      get() {
        return stepIdx(stepType) !== -1;
      },
      set(newValue) {
        updateStepEnabled(newValue, stepType, stepIdx(stepType));
      },
    };
  }

  const stepSummarizeEnabled = computed(getStepGetSet('analyzeSummarize'));
  const stepSentimentEnabled = computed(getStepGetSet('analyzeSentiment'));
  const stepCSatEnabled = computed(getStepGetSet('analyzeCSat'));
  const stepProblemsEnabled = computed(getStepGetSet('analyzeProblems'));
  const stepKPIEnabled = computed(getStepGetSet('analyzeKPI'));
  const stepAgentEnabled = computed(getStepGetSet('analyzeAgent'));
</script>
