<template>
  <div class="col-span-full">
    <label :for="name" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
    <div class="text-xs text-gray-500" v-if="description">{{ description }}</div>
    <div class="mt-2">
      <!--<textarea
        :id="name"
        :name="name"
        style="height: auto; overflow-x: auto"
        class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
        v-model="value"
        :placeholder="placeholder || 'none'"
      />-->
      <!--<Editor v-model="value" />-->
      <Textarea
        v-model="value"
        :unstyled="false"
        :rows="rows"
        autoResize
        :pt="{
          root: {
            class: 'font-source text-md w-full bg-slate-50',
          },
        }"
      />
      <!--<CodeEditor width="100%" v-model="value" :languages="[['javascript', 'JS']]" theme="github" font-size="14px" :line-nums="true"></CodeEditor>-->
      <!--<LinedTextarea :value="value" :nowrap="false" width="100%"></LinedTextarea>-->
    </div>
  </div>
</template>
<script setup>
  //p-inputtextarea p-inputtext p-component p-filled p-inputtextarea-resizable m-0 font-sans text-base text-gray-600 dark:text-white/80 bg-white dark:bg-gray-900 p-3 border border-gray-300 dark:border-blue-900/40 transition-colors duration-200 appearance-none rounded-lg hover:border-blue-500 focus:outline-none focus:outline-offset-0 focus:shadow-[0_0_0_0.2rem_rgba(191,219,254,1)] dark:focus:shadow-[0_0_0_0.2rem_rgba(147,197,253,0.5)] font-source w-full bg-sky-50
  import { computed } from 'vue';
  import Editor from 'primevue/editor';
  import Textarea from 'primevue/textarea';
  //import LinedTextarea from '@/components/Editors/LinedTextarea.vue';
  //import CodeEditor from 'simple-code-editor';

  const props = defineProps(['modelValue', 'name', 'label', 'description', 'placeholder', 'rows']);
  const emit = defineEmits(['update:modelValue']);

  const value = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });
</script>
