<template>
  <router-view />
</template>

<script setup>
  import { onMounted } from 'vue';
  import { useAuthStore } from '@/store/AuthStore';

  const authStore = useAuthStore();
  onMounted(() => {
    // Initialize Auth store
    authStore.init();
  });
</script>
