<template>
  <CollectionDocs collection-type="kpi" icon="check-circle-outline" collection-items-name="KPI" collection-subtitle="A list of all KPIs in the set" :fields="collectionDocFields"></CollectionDocs>
</template>
<script setup>
  import CollectionDocs from '@/components/Collections/CollectionDocs.vue';
  const collectionDocFields = [
    { name: 'title', title: 'Title', type: 'string', key: true },
    { name: 'name', title: 'Name', type: 'string' },
    { name: 'definition', title: 'Definition', type: 'text', tableHide: false },
  ];
</script>
