<template>
  <div class="flex flex-col">
    <header class="z-10 px-6 py-4 sticky bg-white border-b" style="top: calc(4rem + 1px)">
      <div class="flex items-center justify-between md:space-x-5">
        <div class="flex items-center space-x-5">
          <div class="flex-shrink-0">
            <div class="relative bg-violet-600/70 rounded-xl p-1">
              <mdicon :name="botsStore.botIcon" size="62" class="text-violet-100" aria-hidden="true"></mdicon>
            </div>
          </div>
          <div class="">
            <h1 class="text-2xl font-bold text-gray-900">
              {{ botsStore.botSpec?.name }}
            </h1>
            <div class="">{{ botsStore.botTypeTitle }}</div>
          </div>
        </div>

        <div
          v-if="rbac.canEditBot"
          class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3"
        >
          <button type="button" class="flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="onExportBot">
            <mdicon name="download-outline" class="h-6 w-6 mr-1 text-gray-500" aria-hidden="true"></mdicon>
            Export
          </button>

          <button type="button" class="mr-2 flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="onCloneBot">
            <mdicon name="content-duplicate" class="h-6 w-6 mr-1 text-gray-500" aria-hidden="true"></mdicon>
            Clone
          </button>

          <button
            type="button"
            class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="onSaveBot"
          >
            <CheckIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Save
          </button>
        </div>
        <!--<InlineMessage v-else>You do not have privilege to edit bot</InlineMessage>-->
      </div>
    </header>

    <div class="flex-auto mx-6 my-4">
      <router-view />
    </div>

    <ConfirmDialog group="cloneBot">
      <template #message="slotProps">
        <div class="flex flex-col gap-2 w-96">
          <label for="username">Name</label>
          <InputText id="username" class="" v-model="botCloneName" aria-describedby="name-help" />
          <small id="name-help">Enter the name for the new Assistant.</small>
        </div>
      </template>
    </ConfirmDialog>

    <!--<BotSpecEditor v-if="specAvailable" :spec="botSpec" @save="onBotSave"></BotSpecEditor>-->
    <!--
    <ConfirmationDialog :open="executeConfirm" title="Execute Job" description="Are you sure you want to execute Job ?" @cancel="executeConfirm = false" @confirm="onJobExecuteConfirmed"></ConfirmationDialog>
    <ProgressDialog ref="jobStartProgress"></ProgressDialog>
    -->
  </div>
</template>
<script setup>
  import { useRoute, useRouter } from 'vue-router';
  import { useRbacStore } from '@/store/RBACStore';
  import { useBotsStore } from '@/store/BotsStore';
  import { useSidebarStore } from '@/store/SidebarStore';
  import { useToast } from 'vue-toastification';
  import { onMounted, watch, ref, computed } from 'vue';
  import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
  import ProgressDialog from '@/components/Dialogs/ProgressDialog.vue';
  import { CheckIcon } from '@heroicons/vue/20/solid';
  import { ConversationDetailsReport } from '@enegelai/core';
  import InputText from 'primevue/inputtext';
  import ConfirmDialog from 'primevue/confirmdialog';
  import { useConfirm } from 'primevue/useconfirm';
  import InlineMessage from 'primevue/inlinemessage';

  const router = useRouter();
  const route = useRoute();
  const rbac = useRbacStore();

  const sidebarStore = useSidebarStore();
  const botsStore = useBotsStore();

  const toast = useToast();
  const confirm = useConfirm();

  const botCloneName = ref('');

  watch(
    () => route.params.id,
    async (newId) => {
      await initBotEditor();
    },
  );

  function onSaveBot(showToast = true) {
    // Save the latest value
    botsStore.saveBot().then((saveResult) => {
      if (showToast) {
        if (saveResult) {
          toast.success('Bot saved successfully');
        } else {
          toast.error(`Error saving bot`);
        }
      }
    });
  }

  function onExportBot() {
    const botSpecContent = JSON.stringify(botsStore.botSpec);
    downloadBotSpec(`Bot_${botsStore.botId}`, botSpecContent, 'application/json');
  }

  function onCloneBot() {
    // Dialog - new name + confirm
    const suggestedName = `${botsStore.botName} (Copy)`;
    botCloneName.value = suggestedName;
    confirm.require({
      group: 'cloneBot',
      header: 'Clone Assistant',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-outlined p-button-sm',
      acceptClass: 'p-button-sm',
      rejectLabel: 'Cancel',
      acceptLabel: 'Clone',
      accept: () => {
        //toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
        const newBotId = botsStore.cloneBotInPlace(botCloneName.value);
        router.push(`/bots/editor/${newBotId}`);
      },
      reject: () => {
        //toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        console.log(`Bot clone canceled`);
      },
    });
  }

  function downloadBotSpec(filename, content, mimeType) {
    // credit: https://www.bitdegree.org/learn/javascript-download
    let text = content;
    let element = document.createElement('a');
    element.setAttribute('href', `data:${mimeType};charset=utf-8,` + encodeURIComponent(text));
    element.setAttribute('download', filename);
    //element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  async function initBotEditor() {
    let id = route.params?.id || null;
    if (!id) {
      console.log(`ERROR: bot ID must be specified`);
      toast.error(`Bot ID must be specified`);
      return;
    }

    //  https://localhost:5173/bots/editor/bh77fw8sa5/settings
    const botEditorRoutePrefix = `/bots/editor/${id}`; //route.path.split('/').slice(0, 4).join('/');
    const botEditorSectionName = computed(() => botsStore.botName || 'Assistant Details');

    sidebarStore.setExtraItems(botEditorRoutePrefix, [
      //{ title: 'Assistant Details', type: 'subtitle' },
      { title: botEditorSectionName, type: 'sectionTitle' },
      //{ title: 'Summary', link: botEditorRoutePrefix + '/summary', icon: 'finance' },
      { title: 'Settings', link: botEditorRoutePrefix + '/settings', icon: 'cog-outline', iconSize: 16, iconClass: 'bg-orange-800 text-white rounded' },
      //{ title: 'Bot Data', link: botEditorRoutePrefix + '/data', icon: 'database-outline' },
      { title: 'Actions', link: botEditorRoutePrefix + '/actions', icon: 'cursor-default-click-outline', iconSize: 16, iconClass: 'bg-orange-800 text-white rounded' },
      { title: 'Test', link: botEditorRoutePrefix + '/test', icon: 'comment-check-outline', iconSize: 16, iconClass: 'bg-orange-800 text-white rounded' },
    ]);

    await botsStore.loadBot(id);
  }

  onMounted(async () => {
    await initBotEditor();
  });
</script>
