<template>
  <div class="md:w-[420px] sm:w-[100px] h-full flex flex-col overflow-hidden">
    <div class="bg-slate-700 py-4 px-4 sm:px-6 rounded">
      <div class="flex items-center justify-between">
        <div class="text-base font-semibold leading-6 text-white">{{ actionDef?.title }}</div>
      </div>
      <div class="mt-1">
        <p class="text-sm text-gray-400">{{ actionDef?.description }}</p>
      </div>
    </div>
    <form class="flex h-full flex-col divide-y divide-gray-200 bg-white overflow-y-auto custom-scrollbar">
      <div class="h-0 flex-1">
        <div class="flex flex-1 flex-col justify-between">
          <div class="divide-y divide-gray-200 px-4 sm:px-6">
            <div class="space-y-6 pt-6 pb-5">
              <spec-script-action-property v-for="prop in actionDef?.props" :key="prop.name" :prop-def="prop" v-model="actionData[prop.name]"></spec-script-action-property>
            </div>
            <div class="pt-4 pb-6">
              <div class="mt-4 flex text-sm">
                <a :href="`${actionDef?.helpUrl}`" class="group inline-flex items-center text-gray-500 hover:text-gray-900">
                  <QuestionMarkCircleIcon class="h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  <span class="ml-2">Learn more about {{ actionDef?.title }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="flex justify-end px-4 py-4">
      <button type="button" class="rounded-md bg-white py-2 px-3 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="onSave">Cancel</button>
      <button
        type="button"
        class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="onSave"
      >
        Save
      </button>
    </div>
  </div>
</template>

<script setup>
  import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
  import { XMarkIcon } from '@heroicons/vue/24/outline';
  import { LinkIcon, PlusIcon, QuestionMarkCircleIcon } from '@heroicons/vue/20/solid';
  import { reactive, watch, computed } from 'vue';
  import { getActionDef } from '@/spec/ActionDefs.js';
  import SpecScriptActionProperty from '@/components/Spec/SpecScriptActionProperty.vue';

  const props = defineProps({
    idx: {
      type: Number,
      default: -1,
    },
    action: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  const emit = defineEmits(['updateStep']);

  // Clone action and make it reactive - we can make changes to it locally
  let actionData = reactive(JSON.parse(JSON.stringify(props.action)));

  const actionDef = computed(() => getActionDef(props.action?.type));

  watch(
    () => props.action,
    (newValue /*, oldValue*/) => {
      actionData = reactive(JSON.parse(JSON.stringify(newValue)));
      console.log(`Props: step changed: ${actionData.text}`);
    }
  );

  function onSave() {
    // send out updated action
    emit('updateStep', props.idx, actionData);
  }
</script>
