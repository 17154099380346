<template>
  <div class="h-full flex flex-col">
    <div class="mx-4 mt-4 md:flex md:items-center md:justify-between md:space-x-5 pb-4 border-b">
      <div class="flex items-start space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-sky-600/70 rounded-xl p-1">
            <mdicon name="sitemap-outline" size="62" class="text-sky-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-xl font-bold text-gray-900">{{ specData.name }}</h1>
          <div class="mt-1">
            <div class="sm:hidden">
              <label for="tabs" class="sr-only">Select a tab</label>
              <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
              <select id="tabs" name="tabs" class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500">
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">{{ tab.name }}</option>
              </select>
            </div>
            <div class="hidden sm:block">
              <nav class="flex space-x-4 rounded-md" aria-label="Tabs">
                <button
                  v-for="tab in tabs"
                  :key="tab.name"
                  :href="tab.href"
                  :class="[tab.name === currentTab ? 'bg-slate-300/50 text-gray-800' : 'bg-slate-100/80 text-gray-500 hover:text-gray-700', 'rounded-md px-3 py-2 text-sm font-medium']"
                  @click="onSelectTab(tab.name)"
                >
                  {{ tab.name }}
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <button
          type="button"
          class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="onSave"
        >
          <CheckIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Save
        </button>
      </div>
    </div>

    <div class="flex-auto z-0">
      <div v-if="isTabVisible('Settings')" :class="isTabVisible('Settings') ? '' : 'md:hidden'" class="m-4">
        <FlowSpecSettings :spec-data="specData" @update:spec-prop="onSpecPropUpdate"></FlowSpecSettings>
        <div class="z-10 pb-8 pt-6 sm:pb-4 bg-white border-b">
          <div class="mx-auto flex flex-wrap items-center gap-6 sm:flex-nowrap">
            <div class="ml-auto flex">
              <button
                type="button"
                class="mr-2 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                @click="onSelectTab('Flow')"
              >
                <ChevronDoubleRightIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                Define Flow
              </button>
            </div>
          </div>
        </div>
      </div>
      <FlowSpecScriptEditor
        v-if="isTabVisible('Flow')"
        :class="isTabVisible('Flow') ? '' : 'md:hidden'"
        :media="specMedia"
        :steps="specScriptSteps"
        :links="specScriptLinks"
        @select="onActionSelect"
        @add-action="onActionAdd"
        @move-action="onActionMove"
        @action-form-active="onActionFormActive"
        @update:prop-value="onActionUpdatePropValue"
      ></FlowSpecScriptEditor>
    </div>
  </div>
</template>

<script setup>
  import { PlayIcon, CheckIcon, ChevronDoubleRightIcon, HashtagIcon, ArrowDownCircleIcon } from '@heroicons/vue/20/solid';
  import { watch, ref, reactive, computed } from 'vue';
  import { useRouter } from 'vue-router';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useBreakpoint } from '@/hooks/useBreakpoint';
  import { getActionDef } from '@/spec/ActionDefs.js';
  import { useToast } from 'vue-toastification';
  import { nanoid } from 'nanoid';
  import api from '@/api/api';
  import FlowSpecSettings from '@/components/Flows/FlowSpecSettings.vue';
  import FlowSpecScriptEditor from '@/components/Flows/FlowSpecScriptEditor.vue';

  const props = defineProps({
    spec: {
      type: Object,
      default() {
        return {};
      },
    },
  });
  const emit = defineEmits(['save']);

  const layout = useLayoutStore();
  const { breakpoints } = useBreakpoint();

  // Navigation
  const tabs = [
    { name: 'Settings', href: '#', current: true },
    { name: 'Flow', href: '#', current: false },
    { name: 'Test', href: '#', current: false },
  ];
  const currentTab = ref(tabs[0].name);
  function onSelectTab(tabName) {
    currentTab.value = tabName;
  }

  function isTabVisible(tabName) {
    if (layout.print || ['xs', 'sm', 'md'].includes(breakpoints.is)) {
      return true;
    }
    return currentTab.value === tabName;
  }

  // Make a full copy of the spec, and make it reactive
  const specData = reactive(JSON.parse(JSON.stringify(props.spec)));
  // TODO Move to function Normalize spec, i.e. add empty steps, etc
  if (!('id' in specData) || !specData.id) {
    specData.id = nanoid(10);
  }
  if (!('media' in specData)) {
    specData.media = 'recording';
  }
  if (!('script' in specData)) {
    specData.script = {
      type: 'sequence',
      version: 1,
      steps: [],
    };
  }
  if (!Array.isArray(specData.script.steps)) {
    specData.script.steps = [];
  }

  const router = useRouter();
  const toast = useToast();

  const specId = computed(() => specData?.id);

  const specMedia = computed(() => specData?.media);
  const specScriptSteps = computed(() => specData?.script?.steps || []);
  const specScriptLinks = computed(() => specData?.script?.links || []);

  const selectedActionIdx = ref(-1);
  //const selectedAction = computed(() => pathOr(undefined, ['script', 'steps', selectedActionIdx.value], specData));

  function onActionSelect(idx) {
    selectedActionIdx.value = idx;
    console.log(`Selected step: ${idx}`);
  }

  function onActionUpdate(idx, step) {
    specData.script.steps[idx] = step; // ???
    console.log(`Updated step: ${idx} -> ${JSON.stringify(step)}`);
  }

  function onActionAdd(actionData) {
    //specData.script.actions[idx] = step; // ???
    if (!actionData?.type) {
      console.log(`ERROR: Missing action type`);
      return;
    }
    const actionDef = getActionDef(actionData.type);
    const action = actionData;
    actionDef.props.map((p) => {
      action[p.name] = p.default;
    });
    if (!Array.isArray(specData.script.steps)) {
      specData.script.steps = [];
    }
    // Open editor by default on added actions
    action._active = true;
    specData.script.steps.push(action);
    console.log(`onAddAction: Added action: ${JSON.stringify(action)}`);
  }

  function onActionFormActive(idx, active) {
    specData.script.steps[idx]._active = active;
  }

  function onActionUpdatePropValue(index, prop, value) {
    console.log(`Got onUpdatePropValue: ${index}:${prop}=${value}`);
    specData.script.steps[index][prop] = value;
  }

  const moveItemInArray = (array, from, to) => {
    const item = array.splice(from, 1)[0];
    array.splice(to, 0, item);
  };

  function onActionMove(data) {
    const { fromIndex, toIndex } = data;
    console.log(`onActionMove: Moved action: ${fromIndex} -> ${toIndex}`);
    if (fromIndex >= 0 && toIndex >= 0) {
      moveItemInArray(specData.script.steps, fromIndex, toIndex);
    }
  }

  // TODO Support nested, i.e. specData.voice.ANI
  function onSpecPropUpdate(prop, value) {
    //console.log(`onSpecPropUpdate: ${prop} - ${value}`);
    specData[prop] = value;
  }

  async function onSave() {
    emit('save', specData);
  }

  async function onExecute() {
    // TODO
  }
</script>
