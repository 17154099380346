import { BasePlugin } from '@uppy/core';
import { v4 as uuid } from 'uuid';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';

export class UppyFirebaseCloudStorage extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    if (!opts.storageRef) {
      throw Error('Please provide the root storage');
    }
    this.type = 'uploader';
    this.id = 'FirebaseCloudStorage';
    this.title = 'Firebase Cloud Storage';
    this.storageRef = opts.storageRef;
    this.uploadFile = this.uploadFile.bind(this);
  }

  uploadFile(fileIds) {
    return Promise.all(
      fileIds.map((id) => {
        return new Promise((resolve, reject) => {
          const file = this.uppy.getFile(id);
          const refId = file.name;
          const fileRef = storageRef(this.storageRef, refId);
          const metaData = {
            contentType: file.type,
            //cacheControl: 'public,max-age=300' // ???
          };
          this.uppy.emit('upload-start', [file]);
          //const uploadTask = fileRef.put(file.data, metaData);
          const bytes = file.data;
          const uploadTask = uploadBytesResumable(fileRef, bytes, metaData);
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              const progressInfo = {
                uploader: this,
                bytesUploaded: snapshot.bytesTransferred,
                bytesTotal: snapshot.totalBytes,
              };
              this.uppy.emit('upload-progress', file, progressInfo);
            },
            (error) => {
              this.uppy.emit('upload-error', file, error);
              reject(error);
            },
            () => {
              const uploadResp = {
                status: 200, // ??
              };
              this.uppy.emit('upload-success', file, uploadResp);
              resolve(file);
              /*
              getDownloadURL(uploadTask.snapshot.ref).then((downloadUrl) => {
                const file = this.uppy.getFile(id);
                file.downloadUrl = downloadUrl;
                const uploadResp = {
                  status: 200,
                  uploadURL: downloadUrl,
                };
                this.uppy.emit('upload-success', file, uploadResp);
                resolve(file);
              });*/
            }
          );
        });
      })
    );
  }

  install() {
    this.uppy.addUploader(this.uploadFile);
  }

  uninstall() {
    this.uppy.removeUploader(this.uploadFile);
  }
}
