<template>
  <div class="pt-4">
    <div class="relative isolate overflow-hidden">
      <div class="">
        <dl class="mt-5 grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-4">
          <div v-for="stat in stats" :key="stat.name" class="relative overflow-hidden rounded-xl border border-gray-200 px-4 pt-4 pb-2">
            <dt class="truncate text-sm font-medium text-gray-500">{{ stat.name }}</dt>
            <dd class="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{{ stat.value }}</dd>
            <dd class="mt-2 text-sm text-gray-400 font-medium">{{ stat?.description || '' }}</dd>
            <dd v-if="stat?.extraDescription" class="mt-2 text-xs text-gray-400 font-medium">{{ stat?.extraDescription || '' }}</dd>
            <db-easy-pie
              v-if="stat.gauge"
              class="-z-10 absolute right-1 top-1 h-28 w-28 rounded-full text-lg font-semibold text-slate-500"
              :value="stat.value"
              :max="stat.max"
              :line-width="8"
              :animated="true"
              :percent-ranges="stat?.percentRanges || []"
              :show-percents="stat.percents || false"
            ></db-easy-pie>
            <mdicon v-else-if="stat.icon" :name="stat.icon" size="105" class="-z-10 absolute right-1 top-1 rounded-full text-slate-200" />
          </div>
        </dl>
      </div>

      <!--<div class="mt-10 border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
        <dl class="grid max-w-7xl grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
          <div v-for="(stat, statIdx) in stats" :key="stat.name" class="m-4 relative flex items-baseline flex-wrap justify-between pt-8 pb-4 py-4 sm:px-6 xl:px-8">
            <dt class="text-sm font-medium leading-6 text-gray-500">{{ stat.name }}</dt>
            <dd :class="[stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700', 'text-xs font-medium']">{{ stat.change }}</dd>
            <dd class="w-full flex-none text-3xl font-medium leading-10 tracking-tight text-gray-900 dark:text-white">{{ stat.value }}</dd>
            <dd class="text-xs font-medium">{{ stat?.description || '' }}</dd>
          </div>
        </dl>
      </div>-->
    </div>

    <div v-if="conversationSummary && conversationSummary !== ''" class="pt-2 md:pt-4">
      <div class="py-2 mx-auto lg:mx-0 lg:max-w-none">
        <div class="flex items-center justify-between">
          <h2 class="text-base font-semibold leading-7 text-gray-900">Summary</h2>
        </div>
      </div>
      <div class="rounded-xl border border-gray-200 bg-blue-50 p-4">
        <div class="flex items-center">
          <div class="flex-shrink-0 flex items-center">
            <mdicon name="bullseye" size="36" class="text-indigo-600 h-12 w-12 flex-none flex items-center justify-center rounded-lg bg-white object-cover ring-1 ring-gray-900/10" />
          </div>
          <div class="ml-3 font-source font-semibold text-xl text-slate-600">
            {{ conversationSummary }}
          </div>
        </div>
      </div>
    </div>

    <div class="space-y-8 py-4 xl:space-y-8">
      <div class="">
        <div class="mx-auto lg:mx-0 lg:max-w-none">
          <ul role="list" class="mt-4 grid grid-cols-1 gap-x-0 gap-y-4 lg:grid-cols-3 lg:gap-x-6 xl:gap-x-8">
            <li v-for="detailsItem in details" :key="detailsItem.id" :class="[detailsItem.class, 'overflow-hidden rounded-xl border border-gray-200']">
              <div class="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-4">
                <mdicon :name="detailsItem.icon" size="36" class="text-indigo-600 h-12 w-12 flex-none flex items-center justify-center rounded-lg bg-white object-cover ring-1 ring-gray-900/10" />
                <div class="text-sm font-medium leading-6 text-gray-900">{{ detailsItem.name }}</div>
              </div>
              <dl class="-my-3 divide-y divide-gray-100 px-6 pt-2 pb-4 text-sm leading-6">
                <div v-for="(entry, idx) in detailsItem.entries" :key="idx">
                  <div class="flex justify-between gap-x-4 py-2">
                    <dt class="text-gray-500">{{ entry.name }}</dt>
                    <dd class="text-gray-700 font-semibold">
                      {{ entry.value }}
                      <!--<time :datetime="detailsItem.lastInvoice.dateTime">{{ detailsItem.lastInvoice.date }}</time>-->
                    </dd>
                  </div>
                  <equalizer-value v-if="entry.eq" class="mb-2" :value="entry.value || 0" :max="10" :height="8" color="#9f1239"></equalizer-value>
                </div>
              </dl>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!--<db-easy-pie class="m-2 w-32 h-32 bg-slate-700 ring-8 ring-white rounded-full font-bold text-3xl" :value="4.4" :max="5.0" :line-width="10"></db-easy-pie>-->
  </div>
</template>

<script setup>
  import { onMounted, ref, computed } from 'vue';
  import { useTestResultsStore } from '@/store/TestResultsStore';
  import { Dialog, DialogPanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon, Bars3Icon, EllipsisHorizontalIcon, PlusSmallIcon } from '@heroicons/vue/20/solid';
  import { BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
  import DbEasyPie from '@/components/Charts/DbEasyPie.vue';
  import EqualizerValue from '@/components/Charts/EqualizerValue.vue';

  const testResult = useTestResultsStore();

  const sumKPIsScore = computed(() => (testResult.context?.analysis?.kpi || []).reduce((sum, v) => sum + parseInt(v?.score || 0), 0));
  const totalKPIsScore = computed(() => (testResult.context?.analysis?.kpi || []).length * 10);
  const sumProblemsScore = computed(() => (testResult.context?.analysis?.problems || []).reduce((sum, v) => sum + parseInt(v?.importance || 0), 0));
  const totalProblemsScore = computed(() => (testResult.context?.analysis?.problems || []).length * 10);

  const testGoal = computed(() => testResult.context?.goal || null);
  const conversationSummary = computed(() => testResult.context?.summary || null);
  const testId = computed(() => testResult.test?.id || '');
  const testName = computed(() => testResult.test?.name || '');
  const testMedia = computed(() => testResult.test?.media || '');
  const analysisProblems = computed(() => testResult.context?.analysis?.problems || []);

  function formatSeconds(sec) {
    const totalMinutes = Math.floor(sec / 60);
    const seconds = sec % 60;
    //const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  const durationValue = computed(() => formatSeconds(testResult.test?.duration));

  const stats = computed(() => [
    { name: 'Status', value: testResult.status, icon: testResult.status === 'PASS' ? 'check-circle-outline' : 'close-circle-outline' },
    { name: 'Duration', value: durationValue.value, icon: 'clock-outline' },
    {
      name: 'Problems Severity',
      value: sumProblemsScore.value,
      max: 70,
      description: `Smaller is better`,
      extraDescription: `Out of ${totalProblemsScore.value}`,
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#bef264' },
        { value: 25, color: '#fde047' },
        { value: 50, color: '#fca5a5' },
      ],
    },
    {
      name: 'KPI Score',
      value: sumKPIsScore.value,
      max: 70,
      description: 'Higher is better',
      extraDescription: `Out of ${totalKPIsScore.value} (${totalKPIsScore.value/10} KPIs)`,
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 50, color: '#fde047' },
        { value: 75, color: '#bef264' },
      ],
    },
    /*
    {
      name: 'MOS Score',
      value: testResult.mosScore,
      max: 4.5,
      description: `Max MOS score is 4.5`,
      percents: true,
      gauge: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 80, color: '#fde047' },
        { value: 90, color: '#bef264' },
      ],
    },*/
  ]);

  const statuses = {
    Paid: 'text-green-700 bg-green-50 ring-green-600/20',
    Withdraw: 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Overdue: 'text-red-700 bg-red-50 ring-red-600/10',
  };

  const details = computed(() => [
    {
      id: 1,
      name: 'Parameters',
      class: 'col-span-1',
      icon: 'cog',
      entries: [
        { name: 'ID', value: testId.value },
        { name: 'Name', value: testName.value },
        { name: 'Media', value: testMedia.value },
      ],
    },
    {
      id: 2,
      name: 'Problems',
      class: 'col-span-2',
      icon: 'alert-circle-outline',
      entries: analysisProblems.value.map((x) => {
        return { name: x.problem || '', value: parseInt(x?.importance || 0), eq: true };
      }),
    },
  ]);

  onMounted(() => {
    //testResult.loadTestResult('ctx-0419-1', '1');
  });
</script>
