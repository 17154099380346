import { createApp } from 'vue';
import { createPinia } from 'pinia';
import './tailwind.css';
import App from './App.vue';
import { routes } from '@/routes.js';
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import { fbApp } from '@/firebaseApp';
import { VueFire, VueFireAuth, getCurrentUser, globalFirestoreOptions } from 'vuefire';
import { useAuthStore } from '@/store/AuthStore';
import { useRbacStore } from '@/store/RBACStore';
import Toast, { POSITION } from 'vue-toastification';
import vueDebounce from 'vue-debounce';

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
//import ToastService from 'primevue/toastservice';

//import Tailwind from 'primevue/passthrough/tailwind';
import { PrimevueTailwind } from '@/PrimevueTailwind';
import 'primevue/resources/themes/aura-light-blue/theme.css';
//import 'primevue/resources/themes/lara-light-blue/theme.css';
//import 'primevue/resources/themes/lara-light-teal/theme.css';
//import 'primevue/resources/themes/viva-light/theme.css';
//import 'primevue/resources/themes/lara-light-blue/theme.css';
//import 'primevue/resources/themes/tailwind-light/theme.css';
//import 'primevue/resources/primevue.min.css';

/* Toast */
import 'vue-toastification/dist/index.css';

/* these are necessary styles for vue flow */
import '@vue-flow/core/dist/style.css';
import '@vue-flow/core/dist/theme-default.css';
import '@vue-flow/controls/dist/style.css';

/*
import '@finos/perspective';
import '@finos/perspective-viewer';
import '@finos/perspective-viewer-d3fc';
import '@finos/perspective-viewer-datagrid';
*/

import './beams.css';

// Primevue icons
import 'primeicons/primeicons.css';

import mdiVue from 'mdi-vue/v3';
import * as mdijs from '@mdi/js';

const pinia = createPinia();

const app = createApp(App);

const router = createRouter({
  history: createWebHistory('/'),
  //history: createWebHashHistory('/'),
  routes: import.meta.hot ? [] : routes,
});

// This is for hot reloading while developing
if (import.meta.hot) {
  let removeRoutes = [];

  for (const route of routes) {
    removeRoutes.push(router.addRoute(route));
  }

  import.meta.hot.accept('./routes.js', ({ routes }) => {
    for (const removeRoute of removeRoutes) removeRoute();
    removeRoutes = [];
    for (const route of routes) {
      removeRoutes.push(router.addRoute(route));
    }
    router.replace('');
  });
}

// MDI icons, see
// https://github.com/therufa/mdi-vue
app.use(mdiVue, {
  icons: mdijs,
});

app.use(pinia);

app.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true,
});

//app.use(PrimeVue, { unstyled: true, pt: Tailwind });
app.use(PrimeVue, { unstyled: false, pt: PrimevueTailwind });
//app.use(PrimeVue, { unstyled: false });

//app.use(ToastService);
app.use(ConfirmationService);

// ???
globalFirestoreOptions.reset = true;

app.use(VueFire, {
  firebaseApp: fbApp,
  modules: [VueFireAuth()],
});

app.directive('debounce', vueDebounce({ lock: true }));

const authStore = useAuthStore();
const rbac = useRbacStore();

// Ensure user is logged in
router.beforeEach(async (to) => {
  const currentUser = await authStore.getCurrentUserInfo();
  //const currentUser = await getCurrentUser();
  console.log(`router.beforeEach: ${to.fullPath} current user: ${currentUser ? 'NOT NULL' : 'NULL'}`);
  if (!currentUser && to.fullPath.startsWith('/login')) {
    return true;
  }
  if (!currentUser) {
    return {
      path: '/login',
      query: {
        // we keep the current path in the query so we can redirect to it after login
        // with `router.push(route.query.redirect || '/')`
        redirect: to.fullPath,
      },
    };
  }
  if (currentUser && !currentUser.emailVerified) {
    if (to.fullPath.startsWith('/validateemail')) {
      return true;
    }
    return {
      path: '/validateemail',
      query: {
        redirect: to.fullPath,
      },
    };
  }
  if (currentUser && currentUser.emailVerified && to.fullPath.startsWith('/validateemail')) {
    // Send to originally requested location
    return {
      path: to?.query?.redirect || '/',
    };
  }
  // RBAC
  const rbacResult = rbac.checkRoute(to.fullPath);
  if (!rbacResult.success) {
    console.log(`router.beforeEach: ${to.fullPath} RBAC ADJUSTED ROUTE: ${rbacResult.data}`);
    return {
      path: rbacResult.data,
    };
  }
});

app.use(router);

app.mount('#app');
