<template>
  <Menu as="div" class="relative inline-block text-left z-30">
    <div>
      <MenuButton
        class="inline-flex items-center w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <mdicon name="plus" size="18" aria-hidden="true"></mdicon>
        {{ title }}
        <ChevronDownIcon class="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition duration-100 ease-out"
      enter-from-class="transform scale-95 opacity-0"
      enter-to-class="transform scale-100 opacity-100"
      leave-active-class="transition duration-75 ease-in"
      leave-from-class="transform scale-100 opacity-100"
      leave-to-class="transform scale-95 opacity-0"
    >
      <MenuItems class="absolute w-screen max-w-sm right-0 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <MenuItem v-slot="{ active }" v-for="(item, idx) in items" :key="idx">
          <button :class="[active ? 'bg-gray-50' : 'text-gray-900', 'text-left group flex w-full items-start rounded-md px-2 py-5 text-sm gap-x-4']" @click="onClick(item)">
            <mdicon :name="item.icon" size="32" aria-hidden="true" class="text-violet-500"></mdicon>
            <div class="min-w-0">
              <p class="text-sm font-semibold leading-6 text-gray-900">{{ item.title }}</p>
              <p class="mt-1 text-xs leading-5 text-gray-500">{{ item.description }}</p>
            </div>
          </button>
        </MenuItem>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
  import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue';
  import { ChevronDownIcon } from '@heroicons/vue/20/solid';

  defineProps({
    title: {
      type: String,
      default: 'Button',
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  });
  const emit = defineEmits(['select']);

  function onClick(item) {
    emit('select', item);
  }
</script>
