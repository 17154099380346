/*
 * Bot Type definitions and meta-information
 * */

/*
 * Base for all Bot Type Definitions
 * */

export class BotTypeDef {
  constructor() {
    this.type = '';
    this.category = '';
    this.title = '';
    this.description = '';
    this.defaultName = '';
    this.icon = 'lightbulb-on-20';
    this.helpUrl = '';
    this.props = [];
  }
}

export const emptyBotType = new BotTypeDef();

// Standard bot props, applicable for all bots
export const standardBotProps = [
  // COMMON // //////////////////////////////////// //

  {
    name: 'declineMessage',
    advanced: false,
    type: 'text',
    title: 'Decline message',
    default: 'I cannot help with that.',
    description: 'What bot should say when message must not be answered because of guardrails constraints.',
  },

  // HANDOFF // ////////////////////////////////////// //

  // This seems to be not used todo remove
  { name: 'handoffEnabled', advanced: false, type: 'boolean', title: 'Enable Handoff', default: false },

  { name: 'handoffCriteria', advanced: false, type: 'text', title: 'Handoff Criteria', default: '', description: 'Describe when to handoff conversation to a person' },
  {
    name: 'handoffMessage',
    advanced: false,
    type: 'text',
    title: 'Handoff Message',
    default: 'Let me get someone else who can better help with that. One moment please.',
    description: 'Message to send after handoff',
  },

  { name: 'handoffEnableSlackEscalation', advanced: false, type: 'boolean', title: 'Handoff to Slack', default: true, description: 'Handoff Conversation to Slack Channel' },
  { name: 'slackChannel', advanced: false, type: 'object', title: 'Slack Channel', default: {}, description: '' },
  { name: 'slackShowFullUserDetails', advanced: false, type: 'boolean', title: 'Show Slack User', default: false, description: 'Show picture and full name of Slack user, or only the first name' },

  { name: 'handoffEnableEmailEscalation', advanced: false, type: 'boolean', title: 'Handoff to Email', default: false, description: 'Handoff Conversation to Email' },
  { name: 'handoffEmailAddress', advanced: false, type: 'string', title: 'Email addresses', default: '', description: 'Separate email addresses with semicolon, i.e. "alice@mail.com;bob@mail.com"' },

  { name: 'handoffEnableZendeskEscalation', advanced: false, type: 'boolean', title: 'Handoff to Zendesk', default: false, description: 'Handoff Conversation to Zendesk' },

  // CSAT // ////////////////////////////////////// //

  { name: 'csatEnabled', advanced: false, type: 'boolean', title: 'Enable CSat', description: 'Enable real time CSat analysis on each conversation turn', default: false },
  {
    name: 'csatRelevantDomains',
    advanced: false,
    type: 'text',
    title: 'Relevant Domains',
    default: '',
    description: 'List relevant domains for the Customer Service. If provided, CSat will be analysed only if customer inquiry is within relevant domains.',
  },

  // CUES // ////////////////////////////////////// //

  { name: 'cuesEnabled', advanced: false, type: 'boolean', title: 'Enable Cues', description: 'Enable Cues to configure bot what subset of KB to use', default: false },
  { name: 'cuesWebPlacement', advanced: false, type: 'array', title: 'Web page placement Cues', default: [], description: 'Tell Bot which KB Documents to use depending on what web page Bot is placed to' },
  { name: 'cuesContactInformation', advanced: false, type: 'array', title: 'Cues Based on Collected Data', default: [], description: 'Tell Bot which KB Documents to use depending on data collected from user' },

  // VOICE // ////////////////////////////////////// //

  {
    name: 'voiceInstructions',
    advanced: false,
    type: 'text',
    title: 'Behavior Instructions for Voice',
    default: '',
    description: 'Describe how Bot should behave when talking to it over Voice. For example, you may instruct it to to pronounce numbers, dates in a special way.',
  },
  {
    name: 'voiceEngageMessages',
    advanced: false,
    type: 'text',
    title: 'Engage Messages for Voice',
    default: '',
    description: 'What bot should say to re-engage caller into the conversation if caller is silent for too long. You can have multiple messages, bot will select one of them; separate them by new line.',
  },
  { name: 'voiceEngageTimeout', advanced: false, type: 'number', title: 'Engage timeout', default: 5, description: 'How soon to say engage message if caller is silent. Set to 0 to disable.' },
  {
    name: 'voiceEndOfSpeechSensitivity',
    advanced: false,
    type: 'number',
    title: 'End of Speech Sensitivity',
    default: 100,
    description: 'How long to wait before deciding caller finished speaking a sentence. Milliseconds, from 0 to 1000.',
  },
  {
    name: 'voiceNoiseSuppression',
    advanced: false,
    type: 'number',
    title: 'Background Noise Suppression Level',
    default: 0,
    description: 'Suppress noise. From 0 (disables suppression) to 3 (max suppression).',
  },
  {
    name: 'ttsVoice',
    advanced: false,
    type: 'string',
    title: 'Voice',
    default: '',
    description: 'TTS Voice to use. Keep blank to use default.',
  },
  {
    name: 'asrLanguages',
    advanced: false,
    type: 'string',
    title: 'ASR Languages',
    default: '',
    description: 'Supported languages for ASR. Comma separated list of language codes. Keep blank for English only.',
  },

  // KB // ////////////////////////////////////// //

  {
    name: 'kbId',
    advanced: false,
    type: 'string',
    title: 'Knowledge Base',
    description: 'Knowledge Base to use. Keep "default" to maintain default behavior.',
    default: '',
  },

  // COLLECT DATA - CONTACT FORM // ////////////////////////////////////// //

  {
    name: 'contactForm',
    advanced: true,
    type: 'object',
    title: 'Contact Form',
    default: {
      type: 'form',
      name: 'contactData',
      title: 'We just need some more information from you to proceed',
      fields: [],
    },
    description: 'Contact form object',
  },
  { name: 'contactForm.title', advanced: false, type: 'text', title: 'Collect Data Message', default: 'Please provide the following data', description: 'Message to show when collecting data' },
  { name: 'contactForm.fields', advanced: false, type: 'array', title: 'Collect Contact Data', default: [], description: 'Collect this contact data' },

  {
    name: 'collectContactMakeForm',
    advanced: false,
    type: 'boolean',
    title: 'Use Form when possible',
    default: true,
    description: 'Whether or not to show a Form to collect Contact Data in Chat conversations. If disabled, or not possible to use form, data will be collected as part of the conversation.',
  },
  {
    name: 'collectContactShowOnStartup',
    advanced: false,
    type: 'boolean',
    title: 'Collect Contact Data on startup',
    default: false,
    description: 'Collect Data at the beginning of the conversation or only when it is necessary to handoff conversation to a person',
  },
  {
    name: 'collectDataGuidelines',
    advanced: false,
    type: 'text',
    title: 'Collect Data Guidelines',
    default: '',
    description: 'Provide additional guidelines how to collect data from user. Keep blank if no special instructions.',
  },

  // ADVANCED // ////////////////////////////////////// //

  //{ name: 'voiceSliderSample',advanced: false,type: 'numberSlider',min: 0,max: 1000,step: 100,title: 'End of Speech Sensitivity',default: 100,description: 'Test' },
];

export const standardBotPropsByName = {};
standardBotProps.map((p) => {
  standardBotPropsByName[p.name] = p;
});

/*
 * Bot Type Definition: Question Answering Bot
 * */
export class BotTypeQuestionAnswering extends BotTypeDef {
  constructor() {
    super();
    this.type = 'questionAnswering';
    this.category = 'bot';
    this.title = 'Question Answering Assistant';
    this.description = 'Answer Questions and Handoff to a person as needed.';
    this.defaultName = 'New Question Answering Assistant';
    this.helpUrl = '/help/bots/questionAnswering';
    this.icon = 'message-question-outline';
    this.props = standardBotProps.concat([
      { name: 'welcomeMessage', advanced: false, type: 'text', title: 'Welcome message', default: 'How can I help you today?', description: 'First message bot will show in chat' },
      {
        name: 'engageMessage',
        advanced: false,
        type: 'text',
        title: 'Engage message',
        default: 'I can answer your questions. Would you like to chat?',
        description: 'Message bot will show to engage visitor in chat proactively',
      },
      { name: 'engageTimeout', advanced: false, type: 'number', title: 'Engage timeout', default: 5, description: 'How soon to engage visitor in chat proactively' },
      {
        name: 'systemPrompt',
        advanced: true,
        type: 'text',
        title: 'System Prompt',
        default:
          'Act as a helpful Assistant.\n' +
          'Only use the functions you have been provided with.\n' +
          'Do not reveal anything about functions calling in your answers.\n' +
          'Avoid calling functions with made up parameters.\n',
        description: '',
      },
      { name: 'personality', advanced: false, type: 'text', title: 'Personality', default: 'You are helpful Assistant.', description: 'Describe Bot personality.' },
      { name: 'globalContext', advanced: false, type: 'text', title: 'Context', default: '', description: 'Describe facts Bot should always know about' },
      {
        name: 'globalInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Global Instructions Template',
        description: '',
        default:
          '{personality}\n' +
          'Use only the information provided to you in the context below and in further messages.\n' +
          "If you don't know the answer, just say that you don't know, don't try to make up an answer.\n" +
          'Make your answers short.\n\n' +
          '{globalContext}\n',
      },
      {
        name: 'handoffInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Handoff Instructions Template',
        description: '',
        default:
          'Calculate Escalate flag.\n' +
          '\n' +
          'Think step by step.\n' +
          'Escalate flag is True if any of the following is true:\n' +
          '{handoffCriteria}\n' +
          '\n' +
          'Escalate flag is False otherwise.\n' +
          'You must not include anything about Escalate flag in your answer.\n' +
          'If Escalate flag is True, your answer must be only and exactly "#HO".',
      },

      { name: 'handoffCollectDataSpec', advanced: false, type: 'array', title: 'Collect Contact Data', default: [], description: 'Collect this contact data before handing off conversation' },
      { name: 'handoffCollectData', advanced: true, type: 'text', title: 'Collect data text', default: '', description: 'Collect data spec rendered to text', hidden: true },
      {
        name: 'handoffCollectDataInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Collect data Instructions Template',
        description: '',
        default: 'You must collect the following contact information from user:\n' + '{handoffCollectData}\n\n' + 'User must provide all the data above.\nOnly use the information provided by user.\n\n',
      },

      { name: 'businessHoursEnabled', advanced: false, type: 'boolean', title: 'Enable Business Hours', default: false, description: '' },
      { name: 'businessHours', advanced: false, type: 'businessHours', title: 'Business Hours', default: null, description: 'Configure your Business Hours' },
      { name: 'handoffSlackWhenClosed', advanced: false, type: 'boolean', title: 'Slack Handoff when Closed', default: false, description: 'Handoff to Slack when outside of Business Hours' },
      { name: 'handoffSlackWhenOpen', advanced: false, type: 'boolean', title: 'Slack Handoff when Open', default: false, description: 'Handoff to Slack when inside of Business Hours' },
      { name: 'handoffEmailWhenClosed', advanced: false, type: 'boolean', title: 'Email Handoff when Closed', default: true, description: 'Handoff to Email when outside of Business Hours' },
      { name: 'handoffEmailWhenOpen', advanced: false, type: 'boolean', title: 'Email Handoff when Open', default: true, description: 'Handoff to Email when inside of Business Hours' },
      { name: 'handoffZendeskWhenClosed', advanced: false, type: 'boolean', title: 'Zendesk Handoff when Closed', default: true, description: 'Handoff to Zendesk when outside of Business Hours' },
      { name: 'handoffZendeskWhenOpen', advanced: false, type: 'boolean', title: 'Zendesk Handoff when Open', default: true, description: 'Handoff to Zendesk when inside of Business Hours' },

      {
        name: 'handoffMessageWhenClosed',
        advanced: false,
        type: 'text',
        title: 'Handoff Message when Closed',
        default: 'Let me get someone else who can better help with that. One moment please.',
        description: 'Message to send after handoff when outside of Business Hours',
      },

      { name: 'actions', advanced: false, type: 'array', title: 'Actions', default: [], description: 'Define actions to show in Bot' },

      {
        name: 'modelOverride',
        advanced: true,
        type: 'string',
        title: 'Model override',
        description: 'name of the model',
        default: 'gpt-4o',
      },
      {
        name: 'implementationVersion',
        advanced: true,
        type: 'string',
        title: 'Implementation Version',
        description: 'Bot Conversation Implementation Version',
        default: 'v4',
      },
      { name: 'includeImages', advanced: true, type: 'boolean', title: 'Include Images', default: false, description: 'Include images in the answer' },
      { name: 'citeSources', advanced: true, type: 'boolean', title: 'Cite Sources', default: false, description: 'Include source citation in the answer' },
      //{ name: 'text2', type: 'text', title: 'Really Long Title', default: '' },
    ]);
    this.propsByName = {};
    this.props.map((p) => {
      this.propsByName[p.name] = p;
    });
  }
}

export class BotTypeLeadGeneration extends BotTypeDef {
  constructor() {
    super();
    this.type = 'leadGeneration';
    this.category = 'bot';
    this.title = 'Lead Generation Assistant';
    this.description = 'Engage in conversation with web site visitors, and answer questions. Screen and Qualify leads. Escalate Hot leads to live person. Collect contact information from Lead.';
    this.defaultName = 'New Lead Generation Assistant';
    this.helpUrl = '/help/bots/leadGeneration';
    this.icon = 'lightbulb-on-20';
    this.props = standardBotProps.concat([
      { name: 'welcomeMessage', advanced: false, type: 'text', title: 'Welcome message', default: 'How can I help you today?', description: 'First message bot will show in chat' },
      {
        name: 'engageMessage',
        advanced: false,
        type: 'text',
        title: 'Engage message',
        default: 'I can answer your questions. Would you like to chat?',
        description: 'Message bot will show to engage visitor in chat proactively',
      },
      { name: 'engageTimeout', advanced: false, type: 'number', title: 'Engage timeout', default: 5, description: 'How soon to engage visitor in chat proactively' },
      {
        name: 'systemPrompt',
        advanced: true,
        type: 'text',
        title: 'System Prompt',
        default: 'Act as a helpful Assistant.\n' + 'Only use the functions you have been provided with.\n' + 'Do not call functions until all mandatory parameters are provided by user.\n',
        description: '',
      },
      { name: 'personality', advanced: false, type: 'text', title: 'Personality', default: 'You are helpful Assistant.', description: 'Describe Bot personality.' },
      { name: 'globalContext', advanced: false, type: 'text', title: 'Context', default: '', description: 'Describe facts Bot should always know about' },
      {
        name: 'globalInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Global Instructions Template',
        description: '',
        default:
          '{personality}\n' +
          'Answer questions the best way you can.\n' +
          'Use only the information provided to you in the context below and in further messages.\n' +
          //'If available, share relevant web pages links provided in the context.\n' +
          "If you don't know the answer, just say that you don't know, don't try to make up an answer.\n" +
          'Make your answers short.\n\n' +
          '{globalContext}\n',
      },
      { name: 'determineLeadCriteria', advanced: false, type: 'text', title: 'Determine Lead Criteria', default: '', description: 'Describe rules to determine if user is a lead or not' },
      {
        name: 'determineLeadInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Determine Lead Instructions Template',
        description: '',
        default: 'You must determine if user is a lead or not.\n' + 'Use the following rules to decide:\n' + '{determineLeadCriteria}\n' + 'Only use information provided by user.\n',
      },
      { name: 'leadMessage', advanced: false, type: 'text', title: 'Message to Lead', default: '', description: 'Send this message when Lead is detected' },

      { name: 'nonLeadInstructions', advanced: false, type: 'text', title: 'Non Lead Instructions', default: '', description: 'Describe what Bot should do if user is not a lead' },
      {
        name: 'nonLeadInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Non Lead Instructions Template',
        description: '',
        default: '{nonLeadInstructions}\n',
      },
      { name: 'leadQualifyHotLeadCriteria', advanced: false, type: 'text', title: 'Hot Lead', default: '', description: 'Describe when Lead is Hot' },
      // TODO Remove this
      { name: 'leadQualifyRegularLeadCriteria', advanced: false, type: 'text', title: 'Regular Lead', default: '', description: 'Describe when Lead is Regular' },
      { name: 'leadQualifyCollectDataSpec', advanced: false, type: 'array', title: 'Collect data', default: [], description: 'Collect this data to qualify the lead' },
      { name: 'leadQualifyCollectData', advanced: true, type: 'text', title: 'Collect data text', default: '', description: 'Collect data spec rendered to text', hidden: true },
      {
        name: 'leadCollectDataInstructions',
        advanced: false,
        type: 'text',
        title: 'Collect Data Instructions',
        default: '',
        description: "Tell Bot if you'd like to collect data in a special way, for example, one question at a time.",
      },
      {
        name: 'leadCollectDataInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Collect data for qualifying lead Instructions Template',
        description: '',
        default: 'You need to get the following Lead Data from user:\n' + '{leadQualifyCollectData}\n\n' + '\n{leadCollectDataInstructions}\n' + 'Only use the information provided by user.\n' + '\n',
      },
      {
        name: 'leadQualifyInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Qualify Lead Instructions Template',
        description: '',
        default:
          'User is a Lead. You must determine if the Lead is Hot.\n' +
          'To do so, you need to get the following information from user:\n' +
          '{leadQualifyCollectData}\n\n' +
          'Use following rules to decide if the Lead is Hot:\n' +
          '{leadQualifyHotLeadCriteria}\n\n' +
          'Only use the information provided by user.\n' +
          'Do not include any information about Leads in your answers.\n' +
          '\n',
      },
      { name: 'hotLeadInstructions', advanced: false, type: 'text', title: 'Hot Lead Instructions', default: '', description: 'Describe how Bot should behave when lead is Hot' },
      {
        name: 'hotLeadInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Hot Lead Instructions Template',
        description: '',
        default: '{hotLeadInstructions}',
      },
      { name: 'regularLeadInstructions', advanced: false, type: 'text', title: 'Regular Lead Instructions', default: '', description: 'Describe how Bot should behave when lead is regular (not Hot)' },
      {
        name: 'regularLeadInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Regular Lead Instructions Template',
        description: '',
        default: '{regularLeadInstructions}',
      },
      // TODO Remove
      { name: 'hotLeadEnableCollectContactInfo', advanced: false, type: 'boolean', title: 'Collect Contact Information', default: true, description: 'Collect Name, Email and Phone Number' },

      {
        name: 'leadCollectContactInstructions',
        advanced: false,
        type: 'text',
        title: 'Collect Contact Information Instructions',
        default: '',
        description: "Tell Bot if you'd like to collect contact information in a special way, for example, one question at a time.",
      },
      { name: 'leadCollectContactInfoFirst', advanced: false, type: 'boolean', title: 'Collect Contact Information before Qualifying the lead ', default: false, description: 'Collect Name, Email and Phone Number' },

      {
        name: 'getContactInstructionsTemplate',
        advanced: true,
        type: 'text',
        title: 'Get Contact Info Instructions Template',
        description: 'Instructions to get contact info from lead',
        default: 'You need to get the Name, Phone Number and Email from user.\n' + 'Only use information explicitly stated by user in messages.\n',
      },

      { name: 'hotLeadEnableSlackEscalation', advanced: false, type: 'boolean', title: 'Escalate to Slack', default: true, description: 'Escalate Conversation to Slack Channel' },
      // These moved to common properties - handoff
      //{ name: 'slackChannel', advanced: false, type: 'object', title: 'Slack Channel', default: {}, description: '' },
      //{ name: 'slackShowFullUserDetails', advanced: false, type: 'boolean', title: 'Show Slack User', default: false, description: 'Show picture and full name of Slack user, or only the first name' },

      { name: 'hotLeadEnableEmailEscalation', advanced: false, type: 'boolean', title: 'Escalate to Email', default: false, description: 'Escalate Conversation to Email' },
      { name: 'hotLeadEmailAddress', advanced: false, type: 'string', title: 'Email address', default: '', description: '' },
      { name: 'actions', advanced: false, type: 'array', title: 'Actions', default: [], description: 'Define actions to show in Bot' },

      // Misk
      {
        name: 'splitMessagesAtBreak',
        advanced: false,
        type: 'boolean',
        default: false,
        title: 'Split bot messages at break',
        description: 'Enable to split messages in bot at a paragraph break. You can use this so that, for example, messages asking for the information will stand out more.',
      },

      {
        name: 'modelOverride',
        advanced: true,
        type: 'string',
        title: 'Model override',
        description: 'name of the model',
        default: '',
      },
      {
        name: 'implementationVersion',
        advanced: true,
        type: 'string',
        title: 'Implementation Version',
        description: 'Bot Conversation Implementation Version',
        default: 'v1',
      },
    ]);
    this.propsByName = {};
    this.props.map((p) => {
      this.propsByName[p.name] = p;
    });
  }
}

// All known Bot Type Defs by type
export const BOT_TYPE_DEFS = {
  questionAnswering: new BotTypeQuestionAnswering(),
  leadGeneration: new BotTypeLeadGeneration(),
};

// Meta-information about all known Bot Types
export const BOT_TYPE_META = Object.values(BOT_TYPE_DEFS).map((x) => {
  return {
    type: x.type,
    title: x.title,
    description: x.description,
    helpUrl: x.helpUrl,
    icon: x.icon,
  };
});

export function getBotTypeDef(botType) {
  return botType in BOT_TYPE_DEFS ? BOT_TYPE_DEFS[botType] : emptyBotType;
}
