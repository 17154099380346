/*
 * Scenario Result Store
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { getBlob, getDownloadURL, ref as storageRef, getStorage } from 'firebase/storage';

export const useScenarioResultsStore = defineStore('scenarioResults', () => {
  // TODO Cache

  const authStore = useAuthStore();
  const tenantId = computed(() => authStore.tenantId);
  const storage = getStorage();

  // Current test result
  const scenarioResult = ref({});

  // Getters for special properties
  const status = computed(() => scenarioResult.value?.scenario?.status || '');

  const mosScore = computed(() => (isNaN(scenarioResult.value?.audio?.MOS) ? 0 : Number(Math.round(scenarioResult.value.audio.MOS + 'e2') + 'e-2')));

  // Getters for general data blocks in scenario result
  const scenario = computed(() => scenarioResult.value?.scenario || {});
  const context = computed(() => scenarioResult.value?.context || {});
  const analysis = computed(() => scenarioResult.value?.context?.analysis || {});

  // ???
  const call = computed(() => scenarioResult.value?.call || {});
  const audio = computed(() => scenarioResult.value?.audio || {});

  const transcript = computed(() => scenarioResult.value?.context?.transcript || []);
  const transcriptMessages = computed(() => scenarioResult.value?.context?.transcript?.data || []);
  const transcriptRecording = computed(() => scenarioResult.value?.context?.transcript?.recording || null);

  // todo remove transcript of conversation between Caller and Bot ???
  const conversationTranscript = computed(() => transcriptMessages.value.filter((x) => x?.actor === 'Caller' || x?.actor === 'Agent' || x?.actor === 'Bot'));

  async function loadScenarioResult(jobExecutionId, scenarioExecutionId) {
    const resultFileRef = storageRef(storage, `${tenantId.value}/results/${jobExecutionId}/${scenarioExecutionId}/result.json`);
    let resultData = null;
    let result = null;
    try {
      const blob = await getBlob(resultFileRef);
      resultData = await blob.text();
      result = JSON.parse(resultData);
    } catch (e) {
      console.log(`Exception getting scenario ${jobExecutionId}/${scenarioExecutionId} results data: ${e.message}`);
      resultData = null;
      result = null;
    }
    scenarioResult.value = result;
  }

  return {
    scenarioResult,
    context,
    status,
    mosScore,
    scenario,
    analysis,
    call,
    audio,
    transcript,
    transcriptMessages,
    transcriptRecording,
    conversationTranscript,
    loadScenarioResult,
  };
});
