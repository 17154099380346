<template>
  <div>
    <RadioGroup v-model="selectedValue">
      <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        <RadioGroupOption as="template" v-for="setting in settings" :key="setting.name" :value="setting.name" v-slot="{ disabled, checked, active }" :disabled="setting.disabled">
          <div
            :class="[
              checked ? 'border-transparent' : 'border-gray-300',
              active ? 'border-indigo-600 ring-2 ring-indigo-600' : '',
              'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
            ]"
          >
            <span class="flex flex-1">
              <span class="flex flex-col">
                <RadioGroupLabel as="span" :class="[disabled ? 'text-gray-400' : checked ? 'text-indigo-800' : 'text-gray-900', 'block text-sm font-medium']">{{ setting.title }}</RadioGroupLabel>
                <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">{{ setting.description }}</RadioGroupDescription>
              </span>
            </span>
            <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
            <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';
  import { CheckCircleIcon } from '@heroicons/vue/20/solid';

  const settings = [
    { title: 'Analyze', name: 'analyze', description: 'Analyze data, such as collection of transcripts or recordings', disabled: false },
    { title: 'Load Test', name: 'load', description: 'Execute load test, running multiple scenarios at desired rate and concurrency', disabled: true },
  ];

  const props = defineProps(['modelValue']);
  const emit = defineEmits(['update:modelValue']);

  const selectedValue = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });
</script>
