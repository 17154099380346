<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div class="flex items-center">
                <div class="flex h-18 w-18 flex-shrink-0 items-center justify-center rounded-full">
                  <svg v-if="state === 'progress'" class="animate-spin h-18 w-18 text-indigo-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-50" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  <mdicon v-else-if="state === 'success'" name="checkbox-marked-circle-outline" size="80" class="text-indigo-400/75" aria-hidden="true"></mdicon>
                  <mdicon v-else name="alert-circle-outline" size="80" class="text-red-400/75" aria-hidden="true"></mdicon>
                </div>
                <div class="sm:ml-4 text-left">
                  <DialogTitle as="h3" class="text-base leading-6 text-gray-700">{{ title }}</DialogTitle>
                  <div class="mt-2">
                    <p class="text-md font-semibold text-gray-800">
                      {{ description }}
                    </p>
                  </div>
                  <div class="mt-2">
                    <p class="text-sm font-semibold text-gray-800">
                      {{ message }}
                    </p>
                  </div>
                </div>
              </div>
              <div v-if="state !== 'progress'" class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  class="mt-3 inline-flex w-full items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  @click="onConfirm"
                >
                  {{ confirmText }}
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import { ref } from 'vue';
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

  const open = ref(false);
  const title = ref('Operation');
  const description = ref('');
  const state = ref('progress');
  const message = ref('');
  const confirmText = ref('OK');

  const emit = defineEmits(['confirm']);

  function show(options) {
    state.value = 'progress';
    title.value = options?.title || '';
    description.value = options?.description || '';
    message.value = options?.message || '';
    open.value = true;
  }

  function hide() {
    open.value = false;
  }

  function showError(options) {
    message.value = options?.message || '';
    state.value = 'error';
  }

  function showSuccess(options) {
    message.value = options?.message || '';
    state.value = 'success';
  }

  function onConfirm() {
    emit('confirm');
    open.value = false;
  }

  defineExpose({
    show,
    hide,
    showError,
    showSuccess,
  });
</script>
