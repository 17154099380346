/*
 * Store for Agents KPI Scores data
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useAgentsKPIScoresStore = defineStore('AgentsKPIScoresStore', () => {
  // Agents KPI Scores data
  const agentsData = ref([]);
  const KPIColumns = ref([]);
  const KPIStat = ref('avg');
  const sortField = ref('allKpisStats.avg');
  const sortOrder = ref(-1);
  const colorScaleSumMax = ref(100);
  const analyzedTimeInterval = ref(null);

  return {
    agentsData,
    KPIColumns,
    KPIStat,
    sortField,
    sortOrder,
    colorScaleSumMax,
    analyzedTimeInterval,
  };
});
