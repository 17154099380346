<template>
  <div class="">
    <label class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
    <div class="text-xs text-gray-500" v-if="description">{{ description }}</div>
    <div v-for="(item, idx) in arrayValue" :key="idx" class="relative grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-6 md:col-span-2 pb-2 border rounded-lg my-2 p-2">
      <StdPropEditor
        v-for="prop in itemProperties(item)"
        :key="prop.name"
        :prop-def="prop"
        :class="[getPropLayoutClasses(prop)]"
        :model-value="item[prop.name]"
        @update:modelValue="
          (newValue) => {
            onUpdateProp(prop, idx, newValue);
          }
        "
      />
      <button
        type="button"
        class="absolute top-1 right-1 border border-gray-400 rounded-full bg-white p-1 text-gray-600 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="onDeleteItem(idx)"
      >
        <MinusIcon class="h-3 w-3" aria-hidden="true" />
      </button>

      <!--<button
        type="button"
        @click="onDeleteItem(idx)"
        class="absolute top-2 right-0 rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Delete
      </button>-->
    </div>
    <div class="flex justify-end mt-2">
      <button
        type="button"
        class="rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="onAddItem"
      >
        <PlusIcon class="h-4 w-4" aria-hidden="true" />
      </button>
      <!--<button
        type="button"
        @click="onAddItem"
        class="rounded bg-indigo-600 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        Add
      </button>-->
    </div>
  </div>
</template>

<script setup>
  import { computed, watch, reactive } from 'vue';
  import StdPropEditor from '@/components/Editors/StdPropEditor.vue';
  import { getActionDef } from '@/spec/ActionDefs.js';
  import { PlusIcon, MinusIcon } from '@heroicons/vue/20/solid';

  const props = defineProps({
    modelValue: {
      type: Array,
      default() {
        return [];
      },
    },
    propertiesSpec: {
      type: Object,
      default() {
        return {};
      },
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits(['update:modelValue']);

  const entryProperties = computed(() => props.propertiesSpec);

  let arrayValue = props.modelValue ? reactive(JSON.parse(JSON.stringify(props.modelValue))) : [];

  watch(
    () => props.modelValue,
    (newValue) => {
      arrayValue = reactive(JSON.parse(JSON.stringify(newValue)));
    },
  );

  function itemProperties(item) {
    return props.propertiesSpec
      .map((x) => {
        return Object.assign({}, x, { show: 'when' in x ? x.when(item) : true });
      })
      .filter((y) => y.show);
  }

  //watch(props.modelValue, (newValue) => {
  //  console.log(`Array editor: modelValue updated`, newValue);
  //  arrayValue = reactive(JSON.parse(JSON.stringify(newValue)));
  //});

  function onUpdateProp(prop, idx, newValue) {
    //console.log(`onUpdateProp: ${prop.name} ${idx} ${newValue}`);
    arrayValue[idx][prop.name] = newValue;
    emit('update:modelValue', arrayValue);
  }

  function onAddItem() {
    const newItem = {};
    entryProperties.value.map((x) => {
      newItem[x.name] = x.default;
    });
    if (Array.isArray(arrayValue)) {
      arrayValue.push(newItem);
    }
    emit('update:modelValue', arrayValue);
  }

  function onDeleteItem(idx) {
    arrayValue.splice(idx, 1);
    emit('update:modelValue', arrayValue);
  }

  // TODO make common
  const propLayoutClassesByType = {
    text: 'col-span-full',
    boolean: 'col-span-full',
    number: 'sm:col-span-full md:col-span-full lg:col-span-3 xl:col-span-2',
    string: 'sm:col-span-full md:col-span-full lg:col-span-3 xl:col-span-2',
    select: 'sm:col-span-full md:col-span-full lg:col-span-3 xl:col-span-2',
    stringLong: 'col-span-full',
    audioFile: 'col-span-full',
    transcriptsCollection: 'col-span-full',
    recordingsCollection: 'col-span-full',
    kpiCollection: 'col-span-full',
    temperature: 'col-span-full',
  };

  function getPropLayoutClasses(prop) {
    return propLayoutClassesByType[prop?.type] || '';
  }

  const value = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });
</script>
