<template>
  <div>
    <RadioGroup v-model="selectedValue">
      <RadioGroupLabel class="text-base font-semibold leading-6 text-gray-900">Media</RadioGroupLabel>

      <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4">
        <RadioGroupOption as="template" v-for="(setting, settingIdx) in settings" :key="setting.name" :value="setting.name" v-slot="{ disabled, checked, active }" :disabled="setting.disabled">
          <div
            :class="[
              checked ? 'border-transparent' : 'border-gray-300',
              active ? 'border-indigo-600 ring-2 ring-indigo-600' : '',
              'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
            ]"
          >
            <span class="flex flex-1">
              <span class="flex flex-col">
                <RadioGroupLabel as="span" :class="[disabled ? 'text-gray-400' : checked ? 'text-indigo-800' : 'text-gray-900', 'block text-sm font-medium']">{{ setting.title }}</RadioGroupLabel>
                <RadioGroupDescription as="span" class="mt-1 flex items-center text-sm text-gray-500">{{ setting.description }}</RadioGroupDescription>
                <!--<RadioGroupDescription as="span" class="mt-6 text-sm font-medium text-gray-900">{{ mailingList.users }}</RadioGroupDescription>-->
              </span>
            </span>
            <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']" aria-hidden="true" />
            <span :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']" aria-hidden="true" />
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>

    <!--<RadioGroup v-model="selectedValue">
      <RadioGroupLabel class="sr-only">Privacy setting</RadioGroupLabel>
      <div class="-space-y-px rounded-md bg-white">
        <RadioGroupOption as="template" v-for="(setting, settingIdx) in settings" :key="setting.name" :value="setting.name" v-slot="{ disabled, checked, active }" :disabled="setting.disabled">
          <div
            :class="[
              settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
              settingIdx === settings.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
              checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200',
              'relative flex cursor-pointer border p-4 focus:outline-none',
            ]"
          >
            <span
              :class="[
                checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                active ? 'ring-2 ring-offset-2 ring-indigo-600' : '',
                'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center',
              ]"
              aria-hidden="true"
            >
              <span class="rounded-full bg-white w-1.5 h-1.5" />
            </span>
            <span class="ml-3 flex flex-col">
              <RadioGroupLabel as="span" :class="[disabled ? 'text-gray-400' : checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium']">{{ setting.title }}</RadioGroupLabel>
              <RadioGroupDescription as="span" :class="[disabled ? 'text-gray-400' : checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm']">{{ setting.description }}</RadioGroupDescription>
            </span>
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>-->
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';
  import { CheckCircleIcon } from '@heroicons/vue/20/solid';

  const settings = [
    { title: 'Transcript', name: 'transcript', description: 'Process conversation transcript and analyze', disabled: false },
    { title: 'Recording', name: 'recording', description: 'Transcribe recorded audio and analyze', disabled: false },
    { title: 'Voice', name: 'voice', description: 'Make a phone call, carry a conversation processing audio in real time and analyze', disabled: false },
  ];

  const props = defineProps(['modelValue']);
  const emit = defineEmits(['update:modelValue']);

  const selectedValue = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });

  //const selected = ref(settings[1]);
  //value = selected.value.name;
</script>
