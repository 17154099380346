<template>
  <div>
    <Dialog v-model:visible="visible" modal :closable="false" header="Upload Document" :style="{ width: '60vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div v-if="!uploaded">
        <small>You can upload Text or PDF files</small>
        <FileUpload
          name="uploader"
          customUpload
          @uploader="onFileUpload($event)"
          :multiple="false"
          accept="text/*,application/pdf"
          :maxFileSize="10000000"
          :pt="{
            thumbnail: { src: '/img/icon-file.svg' },
          }"
        >
          <template #empty>
            <p>Drag and drop file here to upload.</p>
          </template>
        </FileUpload>
      </div>
      <div v-if="uploaded" class="flex flex-col gap-2">
        <label for="docname">Name</label>
        <small id="docname-help">Enter the name for the new Document. Name must be unique in Knowledge Base.</small>
        <InputText id="docname" class="" v-model="kbDocumentName" aria-describedby="docname-help" />
        <div class="mt-2 flex flex-col gap-1">
          <label for="doctitle">Title</label>
          <small id="doctitle-help">Title for the new Document</small>
          <InputText id="doctitle" class="" v-model="kbDocumentTitle" aria-describedby="doctitle-help" />
        </div>
        <div class="mt-2 flex gap-2 items-center font-source text-sm">
          <div>
            Type: <strong>{{ kbDocumentType }}</strong>
          </div>
          <div>
            Size: <strong>{{ formatBytes(kbDocumentSize) }}</strong>
          </div>
        </div>
      </div>
      <div v-if="contentReady" class="mt-4">
        <div class="my-2">Preview</div>
        <TuiViewer :initial-value="kbDocumentContent" class="h-[200px] overflow-auto border px-2 py-1 rounded bg-slate-50" />
      </div>
      <div class="mt-4">
        <small class="min-h-6 block">{{ progressText }} </small>
        <ProgressBar :mode="progressMode" style="height: 6px"></ProgressBar>
      </div>
      <div v-if="errorMessage" class="w-full my-4">
        <Message :closable="false" severity="error">{{ errorMessage }}</Message>
      </div>
      <template #footer>
        <Button label="Cancel" :disabled="showProgress" severity="secondary" @click="visible = false" autofocus />
        <Button label="Create" :disabled="!contentReady || showProgress" severity="primary" @click="createDocument" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
  import { useRbacStore } from '@/store/RBACStore';
  import { useKbStore } from '@/store/KbStore';
  import { useApi } from '@/hooks/useApi';
  import { useToast } from 'vue-toastification';
  import { ref, nextTick, computed } from 'vue';
  import InputText from 'primevue/inputtext';
  import Dialog from 'primevue/dialog';
  import Button from 'primevue/button';
  import ProgressBar from 'primevue/progressbar';
  import FileUpload from 'primevue/fileupload';
  import Message from 'primevue/message';
  import { formatBytes } from '@/utils/formatBytes';
  import TuiViewer from '@/components/Editors/TuiViewer.vue';

  const kbStore = useKbStore();
  const api = useApi();

  const toast = useToast();

  const visible = ref(false);
  const uploaded = ref(false);
  const showProgress = ref(false);
  const progressMode = computed(() => (showProgress.value ? 'indeterminate' : ''));
  const progressText = ref('');
  const contentReady = ref(false);
  const errorMessage = ref(null);

  const kbDocumentName = ref('');
  const kbDocumentTitle = ref('');
  const kbDocumentType = ref('');
  const kbDocumentSize = ref(0);
  let kbDocumentDataBase64 = null;
  const kbDocumentContent = ref('');

  const emit = defineEmits(['documentCreated']);

  function start() {
    kbDocumentName.value = '';
    kbDocumentType.value = '';
    kbDocumentSize.value = 0;
    kbDocumentDataBase64 = null;
    kbDocumentContent.value = '';
    uploaded.value = false;
    contentReady.value = false;
    progressText.value = '';
    showProgress.value = false;
    errorMessage.value = null;
    visible.value = true;
  }

  const pause = (x) => new Promise((res) => setTimeout(res, x));

  async function fileToBase64DataUrl(file) {
    return await new Promise((resolve, reject) => {
      const reader = Object.assign(new FileReader(), {
        onload: () => {
          let rr = reader.result;
          const idx = rr.indexOf('base64,');
          if (idx === -1) {
            resolve(null);
          }
          rr = rr.substring(idx + 7);
          resolve(rr);
        },
        onerror: () => {
          console.log(`Error converting to base64`, reader.error);
          resolve(null);
        },
      });
      reader.readAsDataURL(file);
    });
  }

  async function onFileUpload(event) {
    const file = event.files[0];
    kbDocumentName.value = file.name;
    kbDocumentType.value = file.type;
    kbDocumentSize.value = file.size;
    //const fab = await file.arrayBuffer();
    kbDocumentDataBase64 = await fileToBase64DataUrl(file);
    console.log(`File uploaded`, event);
    uploaded.value = true;
    if (!kbDocumentDataBase64) {
      errorMessage.value = `Error reading document`;
      return;
    }
    switch (kbDocumentType.value) {
      case 'text/plain': {
        kbDocumentContent.value = await file.text();
        contentReady.value = true;
        break;
      }
      case 'application/pdf': {
        await convertDocumentContent();
        break;
      }
    }
  }

  async function convertDocumentContent() {
    progressText.value = 'Converting document (this may take a minute) ...';
    showProgress.value = true;

    const convResult = await api.convertKbDocumentContent({
      kbId: kbStore.kbId,
      documentContent: kbDocumentDataBase64,
      documentMimeType: kbDocumentType.value,
    });

    showProgress.value = false;
    if (!convResult.success) {
      errorMessage.value = `Error converting document: ${convResult.error}`;
      toast.error(errorMessage.value);
      return;
    }

    kbDocumentContent.value = convResult.data;
    contentReady.value = true;
  }

  async function createDocument() {
    progressText.value = 'Creating document ...';
    showProgress.value = true;
    const meta = {};
    if (kbDocumentTitle.value && kbDocumentTitle.value.length > 0) {
      meta.title = kbDocumentTitle.value;
    }
    const createRes = await kbStore.createKbDocument(kbDocumentName.value, kbDocumentContent.value, kbDocumentDataBase64, kbDocumentType.value, meta);
    progressText.value = '';
    showProgress.value = false;
    visible.value = false;
    if (!createRes.success) {
      toast.error(`Error creating document: ${createRes.error}`);
      return;
    }
    await nextTick();
    emit('documentCreated', createRes.data);
  }

  defineExpose({ start });
</script>
