<template>
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-center space-x-5 w-full">
        <div class="flex-shrink-0">
          <div class="relative bg-purple-600/70 rounded-xl p-1">
            <mdicon name="text-search" size="62" class="text-purple-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="w-full">
          <div class="w-full">
            <div class="relative">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                v-model="searchQuery"
                class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-purple-500/50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:text-md sm:leading-6"
                placeholder="Enter Query to Search Conversations"
                type="search"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <ProgressButton ref="searchProgressButton" title="Search" icon="text-search" @click="loadData"></ProgressButton>
        <!--<button
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="loadData"
        >
          <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
          Export
        </button>-->
      </div>
    </div>

    <div class="mt-1 w-full bg-gray-100/50 rounded-md p-1 flex justify-between font-source text-sm">
      <div class="my-1 ml-2 flex">
        <div>Search for conversations that took place:</div>
        <TimeRangeSelector :inline="true" class="ml-2 font-semibold"></TimeRangeSelector>
      </div>
      <div class="font-semibold my-1 ml-2 mr-2">{{ totalRecords }} total</div>
    </div>

    <div class="mt-1 w-full bg-gray-100/50 rounded-xl p-2">
      <div class="flex text-gray-400 font-source h-[180px] w-full">
        <DbDygraphsBar class="relative" :data="chartData" :options="chartOptions" @db-event="handleDbEvent"></DbDygraphsBar>
      </div>
    </div>

    <div class="mt-6">
      <DataTable
        :value="conversations"
        v-model:expanded-rows="expandedRows"
        lazy
        paginator
        :rows="10"
        :first="first"
        dataKey="id"
        :totalRecords="totalRecords"
        :loading="loading"
        sortField="datetime"
        :sortOrder="-1"
        @page="onPage($event)"
        @sort="onSort($event)"
        class="p-datatable-sm"
        scrollable
        tableStyle="min-width: 50rem"
      >
        <Column expander style="width: 1rem" />
        <!--<Column field="id" header="Conversation" sortable>
          <template #body="slotProps">
            <router-link :to="{ path: `/insights/conversation/${slotProps.data.id}` }">
              <div class="flex items-center">
                <div class="cursor-pointer hover:underline">{{ slotProps.data?.id }}</div>
                <mdicon name="open-in-new" size="18" class="ml-1 text-slate-300"></mdicon>
              </div>
            </router-link>
          </template>
        </Column>-->
        <Column field="id" header="Conversation" style="width: 20%">
          <template #body="slotProps">
            <router-link :to="{ path: `/insights/conversation/${slotProps.data.id}` }">
              <div class="flex items-center">
                <div class="cursor-pointer hover:underline font-source font-semibold">
                  <!--<mdicon name="open-in-new" size="18" class="mt-1 text-slate-300"></mdicon>-->
                  {{ getConversationTitle(slotProps.data) }} ...
                </div>
              </div>
            </router-link>
          </template>
        </Column>
        <Column field="AgentName" header="Agent" sortable>
          <template #body="slotProps">
            <router-link :to="{ path: `/agent/details/home`, query: { agentId: slotProps.data.AgentId } }">
              <div class="flex items-center">
                <div class="cursor-pointer font-medium hover:underline">{{ slotProps.data?.AgentName || slotProps.data?.AgentId || '-' }}</div>
                <mdicon name="open-in-new" size="18" class="ml-1 text-slate-300"></mdicon>
              </div>
            </router-link>
          </template>
        </Column>
        <Column field="score" header="Score" sortable>
          <template #body="slotProps">
            <span :class="['px-2 py-1.5 font-source text-md rounded-md', getScoreClass(slotProps.data)]">
              {{ slotProps.data?.score }}
            </span>
          </template>
        </Column>
        <Column field="CustomerSentiment" header="Sentiment" sortable>
          <template #body="slotProps">
            <div class="flex items-center font-source">
              <mdicon :name="getSentimentIcon(slotProps.data)" :class="['mr-2 p-1 rounded-md', getSentimentIconClass(slotProps.data)]" size="20"></mdicon>
              {{ slotProps.data.CustomerSentiment }}
            </div>
          </template>
        </Column>
        <Column field="ProblemsSeverity" header="Problems Severity" sortable>
          <template #body="slotProps">
            <span :class="['px-2 py-1.5 font-source text-md rounded-md', getProblemSeverityClass(slotProps.data)]">
              {{ slotProps.data?.ProblemsSeverity }}
            </span>
          </template>
        </Column>
        <Column field="KPIScore" header="KPI Score" sortable>
          <template #body="slotProps">
            <span :class="['px-2 py-1.5 font-source text-md rounded-md', getKPIScoreClass(slotProps.data)]">
              {{ slotProps.data?.KPIScore }}
            </span>
          </template>
        </Column>
        <Column field="duration" header="Duration" sortable>
          <template #body="slotProps">
            {{ formatSeconds(slotProps.data?.duration || 0) }}
          </template>
        </Column>
        <Column field="datetime" header="Date" sortable>
          <template #body="slotProps">
            <div class="">{{ dayjs(slotProps.data?.datetime).format('MM/DD/YY HH:mm:ss') }}</div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="ml-2 p-2 font-source text-md text-gray-500">
            {{ slotProps.data?.context?.analysis?.summary }}
          </div>
        </template>
      </DataTable>
    </div>
  </div>
</template>
<script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { ArrowDownCircleIcon } from '@heroicons/vue/20/solid';
  import { useConversationSearchStore } from '@/store/ConversationSearchStore';
  import { useDateRangeStore } from '@/store/DaterangeStore';
  import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import dayjs from 'dayjs';
  import DbDygraphsBar from '@/components/Charts/DbDygraphsBar.vue';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';

  const route = useRoute();
  const conversationSearchStore = useConversationSearchStore();
  const dateRangeStore = useDateRangeStore();
  const q = decodeURIComponent(route?.query?.query || '');
  const searchQuery = ref(q);

  const searchProgressButton = ref(null);

  // Search Results Table
  //const dt = ref();
  const loading = ref(false);
  const totalRecords = computed(() => conversationSearchStore.conversationsTotal);
  const first = computed(() => conversationSearchStore.searchOffset);
  const conversations = computed(() => conversationSearchStore.conversations);

  const expandedRows = ref([]);

  const chartData = computed(() => conversationSearchStore.timeline);
  const chartOptions = {
    stackedGraph: true,
    //title: 'Conversations',
    //ylabel: 'Count',
    labels: ['Date', 'Count'],
    //legend: 'follow',
  };

  const onPage = async (event) => {
    console.log(`onPage: ${JSON.stringify(event)}`);
    if ('first' in event && 'rows' in event) {
      loading.value = true;
      await conversationSearchStore.page(event.first, event.rows);
      loading.value = false;
    }
  };

  const onSort = async (event) => {
    console.log(`onSort: ${JSON.stringify(event)}`);
    // [{field:'name',type:'asc|desc'},...]
    if ('sortField' in event && 'sortOrder' in event) {
      const sortFields = [
        {
          field: event.sortField,
          type: event.sortOrder === 1 ? 'asc' : 'desc',
        },
      ];
      loading.value = true;
      await conversationSearchStore.sort(sortFields);
      loading.value = false;
    }
  };

  async function loadData() {
    loading.value = true;
    await conversationSearchStore.search(searchQuery.value, 10, 0, [{ field: 'datetime', type: 'desc' }]);
    loading.value = false;
    searchProgressButton.value.stopProgress();
  }

  /*
  const rowClass = (data) => {
    //return [{ 'bg-slate-400': true }];
    return ['rounded-xl'];
  };
  const rowStyle = (data) => {
    return {}; //{ 'background-color': '#f8fafc' };
  };
  */

  function getSentimentIcon(data) {
    const s = data?.CustomerSentiment || 0;
    return s < 0 ? 'emoticon-angry-outline' : s > 0 ? 'emoticon-happy-outline' : 'emoticon-neutral-outline';
  }
  function getSentimentIconClass(data) {
    const s = data?.CustomerSentiment || 0;
    return s < 0 ? 'bg-red-500/70 text-white' : s > 0 ? 'bg-green-500/70 text-white' : 'bg-gray-200/60 text-gray-400';
  }

  function getProblemSeverityClass(data) {
    let s = data?.ProblemsSeverity || 0;
    return s < 25 ? `bg-red-400/10` : s < 50 ? `bg-red-400/25` : `bg-red-400/50`;
  }

  function getScoreClass(data) {
    let s = data?.score || 0;
    return s < 0 ? `bg-red-400/10` : `bg-green-400/30`;
  }

  function getKPIScoreClass(data) {
    let s = data?.KPIScore || 0;
    return s < 20 ? `bg-red-400/50` : s < 30 ? `bg-red-400/25` : `bg-green-400/30`;
  }

  function handleDbEvent(event) {
    switch (event.type) {
      case 'zoom': {
        console.log(`Handle Zoom`, event);
        let from = event?.minDate || null;
        let to = event?.maxDate || null;
        if (from && to) {
          event.g.updateOptions({
            dateWindow: null,
            valueRange: null,
          });
          dateRangeStore.setDateRange(from, to);
          loadData();
        }
        break;
      }
    }
  }

  // Get a title for the conversation, as a first customer message
  function getConversationTitle(conv) {
    const messages = conv?.context?.transcript?.data || [];
    if (!Array.isArray(messages) || messages.length <= 0) {
      return conv.id;
    }
    const customerMessage = messages.find((x) => x.actor === 'customer');
    if (!customerMessage || !customerMessage?.message || customerMessage.message === '') {
      return messages[0]?.message || conv.id;
    }
    return customerMessage.message.substring(0, 100);
  }

  function formatSeconds(sec) {
    const diff = sec;
    const totalMinutes = Math.floor(diff / 60);
    const seconds = diff % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0
      ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      : `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  onMounted(() => {
    searchProgressButton.value.startProgress();
    loadData();
  });
</script>
