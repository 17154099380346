// Sample of configuring perspective views // https://bl.ocks.org/texodus/803de90736a3641ad91c5c7a1b49d0a7
<template>
  <perspective-table-view tablename="movies" />
</template>
<script lang="js">
  import PerspectiveTableView from '@/components/PerspectiveTableView.vue';
  export default {
    components: {PerspectiveTableView},
  };
</script>
