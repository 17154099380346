<template>
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5 pb-2">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-emerald-600/70 rounded-xl p-1">
            <mdicon name="finance" size="62" class="text-emerald-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="mb-1 text-2xl font-bold text-gray-900">Usage</h1>
          <div class="my-1 flex items-center">
            <div>Usage report for time period:</div>
            <TimeRangeSelector :inline="true" class="ml-2 font-semibold"></TimeRangeSelector>
          </div>
        </div>
      </div>
      <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <ProgressButton ref="analyzeProgressButton" title="Refresh" icon="motion-play-outline" @click="loadData"></ProgressButton>
      </div>
      <!--<div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <button
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="onExport"
        >
          <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
          Report
        </button>
      </div>-->
    </div>

    <div class="relative isolate overflow-hidden">
      <div class="">
        <dl class="mt-1 grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-4 relative">
          <div v-for="stat in stats" :key="stat.name" :class="['bg-slate-50 relative overflow-hidden rounded-xl my-2 px-4 pt-4 pb-2 min-h-[120px]', stat?.class || '']" @click="onStatClick(stat)">
            <div class="z-20 relative">
              <dt class="truncate text-sm font-medium text-gray-500">{{ stat.name }}</dt>
              <dd class="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{{ stat.value }}</dd>
              <dd class="mt-2 text-sm text-gray-400 font-medium">{{ stat?.description || '' }}</dd>
              <dd v-if="stat?.extraDescription" class="mt-2 text-xs text-gray-400 font-medium">{{ stat?.extraDescription || '' }}</dd>
            </div>
            <db-easy-pie
              v-if="stat.gauge"
              class="z-10 absolute right-1 top-1 h-28 w-28 rounded-full text-lg font-semibold text-slate-500"
              :value="stat.value"
              :max="stat.max"
              :line-width="8"
              :animated="true"
              :percent-ranges="stat?.percentRanges || []"
              :show-percents="stat.percents || false"
            ></db-easy-pie>
            <mdicon v-else-if="stat.icon" :name="stat.icon" size="105" :class="['z-10 absolute right-1 top-1 rounded-full', stat.iconClass || '']" />
          </div>
        </dl>
      </div>
    </div>

    <div class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-emerald-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Voice of Customer Words analyzed</h2>
      </div>
      <DbUplot class="mt-4 w-full h-[300px]" :data="usageStore.vocWordsData" :options="uPlotOptions" @db-event="handleDbEvent"></DbUplot>
    </div>

    <div class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-emerald-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">QA Words analyzed</h2>
      </div>
      <DbUplot class="mt-4 w-full h-[300px]" :data="usageStore.qaWordsData" :options="uPlotOptions" @db-event="handleDbEvent"></DbUplot>
    </div>
  </div>
</template>

<script setup>
  import { onMounted, ref, reactive, computed } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useUsageStore } from '@/store/UsageStore';
  import { ArrowDownCircleIcon, HashtagIcon } from '@heroicons/vue/20/solid';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';
  import dayjs from 'dayjs';
  import DbEasyPie from '@/components/Charts/DbEasyPie.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';
  import { useDateRangeStore } from '@/store/DaterangeStore';

  import uPlot from 'uplot';
  import DbUplot from '@/components/Charts/DbUplot.vue';

  const route = useRoute();
  const router = useRouter();
  const layout = useLayoutStore();
  const usageStore = useUsageStore();
  const dateRangeStore = useDateRangeStore();

  const loading = ref(false);
  const analyzeProgressButton = ref(null);

  const stats = computed(() => [
    {
      id: 'time',
      name: 'Time period',
      value: dateRangeStore.title,
      icon: 'clock-outline',
      //description: `Duration: ${formatSeconds(props.conversation?.duration || 0)}`,
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
    },
    {
      id: 'voc',
      name: 'VoC Words',
      value: usageStore.vocWordsTotal,
      description: 'VoC Total Words Analyzed',
      icon: 'text-box-outline',
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
      //class: 'cursor-pointer hover:bg-slate-100 ring-2 ring-violet-200/50 hover:ring-violet-700/50',
    },
    {
      id: 'qa',
      name: 'QA Words',
      value: usageStore.qaWordsTotal,
      description: 'QA Total Words Analyzed',
      icon: 'text-box-outline',
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
      //class: 'cursor-pointer hover:bg-slate-100 ring-2 ring-violet-200/50 hover:ring-violet-700/50',
    },
    /*
  {
    name: 'Agent',
    value: 'None',
    description: `None`,
    icon: 'face-agent',
    valueClass: 'text-xs',
    iconClass: 'text-gray-200/60',
  },*/
    /*
    {
      id: 'csat',
      name: 'CSat',
      value: parseFloat(csatPctAvg.value.toFixed(2)),
      max: 100,
      description: 'Average CSat %',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 50, color: '#fde047' },
        { value: 75, color: '#bef264' },
      ],
    },
    {
      id: 'csatMin',
      name: 'Min CSat',
      value: parseFloat(csatPctMin.value.toFixed(2)),
      max: 100,
      description: 'Smallest CSat %',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 50, color: '#fde047' },
        { value: 75, color: '#bef264' },
      ],
    },*/
  ]);

  function onStatClick(stat) {
    console.log(`On stat click:`, stat);
    switch (stat.id) {
      case 'conv': {
        // move to conversations
        router.push('/csat/search');
        break;
      }
    }
  }

  const uPlotOptions = {
    // This switches timezone to UTC on chart
    tzDate: (ts) => uPlot.tzDate(new Date(ts * 1e3), 'Etc/UTC'),
    legend: {
      show: true,
    },
    scales: {
      x: { time: true },
    },
    series: [
      {},
      {
        label: 'Words',
        paths: uPlot.paths.bars({ align: 0 }),
        stroke: '#4e79a7',
        fill: 'rgba(78, 121, 167,0.1)',
      },
    ],
    axes: [
      {},
      {
        size: 70,
        values: (u, vals, space) => vals.map((v) => (v / 1000).toFixed(1) + 'K'),
      },
    ],
  };

  async function loadData() {
    analyzeProgressButton.value.startProgress();
    loading.value = true;
    await usageStore.getUsage();
    loading.value = false;
    analyzeProgressButton.value.stopProgress();
  }

  function handleDbEvent(event) {
    switch (event.type) {
      case 'zoom': {
        console.log(`Handle Zoom`, event);
        let from = event?.minDate || null;
        let to = event?.maxDate || null;
        if (from && to) {
          dateRangeStore.setDateRange(from, to);
          loadData();
        }
        break;
      }
    }
  }

  onMounted(() => {
    loadData();
  });
</script>
