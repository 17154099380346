<template>
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-orange-600/70 rounded-xl p-1">
            <mdicon name="face-agent" size="62" class="text-orange-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-2xl font-bold text-gray-900">Agents CSat Scores</h1>
          <div class="my-1 flex items-center">
            <div>Analyzing Agents CSat Scores for the time period:</div>
            <TimeRangeSelector :inline="true" class="ml-2 font-semibold"></TimeRangeSelector>
          </div>
        </div>
      </div>

      <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <ProgressButton ref="analyzeProgressButton" title="Analyze" icon="motion-play-outline" @click="loadData(true)"></ProgressButton>
      </div>
    </div>

    <!--<div class="mt-6 py-2 px-2 leading-7 text-gray-600 mb-4 flex items-center justify-between border-b border-gray-300">
      <div class="text-md font-semibold flex items-center">
        <mdicon name="face-agent" size="18" class="rounded bg-orange-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <div>All Agents</div>
      </div>
    </div>-->
    <div class="mt-4">
      <BlockUI :blocked="loading">
        <DataTable :value="agents" :sortField="tableSortField" :sortOrder="tableSortOrder" removableSort @sort="onTableSort" tableStyle="min-width: 50rem" size="small">
          <ColumnGroup type="header">
            <Row>
              <Column header="Agent" field="agentName" :rowspan="2" sortable />
              <Column header="Conversations" field="count" :rowspan="2" sortable />
              <Column header="CSat Scores" :colspan="10" />
              <Column header="CSat" field="CSat" :rowspan="2" sortable />
            </Row>
            <Row>
              <Column v-for="sc in scoresColumns" :field="`scores.${sc.key}`" :header="sc.key" sortable />
            </Row>
          </ColumnGroup>
          <Column field="agentName" header="Agent" sortable class="" style="width: 20%" :pt="{ bodyCell: { class: 'pl-0 py-0' } }">
            <template #body="slotProps">
              <div class="flex items-center px-1 py-1">
                <mdicon name="face-agent" size="18" class="p-1 mr-2 text-slate-50 bg-slate-400/50 rounded-md" aria-hidden="true"></mdicon>
                <router-link :to="{ path: '/csat/search', query: { filter: getFilterQueryConversations(slotProps.data) } }" class="flex items-center hover:underline">
                  <div class="cursor-pointer font-medium">{{ slotProps.data?.agentName || '-' }}</div>
                </router-link>
              </div>
            </template>
          </Column>
          <!--<Column field="agentId" header="AgentID" sortable class="">
          <template #body="slotProps">
            <div class="font-medium">{{ slotProps.data?.agentId || '-' }}</div>
          </template>
        </Column>-->
          <Column field="count" header="Conversations" sortable style="width: 10%" :pt="{ bodyCell: { class: 'py-0' } }">
            <template #body="slotProps">
              <router-link :to="{ path: '/csat/search', query: { filter: getFilterQueryConversations(slotProps.data) } }" class="px-2 py-1 flex items-center hover:underline">
                <div class="cursor-pointer font-source font-medium">{{ slotProps.data.conversationsCount }}</div>
                <mdicon name="open-in-new" size="20" class="ml-2 text-gray-300"></mdicon>
              </router-link>
            </template>
          </Column>
          <Column v-for="sc in scoresColumns" :field="`scores.${sc.key}`" :header="sc.key" sortable :pt="{ bodyCell: { class: 'py-0' } }">
            <template #body="slotProps">
              <router-link :to="{ path: '/csat/search', query: { filter: getFilterQueryConversationsWithScore(slotProps.data, sc.key) } }" class="flex items-center hover:underline">
                <div :class="['w-fit cursor-pointer font-source px-2 py-1', slotProps.data.scores[sc.key] > 0 ? 'font-medium' : 'text-gray-400']" :style="getValueStyle(parseInt(sc.key), slotProps.data.scores[sc.key])">
                  {{ slotProps.data.scores[sc.key] }}
                </div>
              </router-link>
            </template>
          </Column>
          <Column field="CSat" header="CSat" sortable :pt="{ bodyCell: { class: 'py-0' } }">
            <template #body="slotProps">
              <div class="px-0 py-1 font-bold font-source">{{ (slotProps.data?.CSat || 0).toFixed(2) }}</div>
            </template>
          </Column>
        </DataTable>
      </BlockUI>
      <ProgressSpinner v-if="loading" class="fixed top-1/2 left-1/2"></ProgressSpinner>
    </div>
  </div>
</template>
<script setup>
  // TODO consider switching table to lazy loading to speed up initial rendering
  import { ref, computed, watch, onMounted, nextTick } from 'vue';
  import { useAnalyticsStore } from '@/store/AnalyticsStore';
  import { useDateRangeStore } from '@/store/DaterangeStore';
  import { useFiltersStore } from '@/store/FiltersStore';
  import { AnalyticsQuery } from '@/analytics/AnalyticsQuery';
  import { useAgentsCSatScoresStore } from '@/store/AgentsCSatScoresStore';
  import { getStorePropGetSet } from '@/utils/getStorePropGetSet';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';
  import { pathOr } from 'ramda';
  import Column from 'primevue/column';
  import DataTable from 'primevue/datatable';
  import ColumnGroup from 'primevue/columngroup';
  import Row from 'primevue/row';
  import BlockUI from 'primevue/blockui';
  import ProgressSpinner from 'primevue/progressspinner';
  import * as d3Color from 'd3-color';
  import * as d3 from 'd3';

  const agentsCSatScoresStore = useAgentsCSatScoresStore();

  const analyticsStore = useAnalyticsStore();
  const dateRangeStore = useDateRangeStore();
  const filtersStore = useFiltersStore();

  const analyzeProgressButton = ref(null);

  const loading = ref(false);

  const conversationsCount = ref(0);
  const agentsCount = ref(0);
  const agentsById = ref({}); // hash by agentId with value index in agents array

  const agents = ref([]);
  const tableSortField = computed(getStorePropGetSet(agentsCSatScoresStore, 'sortField'));
  const tableSortOrder = computed(getStorePropGetSet(agentsCSatScoresStore, 'sortOrder'));

  const scoresColumns = [{ key: 10 }, { key: 9 }, { key: 8 }, { key: 7 }, { key: 6 }, { key: 5 }, { key: 4 }, { key: 3 }, { key: 2 }, { key: 1 }];
  const colorScale = d3.scaleSequential(d3.interpolateRdYlGn).domain([0, 10]);

  watch(
    () => dateRangeStore.timeIntervalChanged,
    (newValue) => {
      console.log(`Date range changed: ${JSON.stringify(newValue)}`);
      loadData();
    },
  );

  async function queryAgentScores() {
    const q = new AnalyticsQuery();

    const queryTimeInterval = dateRangeStore.timeInterval;
    q.timeInterval.from = queryTimeInterval?.from || 'StartOfDay';
    q.timeInterval.to = queryTimeInterval?.to || 'now';
    q.timeInterval.interval = dateRangeStore.timeInterval?.interval || '1D'; // '1D'

    agentsCSatScoresStore.analyzedTimeInterval = JSON.parse(JSON.stringify(q.timeInterval));

    q.groupings = [
      {
        name: 'agent',
        title: 'Agent',
        type: 'terms',
        field: 'AgentId',
        size: 250,
        groupings: [
          { name: 'CSat', type: 'terms', size: 15, field: 'CSat' },
          { name: 'AgentName', type: 'terms', size: 1, field: 'AgentName' },
        ],
      },
    ];

    q.request = AnalyticsQuery.prepareQuery({
      timeInterval: q.timeInterval,
      groupings: q.groupings,
      indexPrefix: 'conv',
      timestampField: 'datetime',
    });

    await analyticsStore.executeAnalyticsQuery(q);
    processQueryResult(q);
  }

  function processQueryResult(q) {
    conversationsCount.value = 0;
    agentsCount.value = 0;
    agentsCSatScoresStore.agentsData = [];
    agentsById.value = {};

    if (!q.success) {
      console.log(`Error: query failed: ${q.error}`);
      // TODO toast
      return;
    }

    const queryResult = q.result;

    let currConversations = queryResult?.total || 0;
    const agentsData = [];
    const agentsByIdData = {};
    let agentsLen = 0;

    const agentsBuckets = queryResult?.aggregations?.agent?.buckets || null;
    if (!agentsBuckets) {
      return false;
    }

    const scores = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];

    for (let i = 0; i < agentsBuckets.length; i++) {
      const bucket = agentsBuckets[i];
      //currConversations += bucket?.doc_count || 0;
      const agentScores = {};
      scores.map((s) => {
        agentScores[s] = 0;
      });
      const agentScoresBuckets = pathOr('', ['CSat', 'buckets'], bucket);
      let agentScoreSum = 0; // ???
      let agentScoreCount = 0; // ???
      agentScoresBuckets.map((sb) => {
        const cnt = sb?.doc_count || 0;
        agentScores[sb.key] = cnt;
        agentScoreCount += cnt;
        agentScoreSum += cnt * sb.key;
      });
      const agentCSat = agentScoreCount > 0 ? (agentScoreSum / (agentScoreCount * 10)) * 100 : 0;
      agentsLen = agentsData.push({
        agentId: bucket.key,
        agentName: pathOr('', ['AgentName', 'buckets', 0, 'key'], bucket),
        CSat: agentCSat,
        scores: agentScores,
        conversationsCount: bucket?.doc_count || 0,
        count: bucket?.doc_count || 0,
      });
      agentsByIdData[bucket.key] = agentsLen - 1;
    }

    conversationsCount.value = currConversations;
    agentsById.value = agentsByIdData;
    agentsCount.value = agentsLen;
    agentsCSatScoresStore.agentsData = agentsData;
    agents.value = agentsData;
  }

  async function loadData(forceReload = false) {
    if (!forceReload && agentsCSatScoresStore.agentsData.length > 0 && dateRangeStore.isSameFromTo(agentsCSatScoresStore.analyzedTimeInterval)) {
      // Show existing data
      //analyzeProgressButton.value.startProgress();
      //loading.value = true;
      await nextTick();
      agents.value = agentsCSatScoresStore.agentsData;
      //loading.value = false;
      //analyzeProgressButton.value.stopProgress();
      return;
    }
    analyzeProgressButton.value.startProgress();
    loading.value = true;
    await queryAgentScores();
    loading.value = false;
    analyzeProgressButton.value.stopProgress();
  }

  function onTableSort(evt) {
    tableSortField.value = evt?.sortField || null;
    tableSortOrder.value = evt?.sortOrder || null;
    //console.log(`On table sort:`, evt);
  }

  function rgb2rgba(rgb, opacity) {
    let c = d3Color.color(rgb);
    c.opacity = opacity;
    return c + '';
  }

  function getValueStyle(key, val) {
    if (val > 0) {
      const co = rgb2rgba(colorScale(key), 0.4);
      return `background-color: ${co};`;
    }
    return '';
  }

  function getFilterQueryConversations(data) {
    return [encodeURIComponent(`AgentName:${data.agentName}`)];
  }

  function getFilterQueryConversationsWithScore(data, score) {
    return [encodeURIComponent(`AgentName:${data.agentName}`), encodeURIComponent(`CSat:${score}`)];
  }

  onMounted(() => {
    loadData();
  });
</script>
