<template>
  <ProgressBar v-if="isProgress" mode="indeterminate" class="top-0 z-50 w-full" style="height: 6px; position: absolute; background-color: rgba(0, 0, 0, 0)"></ProgressBar>
</template>
<script setup>
  import ProgressBar from 'primevue/progressbar';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { computed } from 'vue';

  const layoutStore = useLayoutStore();
  const isProgress = computed(() => layoutStore.progressbar !== false);
</script>
