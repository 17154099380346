<template>
  <div ref="chartEl">
    <canvas ref="chartCanvas" style="position: absolute"> </canvas>
  </div>
</template>
<script setup>
  import { ref, onMounted, onBeforeUnmount } from 'vue';
  import * as d3 from 'd3';
  import dbColors from './dbcolors';

  const props = defineProps({
    max: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: null,
    },
    opactity: {
      type: Number,
      default: 0.1,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  });

  const chartEl = ref();
  const chartCanvas = ref();

  const canvasWidth = ref(100);
  const canvasHeight = ref(100);

  function initSize() {
    canvasHeight.value = props.height ? props.height : chartEl.value.clientHeight;
    canvasWidth.value = props.width ? props.width : chartEl.value.clientWidth;
  }

  function handleResize() {
    render();
  }

  function render() {
    initSize();
    let canvas = chartCanvas.value;
    canvas.width = canvasWidth.value;
    canvas.height = canvasHeight.value;
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // TODO Erase everything
    if (!Array.isArray(props.data) || props.data.length <= 0) {
      return;
    }

    // Scale
    let lx = d3
      .scaleLinear()
      .domain([0, props.data.length - 1])
      .range([0, canvas.width]);

    // TODO Support negative y
    let yMin = d3.min(props.data);
    let yMax = props.max || d3.max(props.data);
    if (yMax === 0 && yMin === 0) {
      // adjust if we have all 0s, so nothing is drawn
      yMax = 1;
      yMin = 0;
    } else if (yMin > 0) {
      // adjust to make sure we're based off 0 y
      yMin = 0;
    }
    let ly = d3.scaleLinear().domain([yMin, yMax]).range([canvas.height, 0]);

    let pointsData = props.data.map((d, i) => {
      return { x: i, y: d };
    });

    let line = d3
      .line()
      .x((d) => lx(d.x))
      .y((d) => ly(d.y))
      .curve(d3.curveCardinal);
    line.context(ctx);

    let area = d3
      .area()
      .x((d) => lx(d.x))
      .y1((d) => ly(d.y))
      .y0(canvas.height)
      .curve(d3.curveCardinal);
    area.context(ctx);

    ctx.beginPath();
    //line(pointsData);
    area(pointsData);
    //ctx.fillStyle = 'rgba(0, 255, 0, 0.2)';
    //ctx.fillStyle = dbColors.hex2RGBA('#607d8b',0.3); // Dark
    ctx.fillStyle = dbColors.hex2RGBA(props.color ? props.color : '#78909c', props.opactity); // Light
    ctx.fill();
    // This is for line
    //ctx.lineWidth = 2;
    //ctx.strokeStyle = dbColors.hex2RGBA('#78909c', 0.4); // Light
    //ctx.stroke();
  }

  onMounted(() => {
    window.addEventListener('resize', handleResize);
    render();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });
</script>
