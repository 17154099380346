<template>
  <div class="flex">
    <div class="flex grow space-x-1 rounded-lg bg-slate-100 p-1" aria-orientation="horizontal">
      <!--<button v-for="(filter, idx) in filtersStore.setFilters" :key="idx" :class="['bg-white shadow', 'flex items-center rounded-md py-[0.4375rem] pl-2 pr-2 text-sm font-semibold lg:pr-3']" @click="onSelectFilter(filter)">
      <mdicon :name="'filter'" size="20" :class="['text-slate-300', '']" aria-hidden="true"></mdicon>
      <span class="sr-only lg:not-sr-only lg:ml-1 text-slate-900">{{ filter.title }}</span>
    </button>-->
      <StdDateRangeEditor></StdDateRangeEditor>
      <SplitButton
        v-for="(filter, idx) in filtersStore.setFilters"
        :key="idx"
        :label="filter.getTextValue()"
        :model="
          filterActions.map((x) =>
            Object.assign({}, x, {
              filterField: filter.field,
              visible: () => checkFilterActionVisible(filter, x),
            }),
          )
        "
        :icon="filter.enabled ? 'pi pi-filter' : 'pi pi-ban'"
        @click="onSelectFilter(filter)"
        severity="secondary"
        outlined
        raised
        size="small"
        :class="[filter.enabled ? '' : 'bg-slate-200']"
      ></SplitButton>
    </div>
    <div class="flex-0">
      <Button class="ml-1" icon="pi pi-filter" size="small" severity="info" outlined aria-label="Filter" @click="onShowFilters" />
      <!--<button
        class="ml-1 flex items-center rounded-md bg-indigo-600 p-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        @click="onShowFilters"
      >
        <mdicon name="filter" size="28" class="text-purple-100" aria-hidden="true"></mdicon>
      </button>-->
      <!--
      <div class="ml-1 p-1 rounded bg-violet-500">
        <mdicon :name="'filter'" size="26" class="text-violet-50" aria-hidden="true"></mdicon>
      </div>
      -->
    </div>
  </div>
</template>

<script setup>
  import { ref, computed, watch } from 'vue';
  import Button from 'primevue/button';
  import SplitButton from 'primevue/splitbutton';
  import { useFiltersStore } from '@/store/FiltersStore';
  import StdDateRangeEditor from '@/components/Editors/StdDateRangeEditor.vue';

  const emit = defineEmits(['selectFilter', 'showFilters']);

  const filtersStore = useFiltersStore();

  const filterActions = [
    {
      key: 'edit',
      label: 'Edit',
      icon: 'pi pi-file-edit',
      command: (evt) => {
        const f = filtersStore.setFilters.find((x) => x.field === evt?.item?.filterField);
        if (f) {
          onSelectFilter(f);
        }
      },
    },
    {
      key: 'disable',
      label: 'Disable',
      icon: 'pi pi-ban',
      command: (evt) => {
        setFilterEnabled(evt?.item?.filterField, false);
      },
    },
    {
      key: 'enable',
      label: 'Enable',
      icon: 'pi pi-check-circle',
      command: (evt) => {
        setFilterEnabled(evt?.item?.filterField, true);
      },
    },
    {
      key: 'delete',
      label: 'Delete',
      icon: 'pi pi-times',
      command: (evt) => {
        filtersStore.clearFilterValue(evt?.item?.filterField);
      },
    },
  ];

  function checkFilterActionVisible(filter, action) {
    if (action.key === 'disable' && !filter.enabled) {
      return false;
    }
    if (action.key === 'enable' && filter.enabled) {
      return false;
    }
    return true;
  }

  function setFilterEnabled(field, enabled) {
    if (field) {
      filtersStore.setFilterEnabled(field, enabled);
    }
  }

  function onShowFilters() {
    emit('showFilters');
  }

  function onSelectFilter(filter) {
    emit('selectFilter', filter);
  }
</script>
