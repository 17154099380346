<template>
  <div ref="tuiEditorElement"></div>
</template>

<script setup>
  // TODO search/replace - sample of custom command: https://github.com/nhn/tui.editor/issues/844
  import { ref, computed, onMounted } from 'vue';
  import Editor from '@toast-ui/editor';
  import '@toast-ui/editor/dist/toastui-editor.css';
  //import '@toast-ui/editor/dist/theme/toastui-editor-dark.css';

  const props = defineProps({
    previewStyle: {
      type: String,
      default: 'vertical',
    },
    height: {
      type: String,
      default: '300px',
    },
    initialEditType: {
      type: String,
      default: 'wysiwyg',
    },
    initialValue: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      default: {},
    },
  });

  const tuiEditorElement = ref(null);
  let tuiEditor = null;

  const editorEvents = ['load', 'change', 'caretChange', 'focus', 'blur', 'keydown', 'keyup', 'beforePreviewRender', 'beforeConvertWysiwygToMarkdown'];
  const emit = defineEmits(['load', 'change', 'caretChange', 'focus', 'blur', 'keydown', 'keyup', 'beforePreviewRender', 'beforeConvertWysiwygToMarkdown']);

  const defaultValueMap = {
    initialEditType: 'markdown',
    initialValue: '',
    height: '300px',
    previewStyle: 'vertical',
  };

  const editorOptions = computed(() => {
    const eventOptions = {};

    editorEvents.forEach((event) => {
      eventOptions[event] = (...args) => {
        emit(event, ...args);
      };
    });

    const options = {
      ...props.options,
      initialEditType: props.initialEditType,
      initialValue: props.initialValue,
      height: props.height,
      previewStyle: props.previewStyle,
      usageStatistics: false,
      events: eventOptions,
      //theme: 'dark',
    };

    Object.keys(defaultValueMap).forEach((key) => {
      if (!options[key]) {
        options[key] = defaultValueMap[key];
      }
    });

    return options;
  });

  function setMarkdown(markdown, cursorToEnd) {
    if (tuiEditor) {
      tuiEditor.setMarkdown(markdown, cursorToEnd);
    }
  }

  function getMarkdown() {
    return tuiEditor ? tuiEditor.getMarkdown() : '';
  }

  onMounted(() => {
    // { ...this.computedOptions, el: this.$refs.toastuiEditor };
    const options = { ...editorOptions.value, el: tuiEditorElement.value };
    tuiEditor = new Editor(options);
  });

  defineExpose({ setMarkdown, getMarkdown });
</script>

<style>
  /* Custom CSS to change the font size in TOAST UI Editor */
  .toastui-editor-contents {
    font-size: 16px; /* Change this to your desired font size */
    font-family:
      Source Sans Pro,
      ui-sans-serif,
      system-ui,
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
  }

  .ProseMirror {
    font-size: 16px; /* Change this to your desired font size */
    font-family:
      Source Sans Pro,
      ui-sans-serif,
      system-ui,
      sans-serif,
      'Apple Color Emoji',
      'Segoe UI Emoji',
      'Segoe UI Symbol',
      'Noto Color Emoji';
  }

  .toastui-editor-contents h1 {
    font-size: 2em; /* Custom font size for headers */
    line-height: 120%;
  }

  .toastui-editor-contents h2 {
    font-size: 1.75em;
    line-height: 120%;
  }

  .toastui-editor-contents p {
    font-size: 1em; /* Custom font size for paragraphs */
  }
</style>
