<template>
  <ItemSelector :items="availableActions" :open="open" @select="onSelect" @close="open = false"></ItemSelector>
</template>

<script setup>
  import { computed } from 'vue';
  import { ACTION_META, ACTION_MEDIA } from '@/spec/ActionDefs.js';
  import ItemSelector from '@/components/Selectors/ItemSelector.vue';

  const props = defineProps({
    media: {
      type: String,
      default: 'none',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  });
  const emit = defineEmits(['item-selected', 'update:modelValue']);

  const open = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });

  const availableActions = computed(() => {
    return ACTION_META.filter((x) => x.media & ACTION_MEDIA[props.media]).map((x) => {
      return {
        name: x.title,
        icon: x.icon,
        description: x.description,
        action: x,
      };
    });
  });

  function onSelect(item) {
    emit('item-selected', item.action);
  }
</script>
