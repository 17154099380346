<template>
  <div class="">
    <div v-if="title" class="py-2 my-2 text-xl font-semibold leading-7 text-gray-500 sm:truncate sm:text-xl sm:tracking-tight mb-4">Transcript</div>
    <ConversationRecordingPlayer
      v-if="!layout.print"
      ref="recordingPlayer"
      :conversation="conversation"
      v-model:audio-available="recordingAudioAvailable"
      @timeupdate="onTimeUpdate"
      @play="onPlay"
      @pause="onPause"
    ></ConversationRecordingPlayer>

    <div :class="[layout.print ? '' : 'h-[calc(100vh-410px)]', 'flow-root my-4 font-source overflow-y-auto']">
      <ul role="list" class="divide-y divide-gray-100">
        <li
          v-for="(transcriptItem, transcriptItemIdx) in conversationTranscriptMessages"
          :key="transcriptItem.ts"
          :id="`tmsg-${transcriptItemIdx}`"
          @click="onMessageClick(transcriptItemIdx)"
          class="relative flex items-start justify-between gap-x-4 py-2 cursor-pointer"
        >
          <div class="flex grow min-w-0 gap-x-2 items-start">
            <div class="min-w-12 flex items-start justify-center">
              <mdicon
                :name="transcriptItem[actorProp].toLowerCase() === 'bot' ? 'cog' : transcriptItem[actorProp].toLowerCase() === 'agent' ? 'face-agent' : 'account'"
                size="26"
                :class="['flex items-center h-12 w-12 justify-center rounded-full bg-slate-100 ring-8 ring-white', transcriptItemIdx === currentMessageIdx ? 'text-violet-600' : 'text-slate-400']"
              />
            </div>
            <div :class="['min-w-0 grow px-2 py-1 rounded-md', transcriptItemIdx === currentMessageIdx ? 'bg-violet-50' : 'hover:bg-slate-50']">
              <p class="text-sm leading-6 text-gray-500">{{ getActorTitle(transcriptItem) }}</p>
              <p :class="['mt-1 font-medium flex text-md', transcriptItemIdx === currentMessageIdx ? 'text-gray-800' : 'text-gray-600']">
                {{ transcriptItem.message }}
              </p>
            </div>
          </div>
          <div class="flex shrink-0 items-start gap-x-4 mx-4">
            <div class="hidden sm:flex sm:flex-col sm:items-end">
              <div class="flex items-center" title="Duration">
                <mdicon name="clock-outline" size="14" class="mr-1"></mdicon>
                <p class="text-sm leading-6 text-gray-900">{{ formatSeconds(transcriptItem.originalDuration, false) }}</p>
              </div>
              <div class="flex items-center" title="Speed">
                <p class="mt-1 text-sm leading-5 text-gray-500">{{ (transcriptItem?.speechRate || 0).toFixed(2) }} words/sec</p>
              </div>
            </div>
            <div v-if="!layout.print">
              <button
                v-if="recordingAudioAvailable"
                type="button"
                class="text-violet-700 border border-violet-500 hover:bg-violet-300 focus:ring-4 focus:outline-none focus:ring-violet-300 font-medium rounded-full text-xs p-1.5 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500"
                @click.stop="onMessagePlaybackControl(transcriptItemIdx)"
              >
                <mdicon :name="transcriptItemIdx === currentMessageIdx && recordingIsPlaying ? 'pause' : 'play'" size="22"></mdicon>
              </button>
              <button v-else type="button" class="text-violet-300 border border-violet-300 font-medium rounded-full text-xs p-1.5 text-center inline-flex items-center cursor-not-allowed">
                <mdicon name="play" size="22"></mdicon>
              </button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { pathOr } from 'ramda';
  import { useLayoutStore } from '@/store/LayoutStore';
  import ConversationRecordingPlayer from '@/components/Insights/ConversationRecordingPlayer.vue';
  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';

  dayjs.extend(utc);

  const props = defineProps({
    conversation: {
      type: Object,
      default() {
        return {};
      },
    },
    transcriptMessagesPath: {
      type: Array,
      default() {
        return ['context', 'transcript', 'messages'];
      },
    },
    actorProp: {
      type: String,
      default: 'actor',
    },
    title: {
      type: String,
      default: 'Transcript',
    },
  });

  const layout = useLayoutStore();

  const conversationId = computed(() => props.conversation?.id || null);

  const conversationTranscriptMessages = computed(() => {
    // Copy, adjust timing if needed - to avoid overlap between messages
    const msgs = JSON.parse(JSON.stringify(pathOr([], props.transcriptMessagesPath, props.conversation)));
    for (let i = 0; i < msgs.length; i++) {
      const m = msgs[i];
      const mt = getMessageTime(m);
      m.originalDuration = m.duration;
      const nm = i === msgs.length - 1 ? null : msgs[i + 1];
      if (nm) {
        const nmt = getMessageTime(nm);
        if (mt.msgEnd > nmt.msgStart) {
          m.duration = m.duration - (mt.msgEnd - nmt.msgStart) - 0.1;
        }
      }
    }
    return msgs;
  });

  const currentMessageIdx = ref(-1);

  const recordingPlayer = ref(null);

  const recordingAudioAvailable = ref(false);

  const recordingIsPlaying = defineModel('recordingIsPlaying');

  function formatSeconds(sec, addMs = false) {
    const s = Math.floor(sec); // parseFloat(sec.toFixed(3));
    const ms = (sec % 1).toFixed(3).padEnd(3, '0').substring(1);
    const diff = s;
    const totalMinutes = Math.floor(diff / 60);
    const seconds = diff % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    let res =
      hours > 0 ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}` : `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    if (addMs) {
      res += ms;
    }
    return res;
  }

  function getActorTitle(transcriptItem) {
    let s = formatSeconds(transcriptItem.at) + ' ' + transcriptItem.actor;
    if (transcriptItem.actor === 'agent') {
      s += ' ' + (props.conversation?.AgentName || props.conversation?.AgentId);
    }
    return s;
  }

  function getMessageTime(msg) {
    const msgStart = msg?.at || 0;
    const msgDuration = msg?.duration || 0;
    const msgEnd = msgStart + msgDuration;
    return {
      msgStart,
      msgEnd,
      msgDuration,
    };
  }

  function setCurrentMessageIdx(idx) {
    if (currentMessageIdx.value !== idx) {
      currentMessageIdx.value = idx;
      console.log(`Current message is set to ${idx}`);
      if (idx !== -1) {
        // Scroll message into view
        const mElem = document.getElementById(`tmsg-${idx}`);
        if (mElem) {
          mElem.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
        }
      }
    }
  }

  function detectCurrentMessageIdx(currentTime) {
    if (currentMessageIdx.value >= 0 && currentMessageIdx.value < conversationTranscriptMessages.value.length) {
      const { msgStart, msgEnd } = getMessageTime(conversationTranscriptMessages.value[currentMessageIdx.value]);
      if (msgStart <= currentTime && msgEnd >= currentTime) {
        return; // still current
      }
    }

    // Lookup
    for (let i = 0; i < conversationTranscriptMessages.value.length; i++) {
      const { msgStart, msgEnd } = getMessageTime(conversationTranscriptMessages.value[i]);
      //console.log(`Checking: idx=${i} start=${msgStart} end=${msgEnd} curr=${currentTime}`);
      if (msgStart <= currentTime && msgEnd >= currentTime) {
        //console.log('TRUE');
        setCurrentMessageIdx(i);
        return;
      }
      if (currentTime < msgStart) {
        setCurrentMessageIdx(i - 1);
        return;
      }
    }
    setCurrentMessageIdx(-1);
  }

  function onTimeUpdate(currentTime) {
    //console.log(`onTimeUpdate: ${currentTime}`);
    detectCurrentMessageIdx(currentTime);
  }

  function onMessageClick(idx) {
    onMessagePlaybackControl(idx);
  }

  function onMessagePlaybackControl(idx) {
    if (idx >= 0 && idx < conversationTranscriptMessages.value.length) {
      const { msgStart } = getMessageTime(conversationTranscriptMessages.value[idx]);
      // Current message or not ?
      if (idx === currentMessageIdx.value) {
        if (recordingIsPlaying.value) {
          recordingPlayer.value.pause();
        } else {
          recordingPlayer.value.play();
        }
      } else {
        recordingPlayer.value.setTime(msgStart + 0.1);
        recordingPlayer.value.play();
      }
    }
  }

  function onPlay() {
    recordingIsPlaying.value = true;
  }

  function onPause() {
    recordingIsPlaying.value = false;
  }
</script>
