<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8">
    <div class="sm:flex sm:items-center">
      <div class="flex-auto flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-violet-600/70 rounded-xl p-1">
            <mdicon name="playlist-check" size="62" class="text-violet-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-2xl font-bold text-gray-900">Scenarios</h1>
          <p class="text-sm font-medium text-gray-500">A list of all the Scenarios in your account</p>
        </div>
      </div>

      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <div class="flex items-center">
          <button
            v-if="showDelete"
            type="button"
            class="mr-2 flex items-center gap-x-1 rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            @click="onDeleteScenarios"
          >
            <mdicon name="minus-circle-outline" class="h-6 w-6" aria-hidden="true"></mdicon>
            Delete
          </button>
          <button
            type="button"
            class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            @click="onNewScenario"
          >
            <mdicon name="plus-circle-outline" class="h-6 w-6" aria-hidden="true"></mdicon>
            Add Scenario
          </button>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <DataTable v-model:selection="selectedDocuments" :value="scenariosStore.scenarios" paginator :rows="25" :rowsPerPageOptions="[25, 50, 100]" removableSort tableStyle="min-width: 50rem" class="">
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="name" header="Name" sortable>
          <template #body="slotProps">
            <div class="flex items-center cursor-pointer">
              <div class="flex mr-2 h-7 w-7 flex-none items-center justify-center rounded-md text-violet-500/70 bg-violet-50">
                <mdicon name="playlist-edit" size="18"></mdicon>
              </div>
              <router-link :to="{ path: `/analysis/scenarios/edit/${slotProps.data.id}` }">
                <div class="font-medium hover:underline">{{ slotProps.data.name }}</div>
              </router-link>
            </div>
          </template>
        </Column>
        <Column field="description" header="Description" sortable />
        <Column field="media" header="Media" sortable />
        <Column field="updated" header="Last Updated" sortable>
          <template #body="slotProps"> {{ dayjs(slotProps.data.updated).format('MM/DD/YYYY') }} </template>
        </Column>
      </DataTable>
    </div>
    <ConfirmationDialog :open="deleteConfirm" title="Delete" :description="`Are you sure you want to delete selected Scenarios ?`" @cancel="deleteConfirm = false" @confirm="onDeleteConfirmed"></ConfirmationDialog>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import { useScenariosStore } from '@/store/ScenariosStore';
  import { nanoid } from 'nanoid';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
  import { computed, ref } from 'vue';
  import { useToast } from 'vue-toastification';
  import dayjs from 'dayjs';

  const router = useRouter();
  const scenariosStore = useScenariosStore();
  const toast = useToast();

  const selectedDocuments = ref();
  const showDelete = computed(() => Array.isArray(selectedDocuments.value) && selectedDocuments.value.length > 0);

  function onNewScenario() {
    const newScenarioId = nanoid(10);
    router.push(`/analysis/scenarios/edit/${newScenarioId}`);
  }

  // Delete
  const deleteConfirm = ref(false);

  async function onDeleteConfirmed() {
    deleteConfirm.value = false;
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`Nothing selected to delete`);
      return;
    }
    console.log(`Deleting scenarios: ${JSON.stringify(selectedDocuments.value)}`);
    let overallResult = true;
    for (let i = 0; i < selectedDocuments.value.length; i++) {
      const doc = selectedDocuments.value[i];
      const deleteResult = await scenariosStore.deleteScenario(doc.id);
      overallResult &= deleteResult;
    }
    if (overallResult) {
      toast.success('Deleted successfully');
    } else {
      toast.error(`Error when deleting`);
    }
    selectedDocuments.value = [];
  }

  async function onDeleteScenarios() {
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`Nothing selected to delete`);
      return;
    }
    deleteConfirm.value = true;
  }
</script>
