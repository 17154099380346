<template>
  <component :is="editorComponent"></component>
</template>
<script setup>
  import { computed } from 'vue';
  import { useKbStore } from '@/store/KbStore';
  import KbStandardSettingsEditor from '@/components/KB/editors/KbStandardSettingsEditor.vue';

  const kbStore = useKbStore();

  const supportedEditors = {
    standard: KbStandardSettingsEditor,
    // todo other kb types, as necessary
    none: KbStandardSettingsEditor,
  };

  const editorComponent = computed(() => supportedEditors[kbStore.kbSpec?.type || 'none']);
</script>
