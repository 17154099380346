/*
 * Jobs Store
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useCollection } from 'vuefire';
import { doc, setDoc, getDoc, collection, deleteDoc } from 'firebase/firestore';
import { fbDb } from '@/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useToast } from 'vue-toastification';
import { getBlob, getDownloadURL, ref as storageRef, getStorage } from 'firebase/storage';
import { nanoid } from 'nanoid';

const EMPTY_JOB_SPEC = {
  name: 'New Job',
  //media: 'recording',
};

export const useJobsStore = defineStore('jobs', () => {
  const authStore = useAuthStore();

  const tenantId = computed(() => authStore.tenantId);

  // List of all jobs, with subscription - will be updated if any document is changed in the collection
  const jobs = useCollection(collection(fbDb, `tenants/${tenantId.value}/jobs`));

  // List of all job results
  const jobsResults = useCollection(collection(fbDb, `tenants/${tenantId.value}/results`));

  const storage = getStorage();

  // TODO Reconsider - are those really needed ?
  let currentJobDoc = ref({}); // job document data
  let currentJobSpec = ref({}); // job spec

  // For new Jobs
  let newJobId = ref(null); // job spec
  let newJobSpec = ref({}); // job spec

  const toast = useToast();

  async function createJob(jobType, jobName = 'New Job') {
    // TODO Consider saving it right away here
    // This approach with temp new job in memory is to prevent new jobs saved in store if user is just exploring stuff in UI
    newJobId.value = nanoid(10);
    newJobSpec.value = {
      id: newJobId.value,
      name: jobName,
      type: jobType,
    };
    return newJobId.value;
  }

  // TODO Pass flag new: true | false, so we can do extra check - if doc was supposed to exist but does not
  // Load existing or create new empty job document
  async function loadJob(jobId) {
    // If this is newly created job, return right away, only once
    // TODO Reconsider this flow
    if (newJobId.value === jobId) {
      const spec = newJobSpec.value;
      newJobSpec.value = {};
      newJobId.value = null;
      return spec;
    }
    const emptySpec = Object.assign({ id: jobId }, EMPTY_JOB_SPEC);
    const jobDocRef = doc(fbDb, `tenants/${tenantId.value}/jobs`, jobId);
    const jobDoc = await getDoc(jobDocRef);
    if (jobDoc.exists()) {
      console.log('Document data:', jobDoc.data());
      currentJobDoc.value = jobDoc.data();
      const jobSpecDoc = await getDoc(doc(jobDocRef, 'versions', 'current'));
      if (!jobSpecDoc.exists()) {
        currentJobSpec.value = emptySpec;
      } else {
        const specData = jobSpecDoc.data();
        let spec = null;
        try {
          spec = JSON.parse(specData.spec);
        } catch (e) {
          console.log(`Job[${jobId}]: Failed to parse spec JSON: ${e.message}`);
          spec = emptySpec;
        }
        currentJobSpec.value = spec;
      }
    } else {
      console.log(`Job[${jobId}]: Does not exist, assuming new`);
      currentJobSpec.value = emptySpec;
      // TODO Doc
      //  - created
      //  - by
    }
    return currentJobSpec.value;
  }
  // TODO params

  // Update or Add job (upsert)
  async function setJob(job) {
    const jobId = job?.id || null;
    if (!jobId) {
      return false; // TODO error
    }

    try {
      await setDoc(doc(fbDb, `tenants/${tenantId.value}/jobs`, jobId), {
        name: job?.name || '',
        description: job?.description || '',
        type: job?.type || '',
        updated: Date.now(),
        // TODO updated by
      });
    } catch (e) {
      console.log(`Job[${jobId}]: Error writing document: ${e.message}`);
      return false;
    }

    const jobSpec = JSON.stringify(job);
    // Now write spec
    try {
      await setDoc(doc(fbDb, `tenants/${tenantId.value}/jobs/${jobId}/versions`, 'current'), {
        spec: jobSpec,
      });
    } catch (e) {
      console.log(`Job[${jobId}]: Error writing spec: ${e.message}`);
      return false;
    }
    return true;
  }

  async function deleteJob(jobId) {
    try {
      // TODO Delete versions collection ?
      await deleteDoc(doc(fbDb, `tenants/${tenantId.value}/jobs`, jobId));
    } catch (e) {
      console.log(`Scenario[${jobId}]: Error deleting job: ${e.message}`);
      return false;
    }
    return true;
  }

  // Execute Job - Temp implementation - TODO refactor to async
  async function executeJob(jobSpec) {
    const functions = getFunctions();
    const executeJobFunction = httpsCallable(functions, 'executeJob', {
      timeout: 10 * 60 * 1000, // 9 min max on functions side
    });
    let result = null;
    try {
      result = await executeJobFunction(jobSpec);
    } catch (e) {
      const msg = `ERROR: Job ${jobSpec?.name || ''} execution failed: ${e.message}`;
      console.log(msg);
      toast.error(msg);
      return false;
    }

    const msg = `Job ${jobSpec?.name || ''} executed successfully`;
    console.log(`ExecuteJob result: ${JSON.stringify(result)}`);
    toast.success(msg);
    return result;
  }

  async function getJobResultData(jobExecutionId) {
    const resultFileRef = storageRef(storage, `${tenantId.value}/results/${jobExecutionId}/result.json`);
    let resultData = null;
    try {
      const blob = await getBlob(resultFileRef);
      resultData = await blob.text();
    } catch (e) {
      console.log(`Exception getting Job ${jobExecutionId} results data: ${e.message}`);
      resultData = null;
    }
    return resultData;
  }

  async function getJobResultURL(jobExecutionId) {
    const resultFileRef = storageRef(storage, `${tenantId.value}/results/${jobExecutionId}/result.json`);
    let downloadUrl = null;
    try {
      downloadUrl = await getDownloadURL(resultFileRef);
    } catch (e) {
      console.log(`Exception getting Job ${jobExecutionId} results URL: ${e.message}`);
      downloadUrl = null;
    }
    return downloadUrl;
  }

  async function deleteJobExecutionResults(jobExecutionId) {
    // Delete data in storage and data in firestore
    const functions = getFunctions();
    const executeJobFunction = httpsCallable(functions, 'deleteJobResults', { timeout: 60000 });
    let result = null;
    try {
      result = await executeJobFunction({
        jobExecutionId: jobExecutionId,
      });
    } catch (e) {
      const msg = `ERROR: Failed to delete Job execution results: ${e.message}`;
      console.log(msg);
      return false;
    }
    if (!result?.data?.success) {
      console.log(`Error deleting job execution results: ${JSON.stringify(result)}`);
    }
    return result?.data?.success || false;
  }

  async function startJob(jobId) {
    const functions = getFunctions();
    // test
    const executeJobFunction = httpsCallable(functions, 'startJobCloudRun', { timeout: 10 * 60 * 1000 });
    let result = null;
    try {
      result = await executeJobFunction({
        jobId: jobId,
      });
    } catch (e) {
      const msg = `ERROR: Start Job 2 failed: ${e.message}`;
      console.log(msg);
      toast.error(msg);
      return {
        success: false,
        error: e.message,
      };
    }

    console.log(`Start Job result: ${JSON.stringify(result)}`);
    return result.data;
  }

  async function executeTest() {
    const functions = getFunctions();
    // test
    const executeJobFunction = httpsCallable(functions, 'testFunction', { timeout: 60 * 1000 });
    let result = null;
    try {
      result = await executeJobFunction({
        text: 'test2',
      });
    } catch (e) {
      const msg = `ERROR: Test 2 failed: ${e.message}`;
      console.log(msg);
      toast.error(msg);
      return false;
    }

    console.log(`ExecuteTest result: ${JSON.stringify(result)}`);
    return result;
  }

  return {
    jobs,
    jobsResults,
    currentJobDoc,
    currentJobSpec,
    createJob,
    loadJob,
    setJob,
    deleteJob,
    executeJob,
    startJob,
    executeTest,
    getJobResultData,
    getJobResultURL,
    deleteJobExecutionResults,
  };
});
