<template>
  <div>
    <Dialog v-model:visible="visible" modal :closable="false" header="Download Zendesk Help Centers Documents" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="flex flex-col gap-2">
        <div v-if="reloadAllDocuments">
          <label>Download all documents from Zendesk Help Centers</label>
          <Message :closable="false" severity="warn">
            This operation will download all documents from Zendesk Help Centers and update them in Knowledge Base. It will override changes that were made in Knowledge Base directly.
          </Message>
        </div>
        <div v-else>
          <label>Download documents from Zendesk Help Centers updated since {{ lastUpdated }}</label>
          <Message :closable="false" severity="info">
            This operation will download only documents created or changed in Zendesk Help Centers since last update, and update them in Knowledge Base. Updates made in Zendesk Help Center will override changes that were
            made in Knowledge Base directly.
          </Message>
        </div>

        <label>Zendesk Help Centers Subdomains</label>
        <MultiSelect v-model="hcSubdomainsSelected" :options="hcSubdomains" placeholder="Select Subdomains" :maxSelectedLabels="3" class="w-full" />
      </div>
      <div class="mt-4">
        <small class="min-h-6 block">{{ progressText }} </small>
        <ProgressBar :mode="progressMode" style="height: 6px"></ProgressBar>
      </div>
      <template #footer>
        <Button label="Cancel" :disabled="showProgress" severity="secondary" @click="visible = false" autofocus />
        <Button label="Start" :disabled="!startEnabled || showProgress" severity="primary" @click="startDownload" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
  import { useRbacStore } from '@/store/RBACStore';
  import { useKbStore } from '@/store/KbStore';
  import { useToast } from 'vue-toastification';
  import { ref, nextTick, computed } from 'vue';
  import InputText from 'primevue/inputtext';
  import Dialog from 'primevue/dialog';
  import Button from 'primevue/button';
  import ProgressBar from 'primevue/progressbar';
  import ScrollPanel from 'primevue/scrollpanel';
  import Message from 'primevue/message';
  import MultiSelect from 'primevue/multiselect';

  const kbStore = useKbStore();

  const toast = useToast();

  const visible = ref(false);
  const showProgress = ref(false);
  const progressMode = computed(() => (showProgress.value ? 'indeterminate' : ''));
  const progressText = ref('');

  const reloadAllDocuments = ref(false);
  const lastUpdated = ref('Never');
  const hcSubdomains = ref([]);
  const hcSubdomainsSelected = ref([]);

  const startEnabled = computed(() => hcSubdomainsSelected.value.length > 0);

  function start(reloadAll = false, subdomains = [], lastUpdateDate = 'Never') {
    reloadAllDocuments.value = reloadAll;
    lastUpdated.value = lastUpdateDate;
    hcSubdomains.value = subdomains;
    hcSubdomainsSelected.value = subdomains;
    visible.value = true;
  }

  const pause = (x) => new Promise((res) => setTimeout(res, x));

  async function startDownload() {
    showProgress.value = true;
    progressText.value = 'Downloading documents...';

    const updateRes = await kbStore.updateKbDataSources({
      ds: 'zendeskhc',
      reloadAll: reloadAllDocuments.value,
      subdomains: hcSubdomainsSelected.value,
      // todo subdomains selection
    });

    if (!updateRes.success) {
      toast.error(`Error downloading documents: ${updateRes.error}`);
    } else {
      const documentsLoaded = updateRes?.data?.zendeskhc?.documentsLoaded || 0;
      const documentsUpdated = updateRes?.data?.zendeskhc?.documentsUpdated || 0;
      const documentsErrors = updateRes?.data?.zendeskhc?.documentsErrors || 0;
      if (documentsLoaded <= 0) {
        const msg = reloadAllDocuments.value ? 'No documents were found to download' : 'No new or updated documents were found to download';
        toast.info(msg);
      } else if (documentsErrors === 0) {
        toast.success(`Downloaded and updated ${documentsLoaded} documents`);
      } else {
        toast.warning(`Downloaded ${documentsLoaded} documents, updated ${documentsUpdated} documents, ${documentsErrors} documents had errors while updating`);
      }
    }

    progressText.value = '';
    showProgress.value = false;
    visible.value = false;
  }

  defineExpose({ start });
</script>
<style>
  .p-message-wrapper {
    align-items: start;
  }
  .p-message-icon {
    margin-top: 4px;
  }
</style>
