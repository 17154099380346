<template>
  <component :is="editorComponent"></component>
</template>
<script setup>
  import { computed } from 'vue';
  import { useBotsStore } from '@/store/BotsStore';
  import BotQASettingsEditor from '@/components/Bots/editors/BotQASettingsEditor.vue';
  import BotLeadGenSettingsEditor from '@/components/Bots/editors/BotLeadGenSettingsEditor.vue';

  const botsStore = useBotsStore();

  const supportedEditors = {
    questionAnswering: BotQASettingsEditor,
    leadGeneration: BotLeadGenSettingsEditor,
    none: BotQASettingsEditor, // TODO
  };

  const editorComponent = computed(() => supportedEditors[botsStore.botSpec?.type || 'none']);
</script>
