/*
 * Knowledge Bases Store
 * */
import { defineStore } from 'pinia';
import { ref, reactive, computed } from 'vue';
import { pathOr, hasPath } from 'ramda';
import { useAuthStore } from '@/store/AuthStore';
import { useCollection } from 'vuefire';
import { doc, setDoc, getDoc, collection, deleteDoc } from 'firebase/firestore';
import { fbDb } from '@/firebaseApp';
import { useToast } from 'vue-toastification';
import { useApi } from '@/hooks/useApi';
import { getUUID, pathPut } from '@enegelai/core';
import { KB_TYPE_DEFS } from '@/spec/KbTypeDefs';

const EMPTY_KB_SPEC = {
  name: 'New KB',
};

export const useKbStore = defineStore('kb', () => {
  const authStore = useAuthStore();

  const tenantId = computed(() => authStore.tenantId);

  const api = useApi();

  // List of all kbs, with subscription - will be updated if any document is changed in the collection
  const knowledgeBases = useCollection(collection(fbDb, `tenants/${tenantId.value}/kb`));

  const kbIdAssigned = ref(null);

  const kbSpec = ref(reactive({}));
  const kbId = computed(() => kbSpec.value?.id || null);
  const kbName = computed(() => kbSpec.value?.name || null);
  const kbType = computed(() => kbSpec.value?.type || null);
  const kbTags = computed(() => kbSpec.value?.tags || []);

  const kbTypeTitle = computed(() => {
    return kbType.value in KB_TYPE_DEFS ? KB_TYPE_DEFS[kbType.value].title : 'UNKNOWN';
  });

  const kbIcon = computed(() => {
    return kbType.value in KB_TYPE_DEFS ? KB_TYPE_DEFS[kbType.value].icon : 'message-processing-outline';
  });

  const kbDocumentsCollection = computed(() => (kbId.value ? collection(fbDb, `tenants/${tenantId.value}/kb/${kbId.value}/documents`) : null));
  const kbDocuments = useCollection(kbDocumentsCollection);

  const currentKbDocumentId = ref(null);
  const currentKbDocument = computed(() => (Array.isArray(kbDocuments.value) && currentKbDocumentId.value ? kbDocuments.value.find((x) => x.id === currentKbDocumentId.value) : null));
  const currentKbDocumentTitle = computed(() => (currentKbDocument.value ? currentKbDocument.value?.metadata?.title || null : null));
  const currentKbDocumentName = computed(() => (currentKbDocument.value ? currentKbDocument.value?.name || null : null));
  const currentKbDocumentTags = computed(() => (currentKbDocument.value ? currentKbDocument.value?.metadata?.tags || [] : []));
  const currentKbDocumentDoNotSplit = computed(() => (currentKbDocument.value ? currentKbDocument.value?.doNotSplit || false : false));
  const currentKbDocumentContent = ref();

  const selectedKbDocuments = ref([]);

  const toast = useToast();

  function getKb(kbId) {
    const kb = knowledgeBases.value.find((x) => x.id === kbId);
    return kb ? kb : null;
  }

  function normalizeKbSpec() {
    if (!('id' in kbSpec.value) || !kbSpec.value.id) {
      const id = getUUID();
      setKbSpecProp('id', id);
    }
    if (!('type' in kbSpec.value) || !(kbSpec.value.type in KB_TYPE_DEFS)) {
      setKbSpecProp('type', 'standard');
    }
    const kbType = kbSpec.value?.type || 'standard';
    // Set defaults for all spec props
    const kbTypeDef = KB_TYPE_DEFS[kbType] || null;
    if (kbTypeDef) {
      const props = kbTypeDef?.props || [];
      for (let i = 0; i < props.length; i++) {
        let pName = props[i]?.name;
        let pDef = props[i]?.default;
        // pName could be path in dot notation: prop.childprop ...
        const propPath = pName.split('.');
        const propExists = hasPath(propPath, kbSpec.value);
        //console.log(`Checking: ${propPath}: exists: ${propValue !== null}`);
        if (!propExists) {
          setKbSpecProp(pName, pDef);
        }
      }
    }
  }

  function setKbSpecProp(propPath, value) {
    pathPut(kbSpec.value, propPath, value);
  }

  // Can pass array or dot notation path, like "spec.bot.prop"
  function getKbSpecPropGetSet(propPath) {
    const pp = Array.isArray(propPath) ? propPath : propPath.split('.');
    return {
      get() {
        return pathOr(null, pp, kbSpec.value);
      },
      set(newValue) {
        setKbSpecProp(pp, newValue);
      },
    };
  }

  async function createKb(kbType, kbName = 'New Knowledge Base') {
    const newKbSpec = {
      id: getUUID(),
      name: kbName,
      type: kbType,
    };
    kbSpec.value = reactive(newKbSpec);
    normalizeKbSpec();
    return newKbSpec.id;
  }

  // Should be called from Kb editor when we are already editing spec
  // Just updates name and ID
  function cloneKbInPlace(newName = '') {
    const newKbName = newName && newName !== '' ? newName : kbName.value + ' (Copy)';
    const newKbId = getUUID();
    kbSpec.value.id = newKbId;
    kbSpec.value.name = newKbName;
    return newKbId;
  }

  // TODO Revisit this - should be called from Kb list ?
  async function cloneKb(kbSpec) {
    const newKbName = (kbSpec?.name || 'New Knowledge Base') + ' (Copy)';
    const newKbSpec = Object.assign({}, JSON.parse(JSON.stringify(kbSpec)), {
      id: getUUID(),
      name: newKbName,
    });
    kbSpec.value = reactive(newKbSpec);
    normalizeKbSpec();
    return newKbSpec.id;
  }

  async function importKb(newKbSpec) {
    kbSpec.value = reactive(newKbSpec);
    normalizeKbSpec();
    return kbSpec.value.id;
  }

  // Load existing or create new empty kb document
  // Save id, so we know current kb id while loading spec
  async function loadKb(id) {
    // TODO double-check and refine for create KB case
    kbIdAssigned.value = id;
    selectedKbDocuments.value = [];
    const emptySpec = Object.assign({ id: id }, EMPTY_KB_SPEC);
    let currentKbSpec = null;
    const kbDocRef = doc(fbDb, `tenants/${tenantId.value}/kb`, id);
    const kbDoc = await getDoc(kbDocRef);
    if (kbDoc.exists()) {
      const kbSpecDoc = await getDoc(doc(kbDocRef, 'versions', 'current'));
      if (!kbSpecDoc.exists()) {
        currentKbSpec = emptySpec;
      } else {
        const specData = kbSpecDoc.data();
        let spec = null;
        try {
          spec = JSON.parse(specData.spec);
        } catch (e) {
          console.log(`KB[${id}]: Failed to parse spec JSON: ${e.message}`);
          spec = emptySpec;
        }
        currentKbSpec = spec;
      }
    } else {
      console.log(`KB[${id}]: Does not exist, assuming new`);
      currentKbSpec = emptySpec;
    }
    kbSpec.value = reactive(currentKbSpec);
    normalizeKbSpec();
  }

  async function addTagToKb(tag) {
    const kbId = kbSpec.value?.id || null;
    if (!kbId) {
      return false; // TODO error
    }
    if (!Array.isArray(kbSpec.value?.tags)) {
      kbSpec.value.tags = [];
    }
    kbSpec.value.tags.push(tag);
    await saveKb();
  }

  // Saves currently loaded KB
  async function saveKb() {
    const kbId = kbSpec.value?.id || null;
    if (!kbId) {
      return false; // TODO error
    }
    try {
      await setDoc(
        doc(fbDb, `tenants/${tenantId.value}/kb`, kbId),
        {
          name: kbSpec.value?.name || '',
          description: kbSpec.value?.description || '',
          type: kbSpec.value?.type || '',
          tags: kbSpec.value?.tags || [],
          updated: Date.now(),
          updatedBy: authStore.displayName,
        },
        { merge: true },
      );
    } catch (e) {
      console.log(`KB[${kbId}]: Error writing document: ${e.message}`);
      return false;
    }

    const kbSpecJSON = JSON.stringify(kbSpec.value);
    try {
      await setDoc(doc(fbDb, `tenants/${tenantId.value}/kb/${kbId}/versions`, 'current'), {
        spec: kbSpecJSON,
      });
    } catch (e) {
      console.log(`KB[${kbId}]: Error writing spec: ${e.message}`);
      return false;
    }
    return true;
  }

  async function deleteKb(kbId) {
    try {
      // TODO Delete versions collection ?
      await deleteDoc(doc(fbDb, `tenants/${tenantId.value}/kb`, kbId));
    } catch (e) {
      console.log(`KB[${kbId}]: Error deleting KB: ${e.message}`);
      return false;
    }
    return true;
  }

  async function loadKbDocument(kbId, kbDocumentId) {
    if (kbIdAssigned.value !== kbId) {
      console.log(`Error: kbId mismatch: ${kbIdAssigned.value} !== ${kbId}`);
      return;
    }
    if (currentKbDocumentId.value === kbDocumentId) {
      return;
    }
    currentKbDocumentId.value = kbDocumentId;
    currentKbDocumentContent.value = '';
    const res = await api.getKbDocumentContent({
      kbId: kbId,
      documentId: kbDocumentId,
    });
    if (res.success) {
      currentKbDocumentContent.value = res.data;
    } else {
      currentKbDocumentContent.value = null;
      toast.error(`Unable to load document: ${res.error}`);
    }
  }

  async function unloadKbDocument() {
    currentKbDocumentId.value = null;
    currentKbDocumentContent.value = null;
  }

  function setTagsForCurrentKbDocument(tags) {
    if (!currentKbDocument.value) {
      return;
    }
    if (!currentKbDocument.value?.metadata) {
      currentKbDocument.value.metadata = {};
    }
    currentKbDocument.value.metadata.tags = tags;
  }

  function setDoNotSplitForCurrentKbDocument(val) {
    if (!currentKbDocument.value) {
      return;
    }
    currentKbDocument.value.doNotSplit = val;
  }

  async function saveCurrentKbDocument() {
    const res = await api.setKbDocumentContent({
      kbId: kbId.value,
      documentId: currentKbDocumentId.value,
      documentContent: currentKbDocumentContent.value,
      documentProps: {
        doNotSplit: currentKbDocument.value?.doNotSplit,
        metadata: {
          tags: currentKbDocument.value?.metadata?.tags || [],
        },
      },
      updatedBy: authStore.displayName,
    });
    if (!res.success) {
      // ???
      toast.error(`Error saving document: ${res.error}`);
    }
    return res;
  }

  function getDocumentInfoByMetadata(metadata) {
    if (!Array.isArray(kbDocuments.value) || kbDocuments.value.length <= 0) {
      return {};
    }
    const id = metadata?.id || null;
    const source = metadata?.source || null;
    if (id) {
      const dI = kbDocuments.value.find((x) => x.id === id);
      return dI ? dI : {};
    } else if (source) {
      const dI = kbDocuments.value.find((x) => x?.metadata?.source === source);
      return dI ? dI : {};
    } else {
      return {};
    }
  }

  // Create new KB document
  // TODO refine parameters - meta, original content, etc
  async function createKbDocument(documentName, documentContent, documentOriginalContent = null, documentOriginalMimeType = null, documentMetadata = {}) {
    const res = await api.createKbDocument({
      kbId: kbId.value,
      documentName: documentName,
      documentContent: documentContent,
      documentOriginalContent: documentOriginalContent,
      documentOriginalMimeType: documentOriginalMimeType,
      documentMetadata: documentMetadata,
      updatedBy: authStore.displayName,
    });
    return res;
  }

  async function deleteKbDocument(documentId) {
    const res = await api.deleteKbDocument({
      kbId: kbId.value,
      documentId: documentId,
    });
    return res;
  }

  async function updateKbDataSources(options) {
    const res = await api.updateKbDataSources({
      kbId: kbId.value,
      options: options,
    });
    return res;
  }

  // ACTIONS // ////////////////////////////////////////////////

  // Called before saving KB document.
  // Observers can subscribe and prepare document for saving
  async function beforeSaveKbDocument() {
    console.log(`kbStore: beforeSaveKbDocument executed`);
    return null;
  }

  return {
    knowledgeBases,
    kbSpec,
    kbId,
    kbName,
    kbType,
    kbTypeTitle,
    kbTags,
    kbIcon,
    kbDocuments,
    currentKbDocumentId,
    currentKbDocument,
    currentKbDocumentTitle,
    currentKbDocumentName,
    currentKbDocumentContent,
    currentKbDocumentTags,
    currentKbDocumentDoNotSplit,
    selectedKbDocuments,
    getKb,
    createKb,
    cloneKb,
    cloneKbInPlace,
    importKb,
    loadKb,
    setKbSpecProp,
    getKbSpecPropGetSet,
    addTagToKb,
    saveKb,
    deleteKb,
    loadKbDocument,
    unloadKbDocument,
    setTagsForCurrentKbDocument,
    setDoNotSplitForCurrentKbDocument,
    saveCurrentKbDocument,
    getDocumentInfoByMetadata,
    createKbDocument,
    deleteKbDocument,
    updateKbDataSources,
    beforeSaveKbDocument,
  };
});
