<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="emit('cancel')">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <FileUpload name="demo[]" customUpload @uploader="onFileImport($event)" :multiple="false" accept="application/json" :maxFileSize="1000000"
                          :pt="{
                              thumbnail: { src: '/img/icon-file.svg', class:'text-gray-200' }
                          }"
              >
                <template #empty>
                  <p>Drag and drop files to here to upload.</p>
                </template>
              </FileUpload>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
  import { ref } from 'vue';
  import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
  import FileUpload from 'primevue/fileupload';

  const open = ref(false);
  const title = ref('Operation');
  const description = ref('');
  const state = ref('progress');
  const message = ref('');
  const confirmText = ref('OK');

  const emit = defineEmits(['import','cancel']);

  function show(options) {
    state.value = 'progress';
    title.value = options?.title || '';
    description.value = options?.description || '';
    message.value = options?.message || '';
    open.value = true;
  }

  function hide() {
    open.value = false;
  }

  function showError(options) {
    message.value = options?.message || '';
    state.value = 'error';
  }

  function showSuccess(options) {
    message.value = options?.message || '';
    state.value = 'success';
  }

  function onConfirm() {
    emit('confirm');
    open.value = false;
  }

  async function onFileImport(event) {
    const file = event.files[0];
    const text = await file.text();
    console.log(`Got file text:`, text);
    emit('import',text);
  }

  defineExpose({
    show,
    hide,
    showError,
    showSuccess,
  });
</script>
