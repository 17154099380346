import { useLayoutStore } from '@/store/LayoutStore';

export const useProgress = () => {
  const layoutStore = useLayoutStore();

  const startProgress = (opts) => {
    layoutStore.setProgress(opts?.progress || false);
    layoutStore.setBlockUI(opts?.blockUI || false);
    layoutStore.setSpinner(opts?.spinner || false);
  };

  const stopProgress = () => {
    layoutStore.setProgress(false);
    layoutStore.setBlockUI(false);
    layoutStore.setSpinner(false);
  };

  return {
    startProgress,
    stopProgress,
  };
};
