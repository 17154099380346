<template>
  <Container orientation="vertical" drag-class="bg-teal-50 eng-dragged" @drop="onDrop" drag-handle-selector=".eng-action-menu">
    <Draggable v-for="(item, idx) in props.actions" :key="idx" style="overflow: visible">
      <spec-script-action-render
        :action="item"
        :idx="idx"
        @action-form-active="onActionFormActive"
        @addStepAbove="onAdd(idx)"
        @addStepBelow="onAdd(idx + 1)"
        @deleteStep="onDelete(idx)"
        @update:prop-value="
          (index, prop, value) => {
            emit('update:propValue', index, prop, value);
          }
        "
      ></spec-script-action-render>
    </Draggable>
  </Container>
  <div class="m-2 p-4 border-t flex flex-col justify-center">
    <div class="m-1 p-1 rounded flex flex-wrap items-center justify-center sm:flex-nowrap cursor-pointer hover:bg-indigo-50 text-slate-500 hover:text-slate-600">
      <div class="flex items-center" @click="actionSelectorOpen = true">
        <PlusIcon class="mr-2 h-8 w-8 text-gray-400" aria-hidden="true" />
        Add steps to define the scenario
      </div>
    </div>
    <div class="m-1 p-1 rounded flex flex-wrap items-center justify-center sm:flex-nowrap text-slate-400 hover:text-slate-600">
      <p class="mt-1 text-sm">Dbl-tap or Enter to Edit | Shift-Enter to finish Editing | Drag by menu on the left to Reorder</p>
    </div>
  </div>
  <ScriptStepSelector v-model="actionSelectorOpen" :media="props.media" @item-selected="onAddActionSelected"></ScriptStepSelector>
</template>

<script setup>
  // TODO Consider drag-and-drop options:
  //  https://github.com/gilnd/vue3-smooth-dnd / https://gilnd.github.io/vue3-smooth-dnd/
  //  https://github.com/MaxLeiter/sortablejs-vue3 - single component could be extracted from this one

  //import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/vue/24/outline';
  //import { pathOr } from 'ramda';
  //import { getActionDef } from '@/spec/ActionDefs.js';
  import { ref, onMounted, computed } from 'vue';
  import SpecScriptActionRender from '@/components/Tests/SpecScriptActionRender.vue';
  import { Container, Draggable } from 'vue3-smooth-dnd';
  import SpecScriptActionEditor2 from '@/components/Tests/SpecScriptActionEditor2.vue';
  import SpecScriptActionsMenu from '@/components/Tests/SpecScriptActionsMenu.vue';
  import ScriptStepSelector from '@/components/Selectors/ScriptStepSelector.vue';
  import { PlusIcon } from '@heroicons/vue/20/solid';

  const props = defineProps({
    media: {
      type: String,
      default: 'voice',
    },
    actions: {
      type: Array,
      default() {
        return [];
      },
    },
  });

  const emit = defineEmits(['select', 'add-action', 'move-action', 'action-form-active', 'delete-step', 'update:propValue']);

  function onActionFormActive(idx, active) {
    emit('action-form-active', idx, active);
  }

  function onDrop(dropResult) {
    console.log(`onDrop: ${JSON.stringify(dropResult)}`);
    if (dropResult?.removedIndex >= 0 && dropResult?.addedIndex >= 0) {
      emit('move-action', {
        fromIndex: dropResult.removedIndex,
        toIndex: dropResult.addedIndex,
      });
    }
  }

  const actionSelectorOpen = ref(false);
  let addIndex = -1;
  function onAddActionSelected(action) {
    actionSelectorOpen.value = false;
    console.log(`Add Action Selected: ${JSON.stringify(action)}`);
    emit('add-action', { type: action.type, idx: addIndex });
    addIndex = -1;
  }

  function onAdd(idx) {
    addIndex = idx;
    actionSelectorOpen.value = true;
  }

  function onDelete(idx) {
    emit('delete-step', { idx: idx });
  }

  onMounted(() => {
    console.log(`ScriptEditor: actions: ${JSON.stringify(props.actions)}`);
  });
</script>
