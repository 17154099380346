/*
 * Role Based Access Control Store
 * */
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useSettingsStore } from '@/store/SettingsStore';

export const useRbacStore = defineStore('rbac', () => {
  const authStore = useAuthStore();
  const settingsStore = useSettingsStore();

  const isSuperAdmin = computed(() => {
    if (authStore.userRole !== 'admin') {
      return false;
    }

    if (authStore.userEmail.endsWith('@enegel.com') || authStore.userEmail.endsWith('@crescendocx.ai')) {
      return true;
    }

    return false;
  });

  const navigation = computed(() => {
    // TODO Refine this
    if (authStore.userRole === 'agent') {
      const nav = [];
      if (settingsStore.productEnabledQA) {
        nav.push({ name: 'Agent', to: '/agent' });
      }
      return nav;
    }
    if (authStore.userRole === 'user' || authStore.userRole === 'viewer') {
      const nav = [];
      if (settingsStore.productEnabledCSat) {
        nav.push({ name: 'VoC', to: '/csat' });
      }
      if (settingsStore.productEnabledQA) {
        nav.push({ name: 'Insights', to: '/insights' });
        nav.push({ name: 'Agent', to: '/agent' });
        nav.push({ name: 'Analysis', to: '/analysis' });
        nav.push({ name: 'Data', to: '/datasets' });
      }
      if (settingsStore.productEnabledBot) {
        nav.push({ name: 'Assistants', to: '/bots' });
      }
      return nav;
    }
    if (authStore.userRole === 'admin') {
      const nav = [];
      if (settingsStore.productEnabledCSat) {
        nav.push({ name: 'VoC', to: '/csat' });
      }
      if (settingsStore.productEnabledQA) {
        nav.push({ name: 'Insights', to: '/insights' });
        nav.push({ name: 'Agent', to: '/agent' });
        nav.push({ name: 'Analysis', to: '/analysis' });
        nav.push({ name: 'Data', to: '/datasets' });
      }
      if (settingsStore.productEnabledBot) {
        nav.push({ name: 'Assistants', to: '/bots' });
      }
      if (settingsStore.productEnabledKB) {
        nav.push({ name: 'KB', to: '/kb' });
      }
      nav.push({ name: 'Settings', to: '/settings' });
      return nav;
    }
  });

  const canChangeAgent = computed(() => {
    return authStore.userRole !== 'agent';
  });

  const canEditBot = computed(() => {
    return ['admin', 'user'].includes(authStore.userRole);
  });

  const canEditKb = computed(() => {
    return ['admin', 'user'].includes(authStore.userRole);
  });

  function checkRoute(route) {
    switch (authStore.userRole) {
      case 'agent': {
        // Only agent is allowed for agent
        if (!route.startsWith('/agent')) {
          return { success: false, data: '/agent' };
        }
        break;
      }
      case 'user': {
        // Settings is disabled for users
        if (route.startsWith('/settings')) {
          return { success: false, data: '/' };
        }
        break;
      }
    }
    // OK, route passed
    return {
      success: true,
    };
  }

  return {
    isSuperAdmin,
    navigation,
    canChangeAgent,
    canEditBot,
    canEditKb,
    checkRoute,
  };
});
