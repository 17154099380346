<template>
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-center space-x-5 w-full">
        <div class="flex-shrink-0">
          <div class="relative bg-purple-600/70 rounded-xl p-1">
            <mdicon name="text-search" size="62" class="text-purple-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="w-full">
          <div class="w-full">
            <div class="relative">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                id="search"
                name="search"
                v-model="searchQuery"
                class="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-purple-500/50 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:text-md sm:leading-6"
                placeholder='Enter keywords or "quoted phrase" to Search Conversations'
                type="search"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <ProgressButton ref="searchProgressButton" title="Search" icon="text-search" @click="loadData"></ProgressButton>
        <Button icon="pi pi-upload" label="Upload" severity="secondary" @click="onUploadRecording" />
        <!--<button
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="loadData"
        >
          <ArrowDownCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
          Export
        </button>-->
      </div>
    </div>

    <FiltersList class="mt-2" @select-filter="onSelectFilter" @show-filters="showFilters"></FiltersList>

    <!--
    <div class="mt-1 w-full bg-gray-100/50 rounded-md p-1 flex justify-between font-source text-sm">
      <div class="my-1 ml-2 flex">
        <div>Search for conversations that took place:</div>
        <TimeRangeSelector :inline="true" class="ml-2 font-semibold"></TimeRangeSelector>
      </div>
      <div class="flex items-center">
        <div class="font-semibold my-1 ml-2 mr-2">{{ totalRecords }} total</div>
        <button
          class="flex items-center rounded-md bg-indigo-600 p-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="showFilters"
        >
          <mdicon name="filter" size="16" class="text-purple-100" aria-hidden="true"></mdicon>
        </button>
      </div>
    </div>
    -->

    <!--<div class="mt-1 w-full bg-gray-100/50 rounded-xl p-2">
      <div class="flex text-gray-400 font-source h-[180px] w-full">
        <DbDygraphsBar class="relative" :data="chartData" :options="chartOptions" @db-event="handleDbEvent"></DbDygraphsBar>
      </div>
    </div>-->

    <DbUplot
      class="w-full h-[160px] mt-2 bg-gray-100/50 rounded-xl"
      :data="uPlotData"
      :options="uPlotOptions"
      @db-event="handleDbEvent"
      :tooltip="true"
      :title="`${conversationSearchStore.conversationsTotal} Conversations`"
    ></DbUplot>

    <div class="mt-2">
      <DataTable
        :value="conversations"
        v-model:expanded-rows="expandedRows"
        lazy
        paginator
        :rows="25"
        :first="first"
        dataKey="id"
        :totalRecords="totalRecords"
        :loading="loading"
        sortField="datetime"
        :sortOrder="-1"
        @page="onPage($event)"
        @sort="onSort($event)"
        size="small"
        scrollable
        tableStyle="min-width: 50rem"
        filterDisplay="menu"
        v-model:filters="tableFiltersSpec"
        rowHover
      >
        <Column expander style="width: 1rem" />
        <Column header="Conversation" style="width: 40%" filterField="tags" :showFilterMenu="true" :showFilterMatchModes="false" :showClearButton="false" :showApplyButton="false">
          <template #filter="">
            <EditFilter :filter="filtersStore.getFilter('tags')" class=""></EditFilter>
          </template>
          <template #filterfooter="">
            <div class="flex justify-end">
              <div class="flex-0"><Button label="Edit Filters" icon="pi pi-filter" @click="showFilters" outlined size="small"></Button></div>
            </div>
          </template>
          <template #body="slotProps">
            <router-link :to="{ path: `/csat/conversation/${slotProps.data.id}` }">
              <div class="flex items-center">
                <div class="cursor-pointer hover:underline font-source font-semibold">
                  <!--<mdicon name="open-in-new" size="18" class="mt-1 text-slate-300"></mdicon>-->
                  {{ getConversationTitle(slotProps.data) }} ...
                </div>
              </div>
            </router-link>
            <div v-if="Array.isArray(slotProps.data.tags) && slotProps.data.tags.length > 0" class="mt-1 flex items-center">
              <span
                v-for="(tag, idx) in slotProps.data.tags"
                :key="idx"
                class="cursor-pointer mr-1 rounded-md bg-slate-50 px-2 py-1 text-sm font-source font-medium text-sky-700 ring-1 ring-inset ring-blue-700/10 hover:underline flex items-center"
                @click="onSetFilter('tags', [tag])"
              >
                <!--<router-link :to="{ path: route.path, query: { query: getSearchQueryTagConversations(tag) } }" class="flex items-center hover:underline">
                    <p>{{ tag }}</p>
                    <mdicon name="filter" size="16" class="text-slate-300"></mdicon>
                  </router-link>-->
                {{ tag }}
                <mdicon name="filter" size="20" class="text-slate-300"></mdicon>
              </span>
            </div>
          </template>
        </Column>
        <Column field="Number" header="Number" sortable :showFilterMenu="true" :showFilterMatchModes="false" :showClearButton="false" :showApplyButton="false">
          <template #filter="{ filterModel }">
            <EditFilter :filter="filtersStore.getFilter('Number')" class="p-2 m-2"></EditFilter>
          </template>
          <template #filterfooter="">
            <div class="flex justify-end">
              <div class="flex-0"><Button label="Edit Filters" icon="pi pi-filter" @click="showFilters" outlined size="small"></Button></div>
            </div>
          </template>
          <template #body="slotProps">
            <div class="flex items-center cursor-pointer hover:underline" @click="onSetFilter('Number', [slotProps.data?.Number || null])">
              <div class="font-medium">{{ slotProps.data?.Number || '-' }}</div>
              <mdicon name="filter" size="20" class="ml-2 text-gray-300"></mdicon>
            </div>
            <div v-if="slotProps.data?.NumberName && slotProps.data.NumberName !== ''" class="text-xs text-gray-500">{{ slotProps.data?.NumberName }}</div>
          </template>
        </Column>
        <Column field="AgentName" header="Agent" sortable :showFilterMenu="true" :showFilterMatchModes="false" :showClearButton="false" :showApplyButton="false">
          <template #filter="">
            <EditFilter :filter="filtersStore.getFilter('AgentName')" class="p-2 m-2"></EditFilter>
          </template>
          <template #filterfooter="">
            <div class="flex justify-end">
              <div class="flex-0"><Button label="Edit Filters" icon="pi pi-filter" @click="showFilters" outlined size="small"></Button></div>
            </div>
          </template>
          <template #body="slotProps">
            <div class="flex items-center cursor-pointer hover:underline" @click="onSetFilter('AgentName', [slotProps.data?.AgentName || null])">
              <div class="cursor-pointer font-medium">{{ slotProps.data?.AgentName || slotProps.data?.AgentId || '-' }}</div>
              <mdicon name="filter" size="20" class="ml-2 text-gray-300"></mdicon>
            </div>
          </template>
        </Column>
        <Column field="CSat" header="CSat" sortable :showFilterMenu="true" :showFilterMatchModes="false" :showClearButton="false" :showApplyButton="false">
          <!--<template #filter="">
            <EditFilter :filter="filtersStore.getFilter('CSat')" class=""></EditFilter>
          </template>
          <template #filterfooter="">
            <div class="flex justify-end">
              <div class="flex-0"><Button label="Edit Filters" icon="pi pi-filter" @click="showFilters" outlined size="small"></Button></div>
            </div>
          </template>-->
          <template #body="slotProps">
            <span :class="['px-2 py-1.5 font-source text-md rounded-sm', getCSatClass(slotProps.data)]">
              {{ 'CSat' in slotProps.data ? (slotProps.data.CSat !== null ? slotProps.data.CSat : '-') : '-' }}
              <!--
              / {{ slotProps.data.context.analysis.kpis.find((x) => x.title === 'NPS').score }}
              -->
            </span>
          </template>
        </Column>
        <!--<Column field="KPIScore" header="KPI Score" sortable>
          <template #body="slotProps">
            <span :class="['px-2 py-1.5 font-source text-md rounded-md', getKPIScoreClass(slotProps.data)]">
              {{ slotProps.data?.KPIScore }}
            </span>
          </template>
        </Column>-->
        <Column field="duration" header="Duration" sortable>
          <template #body="slotProps">
            {{ formatSeconds(slotProps.data?.duration || 0) }}
          </template>
        </Column>
        <Column field="datetime" header="Date" sortable>
          <template #body="slotProps">
            <div class="">{{ dayjs(slotProps.data?.datetime).format('MM/DD/YY HH:mm:ss') }}</div>
          </template>
        </Column>
        <template #expansion="slotProps">
          <div class="prose font-source text-md bg-violet-50 rounded-md px-4 py-1 text-slate-600" v-html="getCSatExplanation(slotProps.data)"></div>
        </template>
      </DataTable>
    </div>
    <EditFiltersSidebar v-model:visible="filtersOpen"></EditFiltersSidebar>
    <CSatDialogUploadRecording ref="dialogUploadRecording" @recordingAnalyzed="onRecordingAnalyzed"></CSatDialogUploadRecording>
  </div>
</template>
<script setup>
  import { ref, computed, onMounted, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { ArrowDownCircleIcon } from '@heroicons/vue/20/solid';
  import { useConversationSearchStore } from '@/store/ConversationSearchStore';
  import { useDateRangeStore } from '@/store/DaterangeStore';
  import { useFiltersStore } from '@/store/FiltersStore';
  import { stdConversationFilters } from '@/analytics/filtersDefs';
  import { useMd } from '@/hooks/useMd';
  import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import dayjs from 'dayjs';
  import DbDygraphsBar from '@/components/Charts/DbDygraphsBar.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';
  import EditFiltersSidebar from '@/components/Filters/EditFiltersSidebar.vue';
  import FiltersList from '@/components/Filters/FiltersList.vue';
  import EditFilter from '@/components/Filters/EditFilter.vue';
  import Button from 'primevue/button';
  import DbUplot from '@/components/Charts/DbUplot.vue';
  import uPlot from 'uplot';
  import CSatDialogUploadRecording from '@/components/CSat/dialogs/CSatDialogUploadRecording.vue';

  const route = useRoute();
  const conversationSearchStore = useConversationSearchStore();
  const dateRangeStore = useDateRangeStore();
  const filtersStore = useFiltersStore();
  const md = useMd();

  const q = decodeURIComponent(route?.query?.query || '');
  const queryFilter = route?.query?.filter || null;
  const searchQuery = ref(q);

  const searchProgressButton = ref(null);
  const dialogUploadRecording = ref(null);

  const filtersOpen = ref(false);
  const tableFiltersSpec = ref({
    tags: { value: null },
    Number: { value: null },
    AgentName: { value: null },
    CSat: { value: null },
  });

  // Search Results Table
  //const dt = ref();
  const loading = ref(false);
  const totalRecords = computed(() => conversationSearchStore.conversationsTotal);
  const first = computed(() => conversationSearchStore.searchOffset);
  const conversations = computed(() => conversationSearchStore.conversations);

  const expandedRows = ref([]);

  const chartData = computed(() => conversationSearchStore.timeline);
  const chartOptions = {
    stackedGraph: true,
    //title: 'Conversations',
    ylabel: 'Conversations',
    labels: ['Date', 'Count'],
    //legend: 'follow',
  };

  const uPlotData = computed(() => conversationSearchStore.uPlotTimeline);
  const uPlotOptions = computed(() => {
    return {
      //height: 200,
      legend: {
        show: false,
      },
      tzDate: (ts) => uPlot.tzDate(new Date(ts * 1e3), 'Etc/UTC'),
      scales: {
        x: { time: true },
      },
      series: [
        {},
        {
          label: 'Conversations',
          paths: uPlot.paths.bars({ align: 0 }),
          stroke: 'rgba(78, 121, 167, 0.1 )',
          fill: 'rgba(78, 121, 167, 0.4)',
        },
      ],
    };
  });

  watch(
    route,
    (newRoute) => {
      const nq = decodeURIComponent(newRoute?.query?.query || '');
      if (nq !== searchQuery.value) {
        console.log(`Route query changed: ${nq}`);
        searchQuery.value = nq;
        searchProgressButton.value.startProgress();
        loadData();
      }
    },
    { immediate: true },
  );

  watch(filtersStore.allFilters, (newValue) => {
    if (loading.value) {
      console.log(`Already loading - ignore filters change`);
      return;
    }
    console.log(`Filters changed: ${JSON.stringify(newValue)}`);
    loadData();
  });

  watch(
    () => dateRangeStore.timeIntervalChanged,
    (newValue) => {
      console.log(`Date range changed: ${JSON.stringify(newValue)}`);
      loadData();
    },
  );

  const onPage = async (event) => {
    console.log(`onPage: ${JSON.stringify(event)}`);
    if ('first' in event && 'rows' in event) {
      loading.value = true;
      await conversationSearchStore.page(event.first, event.rows);
      loading.value = false;
    }
  };

  const onSort = async (event) => {
    console.log(`onSort: ${JSON.stringify(event)}`);
    // [{field:'name',type:'asc|desc'},...]
    if ('sortField' in event && 'sortOrder' in event) {
      const sortFields = [
        {
          field: event.sortField,
          type: event.sortOrder === 1 ? 'asc' : 'desc',
        },
      ];
      loading.value = true;
      await conversationSearchStore.sort(sortFields);
      loading.value = false;
    }
  };

  async function loadData() {
    loading.value = true;
    await conversationSearchStore.search(searchQuery.value, 25, 0, [{ field: 'datetime', type: 'desc' }]);
    loading.value = false;
    searchProgressButton.value.stopProgress();
  }

  /*
  const rowClass = (data) => {
    //return [{ 'bg-slate-400': true }];
    return ['rounded-xl'];
  };
  const rowStyle = (data) => {
    return {}; //{ 'background-color': '#f8fafc' };
  };
  */

  function getCSatClass(data) {
    if (!('CSat' in data) || data.CSat === null) {
      return '';
    }
    let s = data.CSat;
    return s < 5 ? `bg-red-400/10` : `bg-green-400/30`;
  }

  // Get a title for the conversation, as a first customer message
  function getConversationTitle(conv) {
    const messages = conv?.context?.transcript?.data || [];
    if (!Array.isArray(messages) || messages.length <= 0) {
      return conv.id;
    }
    const customerMessage = messages.find((x) => x.actor === 'customer');
    if (!customerMessage || !customerMessage?.message || customerMessage.message === '') {
      return messages[0]?.message || conv.id;
    }
    return customerMessage.message.substring(0, 100);
  }

  function getCSatExplanation(conv) {
    return md.mdToHtml(conv?.CSatExplanation || '');
  }

  function formatSeconds(sec) {
    const diff = sec;
    const totalMinutes = Math.floor(diff / 60);
    const seconds = diff % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0
      ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      : `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  function handleDbEvent(event) {
    switch (event.type) {
      case 'zoom': {
        console.log(`Handle Zoom`, event);
        let from = event?.minDate || null;
        let to = event?.maxDate || null;
        if (from && to) {
          /* TODO Remove
          event.g.updateOptions({
            dateWindow: null,
            valueRange: null,
          }); */
          dateRangeStore.setDateRange(from, to);
          loadData();
        }
        break;
      }
    }
  }

  function getSearchQueryAgentConversations(data) {
    return encodeURIComponent(`AgentName:"${data.AgentName}"`);
  }

  function getSearchQueryNumberConversations(data) {
    return encodeURIComponent(`Number:"${data.Number}"`);
  }

  function getSearchQueryTagConversations(tag) {
    return encodeURIComponent(`tags:"${tag}"`);
  }

  function onSelectFilter(filter) {
    // TODO pass which filter to make active
    showFilters();
  }

  function showFilters() {
    filtersOpen.value = true;
  }

  function onSetFilter(field, value) {
    filtersStore.setFilterValue(field, value);
  }

  function setFiltersFromQuery() {
    if (!queryFilter || queryFilter === '') {
      return;
    }
    const f = Array.isArray(queryFilter) ? queryFilter : [queryFilter];
    if (f.length <= 0) {
      return;
    }
    const fDefs = f.map((x) => decodeURIComponent(x));
    console.log(`Setting up filters from query:`, fDefs);
    fDefs.map((x) => {
      let xp = x.split(':');
      if (Array.isArray(xp) && xp.length === 2) {
        filtersStore.setFilterValue(xp[0], [xp[1]]);
        return;
      }
      xp = x.split('>');
      if (Array.isArray(xp) && xp.length === 2) {
        filtersStore.setRangeFilterValue(xp[0], 'gte', [xp[1]]);
      }
    });
  }

  function onUploadRecording() {
    dialogUploadRecording.value.start();
  }

  function onRecordingAnalyzed() {
    loadData();
  }

  onMounted(() => {
    filtersStore.initFilters('conv', stdConversationFilters);
    setFiltersFromQuery();
    searchProgressButton.value.startProgress();
    loadData();
  });
</script>
