<template>
  <Disclosure as="nav" class="sticky top-0 z-50 bg-gray-800" v-slot="{ open }">
    <div class="mx-auto px-2 sm:px-6 lg:px-6">
      <div class="relative flex h-16 items-center justify-between">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <DisclosureButton class="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
        <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
          <a class="-mt-1 flex-none overflow-hidden" href="https://crescendocx.ai" target="_blank">
            <Logo></Logo>
          </a>
          <!--<div class="flex flex-shrink-0 items-center text-slate-200">
            <a class="mr-3 flex-none overflow-hidden" href="https://crescendocx.ai" target="_blank">
              <Logo></Logo>
            </a>
          </div>-->
          <div class="hidden sm:ml-4 sm:block">
            <ul class="flex space-x-2">
              <li v-for="item in rbac.navigation" :key="item.name">
                <router-link :to="item.to" class="text-md rounded-md px-3 py-2 font-medium text-gray-300 hover:bg-gray-700 hover:text-white" active-class="text-white dark:text-white bg-gray-900">
                  {{ item.name }}
                </router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <TimeRangeSelector v-if="timeRangeSelectorVisible" class="mr-4"></TimeRangeSelector>
          <!--<button type="button" class="rounded-full bg-gray-800 p-1 mr-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800">
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6 stroke-slate-500 hover:stroke-slate-200" aria-hidden="true" />
          </button>-->
          <button
            type="button"
            class="rounded-full bg-gray-800 p-1 mr-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-1 focus:ring-offset-gray-800"
            @click="onSetPrint"
          >
            <PrinterIcon :class="[layout.print ? 'stroke-sky-500 hover:stroke-sky-200' : 'stroke-slate-500 hover:stroke-slate-200', 'w-6 h-6']" aria-hidden="true" />
          </button>
          <!--<dark-mode-switch></dark-mode-switch>-->
          <!-- Profile dropdown -->
          <current-user class="ml-2 pl-3 border-l border-slate-400 dark:border-slate-800"></current-user>
        </div>
      </div>
    </div>

    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <DisclosureButton v-for="item in rbac.navigation" :key="item.name" as="div" class="flex">
          <router-link :to="item.to" class="w-full text-md rounded-md px-3 py-2 font-medium text-gray-300 hover:bg-gray-700 hover:text-white" active-class="text-white dark:text-white bg-gray-900">
            {{ item.name }}
          </router-link>
        </DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup>
  import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
  import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/vue/24/outline';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useRbacStore } from '@/store/RBACStore';
  import { PrinterIcon, PowerIcon } from '@heroicons/vue/24/outline';
  import { useRoute } from 'vue-router';
  import { computed } from 'vue';

  import CurrentUser from '@/components/Navbars/CurrentUser.vue';
  import Logo from '@/components/Navbars/Logo.vue';
  import DarkModeSwitch from '@/components/Navbars/DarkModeSwitch.vue';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';

  const rbac = useRbacStore();
  const layout = useLayoutStore();
  const route = useRoute();

  const timeRangeSelectorVisible = computed(() => {
    return route.path.startsWith('/insights') || route.path.startsWith('/bots') || route.path.startsWith('/csat');
  });

  // Allowed navigation items are taken from rbac
  /*
  const navigation = [
    //{ name: 'Summary', to: '/components' },
    { name: 'Insights', to: '/insights', current: true },
    { name: 'Agent', to: '/agent', current: true },
    // { name: 'Bots', to: '/flows', current: true },
    { name: 'Analysis', to: '/analysis', current: true },
    { name: 'Data', to: '/datasets', current: false },
    { name: 'Settings', to: '/settings', current: false },
    //{ name: 'Documentation', to: '/documentation', current: false },
  ];*/

  function onSetPrint() {
    layout.setPrint(!layout.print);
  }
</script>
