<template>
  <div>
    <div class="py-2 my-2 text-xl font-semibold leading-7 text-gray-500 sm:truncate sm:text-xl sm:tracking-tight mb-4">Agent Strengths</div>
    <EqList :items="agentStrengths" name-prop="name" name-title="Strength" :improvement="false"></EqList>
    <div class="py-2 my-2 text-xl font-semibold leading-7 text-gray-500 sm:truncate sm:text-xl sm:tracking-tight mb-4">Agent Weaknesses</div>
    <EqList :items="agentWeaknesses" name-prop="name" name-title="Weakness" :improvement="false" score-color="#991b1b"></EqList>
    <div class="py-2 my-2 text-xl font-semibold leading-7 text-gray-500 sm:truncate sm:text-xl sm:tracking-tight mb-4">Agent Improvement Opportunities</div>
    <EqList :items="agentOpportunities" name-prop="name" name-title="Opportunity" :improvement="false"></EqList>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import EqList from '@/components/Charts/EqList.vue';

  const props = defineProps({
    conversation: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  const agentStrengths = computed(() => props.conversation?.context?.analysis?.agentStrengths || []);
  const agentWeaknesses = computed(() => props.conversation?.context?.analysis?.agentWeaknesses || []);
  const agentOpportunities = computed(() => props.conversation?.context?.analysis?.agentOpportunities || []);
</script>
