/*
 * Filters
 */

export class FilterDef {
  constructor(opts = {}) {
    this.type = null; // term | terms | range
    this.title = null; // Display title
    this.field = null; // field name
    this.icon = null; // ???
    this.op = 'eq'; // eq | lg | le ? | starts | ends | contains
    this.enabled = true;
    this.value = null;
    Object.keys(opts).map((x) => {
      this[x] = opts[x];
    });
  }

  // This should preserve existing value if not overridden in opts
  update(opts = {}) {
    Object.keys(opts).map((x) => {
      this[x] = opts[x];
    });
  }

  hasValue() {
    if (this.value === null) {
      return false;
    }
    if (Array.isArray(this.value) && this.value.length <= 0) {
      return false;
    }
    return true;
  }

  getTextValue() {
    if (!this.hasValue()) {
      return '';
    }
    if (this.type === 'range') {
      let v = this.title + ': ';
      if (Array.isArray(this.value) && this.value.length > 0) {
        switch (this.op) {
          case 'eq': {
            v += `Equal to ${this.value[0]}`;
            break;
          }
          case 'gte': {
            v += `Greater than ${this.value[0]}`;
            break;
          }
          case 'lte': {
            v += `Less than ${this.value[0]}`;
            break;
          }
          case 'btw': {
            v += `Between ${this.value[0]} and ${this.value.length > 1 ? this.value[1] : 'Inf'}`;
          }
        }
      } else {
        v += 'Set value';
      }
      return v;
    }
    let v = Array.isArray(this.value) ? this.value.join(',') : '' + this.value;
    v = v.length > 25 ? v.substring(0, 25) + '...' : v;
    return v;
  }

  toElastic() {
    if (!this.field || !this.value || !this.type || !this.enabled) {
      return null;
    }
    switch (this.type) {
      case 'term': {
        return {
          type: this.type,
          field: this.field,
          value: Array.isArray(this.value) ? (this.value.length > 0 ? this.value[0] : null) : this.value,
        };
      }
      case 'terms': {
        if (Array.isArray(this.value) && this.value.length > 0) {
          return {
            type: this.type,
            field: this.field,
            value: this.value,
          };
        }
        break;
      }
      case 'match': {
        return {
          type: this.type,
          field: this.field,
          value: this.value,
        };
        break;
      }
      case 'range': {
        if (Array.isArray(this.value) && this.value.length > 0) {
          switch (this.op) {
            case 'eq': {
              return { type: 'term', field: this.field, value: this.value[0] };
            }
            case 'gte': {
              const f = {};
              f[this.field] = { gte: this.value[0] };
              return { type: 'range', field: f };
            }
            case 'lte': {
              const f = {};
              f[this.field] = { lte: this.value[0] };
              return { type: 'range', field: f };
            }
            case 'btw': {
              const f = {};
              f[this.field] = { gte: this.value[0] };
              if (this.value.length > 1) {
                f[this.field].lte = this.value[1];
              }
              return { type: 'range', field: f };
            }
          }
        }
      }
    }
    return null; // TODO
  }
}
