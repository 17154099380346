<template>
  <div class="absolute top-0 inset-x-0 flex justify-center overflow-hidden pointer-events-none" style="z-index: -1">
    <div class="w-[108rem] flex-none flex justify-end">
      <picture>
        <!--<source srcset="/media/docs@30.8b9a76a2.avif" type="image/avif" />
        <img src="/media/docs@tinypng.d9e4dcdc.png" alt="" class="w-[71.75rem] flex-none max-w-none dark:hidden" decoding="async" />-->
        <img src="/img/beams/0.jpg" alt="" class="w-[100rem] flex-none max-w-none dark:hidden" decoding="async" />
      </picture>
      <picture>
        <!--<source srcset="/media/docs-dark@30.1a9f8cbf.avif" type="image/avif" />-->
        <img src="/media/docs-dark@tinypng.1bbe175e.png" alt="" class="w-[90rem] flex-none max-w-none hidden dark:block" decoding="async" />
      </picture>
    </div>
  </div>
</template>
