<template>
  <div class="bg-slate-600 rounded-md min-w-[150px] max-w-[250px] font-source">
    <div class="flex items-center text-white/80 bg-sky-600 rounded-t-md p-1">
      <div class="flex-shrink-0 mr-1">
        <mdicon :name="stepDef.icon" size="18" class="text-white/50" />
      </div>
      <div class="min-w-0 flex-1">
        <p class="text-sm">
          <a href="#" class="hover:underline">{{ stepDef.title }}</a>
        </p>
      </div>
      <div class="flex flex-shrink-0 self-center">
        <EllipsisVerticalIcon class="h-5 w-5 text-white/50" aria-hidden="true" />
      </div>
    </div>
    <div class="p-2">
      <div v-if="stepDisplayData.name" class="text-sm text-white">{{ stepDisplayData.name }}</div>
      <div v-if="stepDisplayData.description" class="text-xs text-white/60">{{ stepDisplayData.description }}</div>
      <div v-if="Array.isArray(stepDisplayData.items) && stepDisplayData.items.length > 0">
        <div v-if="stepDisplayData.itemsName" class="mt-1.5 text-xs text-white/40">{{ stepDisplayData.itemsName }}</div>
        <ul role="list" class="mt-2 text-sm">
          <li v-for="(item, idx) in stepDisplayData.items" :key="idx" class="flex items-center my-1 bg-slate-400 rounded-sm">
            <mdicon :name="item.icon" size="16" class="text-white/50 mr-1" />
            <div class="text-white/80">{{ item.name }}</div>
          </li>
        </ul>
      </div>
    </div>
    <Handle id="a" type="target" :position="Position.Left" />
    <Handle id="b" type="source" :position="Position.Right" />
  </div>

  <!--<div>Select a color</div>
  <div style="display: flex; flex-direction: row; flex-wrap: wrap; justify-content: center; max-width: 90%; margin: auto; gap: 3px">OPA</div>
  -->
</template>

<script setup>
  import { Handle, Position } from '@vue-flow/core';
  import { computed } from 'vue';
  import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
  import { getActionDef } from '@/spec/ActionDefs.js';

  const props = defineProps({
    data: {
      type: Object,
      required: true,
    },
  });

  const emit = defineEmits(['change', 'gradient']);

  const stepDef = computed(() => getActionDef(props.data?.type));

  const stepDisplayData = computed(() => stepDef.value.getDisplayData(props.data));

  function onSelect(color) {
    emit('change', color);
  }

  function onGradient() {
    emit('gradient');
  }

  const sourceHandleStyleA = computed(() => ({ backgroundColor: props.data.color, filter: 'invert(100%)', top: '10px' }));

  const sourceHandleStyleB = computed(() => ({
    backgroundColor: props.data.color,
    filter: 'invert(100%)',
    bottom: '10px',
    top: 'auto',
  }));
</script>
