<template>
  <!-- Sidebar component, swap this element with another sidebar if you like -->
  <div v-if="!layout.print" class="h-full lg:w-64 flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white dark:border-0 dark:bg-gray-900 px-6 pb-4 pt-4 custom-scrollbar">
    <nav class="flex flex-1 flex-col">
      <ul role="list" class="flex flex-1 flex-col gap-y-7">
        <li>
          <ul role="list" class="-mx-2 space-y-1">
            <li v-for="item in sidebarItems" :key="item.name">
              <sidebar-item-render :item="item" :breakpoint="breakpoints.is"></sidebar-item-render>
              <!--
              <a :href="item.href" :class="[item.current ? 'bg-gray-800 text-white' : 'text-gray-400 hover:text-white hover:bg-gray-800', 'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold']">
                <component :is="item.icon" class="h-6 w-6 shrink-0" aria-hidden="true" />
                {{ item.name }}
              </a>-->
            </li>
          </ul>
        </li>
        <!--<li>
          <div class="text-xs font-semibold leading-6 text-gray-400 dark:text-gray-400">TODO: Breadcrubms</div>
          <ul role="list" class="-mx-2 mt-2 space-y-1">
            <li v-for="team in teams" :key="team.name">
              <a
                :href="team.href"
                :class="[
                  team.current ? 'bg-gray-50 text-indigo-600 dark:bg-gray-800 dark:text-white' : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-800',
                  'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                ]"
              >
                <span class="flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border dark:border-gray-700 dark:bg-gray-800 text-[0.625rem] font-medium dark:text-gray-400 dark:group-hover:text-white">{{
                  team.initial
                }}</span>
                <span class="truncate">{{ team.name }}</span>
              </a>
            </li>
          </ul>
        </li>-->
        <!--<li class="mt-auto">
          <router-link to="/settings" class="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 dark:text-gray-400 dark:hover:bg-gray-800 dark:hover:text-white">
            <Cog6ToothIcon class="h-6 w-6 shrink-0" aria-hidden="true" />
            Settings
          </router-link>
        </li>-->
      </ul>
    </nav>
  </div>

  <!--<div>
    <div v-if="['sm', 'md', 'lg', 'xl'].includes(breakpoints.is)">
      <sidebar-items-renderer :items="sidebarItems" :breakpoint="breakpoints.is"></sidebar-items-renderer>
    </div>
  </div>-->
</template>

<script setup>
  import { useRoute } from 'vue-router';
  import { ref, watch, computed, onMounted } from 'vue';
  import { useSidebarStore } from '@/store/SidebarStore';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useBreakpoint } from '@/hooks/useBreakpoint';
  import SidebarItemRender from '@/components/Sidebar/SidebarItemRender.vue';

  import { Bars3Icon, BellIcon, CalendarIcon, ChartPieIcon, Cog6ToothIcon, DocumentDuplicateIcon, FolderIcon, HomeIcon, UsersIcon, XMarkIcon } from '@heroicons/vue/24/outline';
  import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid';

  const route = useRoute();
  const { breakpoints } = useBreakpoint();
  const currentRoute = ref();

  const layout = useLayoutStore();
  const store = useSidebarStore();

  watch(
    () => route.path,
    (newPath) => {
      currentRoute.value = newPath;
      store.setRoutePath(newPath);
      console.log(`WATCH: newPath=${newPath}`);
    },
  );

  const sidebarItems = computed(() => store.sidebarItems);

  //console.log(`Current breakpoints: ${JSON.stringify(breakpoints)}`);

  onMounted(() => {
    store.setRoutePath(route.path);
  });

  const teams = [
    { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
    { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
    { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
  ];
</script>
