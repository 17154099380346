<template>
  <div>
    <div v-if="inline" class="inline-flex cursor-pointer items-center hover:underline" @click="onOpen">{{ current }}</div>
    <ItemSelector :items="allAgents" icon="face-agent" placeholder="Search Agents ..." :open="open" @select="onSelect" @close="open = false"></ItemSelector>
  </div>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import ItemSelector from '@/components/Selectors/ItemSelector.vue';
  import { useAgentsInsightsStore } from '@/store/AgentsInsightsStore';

  const props = defineProps({
    inline: {
      type: Boolean,
      default: true,
    },
    current: {
      type: String,
      default: '',
    },
  });
  const emit = defineEmits(['agent-selected']);

  const agentsInsightsStore = useAgentsInsightsStore();

  const open = ref(false);

  agentsInsightsStore.getAllKnownAgents().then(() => {});

  const allAgents = computed(() => {
    if (!Array.isArray(agentsInsightsStore.allKnownAgents)) {
      return [];
    }
    return agentsInsightsStore.allKnownAgents.map((x) => {
      return {
        name: x.agentName,
        description: `AgentID: ${x.agentId}`,
        agentName: x.agentName,
        agentId: x.agentId,
      };
    });
  });

  function onOpen() {
    open.value = true;
  }

  function onSelect(item) {
    open.value = false;
    emit('agent-selected', item);
  }
</script>
