<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8">
    <div class="sm:flex sm:items-center">
      <div class="flex-auto flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-violet-600/70 rounded-xl p-1">
            <mdicon name="file-document-multiple-outline" size="62" class="text-violet-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-2xl font-bold text-gray-900">Job Results</h1>
          <p class="text-sm font-medium text-gray-500">Results of the all executed Jobs</p>
        </div>
      </div>

      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <div class="flex items-center">
          <button
            v-if="showDelete"
            type="button"
            class="mr-2 flex items-center gap-x-1 rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            @click="onDeleteJobResult"
          >
            <mdicon name="minus-circle-outline" class="h-6 w-6" aria-hidden="true"></mdicon>
            Delete
          </button>
        </div>
      </div>
    </div>
    <div class="mt-8">
      <DataTable v-model:selection="selectedDocuments" :value="collectionItems" sortField="started" :sortOrder="-1" paginator :rows="25" :rowsPerPageOptions="[25, 50, 100]" removableSort tableStyle="min-width: 50rem">
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="name" header="Job" sortable class="">
          <template #body="slotProps">
            <div class="flex items-center">
              <div v-if="slotProps.data?.status === 'Running'" class="flex items-center">
                <svg class="animate-spin mr-2 h-5 w-5 text-indigo-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                <div class="font-semibold">{{ slotProps.data?.name || slotProps.data?.id || 'None' }}</div>
              </div>
              <router-link v-else :to="{ path: `/analysis/results/details/${slotProps.data.id}` }" class="flex items-center">
                <mdicon name="file-document-multiple-outline" size="18" :class="['mr-2 ', getIconClass(slotProps.data)]"></mdicon>
                <div class="cursor-pointer font-medium hover:underline">{{ slotProps.data?.name || slotProps.data?.id || 'None' }}</div>
              </router-link>
            </div>
          </template>
        </Column>
        <Column field="started" header="Started" sortable>
          <template #body="slotProps">
            <div class="">{{ dayjs(slotProps.data?.started).format('MMM D HH:mm:ss') }}</div>
          </template>
        </Column>
        <Column field="status" header="Status" sortable></Column>
        <Column field="duration" header="Duration" sortable></Column>
        <Column field="scenariosTotal" header="Scenarios" sortable>
          <template #body="slotProps">
            <div v-if="slotProps.data?.status === 'Running'">
              <div>{{ slotProps.data?.scenariosCompleted }} / {{ slotProps.data?.scenariosTotal }}</div>
              <div class="w-full bg-gray-200 rounded-full h-1 dark:bg-gray-700">
                <div class="bg-blue-600 h-1 rounded-full dark:bg-blue-500" :style="`width: ${Math.floor(((slotProps.data?.scenariosCompleted || 0) / (slotProps.data?.scenariosTotal || 1)) * 100)}%`"></div>
              </div>
            </div>
            <div v-else>
              {{ slotProps.data?.scenariosTotal }}
            </div>
          </template>
        </Column>
        <Column field="scenariosPassed" header="Pass" sortable></Column>
        <Column field="scenariosFailed" header="Fail" sortable></Column>
        <Column field="words" header="Words" sortable></Column>
        <!--<Column header="" style="width: 1%">
          <template #body="slotProps">
            <mdicon name="folder-edit-outline" class="cursor-pointer text-gray-400 hover:text-indigo-600" size="22"></mdicon>
          </template>
        </Column>-->
      </DataTable>
    </div>
    <ConfirmationDialog :open="deleteConfirm" title="Delete" :description="`Are you sure you want to delete selected Jobs Results?`" @cancel="deleteConfirm = false" @confirm="onDeleteConfirmed"></ConfirmationDialog>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useJobsStore } from '@/store/JobsStore';
  import dayjs from 'dayjs';
  import { useToast } from 'vue-toastification';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';

  const jobsStore = useJobsStore();
  const toast = useToast();

  const collectionItems = computed(() => jobsStore.jobsResults);

  const selectedDocuments = ref();
  const showDelete = computed(() => Array.isArray(selectedDocuments.value) && selectedDocuments.value.length > 0);

  //function getIcon(jobResultData) {
  //  return jobResultData?.scenariosFailed > 0 ? 'exclamation-thick' : 'check';
  //}

  function getIconClass(jobResultData) {
    return jobResultData?.scenariosFailed > 0 ? 'text-red-500/50' : 'text-gray-500/50';
  }
  // Delete
  const deleteConfirm = ref(false);

  async function onDeleteConfirmed() {
    deleteConfirm.value = false;
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`Nothing selected to delete`);
      return;
    }
    console.log(`Deleting jobs results: ${JSON.stringify(selectedDocuments.value)}`);
    let overallResult = true;
    for (let i = 0; i < selectedDocuments.value.length; i++) {
      const doc = selectedDocuments.value[i];
      const deleteResult = await jobsStore.deleteJobExecutionResults(doc.id);
      overallResult &= deleteResult;
    }
    if (overallResult) {
      toast.success('Deleted successfully');
    } else {
      toast.error(`Error when deleting`);
    }
    selectedDocuments.value = [];
  }

  async function onDeleteJobResult() {
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`Nothing selected to delete`);
      return;
    }
    deleteConfirm.value = true;
  }
</script>
