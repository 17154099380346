<template>
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-orange-600/70 rounded-xl p-1">
            <mdicon name="face-agent" size="62" class="text-orange-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-2xl font-bold text-gray-900">Agents KPI Scores</h1>
          <div class="my-1 flex items-center">
            <div>Analyzing Agents KPI Scores for the time period:</div>
            <TimeRangeSelector :inline="true" class="ml-2 font-semibold"></TimeRangeSelector>
          </div>
        </div>
      </div>

      <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <!--
        <button type="button" class="flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="onExport">
          <mdicon name="download-outline" class="h-6 w-6 mr-1 text-gray-500" aria-hidden="true"></mdicon>
          Export
        </button>
        -->

        <ProgressButton ref="analyzeProgressButton" title="Analyze" icon="motion-play-outline" @click="loadData(true)"></ProgressButton>
      </div>
    </div>

    <!--<div class="mt-6 py-2 px-2 leading-7 text-gray-600 mb-4 flex items-center justify-between border-b border-gray-300">
      <div class="text-md font-semibold flex items-center">
        <mdicon name="face-agent" size="18" class="rounded bg-orange-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <div>All Agents</div>
      </div>
    </div>-->
    <div class="mt-4">
      <BlockUI :blocked="loading">
        <DataTable ref="reportTable" :value="agents" :sortField="tableSortField" :sortOrder="tableSortOrder" removableSort @sort="onTableSort" tableStyle="min-width: 50rem" size="small">
          <ColumnGroup type="header">
            <Row>
              <Column header="Agent" field="agentName" :rowspan="2" sortable />
              <Column field="count" :rowspan="2" sortable>
                <template #header="slotProps">
                  <span title="Conversations">Conv</span>
                </template>
              </Column>
              <Column :colspan="kpisColumns.length">
                <template #header="slotProps">
                  <div class="w-full flex items-center justify-between">
                    <div>KPI Scores</div>
                    <SelectButton v-model="kpiStat" :options="kpiStatOptions" optionLabel="title" optionValue="value" :allowEmpty="false" aria-labelledby="basic" :pt="{ button: { class: 'py-1' } }" />
                  </div>
                </template>
              </Column>
              <Column header="All" :field="`allKpisStats.${kpiStat}`" :rowspan="2" sortable />
            </Row>
            <Row>
              <Column v-for="kc in kpisColumns" :field="`kpis.${kc.key}.${kpiStat}`" sortable>
                <template #header="slotProps">
                  <span :title="kc.name">{{ kc.key.substring(0, 3) }}</span>
                </template>
              </Column>
            </Row>
          </ColumnGroup>
          <Column field="agentName" header="Agent" sortable class="" :pt="{ bodyCell: { class: 'pl-0 py-0' } }">
            <template #body="slotProps">
              <div class="flex items-center px-1 py-1">
                <mdicon name="face-agent" size="18" class="p-1 mr-2 text-slate-50 bg-slate-400/50 rounded-md" aria-hidden="true"></mdicon>
                <router-link :to="{ path: '/csat/search', query: { filter: getFilterQueryConversations(slotProps.data) } }" class="flex items-center hover:underline">
                  <div class="cursor-pointer font-medium">{{ slotProps.data?.agentName || '-' }}</div>
                </router-link>
              </div>
            </template>
          </Column>
          <!--<Column field="agentId" header="AgentID" sortable class="">
          <template #body="slotProps">
            <div class="font-medium">{{ slotProps.data?.agentId || '-' }}</div>
          </template>
        </Column>-->
          <Column field="count" header="Conv" sortable :pt="{ bodyCell: { class: 'py-0' } }">
            <template #body="slotProps">
              <router-link :to="{ path: '/csat/search', query: { filter: getFilterQueryConversations(slotProps.data) } }" class="px-2 py-1 flex items-center hover:underline">
                <div class="cursor-pointer font-source font-medium">{{ slotProps.data.conversationsCount }}</div>
                <mdicon name="open-in-new" size="20" class="ml-2 text-gray-300"></mdicon>
              </router-link>
            </template>
          </Column>
          <Column v-for="kc in kpisColumns" :pt="{ bodyCell: { class: 'py-0' } }">
            <template #body="slotProps">
              <div :class="['w-fit font-source px-2 py-1', slotProps.data.kpis[kc.key][kpiStat] > 0 ? 'font-medium' : 'text-gray-400']" :style="getValueStyle(slotProps.data.kpis[kc.key][kpiStat])">
                {{ (slotProps.data.kpis[kc.key][kpiStat] || 0).toFixed(2) }}
              </div>
              <!--<router-link :to="{ path: '/csat/search', query: { filter: getFilterQueryConversations(slotProps.data) } }" class="flex items-center hover:underline">
              <div :class="['cursor-pointer font-source px-2 py-1', slotProps.data.kpis[kc.key][kpiStat] > 0 ? 'font-medium' : 'text-gray-400']" :style="getValueStyle(slotProps.data.kpis[kc.key][kpiStat])">
                {{ (slotProps.data.kpis[kc.key][kpiStat] || 0).toFixed(2) }}
              </div>
            </router-link>-->
            </template>
          </Column>
          <Column sortable :pt="{ bodyCell: { class: 'py-0' } }">
            <template #body="slotProps">
              <div class="px-0 py-1 font-bold font-source">{{ (slotProps.data?.allKpisStats[kpiStat] || 0).toFixed(2) }}</div>
            </template>
          </Column>
        </DataTable>
      </BlockUI>
      <ProgressSpinner v-if="loading" class="fixed top-1/2 left-1/2"></ProgressSpinner>
    </div>
  </div>
</template>
<script setup>
  // TODO consider switching table to lazy loading to speed up initial rendering
  import { ref, computed, onMounted, watch, nextTick } from 'vue';
  import { useAnalyticsStore } from '@/store/AnalyticsStore';
  import { useDateRangeStore } from '@/store/DaterangeStore';
  import { useFiltersStore } from '@/store/FiltersStore';
  import { AnalyticsQuery } from '@/analytics/AnalyticsQuery';
  import { useAgentsKPIScoresStore } from '@/store/AgentsKPIScoresStore';
  import { getStorePropGetSet } from '@/utils/getStorePropGetSet';
  import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';
  import AgentsListStats from '@/components/Insights/AgentsListStats.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';
  import { pathOr } from 'ramda';
  import Column from 'primevue/column';
  import DataTable from 'primevue/datatable';
  import ColumnGroup from 'primevue/columngroup';
  import Row from 'primevue/row';
  import SelectButton from 'primevue/selectbutton';
  import BlockUI from 'primevue/blockui';
  import ProgressSpinner from 'primevue/progressspinner';
  import * as d3 from 'd3';
  import * as d3Color from 'd3-color';

  //const conversationSearchStore = useConversationSearchStore();
  const agentsKPIScoresStore = useAgentsKPIScoresStore();
  const analyticsStore = useAnalyticsStore();
  const dateRangeStore = useDateRangeStore();
  const filtersStore = useFiltersStore();

  const analyzeProgressButton = ref(null);
  const reportTable = ref(null);

  // Search Results Table
  //const dt = ref();
  const loading = ref(false);

  const conversationsCount = ref(0);
  const agentsCount = ref(0);
  const agentsById = ref({}); // hash by agentId with value index in agents array

  //const agents = computed(getStorePropGetSet(agentsKPIScoresStore, 'agentsData'));
  const agents = ref([]);
  const kpisColumns = computed(getStorePropGetSet(agentsKPIScoresStore, 'KPIColumns'));

  // KPI Stat to show in the table - avg, min, max, sum
  const kpiStatOptions = [
    { title: 'Avg', value: 'avg' },
    { title: 'Min', value: 'min' },
    { title: 'Max', value: 'max' },
    { title: 'Sum', value: 'sum' },
  ];
  const kpiStat = computed(getStorePropGetSet(agentsKPIScoresStore, 'KPIStat'));
  const tableSortField = computed(getStorePropGetSet(agentsKPIScoresStore, 'sortField'));
  const tableSortOrder = computed(getStorePropGetSet(agentsKPIScoresStore, 'sortOrder'));
  const colorScaleSumMax = computed(getStorePropGetSet(agentsKPIScoresStore, 'colorScaleSumMax'));

  const colorScale = d3.scaleSequential(d3.interpolateRdYlGn).domain([0, 10]);
  let colorScaleSum = d3.scaleSequential(d3.interpolateRdYlGn).domain([0, colorScaleSumMax.value]);

  watch(
    () => dateRangeStore.timeIntervalChanged,
    (newValue) => {
      console.log(`Date range changed: ${JSON.stringify(newValue)}`);
      loadData();
    },
  );

  async function queryAgentScores() {
    const q = new AnalyticsQuery();

    const queryTimeInterval = dateRangeStore.timeInterval;
    q.timeInterval.from = queryTimeInterval?.from || 'StartOfDay';
    q.timeInterval.to = queryTimeInterval?.to || 'now';
    q.timeInterval.interval = queryTimeInterval?.interval || '1D'; // '1D'

    agentsKPIScoresStore.analyzedTimeInterval = JSON.parse(JSON.stringify(q.timeInterval));

    q.groupings = [
      {
        name: 'agent',
        title: 'Agent',
        type: 'terms',
        field: 'AgentId',
        size: 250,
        groupings: [
          {
            name: 'agentKPIScores',
            type: 'nested',
            path: 'context.analysis.kpis',
            groupings: [
              {
                name: 'filteredKpis',
                type: 'filter',
                query: { range: { 'context.analysis.kpis.score': { gte: 0 } } },
                groupings: [
                  {
                    name: 'kpi',
                    type: 'terms',
                    field: 'context.analysis.kpis.title',
                    missing: 'N/A',
                    size: 100,
                    //order: { score: 'desc' },
                    groupings: [
                      { name: 'kpiName', type: 'terms', size: 1, field: 'context.analysis.kpis.kpi' },
                      { name: 'stats', type: 'stats', field: 'context.analysis.kpis.score' },
                    ],
                  },
                  { name: 'allKpisStats', type: 'stats', field: 'context.analysis.kpis.score' },
                ],
              },
            ],
          },
          { name: 'AgentName', type: 'terms', size: 1, field: 'AgentName' },
        ],
      },
    ];

    q.request = AnalyticsQuery.prepareQuery({
      timeInterval: q.timeInterval,
      groupings: q.groupings,
      indexPrefix: 'conv',
      timestampField: 'datetime',
      // We're only interested in conversations that have KPI analyzed
      filters: [
        { type: 'range', field: { KPIScore: { gte: 0 } } },
        /* This does not work
        {
          type: 'nested',
          path: 'context.analysis.kpis',
          query: {
            type: 'range',
            field: { score: { gte: 0 } },
          },
        },*/
      ],
    });

    await analyticsStore.executeAnalyticsQuery(q);
    processQueryResult(q);
  }

  function processQueryResult(q) {
    conversationsCount.value = 0;
    agentsCount.value = 0;
    //agents.value = [];
    agentsKPIScoresStore.agentsData = [];
    agentsById.value = {};

    if (!q.success) {
      console.log(`Error: query failed: ${q.error}`);
      // TODO toast
      return;
    }

    const queryResult = q.result;

    let currConversations = queryResult?.total || 0;
    const agentsData = [];
    const agentsByIdData = {};
    let agentsLen = 0;

    const agentsBuckets = queryResult?.aggregations?.agent?.buckets || null;
    if (!agentsBuckets) {
      return false;
    }

    const allKpis = {};

    // Pass 1: collect all known KPIs
    for (let i = 0; i < agentsBuckets.length; i++) {
      const bucket = agentsBuckets[i];
      const agentKPIsBuckets = pathOr('', ['agentKPIScores', 'filteredKpis', 'kpi', 'buckets'], bucket);
      if (Array.isArray(agentKPIsBuckets)) {
        agentKPIsBuckets.map((x) => {
          allKpis[x.key] = pathOr(x.key, ['kpiName', 'buckets', 0, 'key'], x);
        });
      }
    }
    const kpis = Object.keys(allKpis).sort();
    kpisColumns.value = kpis.map((x) => {
      return { key: x, name: allKpis[x] };
    });
    const zeroKpi = { count: 0, min: 0, max: 0, avg: 0, sum: 0 };

    // Pass 2: collect KPI scores per agent
    let maxKpiSumValue = 0;
    for (let i = 0; i < agentsBuckets.length; i++) {
      const bucket = agentsBuckets[i];
      //currConversations += bucket?.doc_count || 0;
      const agentKpis = {};
      kpis.map((s) => {
        agentKpis[s] = zeroKpi;
      });
      const agentKPIsBuckets = pathOr('', ['agentKPIScores', 'filteredKpis', 'kpi', 'buckets'], bucket);
      agentKPIsBuckets.map((kb) => {
        const kpi = kb.key;
        const kpiStats = kb?.stats || null;
        if (kpiStats) {
          agentKpis[kpi] = kpiStats;
          if (kpiStats.sum > maxKpiSumValue) {
            maxKpiSumValue = kpiStats.sum;
          }
        } else {
          agentKpis[kpi] = zeroKpi;
        }
      });
      agentsLen = agentsData.push({
        agentId: bucket.key,
        agentName: pathOr('', ['AgentName', 'buckets', 0, 'key'], bucket),
        allKpisStats: bucket?.agentKPIScores?.filteredKpis?.allKpisStats || zeroKpi,
        kpis: agentKpis,
        conversationsCount: bucket?.doc_count || 0,
        count: bucket?.doc_count || 0,
      });
      agentsByIdData[bucket.key] = agentsLen - 1;
    }

    conversationsCount.value = currConversations;
    agentsById.value = agentsByIdData;
    agentsCount.value = agentsLen;
    //agents.value = agentsData;
    agentsKPIScoresStore.agentsData = agentsData;
    agents.value = agentsData;
    colorScaleSumMax.value = maxKpiSumValue;
    colorScaleSum = d3.scaleSequential(d3.interpolateRdYlGn).domain([0, maxKpiSumValue]);
  }

  async function loadData(forceReload = false) {
    if (!forceReload && agentsKPIScoresStore.agentsData.length > 0 && dateRangeStore.isSameFromTo(agentsKPIScoresStore.analyzedTimeInterval)) {
      // Show existing data
      //analyzeProgressButton.value.startProgress();
      //loading.value = true;
      await nextTick();
      agents.value = agentsKPIScoresStore.agentsData;
      //loading.value = false;
      //analyzeProgressButton.value.stopProgress();
      return;
    }

    analyzeProgressButton.value.startProgress();
    loading.value = true;
    await queryAgentScores();
    loading.value = false;
    analyzeProgressButton.value.stopProgress();
  }

  function onTableSort(evt) {
    tableSortField.value = evt?.sortField || null;
    tableSortOrder.value = evt?.sortOrder || null;
    //console.log(`On table sort:`, evt);
  }

  function hex2RGBA(hex, opacity) {
    let c = d3Color.color(hex);
    c.opacity = opacity;
    return c + '';
  }

  function rgb2rgba(rgb, opacity) {
    let c = d3Color.color(rgb);
    c.opacity = opacity;
    return c + '';
  }

  function getValueStyle(val) {
    if (val > 0) {
      const c = kpiStat.value === 'sum' ? colorScaleSum(val) : colorScale(val);
      const co = rgb2rgba(c, 0.4);
      return `background-color: ${co};`;
    }
    return '';
  }

  function getFilterQueryConversations(data) {
    //return [encodeURIComponent(`AgentName:"${data.agentName}"`), encodeURIComponent(`CSat:1`)];
    return [encodeURIComponent(`AgentName:${data.agentName}`), encodeURIComponent(`KPIScore>0`)];
  }

  function getFilterQueryConversationsWithScore(data, score) {
    return [encodeURIComponent(`AgentName:${data.agentName}`), encodeURIComponent(`CSat:${score}`)];
  }

  function onExport() {
    // TODO Revisit - this does not export full data by default - because of slots ???
    reportTable.value.exportCSV();
  }

  onMounted(() => {
    loadData();
  });
</script>
