<template>
  <div class="px-6 py-4">
    <div class="sm:flex sm:items-center">
      <div class="flex-auto flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-orange-600/70 rounded-xl p-1">
            <mdicon name="message-processing-outline" size="62" class="text-orange-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-2xl font-bold text-gray-900">Bots</h1>
          <p class="text-sm font-medium text-gray-500">A list of all the Bots in your account</p>
        </div>
      </div>

      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <div class="flex items-center">
          <button type="button" class="mr-2 flex items-center rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="onImportBot">
            <mdicon name="upload-outline" class="h-6 w-6 mr-1 text-gray-500" aria-hidden="true"></mdicon>
            Import
          </button>

          <button
            v-if="showDelete"
            type="button"
            class="mr-2 flex items-center gap-x-1 rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            @click="onDeleteBots"
          >
            <mdicon name="minus-circle-outline" class="h-6 w-6" aria-hidden="true"></mdicon>
            Delete
          </button>
          <DropdownSelectButton class="ml-4" title="Create Bot" :items="newBotItems" @select="onNewBotMenuSelect" />
        </div>
      </div>
    </div>
    <div class="mt-8">
      <DataTable
        v-model:selection="selectedDocuments"
        :value="botsStore.bots"
        sortField="updated"
        :sortOrder="-1"
        paginator
        :rows="25"
        :rowsPerPageOptions="[25, 50, 100]"
        removableSort
        tableStyle="min-width: 50rem"
        class=""
      >
        <Column selectionMode="multiple" headerStyle="width: 1%"></Column>
        <Column field="name" header="Name" sortable>
          <template #body="slotProps">
            <router-link :to="{ path: `/bots/editor/${slotProps.data.id}` }" class="flex items-center">
              <div class="font-medium hover:underline">
                {{ slotProps.data.name }}
              </div>
              <mdicon name="open-in-new" size="18" class="ml-1 text-slate-300" aria-hidden="true"></mdicon>
            </router-link>
          </template>
        </Column>
        <Column field="description" header="Description" sortable />
        <Column field="type" header="Type" sortable>
          <template #body="slotProps"> {{ BOT_TYPE_DEFS[slotProps.data.type]?.title || '' }} </template>
        </Column>
        <Column field="updated" header="Last Updated" sortable>
          <template #body="slotProps"> {{ dayjs(slotProps.data.updated).format('MM/DD/YYYY') }} </template>
        </Column>
      </DataTable>
    </div>
    <ConfirmationDialog :open="deleteConfirm" title="Delete" :description="`Are you sure you want to delete selected Bots ?`" @cancel="deleteConfirm = false" @confirm="onDeleteConfirmed"></ConfirmationDialog>
    <ImportFileDialog ref="importSpecDialog" @cancel="onImportCancel" @import="onSpecImport"></ImportFileDialog>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import { useBotsStore } from '@/store/BotsStore';
  import { nanoid } from 'nanoid';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
  import { useToast } from 'vue-toastification';
  import { computed, ref } from 'vue';
  import dayjs from 'dayjs';
  import { BOT_TYPE_DEFS } from '@/spec/BotTypeDefs';
  import DropdownSelectButton from '@/components/Buttons/DropdownSelectButton.vue';
  import ImportFileDialog from '@/components/Dialogs/ImportFileDialog.vue';

  const router = useRouter();
  const botsStore = useBotsStore();
  const toast = useToast();

  const importSpecDialog = ref(null);

  const newBotItems = computed(() =>
    Object.keys(BOT_TYPE_DEFS).map((j) => {
      const x = BOT_TYPE_DEFS[j];
      return {
        type: x.type,
        title: x.title,
        description: x.description,
        icon: x.icon,
        defaultName: x.defaultName,
      };
    }),
  );

  async function onNewBotMenuSelect(item) {
    const newBotId = await botsStore.createBot(item.type, item.defaultName);
    if (newBotId) {
      await router.push(`/bots/editor/${newBotId}`);
    } else {
      console.log(`Failed to create new Bot`);
    }
  }

  const selectedDocuments = ref();
  const showDelete = computed(() => Array.isArray(selectedDocuments.value) && selectedDocuments.value.length > 0);

  // Delete
  const deleteConfirm = ref(false);

  async function onDeleteConfirmed() {
    deleteConfirm.value = false;
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`Nothing selected to delete`);
      return;
    }
    console.log(`Deleting bots: ${JSON.stringify(selectedDocuments.value)}`);
    let overallResult = true;
    for (let i = 0; i < selectedDocuments.value.length; i++) {
      const doc = selectedDocuments.value[i];
      const deleteResult = await botsStore.deleteBot(doc.id);
      overallResult &= deleteResult;
    }
    if (overallResult) {
      toast.success('Deleted successfully');
    } else {
      toast.error(`Error when deleting`);
    }
    selectedDocuments.value = [];
  }

  async function onDeleteBots() {
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`Nothing selected to delete`);
      return;
    }
    deleteConfirm.value = true;
  }

  function onImportBot() {
    importSpecDialog.value.show();
  }

  function onImportCancel() {
    importSpecDialog.value.hide();
  }

  async function onSpecImport(specText) {
    importSpecDialog.value.hide();
    let botSpec = null;
    try {
      botSpec = JSON.parse(specText);
    } catch (e) {
      console.log(`Failed to parse bot json`, specText);
      toast.error(`Not a valid bot json file`);
      return;
    }

    if (!botSpec?.id || !botSpec?.type) {
      toast.error(`Not a valid bot json file`);
      return;
    }

    const newBotId = await botsStore.importBot(botSpec);
    if (newBotId) {
      await router.push(`/bots/editor/${newBotId}`);
    } else {
      toast.error(`Failed to import Bot`);
    }
  }
</script>
