<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold leading-7 text-gray-900">{{ collectionDoc?.name || '' }}</h1>
        <p class="mt-2 text-sm text-gray-700">A list of all files in the collection</p>
      </div>
      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          type="button"
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="handleUploadOpen"
        >
          <ArrowUpCircleIcon class="-ml-1.5 h-5 w-5" aria-hidden="true" />
          Upload Files
        </button>
        <DashboardModal
          :uppy="uppy"
          :open="uploadOpen"
          :props="{
            proudlyDisplayPoweredByUppy: false,
            onRequestCloseModal: handleUploadClose,
          }"
        />
      </div>
    </div>
    <div class="mt-8">
      <DataTable :value="collectionFilesList" paginator :rows="25" :rowsPerPageOptions="[25, 50, 100]" removableSort tableStyle="min-width: 50rem" class="p-datatable-sm">
        <Column field="name" header="Name" sortable class="">
          <template #body="slotProps">
            <div class="flex items-center">
              <div class="flex mr-2 h-7 w-7 flex-none items-center justify-center rounded-md text-white bg-indigo-500">
                <mdicon name="text-account" size="16"></mdicon>
              </div>
              <div class="font-medium hover:underline">{{ slotProps.data.name }}</div>
            </div>
          </template>
        </Column>
        <Column field="type" header="Type" sortable></Column>
        <Column field="size" header="Size" sortable></Column>
      </DataTable>
    </div>
  </div>
</template>

<script setup>
  // TODO See how to upload to Firebase Storage using Uppy
  //  https://github.com/sne11ius/egghead.space/blob/feature/file_upload/src/components/FileUpload.vue

  import { ref, computed } from 'vue';
  import { useRoute } from 'vue-router';
  import { useAuthStore } from '@/store/AuthStore';
  import { useCollectionsStore } from '@/store/CollectionsStore';
  import { useCollection, useDocument } from 'vuefire';
  import { collection } from 'firebase/firestore';
  import { DashboardModal } from '@uppy/vue';
  import Uppy from '@uppy/core';
  import { UppyFirebaseCloudStorage } from '@/firebase/UppyFirebaseCloudStorage';
  import { ArrowUpCircleIcon } from '@heroicons/vue/20/solid';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import { fbDb } from '@/firebaseApp';

  const route = useRoute();
  const authStore = useAuthStore();
  const collectionsStore = useCollectionsStore();

  const collectionId = ref(route.params?.id || null);
  const collectionType = ref(route.meta?.collectionType || null);
  // This should be pre-loaded already
  const allCollections = useCollection(collectionsStore.getCollection(collectionType.value));
  const collectionDoc = computed(() => allCollections.value.find((x) => x.id === collectionId.value));

  const collectionFilesListPath = `tenants/${authStore.tenantId}/${collectionType.value}/${collectionId.value}/files`;
  const collectionFilesList = useCollection(collection(fbDb, collectionFilesListPath));

  const bucketStorageRef = collectionsStore.getCollectionStorageRef(collectionType.value, collectionId.value);

  let filesUploaded = 0;
  const uploadOpen = ref(false);
  const uppy = new Uppy({
    id: 'uppy',
    autoProceed: false,
    debug: true,
    restrictions: {
      allowedFileTypes: route?.meta?.allowedFileTypes || [],
    },
  }).use(UppyFirebaseCloudStorage, { storageRef: bucketStorageRef });

  uppy.on('upload-success', (file, uploadResp) => {
    onFileUploadSuccess(file, uploadResp);
  });

  // Called when file is successfully uploaded to Firebase storage
  // Add an entry about this file in collection's files in firestore
  function onFileUploadSuccess(file /*, uploadResp*/) {
    if (file?.data && file.data?.name) {
      filesUploaded++;
      collectionsStore.setCollectionFileDoc(collectionType.value, collectionId.value, {
        name: file.data.name,
        type: file.data?.type || '',
        size: file.data?.size || 0,
        uploaded: Date.now(),
      });
    } else {
      console.log(`onFileUploadSuccess: ERROR: missing file attributes`);
    }
  }

  async function handleUploadClose() {
    uploadOpen.value = false;
    if (filesUploaded > 0) {
      // Update number of files in collection - naive implementation
      // Get count of items in 'files' collection after upload
      // TODO what if large number of files and pagination in the collection shown on the UI ?
      const newCount = collectionFilesList.value?.length || 0;
      await collectionsStore.updateCollectionDoc(collectionType.value, collectionId.value, {
        files: newCount,
      });
    }
  }

  function handleUploadOpen() {
    filesUploaded = 0;
    uploadOpen.value = true;
  }
</script>

<style src="@uppy/core/dist/style.css"></style>
<style src="@uppy/dashboard/dist/style.css"></style>
<style src="@uppy/drag-drop/dist/style.css"></style>
<style src="@uppy/progress-bar/dist/style.css"></style>
