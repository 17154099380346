<template>
  <div class="">
    <div class="py-2 text-xl font-bold leading-7 text-gray-500 sm:truncate sm:text-xl sm:tracking-tight mb-4">Agent Weaknesses</div>
    <EqList
      :items="agentDataStore.agentWeaknesses"
      name-prop="name"
      name-title="Weakness"
      trendProp="trendAvgScore"
      :reverse="true"
      score-color="#991b1b"
      pos-improvement-color="#991b1b"
      neg-improvement-color="#065f46"
    ></EqList>
  </div>
</template>

<script setup>
  import EqList from '@/components/Charts/EqList.vue';
  import { useAgentDataStore } from '@/store/AgentDataStore';

  const agentDataStore = useAgentDataStore();
</script>
