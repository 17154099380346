/*
 * Audio Store
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useAudioStore = defineStore('audio', () => {
  // List of audio files
  const audioData = ref([]);

  const audio = computed(() => audioData.value);

  function loadAudio(refresh = true) {
    if (!refresh && this.audioData.length > 0) {
      return;
    }
    const testsUrl = `/api/audio`;
    console.log(`Loading audio: ${testsUrl}`);
    fetch(testsUrl)
      .then(async (response) => {
        const responseData = await response.json();
        this.audioData = responseData;
      })
      .catch((e) => {
        console.log(`Exception retrieving audio ${testsUrl}: ${e.message}`);
      });
  }

  return {
    audio,
    audioData,
    loadAudio,
  };
});
