<template>
  <div>
    <Autocomplete v-model="selectedCollection" :items="collectionItems" display-prop="name" filter-prop="name"></Autocomplete>
  </div>
</template>

<script setup>
  import { computed, ref, watch } from 'vue';
  import Autocomplete from '@/components/Autocomplete/Autocomplete.vue';
  import { useCollectionsStore } from '@/store/CollectionsStore';
  import { useCollection } from 'vuefire';

  const props = defineProps(['type', 'modelValue']);
  const emit = defineEmits(['update:modelValue']);

  const collectionsStore = useCollectionsStore();
  const collectionItems = useCollection(collectionsStore.getCollection(props.type));

  /*
  const collectionItems = computed(() => {
    const c = collection.value;
    console.log(`Getting collections for type=${props.type}: ${c.length} items`);
    return c;
  });*/

  const selectedCollection = ref(null);

  const selectedCollectionId = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });

  selectedCollection.value = collectionItems.value.find((e) => e.id === selectedCollectionId.value) || null;

  watch(
    () => selectedCollection.value,
    async () => {
      selectedCollectionId.value = selectedCollection.value?.id;
    }
  );

  // Correctly reflect selection if we need to load collection first (i.e. it will be updated after load)
  watch(
    () => collectionItems.value,
    async () => {
      selectedCollection.value = collectionItems.value.find((e) => e.id === selectedCollectionId.value) || null;
    }
  );
</script>
