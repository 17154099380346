<template>
  <div class="flex">
    <db-easy-pie class="m-2 w-32 h-32 bg-slate-700 ring-8 ring-white rounded-full font-bold text-3xl" :value="4.4" :max="5.0" :line-width="10"></db-easy-pie>
    <db-easy-pie class="m-2 w-32 h-32 bg-slate-700 ring-8 ring-white rounded-full font-bold text-xl" :value="35" :max="70" :line-width="10" :show-percents="true" :percent-ranges="percentRanges"></db-easy-pie>
  </div>
</template>
<script setup>
  import DbEasyPie from '@/components/Charts/DbEasyPie.vue';

  const percentRanges = [
    { value: 30, color: '#dc2626' },
    { value: 50, color: '#ca8a04' },
    { value: 70, color: '#16a34a' },
  ];
</script>
