// Sample of configuring perspective views // https://bl.ocks.org/texodus/803de90736a3641ad91c5c7a1b49d0a7
<template>
  <div class="flex flex-col">
    <div class="z-20 flex-none lg:flex lg:items-center lg:justify-between p-4 border-b">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 dark:text-slate-400 sm:truncate sm:text-3xl sm:tracking-tight">Dataset</h2>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <button
          type="button"
          class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <CheckIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          Save
        </button>
      </div>
    </div>
    <div class="flex-auto z-0">
      <perspective-viewer ref="pvc" class="h-full w-full" />
      <perspective-table-editor-row :show="showEditor" :row="editRow" :schema="editorSchema" @save="onEditSave" @cancel="onEditCancel"></perspective-table-editor-row>
    </div>
  </div>
</template>
<script setup>
  import { ref, watch, computed, onMounted } from 'vue';
  import PerspectiveTableEditorRow from '@/components/PerspectiveTableEditorRow.vue';
  import { perspectiveTables } from '@/store/perspectivetables';
  // TODO Remove - Temp
  import csvData from '@/data/goals.csv?raw';
  import { CheckIcon, MapPinIcon, PlayIcon } from '@heroicons/vue/20/solid';

  // TODO Props, Emits
  //const props = defineProps(['tablename']);

  const showEditor = ref(false);
  const editRow = ref({});
  const editorSchema = ref({});
  const pvc = ref(null);

  let perspectiveTable = null;
  //let perspectiveView = null;

  async function initialize() {
    // Need to add column with row number, and use it as index. Use perspective temp table for that.
    let originalTable = await perspectiveTables.worker.table(csvData);
    let originalView = await originalTable.view();
    const originalSchema = await originalTable.schema();
    // We do not need to show Row in the editor
    editorSchema.value = originalSchema;
    // See https://github.com/finos/perspective/discussions/2207
    // This would export internal id (__ID__=[row number]), but still need to manipulate json ...
    // const jsonData = await originalView.to_json({ id: true });
    const jsonData = await originalView.to_json();
    for (let i = 0; i < jsonData.length; i++) {
      jsonData[i].Row = i + 1;
    }
    const schema = { Row: 'integer' };
    Object.keys(originalSchema).map((x) => {
      schema[x] = originalSchema[x];
    });
    perspectiveTable = await perspectiveTables.worker.table(schema, { index: 'Row' });
    perspectiveTable.update(jsonData);
    //perspectiveView = await perspectiveTable.view({ columns: ['PodName', 'Node'] });
    pvc.value.load(perspectiveTable);
    // View is created and maintained by perspective-viewer component based on configuration
    // This would show only subset of columns:
    //pvc.value.restore({ columns: ['PodName', 'Node'] });

    // Config examples
    // https://github.com/finos/perspective/blob/master/docs/src/components/Demo/layouts.js

    pvc.value.restore({
      plugin: 'Datagrid',
      plugin_config: { columns: { Row: { neg_bg_color: '#ffa38f', number_bg_mode: 'color', pos_bg_color: '#eef3f7' } }, editable: false, scroll_lock: false },
      theme: 'Pro Light',
      //title: 'Dataset', // Note: supplying title enables showing toolbar
      /*
      group_by: [],
      split_by: [],
      columns: ['Row', 'id', 'value'],
      filter: [],
      sort: [],
      expressions: [],
      aggregates: {},
      */
    });

    // This disables settings button
    pvc.value.shadowRoot.querySelector('div#settings_button').style.display = 'none';

    // Remove temp view and table
    await originalView.delete();
    originalView = null;
    await originalTable.delete();
    originalTable = null;

    pvc.value.addEventListener('perspective-click', (event) => {
      onGridClick(event);
    });
    // TODO how to disable "config" button ?
    //el.toggleConfig();
  }
  async function initialize2() {
    perspectiveTable = await perspectiveTables.worker.table(csvData);
    pvc.value.load(perspectiveTable);
    pvc.value.addEventListener('perspective-click', (event) => {
      onGridClick(event);
    });
  }

  async function onGridClick(eventData) {
    let row = eventData?.detail?.row || null;
    if (!row || !(Array.isArray(eventData?.detail?.column_names) && eventData.detail.column_names.length > 0 && eventData.detail.column_names[0] === 'Row')) {
      return;
    }
    //let schema = await perspectiveTable.schema();
    console.log(`Editing row: ${JSON.stringify(row)}`);
    editRow.value = row;
    // Now we can open dialog with row edit form, and when it's done, submit changes back to the table like that:
    // perspectiveTable.update({ Row: [0], PodName: ['test'] });
    // Open editor
    showEditor.value = true;
  }

  function onEditCancel() {
    showEditor.value = false;
  }

  function onEditSave(rowData) {
    showEditor.value = false;
    const row = rowData?.Row;
    if (row === undefined) {
      return;
    }
    console.log(`Updating row ${row}: ${JSON.stringify(rowData)}`);
    // neew to translate all props in rowData to arrays
    const rowUpdate = {};
    Object.keys(rowData).map((x) => {
      rowUpdate[x] = [rowData[x]];
    });
    console.log(`Updating row ${row}: ${JSON.stringify(rowUpdate)}`);
    perspectiveTable.update(rowUpdate);
    //perspectiveTable.update({ Row: [0], PodName: ['test'] });
  }

  onMounted(async () => {
    const startTs = Date.now();
    await initialize();
    const endTs = Date.now();
    console.log(`Initialize: took ${endTs - startTs} msec`);
  });
</script>
