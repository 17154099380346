<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8">
    <div class="sm:flex sm:items-center">
      <div class="flex-auto flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-violet-600/70 rounded-xl p-1">
            <mdicon name="play-box-outline" size="62" class="text-violet-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-2xl font-bold text-gray-900">Jobs</h1>
          <p class="text-sm font-medium text-gray-500">A list of all the Jobs in your account</p>
        </div>
      </div>

      <div class="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <div class="flex items-center">
          <button
            v-if="showDelete"
            type="button"
            class="mr-2 flex items-center gap-x-1 rounded-md bg-red-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            @click="onDeleteJobs"
          >
            <mdicon name="minus-circle-outline" class="h-6 w-6" aria-hidden="true"></mdicon>
            Delete
          </button>
          <DropdownSelectButton class="ml-4" title="Add Job" :items="newJobItems" @select="onNewJobMenuSelect" />
        </div>
      </div>
    </div>
    <div class="mt-8">
      <DataTable v-model:selection="selectedDocuments" :value="jobsStore.jobs" paginator :rows="25" :rowsPerPageOptions="[25, 50, 100]" removableSort tableStyle="min-width: 50rem" class="">
        <Column selectionMode="multiple" headerStyle="width: 1%"></Column>
        <Column field="name" header="Name" sortable>
          <template #body="slotProps">
            <div class="flex items-center cursor-pointer">
              <mdicon :name="slotProps.data.type in JOB_TYPE_INFO ? JOB_TYPE_INFO[slotProps.data.type].icon : 'play-box-outline'" size="18" class="p-1 text-slate-600/50"></mdicon>
              <!--<div class="flex mr-2 h-7 w-7 flex-none items-center justify-center rounded-md text-violet-500/70 bg-violet-50">
                <mdicon name="play-box-edit-outline" size="18"></mdicon>
              </div>-->
              <router-link :to="{ path: `${routeBase}/jobs/editor/${slotProps.data.id}` }">
                <div class="font-medium hover:underline">{{ slotProps.data.name }}</div>
              </router-link>
            </div>
          </template>
        </Column>
        <Column field="description" header="Description" sortable />
        <Column field="type" header="Type" sortable>
          <template #body="slotProps"> {{ JOB_TYPE_INFO[slotProps.data.type]?.title || '' }} </template>
        </Column>
        <Column field="updated" header="Last Updated" sortable>
          <template #body="slotProps"> {{ dayjs(slotProps.data.updated).format('MM/DD/YYYY') }} </template>
        </Column>
      </DataTable>
    </div>
    <ConfirmationDialog :open="deleteConfirm" title="Delete" :description="`Are you sure you want to delete selected Jobs ?`" @cancel="deleteConfirm = false" @confirm="onDeleteConfirmed"></ConfirmationDialog>
  </div>
</template>

<script setup>
  import { useRouter, useRoute } from 'vue-router';
  import { useJobsStore } from '@/store/JobsStore';
  import { nanoid } from 'nanoid';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
  import { useToast } from 'vue-toastification';
  import { computed, ref } from 'vue';
  import dayjs from 'dayjs';
  import { JOB_TYPE_INFO } from '@/const';
  import DropdownSelectButton from '@/components/Buttons/DropdownSelectButton.vue';

  const router = useRouter();
  const jobsStore = useJobsStore();
  const toast = useToast();

  const route = useRoute();
  const routeBase = computed(() => {
    const rp = route.path.split('/');
    if (Array.isArray(rp) && rp.length >= 2) {
      return `/${rp[1]}`;
    } else {
      return '/analysis';
    }
  });

  const newJobItems = computed(() =>
    Object.keys(JOB_TYPE_INFO).map((j) => {
      const x = JOB_TYPE_INFO[j];
      return {
        type: x.type,
        title: x.title,
        description: x.description,
        icon: x.icon,
        defaultName: x.defaultName,
      };
    }),
  );

  async function onNewJobMenuSelect(item) {
    const newJobId = await jobsStore.createJob(item.type, item.defaultName);
    if (newJobId) {
      await router.push(`${routeBase.value}/jobs/editor/${newJobId}`);
    } else {
      console.log(`Failed to create new Job`);
    }
  }

  const selectedDocuments = ref();
  const showDelete = computed(() => Array.isArray(selectedDocuments.value) && selectedDocuments.value.length > 0);

  // Delete
  const deleteConfirm = ref(false);

  async function onDeleteConfirmed() {
    deleteConfirm.value = false;
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`Nothing selected to delete`);
      return;
    }
    console.log(`Deleting jobs: ${JSON.stringify(selectedDocuments.value)}`);
    let overallResult = true;
    for (let i = 0; i < selectedDocuments.value.length; i++) {
      const doc = selectedDocuments.value[i];
      const deleteResult = await jobsStore.deleteJob(doc.id);
      overallResult &= deleteResult;
    }
    if (overallResult) {
      toast.success('Deleted successfully');
    } else {
      toast.error(`Error when deleting`);
    }
    selectedDocuments.value = [];
  }

  async function onDeleteJobs() {
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`Nothing selected to delete`);
      return;
    }
    deleteConfirm.value = true;
  }
</script>
