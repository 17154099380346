/*
 * Bots Store
 * */
import { defineStore } from 'pinia';
import { ref, reactive, computed } from 'vue';
import { pathOr, hasPath } from 'ramda';
import { useAuthStore } from '@/store/AuthStore';
import { useCollection } from 'vuefire';
import { doc, setDoc, getDoc, collection, deleteDoc } from 'firebase/firestore';
import { fbDb } from '@/firebaseApp';
import { useToast } from 'vue-toastification';
import { getBlob, getDownloadURL, ref as storageRef, getStorage } from 'firebase/storage';
import { getUUID, pathPut } from '@enegelai/core';
import { callFirebaseFunction } from '@/store/callFirebaseFunction';
import { BOT_TYPE_DEFS } from '@/spec/BotTypeDefs';

const EMPTY_BOT_SPEC = {
  name: 'New Bot',
};

export const useBotsStore = defineStore('bots', () => {
  const authStore = useAuthStore();

  const tenantId = computed(() => authStore.tenantId);

  // List of all bots, with subscription - will be updated if any document is changed in the collection
  const bots = useCollection(collection(fbDb, `tenants/${tenantId.value}/bots`));

  // TODO Use to store bot docs
  const storage = getStorage();

  const botSpec = ref(reactive({}));

  const botId = computed(() => botSpec.value?.id || null);
  const botName = computed(() => botSpec.value?.name || null);
  const botType = computed(() => botSpec.value?.type || null);

  const botTypeTitle = computed(() => {
    return botType.value in BOT_TYPE_DEFS ? BOT_TYPE_DEFS[botType.value].title : 'UNKNOWN';
  });

  const botIcon = computed(() => {
    return botType.value in BOT_TYPE_DEFS ? BOT_TYPE_DEFS[botType.value].icon : 'message-processing-outline';
  });

  const toast = useToast();

  function getBot(botId) {
    const bot = bots.value.find((x) => x.id === botId);
    return bot ? bot : null;
  }

  function normalizeBotSpec() {
    if (!('id' in botSpec.value) || !botSpec.value.id) {
      const id = getUUID();
      setBotSpecProp('id', id);
    }
    if (!('type' in botSpec.value) || !(botSpec.value.type in BOT_TYPE_DEFS)) {
      setBotSpecProp('type', 'questionAnswering');
    }
    const botType = botSpec.value?.type || 'questionAnswering';
    // Set defaults for all spec props
    const botTypeDef = BOT_TYPE_DEFS[botType] || null;
    if (botTypeDef) {
      const props = botTypeDef?.props || [];
      for (let i = 0; i < props.length; i++) {
        let pName = props[i]?.name;
        let pDef = props[i]?.default;
        // pName could be path in dot notation: prop.childprop ...
        const propPath = pName.split('.');
        const propExists = hasPath(propPath, botSpec.value);
        //console.log(`Checking: ${propPath}: exists: ${propValue !== null}`);
        if (!propExists) {
          setBotSpecProp(pName, pDef);
        }
      }
    }
  }

  function setBotSpecProp(propPath, value) {
    pathPut(botSpec.value, propPath, value);
  }

  async function createBot(botType, botName = 'New Bot') {
    const newBotSpec = {
      id: getUUID(),
      name: botName,
      type: botType,
    };
    botSpec.value = reactive(newBotSpec);
    normalizeBotSpec();
    return newBotSpec.id;
  }

  // Should be called from Bot editor when we are already editing spec
  // Just updates name and ID
  function cloneBotInPlace(newName = '') {
    const newBotName = newName && newName !== '' ? newName : botName.value + ' (Copy)';
    const newBotId = getUUID();
    botSpec.value.id = newBotId;
    botSpec.value.name = newBotName;
    return newBotId;
  }

  // TODO Revisit this - should be called from Bots list ?
  async function cloneBot(botSpec) {
    const newBotName = (botSpec?.name || 'New bot') + ' (Copy)';
    const newBotSpec = Object.assign({}, JSON.parse(JSON.stringify(botSpec)), {
      id: getUUID(),
      name: newBotName,
    });
    botSpec.value = reactive(newBotSpec);
    normalizeBotSpec();
    return newBotSpec.id;
  }

  async function importBot(newBotSpec) {
    botSpec.value = reactive(newBotSpec);
    normalizeBotSpec();
    return botSpec.value.id;
  }

  // Load existing or create new empty bot document
  async function loadBot(id) {
    // If this already loaded (i.e. newly created bot), return right away
    if (botId.value === id) {
      return;
    }
    const emptySpec = Object.assign({ id: id }, EMPTY_BOT_SPEC);
    let currentBotSpec = null;
    const botDocRef = doc(fbDb, `tenants/${tenantId.value}/bots`, id);
    const botDoc = await getDoc(botDocRef);
    if (botDoc.exists()) {
      const botSpecDoc = await getDoc(doc(botDocRef, 'versions', 'current'));
      if (!botSpecDoc.exists()) {
        currentBotSpec = emptySpec;
      } else {
        const specData = botSpecDoc.data();
        let spec = null;
        try {
          spec = JSON.parse(specData.spec);
        } catch (e) {
          console.log(`Bot[${id}]: Failed to parse spec JSON: ${e.message}`);
          spec = emptySpec;
        }
        currentBotSpec = spec;
      }
    } else {
      console.log(`Bot[${id}]: Does not exist, assuming new`);
      currentBotSpec = emptySpec;
    }
    botSpec.value = reactive(currentBotSpec);
    normalizeBotSpec();
  }

  // Saves currently loaded bot
  async function saveBot() {
    const botId = botSpec.value?.id || null;
    if (!botId) {
      return false; // TODO error
    }
    try {
      await setDoc(
        doc(fbDb, `tenants/${tenantId.value}/bots`, botId),
        {
          name: botSpec.value?.name || '',
          description: botSpec.value?.description || '',
          type: botSpec.value?.type || '',
          updated: Date.now(),
          // TODO updated by
        },
        { merge: true },
      );
    } catch (e) {
      console.log(`Bot[${botId}]: Error writing document: ${e.message}`);
      return false;
    }

    const botSpecJSON = JSON.stringify(botSpec.value);
    try {
      await setDoc(doc(fbDb, `tenants/${tenantId.value}/bots/${botId}/versions`, 'current'), {
        spec: botSpecJSON,
      });
    } catch (e) {
      console.log(`Bot[${botId}]: Error writing spec: ${e.message}`);
      return false;
    }
    return true;
  }

  async function deleteBot(botId) {
    try {
      // TODO Delete versions collection ?
      await deleteDoc(doc(fbDb, `tenants/${tenantId.value}/bots`, botId));
    } catch (e) {
      console.log(`Scenario[${botId}]: Error deleting bot: ${e.message}`);
      return false;
    }
    return true;
  }

  async function slackGetChannels() {
    const result = await callFirebaseFunction('slackGetChannels', {});
    return result;
  }

  // Can pass array or dot notation path, like "spec.bot.prop"
  function getPropGetSet(propPath) {
    const pp = Array.isArray(propPath) ? propPath : propPath.split('.');
    return {
      get() {
        return pathOr(null, pp, botSpec.value);
      },
      set(newValue) {
        setBotSpecProp(pp, newValue);
      },
    };
  }

  return {
    bots,
    botSpec,
    botId,
    botName,
    botType,
    botTypeTitle,
    botIcon,
    getBot,
    createBot,
    cloneBot,
    cloneBotInPlace,
    importBot,
    loadBot,
    setBotSpecProp,
    saveBot,
    deleteBot,
    slackGetChannels,
    getPropGetSet,
  };
});
