/*
 * Use backend API
 */
import { getFunctions, httpsCallable } from 'firebase/functions';

const defaultCallOptions = {
  timeout: 540000, // 9 min by default - max function timeout
};

export const useApi = () => {
  const invokeApiMethod = async (method, params, options = defaultCallOptions) => {
    const fn = httpsCallable(getFunctions(), 'invokeApi', options);
    let result = null;
    try {
      result = await fn({
        method: method,
        params: params,
      });
    } catch (e) {
      const msg = `ERROR: failed to invoke API method ${method}: exception ${e.message}`;
      console.log(msg);
      result = {
        success: false,
        error: msg,
      };
      return result;
    }
    return result?.data || { success: false, error: 'No data returned by API' };
  };

  const executeAnalyticsQuery = async (analyticsQuery, options = defaultCallOptions) => {
    const queryRequest = analyticsQuery?.request || null;
    if (!queryRequest) {
      console.log(`ERROR: request must be provided in analyticsQuery`);
      return {
        success: false,
        error: 'request must be provided in analyticsQuery',
      };
    }

    const result = await invokeApiMethod('executeAnalyticsQuery', queryRequest, options);

    const resultData = result?.data || null;
    const resultSuccess = result?.success || false;
    if (resultSuccess) {
      analyticsQuery.setResult(resultData);
    } else {
      analyticsQuery.setError(result?.error || result?.message || 'Request failed', resultData);
    }
    return analyticsQuery;
  };

  const executeUsageQuery = async (analyticsQuery, options = defaultCallOptions) => {
    const queryRequest = analyticsQuery?.request || null;
    if (!queryRequest) {
      console.log(`ERROR: request must be provided in analyticsQuery`);
      return {
        success: false,
        error: 'request must be provided in analyticsQuery',
      };
    }

    const result = await invokeApiMethod('executeUsageQuery', queryRequest, options);

    const resultData = result?.data || null;
    const resultSuccess = result?.success || false;
    if (resultSuccess) {
      analyticsQuery.setResult(resultData);
    } else {
      analyticsQuery.setError(result?.error || result?.message || 'Request failed', resultData);
    }
    return analyticsQuery;
  };

  const executeJobOnDemand = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('executeJobOnDemand', params, options);
    return result;
  };

  const loadConversationRecording = async (params, options = defaultCallOptions) => {
    // TODO Consider caching audio, for a limited time
    const result = await invokeApiMethod('loadConversationRecording', params, options);
    return result;
  };

  const loadBotConversationRecording = async (params, options = defaultCallOptions) => {
    // TODO Consider caching audio, for a limited time
    const result = await invokeApiMethod('loadBotConversationRecording', params, options);
    return result;
  };

  const getKbDocumentContent = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('getKbDocumentContent', params, options);
    return result;
  };

  const setKbDocumentContent = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('setKbDocumentContent', params, options);
    return result;
  };

  const createKbDocument = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('createKbDocument', params, options);
    return result;
  };

  const deleteKbDocument = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('deleteKbDocument', params, options);
    return result;
  };

  const convertKbDocumentContent = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('convertKbDocumentContent', params, options);
    return result;
  };

  const scrapeKbDocument = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('scrapeKbDocument', params, options);
    return result;
  };

  const searchKb = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('searchKb', params, options);
    return result;
  };

  const updateKbDataSources = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('updateKbDataSources', params, options);
    return result;
  };

  const storeUploadFile = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('storeUploadFile', params, options);
    return result;
  };

  const storeMoveFile = async (params, options = defaultCallOptions) => {
    const result = await invokeApiMethod('storeMoveFile', params, options);
    return result;
  };

  return {
    invokeApiMethod,
    executeAnalyticsQuery,
    executeUsageQuery,
    executeJobOnDemand,
    loadConversationRecording,
    loadBotConversationRecording,
    getKbDocumentContent,
    setKbDocumentContent,
    createKbDocument,
    deleteKbDocument,
    convertKbDocumentContent,
    scrapeKbDocument,
    searchKb,
    updateKbDataSources,
    storeUploadFile,
    storeMoveFile,
  };
};
