<template>
  <div class="px-4 sm:px-6 lg:px-8 py-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-2xl font-semibold leading-7 text-gray-900">{{ title }}</h1>
        <p class="mt-2 text-sm text-gray-700">{{ description }}</p>
      </div>
      <div class="flex mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
        <button
          type="button"
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          @click="onNewCollection"
        >
          <mdicon name="folder-plus-outline" size="20" class="mr-1"></mdicon>
          New
        </button>
      </div>
    </div>
    <div class="mt-8">
      <DataTable :value="collectionItems" :rows="25" :rowsPerPageOptions="[25, 50, 100]" removableSort tableStyle="min-width: 50rem">
        <Column field="name" header="Name" sortable class="">
          <template #body="slotProps">
            <div class="flex items-center">
              <div class="flex mr-2 h-7 w-7 flex-none items-center justify-center rounded-md text-white bg-indigo-500">
                <mdicon name="folder-outline" size="16"></mdicon>
              </div>
              <router-link v-if="type" :to="{ path: `/datasets/${type}/edit/${slotProps.data?.id || 'NONE'}` }">
                <div class="font-medium hover:underline">{{ slotProps.data?.name || 'NONE' }}</div>
              </router-link>
            </div>
          </template>
        </Column>
        <Column field="description" header="Description" sortable></Column>
        <Column v-if="showFiles" field="files" header="Files" sortable></Column>
        <Column header="" style="width: 1%">
          <template #body="slotProps">
            <mdicon name="folder-edit-outline" class="cursor-pointer text-gray-400 hover:text-indigo-600" size="22" @click="onEditCollection(slotProps.data)"></mdicon>
          </template>
        </Column>
      </DataTable>
    </div>
    <CollectionEditDialog :open="editOpen" :collection="editCollection" @cancel="editOpen = false" @save="onSaveCollection"></CollectionEditDialog>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useCollectionsStore } from '@/store/CollectionsStore';
  import { useCollection } from 'vuefire';
  import { nanoid } from 'nanoid';
  import CollectionEditDialog from '@/components/Collections/CollectionEditDialog.vue';
  import { useToast } from 'vue-toastification';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';

  const props = defineProps({
    type: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    showFiles: {
      type: Boolean,
      default: true,
    },
  });

  const collectionsStore = useCollectionsStore();
  const toast = useToast();

  const type = computed(() => {
    return ['transcripts', 'recordings', 'kpi'].includes(props.type) ? props.type : null;
  });
  const collectionItems = useCollection(collectionsStore.getCollection(props.type));

  const editOpen = ref(false);
  const editCollection = ref(null);
  const editNew = ref(false);

  function onNewCollection() {
    editCollection.value = {
      id: nanoid(10),
      name: ``,
      description: '',
      created: Date.now(),
      createdBy: 'todo',
      files: 0,
    };
    editNew.value = true;
    editOpen.value = true;
  }

  function onEditCollection(collection) {
    editCollection.value = collection;
    editNew.value = false;
    editOpen.value = true;
  }

  async function onSaveCollection(collectionDoc) {
    if (!type.value) {
      toast.error(`Unsupported Collection type`);
      return;
    }
    console.log(`onSaveCollection: ${JSON.stringify(collectionDoc)}`);
    editOpen.value = false;
    const saveResult = await collectionsStore.setCollectionDoc(props.type, collectionDoc);
    if (saveResult) {
      toast.success('Collection saved successfully');
    } else {
      toast.error(`Error saving Collection`);
    }
  }
</script>
