<template>
  <div class="w-full text-gray-400 font-source p-4">
    <DbDygraphs class="w-full h-[300px] bg-slate-800 rounded-xl p-4" :data="chartData.dthData"></DbDygraphs>
    <DbDygraphsBar class="mt-10 w-full h-[300px] bg-slate-800 rounded-xl p-4" :data="chartData.dthData2"></DbDygraphsBar>
  </div>
</template>
<script setup>
  import DbDygraphs from '@/components/Charts/DbDygraphs.vue';
  import DbDygraphsBar from '@/components/Charts/DbDygraphsBar.vue';

  const chartData = generateData();

  function generateData() {
    let dthData = [];
    let dthData2 = [];
    let dthData3 = [];
    let sTS = Date.now() - 100 * 3600 * 1000;

    for (let i = 0; i < 100; i++) {
      let cTs = sTS + i * 3600 * 1000;
      let d = new Date(cTs);
      let r = Math.random();
      dthData.push([d, r, r + 0.5]);
      dthData2.push([d, Math.random(), Math.random()]);
      dthData3.push(Math.random());
    }

    return {
      dthData: dthData,
      dthData2: dthData2,
      dthData3: dthData3,
    };
  }
</script>
