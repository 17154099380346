<template>
  <div>
    <div class="border-b py-2 text-xl font-bold leading-7 text-gray-500 sm:truncate sm:text-xl sm:tracking-tight mb-4">Transcript</div>

    <div class="flow-root my-4 font-source">
      <ul role="list" class="-mb-8">
        <li v-for="(transcriptItem, transcriptItemIdx) in scenarioResultsStore.transcriptMessages" :key="transcriptItem.ts">
          <div class="relative pb-6">
            <span v-if="transcriptItemIdx !== scenarioResultsStore.transcriptMessages.length - 1" class="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            <div class="relative flex items-start space-x-3">
              <mdicon
                :name="transcriptItem.actor === 'Bot' ? 'cog' : transcriptItem.actor === 'Agent' ? 'face-agent' : 'account'"
                size="26"
                class="flex h-10 w-10 items-center justify-center rounded-full bg-slate-100 ring-8 ring-white"
              />
              <div class="min-w-0 flex-1">
                <div>
                  <div class="text-sm">{{ formatSeconds(transcriptItem.at) }} {{ transcriptItem.actor }}</div>
                  <!--<p class="mt-0.5 text-sm text-gray-500">{{ formatEntryTime(transcriptItem.ts) }}</p>-->
                </div>
                <div class="mt-2 text-md text-gray-700">
                  {{ transcriptItem.message }}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
  import { useScenarioResultsStore } from '@/store/ScenarioResultsStore';

  const scenarioResultsStore = useScenarioResultsStore();

  function formatSeconds(sec) {
    const diff = sec;
    const totalMinutes = Math.floor(diff / 60);
    const seconds = diff % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0
      ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      : `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
</script>
