<template>
  <div class="space-y-5 divide-y divide-gray-900/10">
    <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">{{ botsStore.botTypeTitle }}</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Specify name and description for the Bot</p>
        </div>
      </div>

      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div class="sm:col-span-4">
              <label for="name" class="block text-sm font-medium leading-6 text-gray-900">Name</label>
              <div class="mt-2">
                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Specify Assistant Name"
                    v-model="specName"
                  />
                </div>
              </div>
            </div>

            <InputTextarea class="col-span-full" name="description" v-model="specDescription" label="Description" placeholder="Provide brief description of the Assistant"></InputTextarea>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Behavior</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Describe bot Personality and Behavior. Add key facts Bot should always know about to the Context.</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <InputTextarea
              class="col-span-full"
              name="personality"
              v-model="personality"
              label="Personality and Behavior"
              description="Describe Bot Personality and how it should behave."
              placeholder="For example:"
              :rows="2"
            ></InputTextarea>

            <InputTextarea
              class="col-span-full"
              name="globalContext"
              v-model="globalContext"
              label="Context"
              description="Describe facts Bot should always know about"
              placeholder="This could be a description of the services your provide, key facts, concise summary of your home page"
              :rows="2"
            ></InputTextarea>

            <InputTextarea
              class="col-span-full"
              name="engageMessage"
              v-model="engageMessage"
              :label="propsDefs['engageMessage'].title"
              :description="propsDefs['engageMessage'].description"
              :placeholder="propsDefs['engageMessage'].placeholder"
              :rows="1"
            ></InputTextarea>

            <div class="sm:col-span-4">
              <label for="name" class="block text-sm font-medium leading-6 text-gray-900">{{ propsDefs['engageTimeout'].title }}</label>
              <div class="text-xs text-gray-500">{{ propsDefs['engageTimeout'].description }}</div>
              <div class="mt-2">
                <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <input
                    type="number"
                    name="engageTimeout"
                    class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    v-model="engageTimeout"
                  />
                </div>
              </div>
            </div>

            <InputTextarea
              class="col-span-full"
              name="welcomeMessage"
              v-model="welcomeMessage"
              :label="propsDefs['welcomeMessage'].title"
              :description="propsDefs['welcomeMessage'].description"
              :placeholder="propsDefs['welcomeMessage'].placeholder"
              :rows="2"
            ></InputTextarea>

            <InputTextarea
              class="col-span-full"
              name="declineMessage"
              v-model="declineMessage"
              :label="propsDefs['declineMessage'].title"
              :description="propsDefs['declineMessage'].description"
              :placeholder="propsDefs['declineMessage'].placeholder"
              :rows="2"
            ></InputTextarea>
          </div>
        </div>
      </div>
    </div>

    <BotSettingsEditorKB></BotSettingsEditorKB>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Determine Lead</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Define rules to detect if user is lead or not</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <InputTextarea
              class="col-span-full"
              name="determineLeadCriteria"
              v-model="determineLeadCriteria"
              :label="propsDefs['determineLeadCriteria'].title"
              :description="propsDefs['determineLeadCriteria'].description"
              :placeholder="propsDefs['determineLeadCriteria'].placeholder"
              :rows="2"
            ></InputTextarea>
            <InputTextarea
              class="col-span-full"
              name="leadMessage"
              v-model="leadMessage"
              :label="propsDefs['leadMessage'].title"
              :description="propsDefs['leadMessage'].description"
              :placeholder="propsDefs['leadMessage'].placeholder"
              :rows="1"
            ></InputTextarea>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Collect Contact Information</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Define how to collect contact information from leads</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <h2 class="text-base font-semibold leading-7 text-gray-900">Bot will Collect Name, Email and Phone Number from the Lead</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">You can collect contact information before or after qualifying lead.</p>
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <ToggleGroup class="col-span-full" v-model="leadCollectContactInfoFirst" :name="propsDefs['leadCollectContactInfoFirst'].title"> </ToggleGroup>
            <InputTextarea
              class="col-span-full"
              name="leadCollectContactInstructions"
              v-model="leadCollectContactInstructions"
              :label="propsDefs['leadCollectContactInstructions'].title"
              :description="propsDefs['leadCollectContactInstructions'].description"
              :placeholder="propsDefs['leadCollectContactInstructions'].placeholder"
              :rows="1"
            ></InputTextarea>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Qualify Lead</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Define how to qualify leads</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
            <StdPropArrayEditor
              class="col-span-full"
              v-model="leadQualifyCollectDataSpec"
              :label="propsDefs['leadQualifyCollectDataSpec'].title"
              :description="propsDefs['leadQualifyCollectDataSpec'].description"
              :propertiesSpec="leadQualifyCollectDataSpecProps"
            >
            </StdPropArrayEditor>
            <InputTextarea
              class="col-span-full"
              name="leadCollectDataInstructions"
              v-model="leadCollectDataInstructions"
              :label="propsDefs['leadCollectDataInstructions'].title"
              :description="propsDefs['leadCollectDataInstructions'].description"
              :placeholder="propsDefs['leadCollectDataInstructions'].placeholder"
              :rows="1"
            ></InputTextarea>
            <InputTextarea
              class="col-span-full"
              name="leadQualifyHotLeadCriteria"
              v-model="leadQualifyHotLeadCriteria"
              :label="propsDefs['leadQualifyHotLeadCriteria'].title"
              :description="propsDefs['leadQualifyHotLeadCriteria'].description"
              :placeholder="propsDefs['leadQualifyHotLeadCriteria'].placeholder"
              :rows="2"
            ></InputTextarea>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="rounded-md px-2 py-1 text-base text-white font-semibold leading-7 text-gray-900 bg-green-700">Hot Lead Behavior</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Define how Bot should process Hot Leads</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
            <ToggleGroup class="col-span-full" v-model="hotLeadEnableSlackEscalation" :name="propsDefs['hotLeadEnableSlackEscalation'].title" :description="propsDefs['hotLeadEnableSlackEscalation'].description">
              <div class="mt-2 pl-4 col-span-full border-l-2 border-indigo-100">
                <SlackChannelSelector v-model="slackChannel"></SlackChannelSelector>
                <ToggleGroup class="mt-4 col-span-full" v-model="slackShowFullUserDetails" :name="propsDefs['slackShowFullUserDetails'].title" :description="propsDefs['slackShowFullUserDetails'].description" />
              </div>
            </ToggleGroup>
            <ToggleGroup class="col-span-full" v-model="hotLeadEnableEmailEscalation" :name="propsDefs['hotLeadEnableEmailEscalation'].title" :description="propsDefs['hotLeadEnableEmailEscalation'].description">
              <div class="mt-2 pl-4 col-span-full border-l-2 border-indigo-100">
                <InputTextarea
                  class="mt-2 col-span-full"
                  name="hotLeadEmailAddress"
                  v-model="hotLeadEmailAddress"
                  :label="propsDefs['hotLeadEmailAddress'].title"
                  :description="propsDefs['hotLeadEmailAddress'].description"
                  :placeholder="propsDefs['hotLeadEmailAddress'].placeholder"
                  :rows="1"
                ></InputTextarea>
              </div>
            </ToggleGroup>
            <InputTextarea
              class="col-span-full"
              name="hotLeadInstructions"
              v-model="hotLeadInstructions"
              :label="propsDefs['hotLeadInstructions'].title"
              :description="propsDefs['hotLeadInstructions'].description"
              :placeholder="propsDefs['hotLeadInstructions'].placeholder"
              :rows="2"
            ></InputTextarea>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Regular Leads</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Define how Bot should process regular leads (not Hot)</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <InputTextarea
              class="col-span-full"
              name="nonLeadInstructions"
              v-model="regularLeadInstructions"
              :label="propsDefs['regularLeadInstructions'].title"
              :description="propsDefs['regularLeadInstructions'].description"
              :placeholder="propsDefs['regularLeadInstructions'].placeholder"
              :rows="2"
            ></InputTextarea>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Non Leads</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Define how Bot should process non leads</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <InputTextarea
              class="col-span-full"
              name="nonLeadInstructions"
              v-model="nonLeadInstructions"
              :label="propsDefs['nonLeadInstructions'].title"
              :description="propsDefs['nonLeadInstructions'].description"
              :placeholder="propsDefs['nonLeadInstructions'].placeholder"
              :rows="2"
            ></InputTextarea>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <h2 class="text-base font-semibold leading-7 text-gray-900">Miscellaneous</h2>
          <p class="mt-1 text-sm leading-6 text-gray-600">Miscellaneous options</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <ToggleGroup class="col-span-full" v-model="splitMessagesAtBreak" :name="propsDefs['splitMessagesAtBreak'].title" :description="propsDefs['splitMessagesAtBreak'].description"> </ToggleGroup>
          </div>
        </div>
      </div>
    </div>

    <BotSettingsEditorCSat />

    <div v-if="showAdvanced" class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div class="px-4 sm:px-0 relative">
        <div>
          <p class="mt-1 text-sm leading-6 text-gray-600">Show advanced properties</p>
        </div>
      </div>
      <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div class="px-4 py-2">
          <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
            <div class="col-span-full">
              <ToggleGroup v-model="advancedEnabled" name="Advanced" description="">
                <InputTextarea class="col-span-full" name="systemPrompt" v-model="systemPrompt" label="System Prompt" placeholder="" :rows="2"></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="globalInstructionsTemplate"
                  v-model="globalInstructionsTemplate"
                  label="Global Instructions template"
                  description="Give bot instructions what it should do and how it should act"
                  placeholder="For example:"
                  :rows="4"
                ></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="determineLeadInstructionsTemplate"
                  v-model="determineLeadInstructionsTemplate"
                  :label="propsDefs['determineLeadInstructionsTemplate'].title"
                  :description="propsDefs['determineLeadInstructionsTemplate'].description"
                  :placeholder="propsDefs['determineLeadInstructionsTemplate'].placeholder"
                  :rows="4"
                ></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="determineLeadInstructionsTemplate"
                  v-model="leadCollectDataInstructionsTemplate"
                  :label="propsDefs['leadCollectDataInstructionsTemplate'].title"
                  :description="propsDefs['leadCollectDataInstructionsTemplate'].description"
                  :placeholder="propsDefs['leadCollectDataInstructionsTemplate'].placeholder"
                  :rows="4"
                ></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="determineLeadInstructionsTemplate"
                  v-model="leadQualifyInstructionsTemplate"
                  :label="propsDefs['leadQualifyInstructionsTemplate'].title"
                  :description="propsDefs['leadQualifyInstructionsTemplate'].description"
                  :placeholder="propsDefs['leadQualifyInstructionsTemplate'].placeholder"
                  :rows="4"
                ></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="determineLeadInstructionsTemplate"
                  v-model="getContactInstructionsTemplate"
                  :label="propsDefs['getContactInstructionsTemplate'].title"
                  :description="propsDefs['getContactInstructionsTemplate'].description"
                  :placeholder="propsDefs['getContactInstructionsTemplate'].placeholder"
                  :rows="4"
                ></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="hotLeadInstructions"
                  v-model="hotLeadInstructionsTemplate"
                  :label="propsDefs['hotLeadInstructionsTemplate'].title"
                  :description="propsDefs['hotLeadInstructionsTemplate'].description"
                  :placeholder="propsDefs['hotLeadInstructionsTemplate'].placeholder"
                  :rows="2"
                ></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="regularLeadInstructionsTemplate"
                  v-model="regularLeadInstructionsTemplate"
                  :label="propsDefs['regularLeadInstructionsTemplate'].title"
                  :description="propsDefs['regularLeadInstructionsTemplate'].description"
                  :placeholder="propsDefs['regularLeadInstructionsTemplate'].placeholder"
                  :rows="2"
                ></InputTextarea>
                <InputTextarea
                  class="mt-4 col-span-full"
                  name="nonLeadInstructionsTemplate"
                  v-model="nonLeadInstructionsTemplate"
                  :label="propsDefs['nonLeadInstructionsTemplate'].title"
                  :description="propsDefs['nonLeadInstructionsTemplate'].description"
                  :placeholder="propsDefs['nonLeadInstructionsTemplate'].placeholder"
                  :rows="4"
                ></InputTextarea>
                <InputString
                  class="mt-4 col-span-full"
                  name="overrideModel"
                  v-model="modelOverride"
                  :label="propsDefs['modelOverride'].title"
                  :description="propsDefs['modelOverride'].description"
                  :placeholder="propsDefs['modelOverride'].placeholder"
                  :rows="4"
                ></InputString>
                <InputString
                  class="mt-4 col-span-full"
                  name="implementationVersion"
                  v-model="implementationVersion"
                  :label="propsDefs['implementationVersion'].title"
                  :description="propsDefs['implementationVersion'].description"
                  :placeholder="propsDefs['implementationVersion'].placeholder"
                  :rows="4"
                ></InputString>
              </ToggleGroup>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed, ref } from 'vue';
  import { useRoute } from 'vue-router';
  import { pathOr } from 'ramda';
  import { useBotsStore } from '@/store/BotsStore';
  import ToggleGroup from '@/components/Toggles/ToggleGroup.vue';
  import InputTextarea from '@/components/Bots/editors/InputTextarea.vue';
  import InputString from '@/components/Bots/editors/InputString.vue';
  import StdPropArrayEditor from '@/components/Editors/StdPropArrayEditor.vue';
  import SlackChannelSelector from '@/components/Selectors/SlackChannelSelector.vue';
  import BotSettingsEditorCSat from '@/components/Bots/editors/BotSettingsEditorCSat.vue';
  import BotSettingsEditorKB from '@/components/Bots/editors/BotSettingsEditorKB.vue';
  import { BOT_TYPE_DEFS } from '@/spec/BotTypeDefs';

  const route = useRoute();
  const showAdvanced = computed(() => 'advanced' in (route?.query || {}));
  const botsStore = useBotsStore();
  // TEMP
  const advancedEnabled = ref(false);

  const propsDefs = {};
  (BOT_TYPE_DEFS['leadGeneration']?.props || []).map((p) => {
    propsDefs[p.name] = {
      title: p?.title || '',
      description: p?.description || '',
      default: p?.default || '',
      placeholder: p?.placeholder || '',
    };
  });

  const leadQualifyCollectDataSpecProps = [
    { name: 'name', type: 'string', title: 'Name', default: '' },
    { name: 'type', type: 'select', title: 'Type', options: ['string', 'integer'], values: ['string', 'integer'], default: 'string' },
    { name: 'description', type: 'stringLong', title: 'Description', default: '' },
  ];

  // Can pass array or dot notation path, like "spec.bot.prop"
  // TODO Generalize - move to hooks ? or store ?
  function getPropGetSet(propPath) {
    const pp = Array.isArray(propPath) ? propPath : propPath.split('.');
    return {
      get() {
        return pathOr(null, pp, botsStore.botSpec);
      },
      set(newValue) {
        botsStore.setBotSpecProp(pp, newValue);
      },
    };
  }

  const specName = computed(getPropGetSet('name'));
  const specDescription = computed(getPropGetSet('description'));

  const engageMessage = computed(getPropGetSet('engageMessage'));
  const engageTimeout = computed(getPropGetSet('engageTimeout'));
  const welcomeMessage = computed(getPropGetSet('welcomeMessage'));
  const declineMessage = computed(getPropGetSet('declineMessage'));

  const personality = computed(getPropGetSet('personality'));
  const globalContext = computed(getPropGetSet('globalContext'));

  const determineLeadCriteria = computed(getPropGetSet('determineLeadCriteria'));
  const determineLeadInstructionsTemplate = computed(getPropGetSet('determineLeadInstructionsTemplate'));
  const leadMessage = computed(getPropGetSet('leadMessage'));

  const nonLeadInstructions = computed(getPropGetSet('nonLeadInstructions'));
  const nonLeadInstructionsTemplate = computed(getPropGetSet('nonLeadInstructionsTemplate'));

  const leadQualifyHotLeadCriteria = computed(getPropGetSet('leadQualifyHotLeadCriteria'));
  const leadQualifyRegularLeadCriteria = computed(getPropGetSet('leadQualifyRegularLeadCriteria'));
  const leadQualifyCollectDataSpec = computed(getPropGetSet('leadQualifyCollectDataSpec'));

  const leadCollectDataInstructions = computed(getPropGetSet('leadCollectDataInstructions'));
  const leadCollectDataInstructionsTemplate = computed(getPropGetSet('leadCollectDataInstructionsTemplate'));

  const leadQualifyInstructionsTemplate = computed(getPropGetSet('leadQualifyInstructionsTemplate'));

  const hotLeadEnableCollectContactInfo = computed(getPropGetSet('hotLeadEnableCollectContactInfo'));

  const leadCollectContactInstructions = computed(getPropGetSet('leadCollectContactInstructions'));
  const leadCollectContactInfoFirst = computed(getPropGetSet('leadCollectContactInfoFirst'));

  const getContactInstructionsTemplate = computed(getPropGetSet('getContactInstructionsTemplate'));

  const hotLeadEnableSlackEscalation = computed(getPropGetSet('hotLeadEnableSlackEscalation'));
  const slackChannel = computed(getPropGetSet('slackChannel'));
  const slackShowFullUserDetails = computed(getPropGetSet('slackShowFullUserDetails'));

  const hotLeadEnableEmailEscalation = computed(getPropGetSet('hotLeadEnableEmailEscalation'));
  const hotLeadEmailAddress = computed(getPropGetSet('hotLeadEmailAddress'));

  const systemPrompt = computed(getPropGetSet('systemPrompt'));
  const globalInstructionsTemplate = computed(getPropGetSet('globalInstructionsTemplate'));

  const hotLeadInstructions = computed(getPropGetSet('hotLeadInstructions'));
  const hotLeadInstructionsTemplate = computed(getPropGetSet('hotLeadInstructionsTemplate'));

  const regularLeadInstructions = computed(getPropGetSet('regularLeadInstructions'));
  const regularLeadInstructionsTemplate = computed(getPropGetSet('regularLeadInstructionsTemplate'));

  const splitMessagesAtBreak = computed(getPropGetSet('splitMessagesAtBreak'));

  const modelOverride = computed(getPropGetSet('modelOverride'));
  const implementationVersion = computed(getPropGetSet('implementationVersion'));

  async function getChannels() {
    const getChannelsResult = await botsStore.slackGetChannels();
    console.log(`Get channels result:`, getChannelsResult);
  }
</script>
