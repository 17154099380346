<template>
  <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
    <div class="px-4 sm:px-0 relative">
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">CSat</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Enable CSat Analysis. Set Relevant Domains so that CSat is analyzed only on the relevant conversations</p>
      </div>
    </div>
    <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
      <div class="px-4 py-2">
        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <ToggleGroup class="col-span-full" v-model="csatEnabled" :name="propsDefs['csatEnabled'].title" :description="propsDefs['csatEnabled'].description">
            <div class="mt-2 pl-4 col-span-full border-l-2 border-indigo-100">
              <InputTextarea
                class="mt-2 col-span-full"
                name="handoffEmailAddress"
                v-model="csatRelevantDomains"
                :label="propsDefs['csatRelevantDomains'].title"
                :description="propsDefs['csatRelevantDomains'].description"
                :placeholder="propsDefs['csatRelevantDomains'].placeholder"
                :rows="1"
              ></InputTextarea>
            </div>
          </ToggleGroup>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import InputTextarea from '@/components/Bots/editors/InputTextarea.vue';
  import ToggleGroup from '@/components/Toggles/ToggleGroup.vue';
  import StdBusinessHoursEditor from '@/components/Editors/StdBusinessHoursEditor.vue';
  import { BOT_TYPE_DEFS } from '@/spec/BotTypeDefs';
  import { useBotsStore } from '@/store/BotsStore';
  import { pathOr } from 'ramda';
  import { computed } from 'vue';

  const botsStore = useBotsStore();

  // TODO Refine this - move to BotTypeDefs
  const propsDefs = {};
  (BOT_TYPE_DEFS['questionAnswering']?.props || []).map((p) => {
    propsDefs[p.name] = {
      title: p?.title || '',
      description: p?.description || '',
      default: p?.default || '',
      placeholder: p?.placeholder || '',
    };
  });

  // Can pass array or dot notation path, like "spec.bot.prop"
  // TODO Generalize - move to hooks ? or store ?
  function getPropGetSet(propPath) {
    const pp = Array.isArray(propPath) ? propPath : propPath.split('.');
    return {
      get() {
        return pathOr(null, pp, botsStore.botSpec);
      },
      set(newValue) {
        botsStore.setBotSpecProp(pp, newValue);
      },
    };
  }

  const csatEnabled = computed(getPropGetSet('csatEnabled'));
  const csatRelevantDomains = computed(getPropGetSet('csatRelevantDomains'));
</script>
