<template>
  <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
    <div class="px-4 sm:px-0 relative">
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Knowledge Base</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Knowledge Base contains information used by Assistant</p>
      </div>
    </div>
    <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
      <div class="px-4 py-2">
        <KBSelector v-model="kbId" :label="propsDefs['kbId'].title" :description="propsDefs['kbId'].description"></KBSelector>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import InputTextarea from '@/components/Bots/editors/InputTextarea.vue';
  import ToggleGroup from '@/components/Toggles/ToggleGroup.vue';
  import KBSelector from '@/components/Selectors/KBSelector.vue';
  import { BOT_TYPE_DEFS, standardBotPropsByName } from '@/spec/BotTypeDefs';
  import { useBotsStore } from '@/store/BotsStore';
  import { computed } from 'vue';

  const botsStore = useBotsStore();

  const propsDefs = standardBotPropsByName;

  const kbId = computed(botsStore.getPropGetSet('kbId'));
</script>
