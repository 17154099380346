<template>
  <div class="flex my-2 py-4 px-4 bg-slate-50 rounded-xl">
    <mdicon name="account-school-outline" size="60" class="text-orange-300" aria-hidden="true"></mdicon>
    <div class="flex-auto">
      <div class="prose px-4" v-html="suggestionHtml"></div>
      <div class="mt-4 px-4 flex items-center justify-between text-md text-gray-500 font-source">
        <div class="flex-none">
          <div v-if="hasConversations" class="flex items-center">
            <div>Based on conversations:</div>
            <div v-for="(c, i) in suggestion.conversations" :key="i">
              <router-link :to="{ path: `/insights/conversation/${c}` }">
                <div class="cursor-pointer hover:underline text-blue-500 mx-1">{{ i + 1 }}</div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex-none">{{ dayjs(suggestion?.timestamp).format('MMM D YYYY') }}</div>
      </div>
      <div v-if="ack" class="flex items-baseline justify-end mt-4 px-4">
        <button
          class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <mdicon name="checkbox-marked-circle-outline" size="20" class="text-gray-100/70" aria-hidden="true"></mdicon>
          Acknowledge
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import dayjs from 'dayjs';
  import MarkdownIt from 'markdown-it';

  const props = defineProps({
    suggestion: {
      type: Object,
      default() {
        return {};
      },
    },
    ack: {
      type: Boolean,
      default: true,
    },
  });

  const hasConversations = computed(() => {
    return Array.isArray(props.suggestion?.conversations) && props.suggestion.conversations.length > 0;
  });

  const suggestionHtml = computed(() => {
    if (!props.suggestion || !('text' in props.suggestion)) {
      return '';
    }
    return mdToHtml(props.suggestion.text);
  });

  function mdToHtml(md) {
    const mdIt = new MarkdownIt({
      html: true,
      linkify: true,
      typographer: true,
    });

    const html = mdIt.render(md);
    return html;
  }
</script>
