<template>
  <button
    :disabled="isProgress"
    class="flex items-center gap-x-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:opacity-50"
    @click="onClick"
  >
    <svg v-if="isProgress" class="animate-spin mr-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
    <mdicon v-else :name="icon" size="20" class="px-1" aria-hidden="true"></mdicon>
    {{ title }}
  </button>
</template>
<script setup>
  import { useLayoutStore } from '@/store/LayoutStore';
  import { ref } from 'vue';

  defineProps({
    title: {
      type: String,
      default: 'Button',
    },
    icon: {
      type: String,
      default: 'magnify',
    },
  });

  const layoutStore = useLayoutStore();
  const emit = defineEmits(['click']);

  const isProgress = ref(false);

  function onClick() {
    startProgress();
    emit('click');
  }

  function startProgress() {
    isProgress.value = true;
    layoutStore.setProgress(true);
  }

  function stopProgress() {
    layoutStore.setProgress(false);
    isProgress.value = false;
  }

  defineExpose({
    startProgress,
    stopProgress,
  });
</script>
