import { getFunctions, httpsCallable } from 'firebase/functions';

export async function callFirebaseFunction(name, data, timeout = 600000) {
  const functions = getFunctions();
  const executeFunction = httpsCallable(functions, name, { timeout: timeout });
  let result = null;
  try {
    result = await executeFunction(data);
  } catch (e) {
    const msg = `ERROR: Function ${name} call failed: ${e.message}`;
    console.log(msg);
    return {
      success: false,
      error: e.message,
    };
  }
  return result.data;
}
