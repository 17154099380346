/*
 * Bot Conversation Search Store
 * */
import { ref, markRaw } from 'vue';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useAnalyticsStore } from '@/store/AnalyticsStore';
import { useDateRangeStore } from '@/store/DaterangeStore';
import { useFiltersStore } from '@/store/FiltersStore';
import { conversationCache } from '@/store/ConversationCache';
import { AnalyticsQuery } from '@/analytics/AnalyticsQuery';
import { useToast } from 'vue-toastification';

/*
 * Bot Conversation Search Store
 * TODO
 *  - Filters
 *  - Generalize, combine with conversation search store
 * */
export const useBotConversationSearchStore = defineStore('botConversationSearch', () => {
  const authStore = useAuthStore();
  const tenantId = computed(() => authStore.tenantId);

  const analyticsStore = useAnalyticsStore();
  const dateRangeStore = useDateRangeStore();
  const filtersStore = useFiltersStore();

  const toast = useToast();

  // Search parameters
  const searchQuery = ref('*');
  const searchSize = ref(50);
  const searchOffset = ref(0);
  const sortFields = ref(null);
  const sortFieldName = ref('datetime');
  const searchTimeIntervalTs = ref(0);

  // Search results
  let conv = null;
  const conversations = ref(null);
  const conversationsTotal = ref(0);
  const timelineHeader = ref([]);
  const timeline = ref([]);
  const uPlotTimeline = ref([]);

  // Conversations cache TODO Consider moving out to separate class
  const conversationsCache = {};

  // Add / update conversations to cache
  // When searched and found some conversations, we can add them to cache
  // so that we don't need to reload conversation from ES when opening details view
  function cacheConversations(conversations) {
    if (!Array.isArray(conversations) || conversations.length <= 0) {
      return;
    }
    for (let c of conversations) {
      const id = c?.id || null;
      if (id) {
        conversationsCache[id] = c;
      }
    }
  }

  async function getConversation(id) {
    if (id in conversationsCache) {
      return conversationsCache[id];
    }
    // Load this specific conversation from ES, if not found in cache
    const q = AnalyticsQuery.searchConversationQuery(id, 'botconv');
    await analyticsStore.executeAnalyticsQuery(q);
    if (q.success) {
      const conversationsDocs = (q?.result?.hits || []).map((x) => x._source);
      cacheConversations(conversationsDocs);
    }
    return conversationsCache[id] || {};
  }

  // Sort: [{field:'name',type:'asc|desc'},...]
  async function sort(sort) {
    sortFields.value = sort;
    sortFieldName.value = Array.isArray(sort) && sort.length > 0 ? sort[0]?.field || 'datetime' : 'datetime';
    searchOffset.value = 0;
    return executeSearch({ dateHistogram: false });
  }

  async function page(offset, size) {
    searchSize.value = size;
    searchOffset.value = offset;
    return executeSearch({ dateHistogram: false });
  }

  async function search(query = '*', size = 10, offset = 0, sort = null, dateHistogram = true, filters = null) {
    searchQuery.value = query === '' ? '*' : query;
    searchSize.value = size;
    searchOffset.value = offset;
    sortFields.value = sort;
    return executeSearch({ dateHistogram: dateHistogram });
  }

  async function aggregate(groupings = null) {
    const filters = filtersStore.toElasticFilters();
    const q = AnalyticsQuery.aggregateQuery(dateRangeStore.timeInterval, filters, groupings, 'botconv');
    await analyticsStore.executeAnalyticsQuery(q);
    return q;
  }

  async function executeSearch({ dateHistogram = true }) {
    const filters = filtersStore.toElasticFilters();
    searchTimeIntervalTs.value = dateRangeStore.timeIntervalChanged;
    const q = AnalyticsQuery.searchQuery(dateRangeStore.timeInterval, searchQuery.value, searchSize.value, searchOffset.value, sortFields.value, dateHistogram, filters, 'botconv');
    await analyticsStore.executeAnalyticsQuery(q);
    if (q.success) {
      const conversationsDocs = (q?.result?.hits || []).map((x) => {
        return x._source;
      });
      conversations.value = Object.freeze(conversationsDocs); //markRaw(conversationsDocs);
      //conv = Object.freeze(conversationsDocs); //markRaw(conversationsDocs);
      //conversationCache.setConv(Object.freeze(conversationsDocs));
      conversationsTotal.value = q?.result?.total || 0;
      cacheConversations(conversationsDocs);
      // Date histogram
      if (dateHistogram) {
        const countsTable = q.toTable({
          timeline: { title: 'Count' },
        });
        const uPlotTableData = [[], []];
        const dyTableData = countsTable.tableData.map((row) => {
          uPlotTableData[0].push(Math.floor(row[0] / 1000));
          uPlotTableData[1].push(row[1]);
          row[0] = new Date(row[0]);
          return row;
        });
        timelineHeader.value = countsTable.tableHeader;
        timeline.value = dyTableData;
        uPlotTimeline.value = uPlotTableData;
      }
    }
  }

  return {
    search,
    sort,
    page,
    aggregate,
    cacheConversations,
    getConversation,
    conv,
    conversations,
    conversationsTotal,
    timeline,
    timelineHeader,
    uPlotTimeline,
    searchQuery,
    searchSize,
    searchOffset,
    searchTimeIntervalTs,
    sortFields,
    sortFieldName,
  };
});
