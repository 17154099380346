<template>
  <div class="">
    <div class="pb-4 sm:flex sm:items-center sm:justify-between border-b">
      <h3 class="text-base font-semibold leading-6 text-gray-900">{{ kbStore.kbDocuments.length }} documents</h3>
      <div class="mt-3 sm:ml-4 sm:mt-0">
        <div class="flex rounded-md shadow-sm">
          <div class="relative flex-grow focus-within:z-10">
            <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              v-model="filters['global'].value"
              name="desktop-search-candidate"
              id="desktop-search-candidate"
              class="w-full rounded-none rounded-l-md border-0 py-1.5 pl-10 text-sm leading-6 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600"
              placeholder="Filter"
            />
          </div>
          <button
            type="button"
            class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            @click="clearFilter()"
          >
            <XMarkIcon class="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Clear
          </button>
        </div>
      </div>
    </div>
    <div class="mt-1">
      <DataTable
        v-model:selection="selectedDocuments"
        v-model:filters="filters"
        :value="kbStore.kbDocuments"
        paginator
        :rows="25"
        :rowsPerPageOptions="[25, 50, 100]"
        removableSort
        sortField="updated"
        :sortOrder="-1"
        tableStyle="min-width: 50rem"
        class=""
        rowHover
      >
        <Column selectionMode="multiple" headerStyle="width: 1%"></Column>
        <Column field="name" header="Name" headerStyle="width: 69%" sortable>
          <template #body="slotProps">
            <div v-if="slotProps.data?.metadata?.title">
              <router-link :to="{ path: `/kb/editor/${kbStore.kbId}/document/${slotProps.data.id}` }" class="flex-col items-center cursor-pointer hover:underline">
                <div class="font-source font-medium">
                  {{ slotProps.data.metadata.title }}
                </div>
                <div class="mt-1 text-sm font-source font-medium text-slate-500">
                  {{ slotProps.data.name }}
                </div>
              </router-link>
            </div>
            <div v-else>
              <router-link :to="{ path: `/kb/editor/${kbStore.kbId}/document/${slotProps.data.id}` }" class="mt-1 flex items-center">
                <div class="cursor-pointer font-source font-medium hover:underline">
                  {{ slotProps.data.name }}
                </div>
              </router-link>
            </div>
            <div v-if="Array.isArray(slotProps.data?.metadata?.tags) && slotProps.data.metadata.tags.length > 0" class="mt-1 gap-y-1 flex items-center flex-wrap">
              <span
                v-for="(tag, idx) in slotProps.data.metadata.tags"
                :key="idx"
                class="cursor-pointer mr-1 rounded-md bg-slate-50 px-2 py-1 text-xs font-source font-medium text-slate-500 ring-1 ring-inset ring-blue-700/10 hover:underline flex items-center"
                @click="onSetFilter(tag)"
              >
                {{ tag }}
                <mdicon name="filter" size="16" class="text-slate-300"></mdicon>
              </span>
            </div>
          </template>
        </Column>

        <Column field="metadata.title" header="Title" hidden> </Column>

        <Column field="metadata.tags" header="Tags" hidden> </Column>

        <Column field="size" header="Size" sortable>
          <template #body="slotProps"> {{ formatBytes(slotProps.data.size) }} </template>
        </Column>
        <!--
        <Column field="type" header="Type" sortable>
          <template #body="slotProps"> {{ KB_TYPE_DEFS[slotProps.data.type]?.title || '' }} </template>
        </Column>
        -->
        <Column field="updated" header="Last Updated" sortable>
          <template #body="slotProps"> {{ dayjs(slotProps.data.updated).format('MM/DD/YYYY') }} </template>
        </Column>
        <Column field="updatedBy" header="Updated By" sortable> </Column>
      </DataTable>
    </div>
    <ConfirmationDialog
      :open="deleteConfirm"
      title="Delete"
      :description="`Are you sure you want to delete selected Knowledge Base Documents ?`"
      @cancel="deleteConfirm = false"
      @confirm="onDeleteConfirmed"
    ></ConfirmationDialog>
    <ImportFileDialog ref="importSpecDialog" @cancel="onImportCancel" @import="onSpecImport"></ImportFileDialog>
  </div>
</template>

<script setup>
  import { useRouter } from 'vue-router';
  import { useKbStore } from '@/store/KbStore';
  import { nanoid } from 'nanoid';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import Button from 'primevue/button';
  import { FilterMatchMode, FilterOperator } from 'primevue/api';
  import { FunnelIcon, XMarkIcon, MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
  import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
  import ConfirmDialog from 'primevue/confirmdialog';
  import { useConfirm } from 'primevue/useconfirm';
  import { useToast } from 'vue-toastification';
  import { computed, ref } from 'vue';
  import dayjs from 'dayjs';
  import { KB_TYPE_DEFS } from '@/spec/KbTypeDefs';
  import DropdownSelectButton from '@/components/Buttons/DropdownSelectButton.vue';
  import ImportFileDialog from '@/components/Dialogs/ImportFileDialog.vue';
  import { formatBytes } from '@/utils/formatBytes';
  import InputText from 'primevue/inputtext';

  const router = useRouter();
  const kbStore = useKbStore();
  const toast = useToast();
  const confirm = useConfirm();

  const selectedDocuments = computed({
    get() {
      return kbStore.selectedKbDocuments;
    },
    set(value) {
      kbStore.selectedKbDocuments = value;
    },
  });

  const filters = ref();
  const initFilters = () => {
    filters.value = {
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    };
  };

  initFilters();

  const onSetFilter = (value) => {
    filters.value['global'].value = value;
  };

  const clearFilter = () => {
    initFilters();
  };

  const importSpecDialog = ref(null);
  const newKbItems = computed(() =>
    Object.keys(KB_TYPE_DEFS).map((j) => {
      const x = KB_TYPE_DEFS[j];
      return {
        type: x.type,
        title: x.title,
        description: x.description,
        icon: x.icon,
        defaultName: x.defaultName,
      };
    }),
  );

  async function onNewKbMenuSelect(item) {
    const newKbId = await kbStore.createKb(item.type, item.defaultName);
    if (newKbId) {
      await router.push(`/kb/editor/${newKbId}`);
    } else {
      console.log(`Failed to create new Kb`);
    }
  }

  const kbDocumentName = ref('New Document');
  function onNewKBDocument() {
    // Dialog - new name + confirm
    const suggestedName = 'New document'; //`${botsStore.botName} (Copy)`;
    kbDocumentName.value = suggestedName;
    confirm.require({
      group: 'createNewDocument',
      header: 'Create new KB Document',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-outlined p-button-sm',
      acceptClass: 'p-button-sm',
      rejectLabel: 'Cancel',
      acceptLabel: 'Create',
      accept: () => {
        // TODO create
        //const newBotId = botsStore.cloneBotInPlace(botCloneName.value);
        //router.push(`/bots/editor/${newBotId}`);
      },
      reject: () => {
        //toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        console.log(`KB Document create canceled`);
      },
    });
  }

  const showDelete = computed(() => Array.isArray(selectedDocuments.value) && selectedDocuments.value.length > 0);

  // Delete
  const deleteConfirm = ref(false);
  async function onDeleteConfirmed() {
    deleteConfirm.value = false;
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`Nothing selected to delete`);
      return;
    }
    console.log(`Deleting KBs: ${JSON.stringify(selectedDocuments.value)}`);
    let overallResult = true;
    for (let i = 0; i < selectedDocuments.value.length; i++) {
      const doc = selectedDocuments.value[i];
      const deleteResult = await kbStore.deleteKb(doc.id);
      overallResult &= deleteResult;
    }
    if (overallResult) {
      toast.success('Deleted successfully');
    } else {
      toast.error(`Error when deleting`);
    }
    selectedDocuments.value = [];
  }

  async function onDeleteKbs() {
    if (!Array.isArray(selectedDocuments.value) || selectedDocuments.value.length <= 0) {
      console.log(`Nothing selected to delete`);
      return;
    }
    deleteConfirm.value = true;
  }

  function onImportKb() {
    importSpecDialog.value.show();
  }

  function onImportCancel() {
    importSpecDialog.value.hide();
  }

  async function onSpecImport(specText) {
    importSpecDialog.value.hide();
    let kbSpec = null;
    try {
      kbSpec = JSON.parse(specText);
    } catch (e) {
      console.log(`Failed to parse kb json`, specText);
      toast.error(`Not a valid KB json file`);
      return;
    }

    if (!kbSpec?.id || !kbSpec?.type) {
      toast.error(`Not a valid KB json file`);
      return;
    }

    const newKbId = await kbStore.importKb(kbSpec);
    if (newKbId) {
      await router.push(`/kb/editor/${newKbId}`);
    } else {
      toast.error(`Failed to import Kb`);
    }
  }
</script>
