<template>
  <div>
    <Dialog v-model:visible="visible" modal :closable="false" header="Delete Documents" :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
      <div class="flex flex-col gap-2">
        <label for="username"
          >Please confirm deleting <strong>{{ documentsToDelete.length }}</strong> documents:</label
        >
        <div class="rounded border bg-slate-100 pt-2 pb-4 px-2">
          <ScrollPanel
            class="w-full h-[150px] font-source font-medium"
            :pt="{
              wrapper: {
                style: { 'border-right': '10px solid var(--surface-ground)' },
              },
              bary: 'hover:bg-violet-400 bg-violet-300 opacity-100',
            }"
          >
            <div v-for="doc in documentsToDelete" :key="doc.id">{{ doc.name }}</div>
          </ScrollPanel>
        </div>
      </div>
      <div class="mt-4">
        <small class="min-h-6 block">{{ progressText }} </small>
        <ProgressBar :mode="progressMode" style="height: 6px"></ProgressBar>
      </div>
      <template #footer>
        <Button label="Cancel" :disabled="showProgress" severity="secondary" @click="visible = false" autofocus />
        <Button label="Delete" :disabled="showProgress" severity="danger" @click="deleteDocuments" autofocus />
      </template>
    </Dialog>
  </div>
</template>

<script setup>
  import { useRbacStore } from '@/store/RBACStore';
  import { useKbStore } from '@/store/KbStore';
  import { useToast } from 'vue-toastification';
  import { ref, nextTick, computed } from 'vue';
  import InputText from 'primevue/inputtext';
  import Dialog from 'primevue/dialog';
  import Button from 'primevue/button';
  import ProgressBar from 'primevue/progressbar';
  import ScrollPanel from 'primevue/scrollpanel';

  const kbStore = useKbStore();

  const toast = useToast();

  const visible = ref(false);
  const showProgress = ref(false);
  const progressMode = computed(() => (showProgress.value ? 'indeterminate' : ''));
  const progressText = ref('');

  const documentsToDelete = ref([]);

  const emit = defineEmits(['documentCreated']);

  function start(documents) {
    documentsToDelete.value = documents;
    visible.value = true;
  }

  const pause = (x) => new Promise((res) => setTimeout(res, x));

  async function deleteDocuments() {
    showProgress.value = true;
    for (let i = 0; i < kbStore.selectedKbDocuments.length; i++) {
      const d = kbStore.selectedKbDocuments[i];
      progressText.value = `Deleting document ${d.name} ...`;
      const deleteRes = await kbStore.deleteKbDocument(d.id);
      if (!deleteRes.success) {
        toast.error(`Error deleting document "${d.name}" : ${deleteRes.error}`);
      } else {
        toast.info(`Document deleted: "${d.name}"`);
      }
    }
    kbStore.selectedKbDocuments = [];
    progressText.value = '';
    showProgress.value = false;
    visible.value = false;
  }

  defineExpose({ start });
</script>
