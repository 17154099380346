<template>
  <div>
    <JobSpecEditor v-if="specAvailable" :spec="jobSpec" @save="onJobSave" @execute="onJobExecute"></JobSpecEditor>
    <ConfirmationDialog :open="executeConfirm" title="Execute Job" description="Are you sure you want to execute Job ?" @cancel="executeConfirm = false" @confirm="onJobExecuteConfirmed"></ConfirmationDialog>
    <ProgressDialog ref="jobStartProgress"></ProgressDialog>
  </div>
</template>
<script setup>
  import { useRoute, useRouter } from 'vue-router';
  import { useJobsStore } from '@/store/JobsStore';
  import { useToast } from 'vue-toastification';
  import { onMounted, ref } from 'vue';
  import JobSpecEditor from '@/components/Jobs/JobSpecEditor.vue';
  import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
  import ProgressDialog from '@/components/Dialogs/ProgressDialog.vue';

  const route = useRoute();
  const router = useRouter();

  const jobsStore = useJobsStore();

  // Not reactive - no need. Keeps a latest saved job spec.
  let jobSpec = null;
  const specAvailable = ref(false);

  const toast = useToast();

  function onJobSave(job, showToast = true) {
    jobSpec = job;
    const saveResult = jobsStore.setJob(jobSpec);
    if (showToast) {
      if (saveResult) {
        toast.success('Job saved successfully');
      } else {
        toast.error(`Error saving job`);
      }
    }
    return saveResult;
  }

  const jobStartProgress = ref(null);
  const executeConfirm = ref(false);

  function onJobExecute(job) {
    // Save job always before execution
    onJobSave(job, false);
    console.log(`Starting execution ...`);
    executeConfirm.value = true;
    // TODO Navigate
  }

  async function onJobExecuteConfirmed() {
    executeConfirm.value = false;
    jobStartProgress.value.show({ title: 'Starting Job', description: jobSpec.name });
    const startResult = await jobsStore.startJob(jobSpec.id);
    if (startResult.success) {
      console.log(`Job started successfully`);
      jobStartProgress.value.hide();
      toast.success(`Job ${jobSpec.name} started`);
      // Move to Results right away
      await router.push(`/analysis/results`);
      return;
    }
    jobStartProgress.value.showError({ message: `ERROR: ${startResult.error}` });
    //jobsStore.executeJob(jobSpec);
  }

  onMounted(async () => {
    // ??? Todo move up to setup - simplify
    let id = route.params?.id || null;
    if (!id) {
      console.log(`ERROR: job ID must be specified`);
      return;
    }

    jobSpec = await jobsStore.loadJob(id);
    if (jobSpec) {
      specAvailable.value = true;
    }
  });
</script>
