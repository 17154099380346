<template>
  <div class="pt-6 grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
    <div class="px-4 sm:px-0 relative">
      <div>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Handoff</h2>
        <p class="mt-1 text-sm leading-6 text-gray-600">Define when to handoff a Conversation to a Person</p>
      </div>
    </div>
    <div class="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
      <div class="px-4 py-2">
        <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6">
          <InputTextarea
            class="col-span-full"
            name="handoffCriteria"
            v-model="handoffCriteria"
            :label="propsDefs['handoffCriteria'].title"
            :description="propsDefs['handoffCriteria'].description"
            :placeholder="propsDefs['handoffCriteria'].placeholder"
            :rows="2"
          ></InputTextarea>

          <ToggleGroup class="col-span-full" v-model="handoffEnableSlackEscalation" :name="propsDefs['handoffEnableSlackEscalation'].title" :description="propsDefs['handoffEnableSlackEscalation'].description">
            <div class="mt-2 pl-4 col-span-full border-l-2 border-indigo-100">
              <SlackChannelSelector v-model="slackChannel"></SlackChannelSelector>
              <ToggleGroup class="mt-4 col-span-full" v-model="slackShowFullUserDetails" :name="propsDefs['slackShowFullUserDetails'].title" :description="propsDefs['slackShowFullUserDetails'].description" />
            </div>
          </ToggleGroup>

          <ToggleGroup class="col-span-full" v-model="handoffEnableEmailEscalation" :name="propsDefs['handoffEnableEmailEscalation'].title" :description="propsDefs['handoffEnableEmailEscalation'].description">
            <div class="mt-2 pl-4 col-span-full border-l-2 border-indigo-100">
              <InputTextarea
                class="mt-2 col-span-full"
                name="handoffEmailAddress"
                v-model="handoffEmailAddress"
                :label="propsDefs['handoffEmailAddress'].title"
                :description="propsDefs['handoffEmailAddress'].description"
                :placeholder="propsDefs['handoffEmailAddress'].placeholder"
                :rows="1"
              ></InputTextarea>
            </div>
          </ToggleGroup>

          <ToggleGroup
            v-if="settingsStore.zendeskIntegrationEnabled"
            class="col-span-full"
            v-model="handoffEnableZendeskEscalation"
            :name="propsDefs['handoffEnableZendeskEscalation'].title"
            :description="propsDefs['handoffEnableZendeskEscalation'].description"
          >
          </ToggleGroup>

          <InputTextarea class="col-span-full" name="handoffMessage" v-model="handoffMessage" label="Handoff Message" description="Message to send after handoff" placeholder="" :rows="2"></InputTextarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { useSettingsStore } from '@/store/SettingsStore';
  import InputTextarea from '@/components/Bots/editors/InputTextarea.vue';
  import ToggleGroup from '@/components/Toggles/ToggleGroup.vue';
  import StdBusinessHoursEditor from '@/components/Editors/StdBusinessHoursEditor.vue';
  import StdPropEditor from '@/components/Editors/StdPropEditor.vue';
  import { BOT_TYPE_DEFS, standardBotPropsByName } from '@/spec/BotTypeDefs';
  import { useBotsStore } from '@/store/BotsStore';
  import { computed } from 'vue';
  import SlackChannelSelector from '@/components/Selectors/SlackChannelSelector.vue';

  const settingsStore = useSettingsStore();
  const botsStore = useBotsStore();

  const propsDefs = standardBotPropsByName;

  const handoffCriteria = computed(botsStore.getPropGetSet('handoffCriteria'));
  const handoffEnableSlackEscalation = computed(botsStore.getPropGetSet('handoffEnableSlackEscalation'));
  const slackChannel = computed(botsStore.getPropGetSet('slackChannel'));
  const slackShowFullUserDetails = computed(botsStore.getPropGetSet('slackShowFullUserDetails'));
  const handoffEnableEmailEscalation = computed(botsStore.getPropGetSet('handoffEnableEmailEscalation'));
  const handoffEmailAddress = computed(botsStore.getPropGetSet('handoffEmailAddress'));
  const handoffMessage = computed(botsStore.getPropGetSet('handoffMessage'));
  const handoffEnableZendeskEscalation = computed(botsStore.getPropGetSet('handoffEnableZendeskEscalation'));
</script>
