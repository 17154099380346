<template>
  <div class="mx-6 my-6">
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-orange-600/70 rounded-xl p-1">
            <mdicon name="face-agent" size="62" class="text-orange-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="text-2xl font-bold text-gray-900">Agents Activity</h1>
          <div class="my-1 flex items-center">
            <div>Analyzing Agents activity for the time period:</div>
            <TimeRangeSelector :inline="true" class="ml-2 font-semibold"></TimeRangeSelector>
          </div>
        </div>
      </div>

      <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <ProgressButton ref="analyzeProgressButton" title="Analyze" icon="motion-play-outline" @click="loadData"></ProgressButton>
      </div>
    </div>

    <div class="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 xl:grid-cols-3 font-source">
      <div class="bg-gray-50 rounded-xl px-4 py-2">
        <div class="text-sm font-semibold text-gray-600 border-b py-1">Agents</div>
        <div class="mt-4 text-4xl font-semibold text-gray-800">{{ agentsInsightsStore.agents.length }}</div>
        <div class="mt-4 text-sm text-gray-600">Number of agents who handled conversations during this time period</div>
      </div>
      <div class="bg-gray-50 rounded-xl px-4 py-2">
        <div class="text-sm font-semibold text-gray-600 border-b py-1">Conversations</div>
        <div class="mt-4 text-4xl font-semibold text-gray-800">{{ agentsInsightsStore.conversationsCount }}</div>
        <div class="mt-4 text-sm text-gray-600">Number of conversations occurred during this time period</div>
      </div>
      <div class="bg-gray-50 rounded-xl px-4 py-2">
        <div class="text-sm font-semibold text-gray-600 border-b py-1">Average Score Range</div>
        <div class="mt-4 mb-2 text-md font-semibold text-gray-800 flex items-center justify-between">
          <div>
            {{ agentsInsightsStore.minAvgScore }}
            <span class="ml-1 text-sm font-normal text-gray-600">(Lowest)</span>
          </div>
          <div><span class="text-sm font-normal text-gray-600 mr-1">(Highest)</span>{{ agentsInsightsStore.maxAvgScore }}</div>
        </div>
        <equalizer-value class="w-full" :value="10" :max="10" :height="20" :reverse="true"></equalizer-value>
        <div class="mt-2 text-sm text-gray-600">Lowest and Highest Average Conversation Score Agents have</div>
      </div>
    </div>

    <div class="mt-6">
      <AgentsListStats :agents="agentsInsightsStore.agents"></AgentsListStats>
    </div>
  </div>
</template>
<script setup>
  import { ref, computed } from 'vue';
  import { useAgentsInsightsStore } from '@/store/AgentsInsightsStore';
  import { MagnifyingGlassIcon } from '@heroicons/vue/20/solid';
  import DbDygraphsBar from '@/components/Charts/DbDygraphsBar.vue';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';
  import AgentsListStats from '@/components/Insights/AgentsListStats.vue';
  import EqListCondensed from '@/components/Charts/EqListCondensed.vue';
  import EqualizerValue from '@/components/Charts/EqualizerValue.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';

  //const conversationSearchStore = useConversationSearchStore();
  const agentsInsightsStore = useAgentsInsightsStore();

  const analyzeProgressButton = ref(null);

  // Search Results Table
  //const dt = ref();
  const loading = ref(false);

  const chartData = []; //computed(() => []);
  const chartOptions = {
    stackedGraph: true,
    //title: 'Conversations',
    //ylabel: 'Count',
    labels: [],
    //legend: 'follow',
  };

  async function loadData() {
    loading.value = true;
    //await conversationSearchStore.search(searchQuery.value, 10, 0, [{ field: 'datetime', type: 'desc' }]);
    await agentsInsightsStore.analyzeAgents();
    loading.value = false;
    analyzeProgressButton.value.stopProgress();
  }
</script>
