<template>
  <!-- See https://web.dev/one-line-layouts/ -->
  <div class="antialiased text-slate-500 dark:text-slate-400 bg-white dark:bg-slate-900">
    <ProgressBarTop></ProgressBarTop>
    <NavBar></NavBar>
    <div class="flex">
      <div class="flex-none bg-sky-50 sticky overflow-y-auto overflow-x-hidden custom-scrollbar" style="top: calc(4rem + 1px); height: calc(100vh - 4rem - 1px)">
        <sidebar></sidebar>
      </div>
      <div class="flex-auto">
        <router-view />
      </div>
    </div>
    <BlockUI :blocked="layoutStore.blockUI" fullScreen />
    <div v-if="layoutStore.spinner" class="absolute inset-0 z-100 flex flex-col items-center justify-center h-screen">
      <ProgressSpinner></ProgressSpinner>
    </div>
  </div>
</template>
<script setup>
  import NavBar from '@/components/Navbars/NavBar.vue';
  import Sidebar from '@/components/Sidebar/Sidebar.vue';
  import ProgressBar from '@/components/Progress.vue';
  import ProgressBarTop from '@/components/ProgressBarTop.vue';
  import BlockUI from 'primevue/blockui';
  import ProgressSpinner from 'primevue/progressspinner';
  import { useLayoutStore } from '@/store/LayoutStore';

  const layoutStore = useLayoutStore();
</script>
