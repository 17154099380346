<template>
  <div class="mx-6 my-4">
    <div class="md:flex md:items-center md:justify-between md:space-x-5">
      <div class="flex items-center space-x-5">
        <div class="flex-shrink-0">
          <div class="relative bg-emerald-600/70 rounded-xl p-1">
            <mdicon name="finance" size="62" class="text-emerald-100" aria-hidden="true"></mdicon>
          </div>
        </div>
        <div class="">
          <h1 class="mb-1 text-2xl font-bold text-gray-900">Bots Dashboard</h1>
          <p class="text-sm font-medium text-gray-500">Statistics for your Bots</p>
        </div>
      </div>
      <div class="flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
        <ProgressButton ref="analyzeProgressButton" title="Analyze" icon="motion-play-outline" @click="loadData"></ProgressButton>
      </div>
    </div>

    <FiltersList class="mt-2" @select-filter="onSelectFilter" @show-filters="showFilters"></FiltersList>

    <div class="relative isolate overflow-hidden">
      <div class="mt-2">
        <dl class="mx-1 mt-1 grid grid-cols-1 gap-x-4 lg:grid-cols-2 xl:grid-cols-4 relative">
          <div v-for="stat in stats" :key="stat.name" :class="['bg-slate-50 relative overflow-hidden rounded-xl my-2 px-4 pt-2 pb-2 min-h-[120px]', stat?.class || '']" @click="onStatClick(stat)">
            <div class="z-20 relative">
              <dt class="truncate text-sm font-medium text-gray-500">{{ stat.name }}</dt>
              <dd class="mt-1 text-2xl font-semibold tracking-tight text-gray-900">{{ stat.displayValue || stat.value }}</dd>
              <dd class="mt-2 text-sm text-gray-400 font-medium">{{ stat?.description || '' }}</dd>
              <dd v-if="stat?.extraDescription" class="mt-2 text-xs text-gray-400 font-medium">{{ stat?.extraDescription || '' }}</dd>
            </div>
            <db-easy-pie
              v-if="stat.gauge"
              class="z-10 absolute right-1 top-1 h-28 w-28 rounded-full text-lg font-semibold text-slate-500"
              :value="stat.value"
              :max="stat.max"
              :display-value="stat?.gaugeDisplayValue || null"
              :line-width="8"
              :animated="true"
              :percent-ranges="stat?.percentRanges || []"
              :show-percents="stat.percents || false"
            ></db-easy-pie>
            <mdicon v-else-if="stat.icon" :name="stat.icon" size="105" :class="['z-10 absolute right-1 top-1 rounded-full', stat.iconClass || '']" />
          </div>
        </dl>
      </div>
    </div>

    <div class="overflow-hidden mt-2">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-emerald-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Conversations over Time</h2>
      </div>
      <DbUplot
        class="w-full h-[200px] mt-2 bg-gray-100/50 rounded-xl"
        :data="uPlotConversationsData"
        :options="uPlotOptionsConvs"
        @db-event="handleDbEvent"
        :tooltip="true"
        :title="`${conversationsTotal} Conversations`"
      ></DbUplot>
    </div>

    <div class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-emerald-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">CSat over time</h2>
      </div>
      <DbUplot class="w-full h-[200px] mt-2 bg-gray-100/50 rounded-xl" :data="uPlotCSatData" :options="uPlotOptionsCSat" @db-event="handleDbEvent" :tooltip="true" :title="`${cSat}% Overall average CSat`"></DbUplot>
    </div>

    <div class="overflow-hidden mt-6">
      <div class="flex items-center border-b border-gray-300 py-2">
        <mdicon name="text" size="18" class="rounded-md bg-emerald-600/70 p-1 mr-2 text-white" aria-hidden="true"></mdicon>
        <h2 class="text-base font-semibold leading-7 text-gray-900">Average Time to Agent</h2>
      </div>
      <DbUplot
        class="w-full h-[200px] mt-2 bg-gray-100/50 rounded-xl"
        :data="uPlotAvgTTAData"
        :options="uPlotOptionsAvgTTA"
        @db-event="handleDbEvent"
        :tooltip="true"
        :title="`${formatSeconds(avgTTA, false)} Overall average TTA`"
      ></DbUplot>
    </div>

    <EditFiltersSidebar v-model:visible="filtersOpen"></EditFiltersSidebar>
  </div>
</template>

<script setup>
  import { onMounted, ref, reactive, computed, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useLayoutStore } from '@/store/LayoutStore';
  import { useBotConversationSearchStore } from '@/store/BotConversationSearchStore';
  import { useFiltersStore } from '@/store/FiltersStore';
  import { stdBotConversationFilters } from '@/analytics/filtersDefs';
  import { ArrowDownCircleIcon, HashtagIcon } from '@heroicons/vue/20/solid';
  import TimeRangeSelector from '@/components/TimeRange/TimeRangeSelector.vue';
  import dayjs from 'dayjs';
  import DbEasyPie from '@/components/Charts/DbEasyPie.vue';
  import ProgressButton from '@/components/Buttons/ProgressButton.vue';
  import { useDateRangeStore } from '@/store/DaterangeStore';
  import DbUplot from '@/components/Charts/DbUplot.vue';
  import uPlot from 'uplot';
  import EditFiltersSidebar from '@/components/Filters/EditFiltersSidebar.vue';
  import FiltersList from '@/components/Filters/FiltersList.vue';
  import dbColors from '@/components/Charts/dbcolors';
  import { observable10Colors, tableauColors } from '@/components/Charts/dbcolors';
  import { formatSeconds } from '@/utils/formatSeconds';

  const route = useRoute();
  const router = useRouter();
  const layout = useLayoutStore();
  const conversationSearchStore = useBotConversationSearchStore();
  const dateRangeStore = useDateRangeStore();
  const filtersStore = useFiltersStore();

  const loading = ref(false);
  const conversationsTotal = ref(0);
  const conversationsContained = ref(0);
  const conversationsEscalated = ref(0);
  const conversationsAbandoned = ref(0);
  const botMessagesTotal = ref(0);
  const avgTTA = ref(0);
  const maxTTA = ref(0);
  const cSat = ref(0);
  const cSatMin = ref(0);

  const analyzeProgressButton = ref(null);

  const filtersOpen = ref(false);

  const stats = computed(() => [
    /*
    {
      id: 'time',
      name: 'Time period',
      value: dateRangeStore.title,
      icon: 'clock-outline',
      //description: `Duration: ${formatSeconds(props.conversation?.duration || 0)}`,
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
    }, */
    {
      id: 'conv',
      name: 'Conversations',
      value: conversationsTotal.value,
      description: `Bot messages: ${botMessagesTotal.value}`,
      icon: 'forum-outline',
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
      class: 'cursor-pointer hover:bg-slate-100 ring-2 ring-violet-200/50 hover:ring-violet-700/50',
    },
    /*
    {
      id: 'botmessages',
      name: 'Bot Messages',
      value: botMessagesTotal.value,
      description: 'Total bot messages',
      icon: 'message-check-outline',
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
    }, */
    {
      id: 'contained',
      name: 'Contained',
      value: conversationsContained.value,
      max: conversationsTotal.value,
      //description: `Out of ${conversationsTotal.value}`,
      description: 'Handled by Bot',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 40, color: '#fde047' },
        { value: 55, color: '#bef264' },
      ],
    },
    {
      id: 'escalated',
      name: 'Escalated',
      value: conversationsEscalated.value,
      max: conversationsTotal.value,
      //description: `Out of ${conversationsTotal.value}`,
      description: 'Escalated Conversations',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#bef264' },
        { value: 40, color: '#fde047' },
        { value: 55, color: '#fca5a5' },
      ],
    },
    {
      id: 'abandoned',
      name: 'Abandoned',
      value: conversationsAbandoned.value,
      max: conversationsEscalated.value,
      //description: `Out of ${conversationsTotal.value}`,
      description: `Out of ${conversationsEscalated.value} escalated`,
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#bef264' },
        { value: 40, color: '#fde047' },
        { value: 55, color: '#fca5a5' },
      ],
    },
    {
      id: 'avgTTA',
      name: 'Avg TTA',
      value: avgTTA.value,
      displayValue: formatSeconds(avgTTA.value, false),
      max: 30 * 60,
      //description: `Out of ${conversationsTotal.value}`,
      description: 'Average Time to Agent',
      gauge: true,
      percents: true,
      gaugeDisplayValue: `${avgTTA.value > 1800 ? '>30m' : '<30m'}`,
      percentRanges: [
        { value: 0, color: '#bef264' },
        { value: 40, color: '#fde047' },
        { value: 55, color: '#fca5a5' },
      ],
    },
    {
      id: 'maxTTA',
      name: 'Max TTA',
      value: maxTTA.value,
      displayValue: formatSeconds(maxTTA.value, false),
      max: 30 * 60,
      //description: `Out of ${conversationsTotal.value}`,
      description: 'Maximum Time to Agent',
      gauge: true,
      gaugeDisplayValue: `${maxTTA.value > 1800 ? '>30m' : '<30m'}`,
      percents: true,
      percentRanges: [
        { value: 0, color: '#bef264' },
        { value: 40, color: '#fde047' },
        { value: 55, color: '#fca5a5' },
      ],
    },
    {
      id: 'csat',
      name: 'CSat',
      value: cSat.value,
      max: 100,
      description: 'Average CSat %',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 40, color: '#fde047' },
        { value: 60, color: '#bef264' },
      ],
    },
    {
      id: 'mincsat',
      name: 'Min CSat',
      value: cSatMin.value,
      max: 100,
      description: 'Lowest CSat %',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 40, color: '#fde047' },
        { value: 60, color: '#bef264' },
      ],
    },

    /*
    {
      name: 'Agent',
      value: 'None',
      description: `None`,
      icon: 'face-agent',
      valueClass: 'text-xs',
      iconClass: 'text-gray-200/60',
    },
    {
      id: 'csatMin',
      name: 'Min CSat',
      value: parseFloat(csatPctMin.value.toFixed(2)),
      max: 100,
      description: 'Smallest CSat %',
      gauge: true,
      percents: true,
      percentRanges: [
        { value: 0, color: '#fca5a5' },
        { value: 50, color: '#fde047' },
        { value: 75, color: '#bef264' },
      ],
    },*/
  ]);

  watch(filtersStore.allFilters, (newValue) => {
    if (loading.value) {
      return;
    }
    console.log(`Filters changed: ${JSON.stringify(newValue)}`);
    loadData();
  });

  watch(
    () => dateRangeStore.timeIntervalChanged,
    (newValue) => {
      console.log(`Date range changed: ${JSON.stringify(newValue)}`);
      loadData();
    },
  );

  function onStatClick(stat) {
    console.log(`On stat click:`, stat);
    switch (stat.id) {
      case 'conv': {
        // move to conversations
        router.push('/bots/conversations');
        break;
      }
    }
  }

  const uPlotConversationsData = ref([]);
  const uPlotBotMessagesData = ref([]);
  const uPlotAvgTTAData = ref([]);
  const uPlotCSatData = ref([]);

  const uPlotOptionsConvs = computed(() => {
    return {
      //height: 200,
      legend: {
        show: true,
      },
      tzDate: (ts) => uPlot.tzDate(new Date(ts * 1e3), 'Etc/UTC'),
      scales: {
        x: { time: true },
      },
      series: [
        {},
        {
          label: 'Total',
          paths: uPlot.paths.bars({ align: 0 }),
          stroke: dbColors.hex2RGBA(observable10Colors[0], 0.7), //'rgba(78, 121, 167, 0.1 )',
          fill: dbColors.hex2RGBA(observable10Colors[0], 0.7), //'rgba(78, 121, 167, 0.4)',
        },
        {
          label: 'Escalated',
          paths: uPlot.paths.bars({ align: 0 }),
          stroke: dbColors.hex2RGBA(observable10Colors[1], 1), //'rgba(78, 121, 167, 0.1 )',
          fill: dbColors.hex2RGBA(observable10Colors[1], 1), //'rgba(78, 121, 167, 0.4)',
        },
      ],
    };
  });
  const uPlotOptionsMessages = computed(() => {
    return {
      //height: 200,
      legend: {
        show: true,
      },
      tzDate: (ts) => uPlot.tzDate(new Date(ts * 1e3), 'Etc/UTC'),
      scales: {
        x: { time: true },
      },
      series: [
        {},
        {
          label: 'Total',
          paths: uPlot.paths.bars({ align: 0 }),
          stroke: dbColors.hex2RGBA(observable10Colors[0], 0.7), //'rgba(78, 121, 167, 0.1 )',
          fill: dbColors.hex2RGBA(observable10Colors[0], 0.7), //'rgba(78, 121, 167, 0.4)',
        },
      ],
    };
  });
  const uPlotOptionsAvgTTA = computed(() => {
    return {
      //height: 200,
      legend: {
        show: true,
      },
      tzDate: (ts) => uPlot.tzDate(new Date(ts * 1e3), 'Etc/UTC'),
      scales: {
        x: { time: true },
      },
      series: [
        {},
        {
          label: 'Avg TTA',
          paths: uPlot.paths.bars({ align: 0 }),
          stroke: dbColors.hex2RGBA(observable10Colors[0], 0.7), //'rgba(78, 121, 167, 0.1 )',
          fill: dbColors.hex2RGBA(observable10Colors[0], 0.7), //'rgba(78, 121, 167, 0.4)',
          tooltipFormatter: (v) => formatSeconds(v, false),
        },
      ],
      axes: [
        {},
        {
          size: 70,
          values: (u, vals, space) => vals.map((v) => formatSeconds(v)),
        },
      ],
    };
  });
  const uPlotOptionsCSat = {
    scales: {
      x: { time: true },
      y: { range: { min: { soft: 0, mode: 2 }, max: { soft: 100, mode: 2 } } },
    },
    series: [
      {},
      {
        label: 'CSat',
        value: (u, v, sidx, didx) => (didx == null ? null : v == null ? '' : v.toFixed(1) + '%'),
        stroke: dbColors.hex2RGBA(observable10Colors[0], 0.7), //'rgba(78, 121, 167, 0.1 )',
        fill: dbColors.hex2RGBA(observable10Colors[0], 0.3), //'rgba(78, 121, 167, 0.4)',
      },
    ],
    axes: [
      {},
      {
        values: (u, vals, space) => vals.map((v) => +v.toFixed(1) + '%'),
      },
    ],
  };

  // Load stats for escalated converstions
  // Needs to be done separately for now, as "escalated" field have different type in old indexes
  // Over time, when old indexes expire, merge this to main aggregation
  async function loadEscalatedData() {
    const groupings = [
      {
        name: 'timeline',
        title: 'Date',
        type: 'date_histogram',
        interval: dateRangeStore.timeInterval.interval,
        extended_bounds: { min: dateRangeStore.timeInterval.from, max: dateRangeStore.timeInterval.to },
        /*
        groupings: [
          {
            name: 'escalated',
            type: 'filter',
            query: { term: { 'state.escalated': true } },
          },
        ],*/
      },
      /*
      {
        name: 'escalated',
        type: 'filter',
        query: { term: { 'state.escalated': true } },
      },*/
      {
        name: 'totalEscalated',
        type: 'terms',
        field: 'state.escalated',
      },
      {
        name: 'totalAbandoned',
        type: 'terms',
        field: 'state.abandoned',
      },
    ];
    const aggregateResult = await conversationSearchStore.aggregate(groupings);
    const a = 1;
  }

  async function loadData() {
    analyzeProgressButton.value.startProgress();
    loading.value = true;

    //await loadEscalatedData();

    const groupings = [
      {
        name: 'timeline',
        title: 'Date',
        type: 'date_histogram',
        interval: dateRangeStore.timeInterval.interval,
        extended_bounds: { min: dateRangeStore.timeInterval.from, max: dateRangeStore.timeInterval.to },
        groupings: [
          { name: 'escalated', type: 'filter', query: { term: { 'state.escalated': true } } },
          { name: 'withTTA', type: 'filter', query: { range: { 'stats.timeToAgent': { gt: 0 } } }, groupings: [{ name: 'TTA', type: 'stats', field: 'stats.timeToAgent' }] },
          { name: 'CSat', title: 'CSat', type: 'stats', field: 'CSat' },
          { name: 'botMessages', title: 'botMessages', type: 'stats', field: 'stats.botMessages' },
          {
            name: 'messages',
            type: 'nested',
            path: 'messages',
            groupings: [
              {
                name: 'role',
                type: 'terms',
                field: 'messages.role',
                missing: 'N/A',
              },
            ],
          },
          //{ name: 'escOverTime', type: 'terms', field: 'state.escalated' },
        ],
      },
      { name: 'totalEscalated', type: 'filter', query: { term: { 'state.escalated': true } } },
      { name: 'totalAbandoned', type: 'filter', query: { term: { 'state.abandoned': true } } },
      { name: 'withTTA', type: 'filter', query: { range: { 'stats.timeToAgent': { gt: 0 } } }, groupings: [{ name: 'totalTTA', type: 'stats', field: 'stats.timeToAgent' }] },
      //{ name: 'escalatedTerms', type: 'terms', field: 'state.escalated' },
      //{ name: 'abandonedTerms', type: 'terms', field: 'state.abandoned' },
    ];
    const aggregateResult = await conversationSearchStore.aggregate(groupings);
    processAggregateResult(aggregateResult);
    loading.value = false;
    analyzeProgressButton.value.stopProgress();
  }

  function processAggregateResult(aggregateResult) {
    if (!aggregateResult.success) {
      conversationsTotal.value = 0;
      botMessagesTotal.value = 0;
      uPlotConversationsData.value = [[], []];
      uPlotBotMessagesData.value = [[], []];
      console.log(`Error aggregating data: ${aggregateResult.error}`);
      return;
    }

    conversationsTotal.value = aggregateResult?.result?.total || 0;
    conversationsEscalated.value = aggregateResult?.result?.aggregations?.totalEscalated?.doc_count || 0;
    conversationsContained.value = conversationsTotal.value - conversationsEscalated.value;
    conversationsAbandoned.value = aggregateResult?.result?.aggregations?.totalAbandoned?.doc_count || 0;
    avgTTA.value = aggregateResult?.result?.aggregations?.withTTA?.totalTTA?.avg || 0;
    maxTTA.value = aggregateResult?.result?.aggregations?.withTTA?.totalTTA?.max || 0;

    const countsTable = aggregateResult.toTable(
      {
        timeline: { title: 'Conversations' },
        'timeline.messages.role': { title: 'Messages Role' },
        'timeline.escalated': { title: 'Escalated' },
      },
      ['timeline'],
      'Date',
    );

    let convCountIdx = -1;
    let botMessagesIdx = -1;
    let escCountIdx = -1;
    if (Array.isArray(countsTable?.tableHeader) && countsTable.tableHeader.length > 0) {
      convCountIdx = countsTable.tableHeader.findIndex((x) => x === 'Conversations');
      botMessagesIdx = countsTable.tableHeader.findIndex((x) => x === 'assistant');
      escCountIdx = countsTable.tableHeader.findIndex((x) => x === 'Escalated');
    }
    if (convCountIdx === -1 || botMessagesIdx === -1) {
      conversationsTotal.value = 0;
      botMessagesTotal.value = 0;
      uPlotConversationsData.value = [[], [], []];
      uPlotBotMessagesData.value = [[], []];
      console.log(`Cannot analyze aggregated data`, countsTable);
      return;
    }

    let totalBotMessages = 0;
    const uPlotConvData = [[], [], []];

    //console.log('Hdr:', countsTable.tableHeader);
    countsTable.tableData.map((row) => {
      const convCount = row.length > convCountIdx ? row[convCountIdx] : 0;
      const escCount = row.length > escCountIdx ? row[escCountIdx] : 0;
      const msgCount = row.length > botMessagesIdx ? row[botMessagesIdx] : 0;
      totalBotMessages += msgCount;
      uPlotConvData[0].push(Math.floor(row[0] / 1000));
      uPlotConvData[1].push(convCount === 0 ? null : convCount);
      uPlotConvData[2].push(escCount === 0 ? null : escCount);
    });
    uPlotConversationsData.value = uPlotConvData;

    // Avg TTA graph, CSat graph
    const uPlotTTAData = [[], []];
    const uPlotCSat = [[], []];
    let csatSum = 0;
    let csatMin = 100;
    let csatCount = 0;
    if (Array.isArray(aggregateResult?.normalized?.timeline)) {
      aggregateResult.normalized.timeline.map((x) => {
        uPlotTTAData[0].push(Math.floor(x.key / 1000));
        uPlotCSat[0].push(Math.floor(x.key / 1000));
        let tta = x?.withTTA?.TTA?.avg || null;
        if (tta) {
          tta = parseFloat(tta.toFixed(2));
        }
        uPlotTTAData[1].push(tta);
        const csCount = x?.CSat?.value?.count || 0;
        if (csCount > 0) {
          const csSum = x?.CSat?.value?.sum || 0;
          const csAvgPct = parseFloat(((x?.CSat?.value?.avg || 0) * 10).toFixed(2));
          csatSum += csSum;
          csatCount += csCount;
          if (csAvgPct < csatMin) {
            csatMin = csAvgPct;
          }
          uPlotCSat[1].push(csAvgPct);
        } else {
          uPlotCSat[1].push(null);
        }
        // TODO Enable this in a month or so - rely on stats instead of aggregating all messages
        //const botMessagesSum = x?.botMessages?.value?.sum || 0;
        //totalBotMessages += botMessagesSum;
      });
    }
    uPlotAvgTTAData.value = uPlotTTAData;
    uPlotCSatData.value = uPlotCSat;
    cSat.value = parseFloat(((csatSum / (csatCount * 10)) * 100).toFixed(2));
    cSatMin.value = csatMin;
    botMessagesTotal.value = totalBotMessages;
  }

  function handleDbEvent(event) {
    switch (event.type) {
      case 'zoom': {
        console.log(`Handle Zoom`, event);
        let from = event?.minDate || null;
        let to = event?.maxDate || null;
        if (from && to) {
          dateRangeStore.setDateRange(from, to);
          loadData();
        }
        break;
      }
    }
  }

  function onSelectFilter(filter) {
    // TODO pass which filter to make active
    showFilters();
  }

  function showFilters() {
    filtersOpen.value = true;
  }

  onMounted(() => {
    filtersStore.initFilters('botconv', stdBotConversationFilters);
    loadData();
  });
</script>
