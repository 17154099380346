ace.define(
  "ace/mode/log_highlight_rules",
  [
    "require",
    "exports",
    "module",
    "ace/lib/oop",
    "ace/mode/text_highlight_rules"
  ],
  function(acequire, exports, module) {
    "use strict";

    var oop = acequire("../lib/oop");
    var TextHighlightRules = acequire("./text_highlight_rules")
      .TextHighlightRules;

    var LogHighlightRules = function() {
      var keywords =
        "MESSAGE|ACCEPT|MERGE|SUM|ADD||MESSAGE|TABLE|ADVANCING|MODE|TAPE|" +
        "AFTER|MULTIPLY|TEST|ALL|NEGATIVE|TEXT|ALPHABET|NEXT|THAN|" +
        "ALSO|NO|THEN|ALTERNATE|NOT|THROUGH|AND|NUMBER|THRU|ANY|OCCURS|" +
        "TIME|ARE|OF|TO|AREA|OFF|TOP||ASCENDING|OMITTED|TRUE|ASSIGN|ON|TYPE|AT|OPEN|" +
        "UNIT|AUTHOR|OR|UNTIL|BEFORE|OTHER|UP|BLANK|OUTPUT|USE|BLOCK|PAGE|USING|BOTTOM|" +
        "PERFORM|VALUE|BY|PIC|VALUES|CALL|PICTURE|WHEN|CANCEL|PLUS|WITH|CD|POINTER|WRITE|" +
        "CHARACTER|POSITION||ZERO|CLOSE|POSITIVE|ZEROS|COLUMN|PROCEDURE|ZEROES|COMMA|PROGRAM|" +
        "COMMON|PROGRAM-ID|COMMUNICATION|QUOTE|COMP|RANDOM|COMPUTE|READ|CONTAINS|RECEIVE|CONFIGURATION|" +
        "RECORD|CONTINUE|REDEFINES|CONTROL|REFERENCE|COPY|REMAINDER|COUNT|REPLACE|DATA|REPORT|DATE|RESERVE|" +
        "DAY|RESET|DELETE|RETURN|DESTINATION|REWIND|DISABLE|REWRITE|DISPLAY|RIGHT|DIVIDE|RUN|DOWN|SAME|" +
        "ELSE|SEARCH|ENABLE|SECTION|END|SELECT|ENVIRONMENT|SENTENCE|EQUAL|SET|ERROR|SIGN|EXIT|SEQUENTIAL|" +
        "EXTERNAL|SIZE|FLASE|SORT|FILE|SOURCE|LENGTH|SPACE|LESS|STANDARD|LIMIT|START|LINE|STOP|LOCK|STRING|LOW-VALUE|SUBTRACT";

      var builtinConstants = "true|false|null";

      var builtinFunctions = "count|min|max|avg|sum|rank|now|coalesce|main";

      var keywordMapper = this.createKeywordMapper(
        {
          "support.function": builtinFunctions,
          keyword: keywords,
          "constant.language": builtinConstants
        },
        "identifier",
        true
      );

      this.$rules = {
        start: [
          {
            token: "comment",
            regex: "\\*.*$"
          },
          {
            token: "string", // " string
            regex: '".*?"'
          },
          {
            token: "string", // ' string
            regex: "'.*?'"
          },
          {
            token: "constant.numeric", // float
            regex: "[+-]?\\d+(?:(?:\\.\\d*)?(?:[eE][+-]?\\d+)?)?\\b"
          },
          {
            token: keywordMapper,
            regex: "[a-zA-Z_$][a-zA-Z0-9_$]*\\b"
          },
          {
            token: "keyword.operator",
            regex: "\\+|\\-|\\/|\\/\\/|%|<@>|@>|<@|&|\\^|~|<|>|<=|=>|==|!=|<>|="
          },
          {
            token: "paren.lparen",
            regex: "[\\(]"
          },
          {
            token: "paren.rparen",
            regex: "[\\)]"
          },
          {
            token: "text",
            regex: "\\s+"
          }
        ]
      };
    };

    oop.inherits(LogHighlightRules, TextHighlightRules);

    exports.LogHighlightRules = LogHighlightRules;
  }
);

ace.define(
  "ace/mode/log",
  [
    "require",
    "exports",
    "module",
    "ace/lib/oop",
    "ace/mode/text",
    "ace/mode/log_highlight_rules",
    "ace/range"
  ],
  function(acequire, exports, module) {
    "use strict";

    var oop = acequire("../lib/oop");
    var TextMode = acequire("./text").Mode;
    var LogHighlightRules = acequire("./log_highlight_rules").LogHighlightRules;
    var Range = acequire("../range").Range;

    var Mode = function() {
      this.HighlightRules = LogHighlightRules;
    };
    oop.inherits(Mode, TextMode);

    (function() {
      this.lineCommentStart = "*";

      this.$id = "ace/mode/log";
    }.call(Mode.prototype));

    exports.Mode = Mode;
  }
);
