<template>
  <Sidebar
    v-model:visible="visible"
    position="right"
    :pt="{
      root: { class: 'w-full md:w-2/5' },
    }"
    class="relative"
  >
    <template #container="{ closeCallback }">
      <div class="flex flex-col h-full relative">
        <div class="flex items-center justify-between px-4 py-4 mb-4 flex-shrink-0 border-b">
          <span class="inline-flex items-center gap-2">
            <div class="relative bg-purple-600/70 rounded-md p-1">
              <mdicon name="filter" size="32" class="text-purple-100" aria-hidden="true"></mdicon>
            </div>
            <span class="font-semibold text-xl text-primary">Filters</span>
          </span>
          <span>
            <Button type="button" @click="closeCallback" icon="pi pi-times" rounded outlined size="small" class="h-8 w-8"></Button>
          </span>
        </div>
        <div class="px-2 relative overflow-y-auto">
          <EditFilter
            v-for="(filter, idx) in filtersStore.activeFilters"
            :key="idx"
            :filter="filter"
            class="p-2 my-2 rounded-md hover:bg-slate-50 focus-within:bg-slate-50 focus-within:ring-2 focus-within:ring-offset-2"
          ></EditFilter>
        </div>
      </div>
    </template>
  </Sidebar>
  <BlockUI :blocked="visible" fullScreen />
</template>

<script setup>
  import { ref, computed, watch } from 'vue';
  import Sidebar from 'primevue/sidebar';
  import Button from 'primevue/button';
  import EditFilter from '@/components/Filters/EditFilter.vue';
  import BlockUI from 'primevue/blockui';
  import { useFiltersStore } from '@/store/FiltersStore';

  const visible = defineModel('visible');

  const emit = defineEmits(['close']);

  const filtersStore = useFiltersStore();
</script>
