<template>
  <div class="m-4 prose font-source">
    <div v-html="docHtml"></div>
  </div>
</template>
<script setup>
  import { useRoute } from 'vue-router';
  import { useDocumentationStore } from '@/store/DocumentationStore';
  import MarkdownIt from 'markdown-it';
  import MarkdownItAnchor from 'markdown-it-anchor';
  import MarkdownItAttrs from 'markdown-it-attrs';

  const route = useRoute();
  const documentationStore = useDocumentationStore();
  const path = '/src/docs/' + route.params.fileName + '.md';
  const md = documentationStore.docFiles[path];
  console.log(`Got path: ${path} MD: ${md}`);

  const mdIt = new MarkdownIt({
    html: true,
    linkify: true,
    typographer: true,
  })
    .use(MarkdownItAttrs)
    .use(MarkdownItAnchor);

  const docHtml = mdIt.render(md);
</script>
