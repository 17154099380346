<template>
  <div class="my-2 py-2 min-w-[300px]">
    <label class="block text-md font-medium leading-6 text-gray-900">{{ filter?.title }}</label>
    <div class="text-sm" v-if="filter?.description">{{ filter?.description }}</div>
    <div class="mt-2 relative">
      <div v-if="filter?.type === 'terms'" class="relative">
        <MultiSelect v-model="filterValue" :options="termsOptions" filter display="chip" :placeholder="`Select ${filter?.title || ''}`" :maxSelectedLabels="3" class="w-full md:w-20rem" />
      </div>
      <div v-else-if="filter?.type === 'match'" class="relative">
        <span class="p-input-icon-left w-full">
          <i class="pi pi-search" />
          <InputText type="text" :value="props.filter?.value || null" v-debounce:1s="setStringValue" class="pl-8 w-full" size="normal" />
        </span>
      </div>
      <div v-else-if="filter?.type === 'range'" class="flex">
        <StdNumberRuleEditor :op="filter?.op" :value="filter?.value" @change="onRangeChange"></StdNumberRuleEditor>
      </div>
      <!--
      <div v-else-if="propDef?.type === 'number'" class="relative rounded-md shadow-sm">
        <div v-if="propDef?.prefix" class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <span class="text-gray-500 sm:text-sm">$</span>
        </div>
        <input
            :id="propDef?.name"
            v-model="value"
            type="number"
            :placeholder="propDef?.placeholder || ''"
            class="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            :class="{ 'pl-7': propDef?.prefix, 'pr-18': propDef?.suffix }"
        />
        <div v-if="propDef?.suffix" class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <span class="text-gray-500 sm:text-sm" id="price-currency">{{ propDef?.suffix }}</span>
        </div>
      </div>
      <div v-else-if="propDef?.type === 'boolean'" class="relative rounded-md flex items-center">
        <input :id="propDef?.name" v-model="value" type="checkbox" class="h-4 w-4 text-indigo-600 rounded focus:ring-indigo-600" />
        <label :for="propDef?.name" class="pl-2 block text-sm font-medium leading-6 text-gray-900">{{ propDef?.title }}</label>
      </div>
      <div v-else-if="propDef?.type === 'temperature'" class="px-1 relative rounded-md shadow-sm">
        <div class="flex items-center justify-between">
          <div class="block text-sm leading-6 text-gray-900">More Strict</div>
          <div class="flex-shrink block text-md font-medium leading-6 text-gray-900">{{ propDef?.title }}: {{ value.toFixed(2) }}</div>
          <div class="block text-sm leading-6 text-gray-900">More Creative</div>
        </div>
        <Slider :id="propDef?.name" v-model="value" :min="0" :max="1" :step="0.05" class="mt-2 w-14rem" />
      </div>-->
    </div>
  </div>
</template>

<script setup>
  import { computed, ref, onMounted } from 'vue';
  import { useFiltersStore } from '@/store/FiltersStore';
  import MultiSelect from 'primevue/multiselect';
  import InputText from 'primevue/inputtext';
  import StdNumberRuleEditor from '@/components/Editors/StdNumberRuleEditor.vue';

  const props = defineProps({
    filter: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  const filtersStore = useFiltersStore();

  const termsOptions = ref([]);

  const filterValue = computed({
    get() {
      return props.filter?.value || null;
    },
    set(value) {
      filtersStore.setFilterValue(props.filter?.field, value);
    },
  });

  function setStringValue(value) {
    filtersStore.setFilterValue(props.filter?.field, value);
  }

  function onRangeChange(data) {
    filtersStore.setRangeFilterValue(props.filter?.field, data?.op, data?.value);
  }

  async function initTermsOptions() {
    if (props.filter?.type !== 'terms') {
      return;
    }
    const fieldTerms = await filtersStore.getFieldTerms(props.filter.field);
    if (!Array.isArray(fieldTerms)) {
      termsOptions.value = [];
      return;
    }
    termsOptions.value = fieldTerms;
  }

  onMounted(async () => {
    await initTermsOptions();
  });
</script>
