<template>
  <div class="">
    <div class="py-2 text-xl font-bold leading-7 text-gray-500 sm:truncate sm:text-xl sm:tracking-tight mb-4">Personal Coach Suggestions</div>
    <Message v-if="agentDataStore.generatingCoachingSuggestions" severity="info">Creating New Coaching Suggestions ...</Message>
    <div class="">
      <AgentCoachingSuggestion v-for="(s, idx) in agentDataStore.coachingSuggestions" :key="idx" :suggestion="s"></AgentCoachingSuggestion>
    </div>
  </div>
</template>

<script setup>
  import { useAgentDataStore } from '@/store/AgentDataStore';
  import Message from 'primevue/message';
  import AgentCoachingSuggestion from '@/components/Agents/AgentCoachingSuggestion.vue';

  const agentDataStore = useAgentDataStore();
</script>
