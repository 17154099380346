/*
 * TODO Consider presets: https://tailwind.primevue.org/builder
 * */
import { usePassThrough } from 'primevue/passthrough';
import Tailwind from 'primevue/passthrough/tailwind';

/*
 * Customize Primevue appearance here
 * */

export const PrimevueTailwind = usePassThrough(
  //Tailwind,
  {},
  {
    panel: {
      title: {
        class: ['leading-none font-light text-2xl'],
      },
    },
  },
  {
    mergeSections: true,
    mergeProps: false,
  },
);
