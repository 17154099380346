<template>
  <DataTable :value="agents" :sortField="sortField" :sortOrder="sortOrder" removableSort tableStyle="min-width: 50rem" size="small">
    <Column field="agentName" header="Agent" sortable class="" style="width: 25%">
      <template #body="slotProps">
        <div class="flex items-center">
          <mdicon name="face-agent" size="18" class="p-1 mr-2 text-slate-50 bg-slate-400/50 rounded-md" aria-hidden="true"></mdicon>
          <router-link v-if="agentLink" :to="{ path: `/agent/details/home`, query: { agentId: slotProps.data.agentId } }">
            <div class="cursor-pointer font-medium hover:underline">{{ slotProps.data?.agentName || '-' }}</div>
          </router-link>
          <div v-else class="font-medium">{{ slotProps.data?.agentName || '-' }}</div>
        </div>
      </template>
    </Column>
    <Column field="agentId" header="AgentID" sortable class="">
      <template #body="slotProps">
        <router-link v-if="agentLink" :to="{ path: `/agent/details/home`, query: { agentId: slotProps.data.agentId } }">
          <div class="cursor-pointer font-medium hover:underline">{{ slotProps.data?.agentId || '-' }}</div>
        </router-link>
        <div v-else class="font-medium">{{ slotProps.data?.agentId || '-' }}</div>
      </template>
    </Column>
    <Column field="count" header="Conversations" sortable>
      <template #body="slotProps">
        <router-link :to="{ path: conversationsPath, query: { query: getSearchQueryConversations(slotProps.data) } }" class="flex items-center hover:underline">
          <div class="cursor-pointer font-medium">{{ slotProps.data.conversationsCount }}</div>
          <mdicon name="open-in-new" size="20" class="ml-2 text-gray-300"></mdicon>
        </router-link>
      </template>
    </Column>
    <Column v-for="item in fields" :field="item.field" :header="item.title" sortable>
      <template #body="slotProps">
        {{ item.formatter ? item.formatter(slotProps.data[item.field]) : slotProps.data[item.field] }}
      </template>
    </Column>
  </DataTable>
</template>

<script setup>
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';

  defineProps({
    agents: {
      type: Array,
      default() {
        return [];
      },
    },
    conversationsPath: {
      type: String,
      default: '/insights/search',
    },
    agentLink: {
      type: Boolean,
      default: true,
    },
    sortField: {
      type: String,
      default: 'scoreAvg',
    },
    sortOrder: {
      type: Number,
      default: -1,
    },
    fields: {
      type: Array,
      default() {
        return [
          { field: 'avg', title: 'Score Avg' },
          { field: 'min', title: 'Score Min' },
          { field: 'max', title: 'Score Max' },
        ];
      },
    },
  });

  function getSearchQueryConversations(data) {
    return encodeURIComponent(`AgentName:"${data.agentName}"`);
  }
</script>
