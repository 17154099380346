/*
 * Conversation Search Store
 * */
import { ref, markRaw } from 'vue';
import { defineStore } from 'pinia';
import { computed } from 'vue';
import { useAuthStore } from '@/store/AuthStore';
import { useAnalyticsStore } from '@/store/AnalyticsStore';
import { useDateRangeStore } from '@/store/DaterangeStore';
import { AnalyticsQuery } from '@/analytics/AnalyticsQuery';
import { useToast } from 'vue-toastification';
import { pathOr } from 'ramda';

export const useAgentsInsightsStore = defineStore('agentsInsights', () => {
  const authStore = useAuthStore();
  const tenantId = computed(() => authStore.tenantId);

  const analyticsStore = useAnalyticsStore();
  const dateRangeStore = useDateRangeStore();

  const toast = useToast();

  // Default score calculate method: by average of metric
  const defaultCalculateScore = (stats) => stats.avg;

  // Results
  const analyzedScoreProperty = ref('score'); // By default, analyze by conversation score
  const conversationsCount = ref(0);
  const minScore = ref(0);
  const maxScore = ref(0);
  const scoreRange = computed(() => maxScore.value - minScore.value);
  const scoreRange10pct = computed(() => Math.floor(scoreRange.value * 0.1));
  const scoreCutoffBottom = computed(() => minScore.value + scoreRange10pct.value);
  const scoreCutoffTop = computed(() => maxScore.value - scoreRange10pct.value);

  const agentsCount = ref(0);
  const agents = ref([]);
  // hash by agentId with value index in agents array
  const agentsById = ref({});
  const agentsTopPerformers = ref([]);
  const agentsBottomPerformers = ref([]);
  const histogramData = ref([]);
  const histogramHighlightRanges = ref({});

  const analyzedTimeInterval = ref(null);

  // TODO Revisit this. Not efficient.
  // All known agents
  const allKnownAgents = ref(null);
  async function getAllKnownAgents() {
    if (allKnownAgents.value !== null) {
      return allKnownAgents.value;
    }
    console.log(`Searching for all known agents ...`);
    const agentsQuery = await queryAgents({ from: 'P1Y', to: 'now', interval: '1M' });
    if (agentsQuery.success) {
      const allAgents = [];
      const agentsBuckets = agentsQuery?.result?.aggregations?.agent?.buckets || null;
      if (agentsBuckets) {
        for (let i = 0; i < agentsBuckets.length; i++) {
          const bucket = agentsBuckets[i];
          allAgents.push({
            agentId: bucket.key,
            agentName: pathOr('', ['AgentName', 'buckets', 0, 'key'], bucket),
            conversationsCount: bucket?.doc_count || 0,
          });
        }
        allKnownAgents.value = allAgents;
      }
    }
    console.log(`All known agents: found ${Array.isArray(allKnownAgents.value) ? allKnownAgents.value.length : 0} agents.`);
    return allKnownAgents.value;
  }

  async function findAgent(agentId) {
    if (!dateRangeStore.isSameTimeInterval(analyzedTimeInterval.value)) {
      // Need to analyze first to get agents data
      await analyzeAgents();
    }
    if (agentId in agentsById.value) {
      const idx = agentsById.value[agentId];
      return agents.value[idx];
    }
    return null;
  }

  async function analyzeAgents(scoreProperty = null, calculateScore = defaultCalculateScore) {
    analyzedScoreProperty.value = scoreProperty ? scoreProperty : 'score';
    const agentsQuery = await queryAgents();
    if (agentsQuery.success) {
      processQueryResult(agentsQuery.result, calculateScore);
    }
  }

  async function queryAgents(timeInterval = null) {
    const q = new AnalyticsQuery();

    const queryTimeInterval = timeInterval ? timeInterval : dateRangeStore.timeInterval;
    q.timeInterval.from = queryTimeInterval?.from || 'StartOfDay';
    q.timeInterval.to = queryTimeInterval?.to || 'now';
    q.timeInterval.interval = dateRangeStore.timeInterval?.interval || '1D'; // '1D'

    analyzedTimeInterval.value = q.timeInterval;

    q.groupings = [
      {
        name: 'agent',
        title: 'Agent',
        type: 'terms',
        field: 'AgentId',
        size: 250,
        groupings: [
          {
            name: 'stats',
            title: 'Stats',
            type: 'stats',
            field: analyzedScoreProperty.value, //'score',
          },
          { name: 'AgentName', type: 'terms', size: 1, field: 'AgentName' },
        ],
      },
    ];

    q.request = AnalyticsQuery.prepareQuery({
      timeInterval: q.timeInterval,
      groupings: q.groupings,
      indexPrefix: 'conv',
      timestampField: 'datetime',
    });

    await analyticsStore.executeAnalyticsQuery(q);
    return q;
  }

  function processQueryResult(queryResult, calculateScore = defaultCalculateScore) {
    conversationsCount.value = 0;
    minScore.value = 0;
    maxScore.value = 0;
    agentsCount.value = 0;
    agents.value = [];
    agentsById.value = {};
    agentsTopPerformers.value = [];
    agentsBottomPerformers.value = [];
    histogramData.value = [];
    histogramHighlightRanges.value = {};

    let currMinScore = 1000;
    let currMaxScore = -1000;
    let currConversations = queryResult?.total || 0;
    const agentsData = [];
    const agentsByIdData = {};
    let agentsLen = 0;

    const agentsBuckets = queryResult?.aggregations?.agent?.buckets || null;
    if (!agentsBuckets) {
      return false;
    }

    for (let i = 0; i < agentsBuckets.length; i++) {
      const bucket = agentsBuckets[i];
      // Cut off agents who have no CSat scores at all
      if ((bucket?.stats?.count || 0) > 0) {
        const scoreCurr = calculateScore(bucket?.stats); //bucket?.stats?.avg || 0;
        if (scoreCurr < currMinScore) {
          currMinScore = scoreCurr;
        }
        if (scoreCurr > currMaxScore) {
          currMaxScore = scoreCurr;
        }
        //currConversations += bucket?.doc_count || 0;
        agentsLen = agentsData.push({
          agentId: bucket.key,
          agentName: pathOr('', ['AgentName', 'buckets', 0, 'key'], bucket),
          score: scoreCurr,
          conversationsCount: bucket?.doc_count || 0,
          count: bucket?.stats?.count || 0,
          min: bucket?.stats?.min || 0,
          max: bucket?.stats?.max || 0,
          avg: parseFloat((bucket?.stats?.avg || 0).toFixed(2)),
          sum: bucket?.stats?.sum || 0,
        });
        agentsByIdData[bucket.key] = agentsLen - 1;
      }
    }

    conversationsCount.value = currConversations;
    minScore.value = parseFloat(currMinScore.toFixed(2));
    maxScore.value = parseFloat(currMaxScore.toFixed(2));
    agents.value = agentsData;
    agentsById.value = agentsByIdData;
    agentsCount.value = agentsLen;
    agentsTopPerformers.value = agentsData.filter((x) => x.score >= scoreCutoffTop.value);
    agentsBottomPerformers.value = agentsData.filter((x) => x.score <= scoreCutoffBottom.value);

    calculateHistogram(calculateScore);
  }

  function calculateHistogram(calculateScore = defaultCalculateScore) {
    // Histogram
    const scoreRangeBuckets = scoreRange.value > 100 ? 100 : Math.floor(scoreRange.value) + 1;
    const rs = scoreRange.value / scoreRangeBuckets;
    const scoreRangeStep = rs < 1 ? 1 : Math.round(rs);
    const lowerBucket = Math.floor(minScore.value / scoreRangeStep) * scoreRangeStep - scoreRangeStep;
    const upperBucket = Math.floor(maxScore.value / scoreRangeStep) * scoreRangeStep + scoreRangeStep;
    const buckets = [];
    const counts = [];
    const bucketsIdx = {};
    let cntr = 0;
    for (let i = lowerBucket; i <= upperBucket; i += scoreRangeStep) {
      buckets.push(i);
      counts.push(0);
      bucketsIdx[i] = cntr++;
    }
    agents.value.map((a) => {
      const ab = Math.floor(calculateScore(a) / scoreRangeStep) * scoreRangeStep;
      const abIdx = bucketsIdx[ab];
      counts[abIdx] += 1;
    });
    const hData = [];
    for (let i = 0; i < buckets.length; i++) {
      hData.push([buckets[i], counts[i]]);
    }
    histogramHighlightRanges.value = {
      low: {
        start: lowerBucket,
        end: Math.floor((lowerBucket + scoreRange10pct.value) / scoreRangeStep) * scoreRangeStep + scoreRangeStep,
      },
      high: {
        start: Math.floor((upperBucket - scoreRange10pct.value) / scoreRangeStep) * scoreRangeStep - scoreRangeStep,
        end: upperBucket,
      },
    };
    histogramData.value = hData;
  }

  return {
    analyzeAgents,
    getAllKnownAgents,
    findAgent,
    analyzedScoreProperty,
    conversationsCount,
    minScore,
    maxScore,
    scoreRange,
    scoreRange10pct,
    scoreCutoffBottom,
    scoreCutoffTop,
    agents,
    agentsTopPerformers,
    agentsBottomPerformers,
    allKnownAgents,
    histogramData,
    histogramHighlightRanges,
  };
});
