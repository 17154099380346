/*
 * Test Result Store
 * */
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import api from '@/api/api';

export const useTestsStore = defineStore('tests', () => {
  // TODO Cache

  // List of tests
  const testsData = ref([]);
  const testRuns = ref([]);
  const jobResults = ref([]); // ???

  const tests = computed(() => testsData.value);

  function loadTests() {
    return;
    const testsUrl = `/api/tests`;
    console.log(`Loading tests: ${testsUrl}`);
    fetch(testsUrl)
      .then((response) => response.json())
      .then((data) => (this.testsData = data))
      .catch((e) => {
        console.log(`Exception retrieving tests ${testsUrl}: ${e.message}`);
      });
  }

  async function loadTestRuns() {
    const res = await api.getTestRuns();
    if (res.success) {
      this.testRuns = res.payload;
    }
  }

  async function loadJobResults(jobId) {
    const res = await api.getJobResults(jobId);
    if (res.success) {
      if (Array.isArray(res.payload) && res.payload.length > 0) {
        this.jobResults = res.payload.sort((a, b) => b.ctimeMs - a.ctimeMs);
      } else {
        this.jobResults = [];
      }
    }
  }

  async function loadTest(id) {
    const testUrl = `/api/test/${id}`;
    console.log(`Loading test: ${testUrl}`);

    let response = null;
    try {
      response = await fetch(testUrl);
    } catch (e) {
      console.log(`Exception retrieving test ${testUrl}: ${e.message}`);
      return null;
    }

    const responseData = await response.json();
    if (!responseData.success) {
      return null;
    }
    return responseData.test;
  }

  return {
    tests,
    testsData,
    testRuns,
    jobResults,
    loadTests,
    loadTestRuns,
    loadTest,
    loadJobResults,
  };
});
