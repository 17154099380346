<template>
  <div ref="engActionElement" tabindex="0" class="group relative flex items-start py-1 focus:outline-none" :class="{ 'form-active': formActive }" @keyup.enter="onEnter" @keyup.shift.enter="onShiftEnter">
    <div class="flex min-w-0 flex-1">
      <div v-if="menuEnabled" class="shrink-0 w-[10px]">
        <div class="cursor-pointer invisible z-10 group-hover:visible group-focus:visible absolute top-[-10px] left-[-5px]">
          <mdicon title="Add above ..." name="plus" size="20" class="text-slate-300 hover:text-slate-900" @click="emit('addStepAbove')" />
        </div>
        <div class="cursor-pointer invisible z-10 group-hover:visible group-focus:visible absolute bottom-[-10px] left-[-5px]">
          <mdicon title="Add below ..." name="plus" size="20" class="text-slate-300 hover:text-slate-900" @click="emit('addStepBelow')" />
        </div>
      </div>
      <div
        class="eng-action-menu cursor-move shrink-0 px-1 pt-1 text-slate-300 group-focus:bg-gray-200 group-focus:text-slate-500 group-hover:bg-gray-200 group-hover:text-slate-500 group-[.form-active]:bg-indigo-200 group-[.form-active]:text-slate-500"
      >
        <mdicon :name="actionDef.icon" size="20" class="" />
        <spec-script-step-menu v-if="menuEnabled" class="mt-2 cursor-pointer" @step-edit="onDblClick" @step-delete="emit('deleteStep')"></spec-script-step-menu>
        <!--
        <div class="invisible group-hover:visible bg-blue-500 text-white absolute top-0 flex flex-col h-full">
          <button>
            <mdicon :name="actionDef.icon" size="20" class="text-slate-300" />
          </button>
        </div>-->
      </div>
      <div class="eng-action-content flex flex-col min-w-0 flex-1">
        <div @dblclick="onDblClick">
          <div class="py-1 px-2 flex items-center group-focus:bg-gray-50 group-hover:bg-gray-50 group-[.form-active]:bg-indigo-50">
            <div class="font-source text-sm font-medium text-gray-400">{{ actionDef.title }}</div>
          </div>
          <div class="py-1 px-2 font-source text-md font-medium group-focus:bg-gray-50 group-hover:bg-gray-50 group-[.form-active]:bg-indigo-50">
            {{ actionDisplay }}
          </div>
        </div>
        <div v-if="formActive" class="p-1 font-source">
          <spec-script-action-editor-2
            :idx="props.idx"
            :action="props.action"
            @update:prop-value="
              (index, prop, value) => {
                emit('update:propValue', index, prop, value);
              }
            "
          >
          </spec-script-action-editor-2>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  //import { CheckCircleIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/vue/24/outline';
  import { ref, onMounted, computed } from 'vue';
  import { pathOr } from 'ramda';
  import SpecScriptStepMenu from '@/components/Spec/SpecScriptStepMenu.vue';
  import SpecScriptActionEditor2 from '@/components/Tests/SpecScriptActionEditor2.vue';
  import BannerError from '@/components/Banners/BannerError.vue';
  import { getActionDef } from '@/spec/ActionDefs.js';
  import SpecScriptActionProperty2 from '@/components/Tests/SpecScriptActionProperty2.vue';

  const props = defineProps({
    idx: {
      type: Number,
      default: -1,
    },
    action: {
      type: Object,
      default() {
        return {};
      },
    },
    menuEnabled: {
      type: Boolean,
      default: true,
    },
  });

  const engActionElement = ref(null);

  const emit = defineEmits(['action-form-active', 'addStepAbove', 'addStepBelow', 'deleteStep', 'update:propValue']);

  const actionDef = computed(() => getActionDef(props.action?.type));

  const actionDisplay = computed(() => (actionDef?.value?.displayProp ? props.action[actionDef.value.displayProp] || 'text' : actionDef?.value?.description));

  const formActive = computed(() => props.action?._active || false); //ref(false);

  //const selectedAction = computed(() => pathOr(undefined, [selectedIdx.value], props.actions));

  // todo reconsider, could be inefficient

  function onDblClick() {
    //formActive.value = !formActive.value;
    emit('action-form-active', props.idx, !formActive.value);
  }

  function onEnter() {
    // Only enable to expand when enter pressed
    if (!formActive.value) {
      emit('action-form-active', props.idx, true);
    }
  }

  function onShiftEnter() {
    if (formActive.value) {
      emit('action-form-active', props.idx, false);
      engActionElement.value.focus();
    }
  }

  onMounted(() => {
    console.log(`ScriptActionRender: action: ${JSON.stringify(props.action)}`);
  });
</script>
