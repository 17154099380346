<template>
  <Menu as="div" class="relative inline-block text-left">
    <div>
      <MenuButton as="div" class="flex items-center rounded-full hover:outline-none hover:ring-2 hover:ring-slate-400">
        <span class="sr-only">Open options</span>
        <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems class="left-full -top-1/2 absolute mt-2 z-50 mr-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div class="py-1">
          <MenuItem v-slot="{ active }">
            <div class="flex items-center" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" @click="emit('step-edit')">
              <mdicon name="square-edit-outline" size="18" class="text-gray-500/70 mr-2"></mdicon>
              <div>Edit</div>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }">
            <div class="flex items-center" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']" @click="emit('step-delete')">
              <mdicon name="close-circle-outline" size="18" class="text-gray-500/70 mr-2"></mdicon>
              <div>Delete</div>
            </div>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script setup>
  import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
  import { EllipsisVerticalIcon } from '@heroicons/vue/20/solid';
  const emit = defineEmits(['step-edit', 'step-delete']);
</script>
