<template>
  <!--<div class="sticky grid grid-rows-2 grid-cols-1" style="grid-template-rows: auto 1fr; top: calc(4rem + 1px); height: calc(100vh - 4rem - 1px)">-->
  <div class="bg-teal-400 grid grid-cols-1 grid-rows-3" style="grid-template-rows: auto 1fr auto; min-height: 0; min-width: 0; height: 100%">
    <!--<div class="lg:flex lg:items-center lg:justify-between p-2 my-2 border-b">
      <div class="min-w-0 flex-1">
        <h2 class="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">TITLE</h2>
        <div class="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <BriefcaseIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Voice
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <MapPinIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            11111
          </div>
          <div class="mt-2 flex items-center text-sm text-gray-500">
            <CalendarIcon class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
            Modified on March 28, 2023
          </div>
        </div>
      </div>
      <div class="mt-5 flex lg:mt-0 lg:ml-4">
        <span class="hidden sm:block">
          <button type="button" class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            <PencilIcon class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            Settings
          </button>
        </span>

        <span class="sm:ml-3">
          <button
            type="button"
            class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <CheckIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            Save
          </button>
        </span>
      </div>
    </div>-->
    <div class="m-2 bg-gray-200">OPA Header</div>
    <!--<div class="m-2 bg-orange-200 grid" style="grid-template-columns: 1fr auto; height: 220px">
      <div class="m-2 bg-blue-200 overflow-auto">
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
        <div>Text</div>
      </div>
      <div class="m-2 bg-red-300">
        <div class="w-[250px]">Props</div>
      </div>
    </div>-->
    <div class="m-2 bg-red-300 overflow-auto" style="">
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
      <div>Content</div>
    </div>
    <div class="m-2 bg-yellow-300">OPA Footer</div>
  </div>
</template>
