<template>
  <div>
    <div class="m-2 grid grid-cols-1 gap-x-8 gap-y-4 xl:grid-cols-2 lg:gap-x-8">
      <div v-for="(entry, idx) in stdProps" :key="idx" class="border-b">
        <div class="flex justify-between">
          <dt class="text-gray-500">{{ entry.name }}</dt>
          <dd class="text-gray-700 font-semibold">
            {{ entry.value }}
          </dd>
        </div>
      </div>
    </div>
    <div v-if="hasTags" class="m-2 mt-4 flex items-center flex-wrap">
      <h3 class="text-base font-semibold leading-6 text-gray-900 mr-2">Tags:</h3>
      <span v-for="(tag, idx) in conversationTags" :key="idx" class="m-1 inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{{ tag }}</span>
    </div>
    <div v-if="hasGroups" class="m-2 mt-4 flex items-center flex-wrap">
      <h3 class="text-base font-semibold leading-6 text-gray-900 mr-2">Groups:</h3>
      <span v-for="(group, idx) in conversationGroups" :key="idx" class="m-1 inline-flex items-center rounded-full bg-blue-50 px-2 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10">{{
        group
      }}</span>
    </div>
    <div v-if="hasComments" class="m-2 mt-4">
      <h3 class="text-base font-semibold leading-6 text-gray-900 mb-2 border-b">Comments</h3>
      <div v-for="(comment, idx) in conversationComments" :key="idx" class="font-source text-gray-800 text-md my-2 p-1 bg-gray-50 rounded-md">{{ comment }}</div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from 'vue';
  import EqualizerValue from '@/components/Charts/EqualizerValue.vue';

  const props = defineProps({
    conversation: {
      type: Object,
      default() {
        return {};
      },
    },
  });

  const conversationTranscript = computed(() => props.conversation?.context?.transcript || {});
  const conversationTranscriptMetadata = computed(() => conversationTranscript.value?.metadata || {});

  const hasTags = computed(() => Array.isArray(props.conversation.tags) && props.conversation.tags.length > 0);
  const conversationTags = computed(() => props.conversation?.tags || []);

  const hasGroups = computed(() => Array.isArray(props.conversation.groups) && props.conversation.groups.length > 0);
  const conversationGroups = computed(() => props.conversation?.groups || []);

  const hasComments = computed(() => Array.isArray(props.conversation.comments) && props.conversation.comments.length > 0);
  const conversationComments = computed(() => props.conversation?.comments || []);

  // Standard Conversation properties
  const stdPropsDefs = [
    { name: 'id', title: 'ID' },
    { name: 'datetime', title: 'Date' },
    { name: 'duration', title: 'Duration' },
    { name: 'direction', title: 'Direction' },
    { name: 'media', title: 'Media' },
    { name: 'AgentId', title: 'Agent ID' },
    { name: 'AgentName', title: 'Agent Name' },
    { name: 'Number', title: 'Number' },
    { name: 'NumberName', title: 'Number Name' },
    { name: 'CustomerId', title: 'Customer' },
    { name: 'CustomerName', title: 'Customer Name' },
  ];

  const stdProps = computed(() => {
    const res = [];
    stdPropsDefs.map((pd) => {
      if (pd.name in props.conversation) {
        let pv = props.conversation[pd.name];
        if (pd.name === 'duration') {
          pv = formatSeconds(pv);
        }
        if (pv && pv !== '') {
          res.push({
            name: pd.title,
            value: pv,
          });
        }
      }
    });
    return res;
  });

  /*
    this.id = scenario.getConversationId();
    this.datetime = scenario.getConversationDate();
    this.media = scenario.ScenarioMedia;

    this.Words = scenario.getAnalyzedTranscriptWords();

    const transcriptInfo = scenario.TranscriptInfo;

    this.duration = transcriptInfo?.duration || 0;
    this.direction = transcriptInfo?.direction || '';

    this.AgentId = transcriptInfo?.agentId || '';
    this.AgentName = transcriptInfo?.agentName || '';
    this.AgentEmail = transcriptInfo?.agentEmail || '';

    this.groups = transcriptInfo?.groups || [];

  * */

  function formatSeconds(sec) {
    const diff = sec;
    const totalMinutes = Math.floor(diff / 60);
    const seconds = diff % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return hours > 0
      ? `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
      : `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
</script>
