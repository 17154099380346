/*
 * Standard filters definitions
 */

// Standard Conversation Filters
export const stdConversationFilters = [
  { type: 'terms', title: 'Number', field: 'Number' },
  { type: 'terms', title: 'Agent', field: 'AgentName' },
  { type: 'terms', title: 'Tags', field: 'tags' },
  { type: 'range', title: 'Duration', field: 'duration', description: 'Conversation duration in seconds' },
  //{ type: 'match', title: 'CSat Explanation', field: 'CSatExplanation' },
  { type: 'range', title: 'CSat', field: 'CSat' },
  { type: 'range', title: 'Sentiment', field: 'CustomerSentiment' },
  { type: 'range', title: 'Problems Severity', field: 'ProblemsSeverity' },
  { type: 'range', title: 'KPI Score', field: 'KPIScore' },
];

// Standard Bot Conversation Filters
export const stdBotConversationFilters = [
  { type: 'terms', title: 'Bot', field: 'botName' },
  //{ type: 'bool', title: 'Escalated', field: 'state.escalated' },
  { type: 'range', title: 'CSat', field: 'CSat' },
  { type: 'terms', title: 'Agent', field: 'AgentName' },
  { type: 'range', title: 'Time To Agent', field: 'stats.timeToAgent', description: 'Time To agent in seconds: time between Escalated and Agent Joined' },
];
