<template>
  <div ref="chartEl" :style="`height:${height}px;`">
    <canvas ref="canvasEl" style="position: absolute"> </canvas>
  </div>
</template>
<script setup>
  /* Shows numeric value as an equalizer-like bar, with various coloring modes
   * TODO Support vertical mode
   */
  import { ref, reactive, watch, computed, nextTick, onMounted, onBeforeUnmount } from 'vue';
  import * as d3 from 'd3';
  import * as d3Color from 'd3-color';

  const props = defineProps({
    value: {
      type: [Number, String],
      default: 0,
    },
    height: {
      type: Number,
      default: 20,
    },
    color: {
      type: String,
      default: null,
    },
    colorMode: {
      type: String,
      default: 'default',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
    negative: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 100,
    },
  });

  const chartEl = ref(null);
  const canvasEl = ref(null);

  let canvasWidth = 100;
  let canvasHeight = 50;
  // eslint-disable-next-line vue/no-setup-props-destructure
  let maxObservedValue = props.value;

  watch(
    () => [props.value, props.height, props.color, props.colorMode, props.reverse, props.max],
    async () => {
      await nextTick();
      render();
    }
  );

  onMounted(() => {
    window.addEventListener('resize', handleResize);
    render();
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize);
  });

  function initSize() {
    canvasHeight = props.height ? props.height : chartEl.value.clientHeight;
    canvasWidth = props.width ? props.width : chartEl.value.clientWidth;
  }

  async function handleResize(/*event*/) {
    await nextTick();
    render();
  }

  function hex2RGBA(hex, opacity) {
    let c = d3Color.color(hex);
    c.opacity = opacity;
    return c + '';
  }

  //https://github.com/d3/d3-shape#curves
  //https://bl.ocks.org/d3indepth/64be9fc39a92ef074034e9a8fb29dcce
  function render() {
    let startTs = Date.now();
    //console.log('Rendering EqualizerValue ...');

    initSize();
    let canvas = canvasEl.value;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    //ctx.fillStyle = dbColors.hex2RGBA('#78909c', 0.2); // Light

    let rw = 6;
    let rh = canvas.height;
    let rp = 2;
    let rfw = rw + rp;

    let numrect = Math.floor(canvas.width / (rw + rp));

    // Scale
    const scaleDomain = props.negative ? [numrect, 0] : [0, numrect];
    let lx = d3.scaleLinear().domain(scaleDomain).range([0, props.max]);

    let colorScale = d3.scaleSequential(d3.interpolateRdYlGn).domain([0, numrect]);
    let colorValueScale = d3.scaleSequential(d3.interpolateRdYlGn).domain([0, props.max]);

    for (let i = 0; i < numrect; i++) {
      //console.log(`i=${i}, lx(i)=${lx(i)}`);
      // Determine color
      let c = props.color;
      if (!c) {
        switch (props.colorMode) {
          case 'uniform': {
            c = props.reverse ? colorValueScale(props.value) : colorValueScale(props.max - props.value);
            break;
          }
          default: {
            c = props.reverse ? colorScale(i) : colorScale(numrect - i);
            break;
          }
        }
      }
      let scaledValue = lx(i);
      if (scaledValue < props.value) {
        ctx.fillStyle = hex2RGBA(c, 0.7); // Light
        // Note: this messes up sorting in the table - when different value set for the same instance of component
        //} else if (scaledValue < maxObservedValue) {
        //  ctx.fillStyle = hex2RGBA(c, 0.2); // Medium
      } else {
        ctx.fillStyle = hex2RGBA('#78909c', 0.1); // Light
      }
      ctx.beginPath();
      ctx.rect(rfw * i, 0, rw, rh);
      ctx.fill();
    }

    let endTs = Date.now();
    //console.log(`Finished render Equalizer Value: ${endTs} - ${endTs - startTs} msec`);
  }
</script>
