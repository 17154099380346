<template>
  <div class="">
    <label :for="name" class="block text-sm font-medium leading-6 text-gray-900">{{ label }}</label>
    <div class="text-xs text-gray-500" v-if="description">{{ description }}</div>
    <div class="mt-2">
      <div class="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
        <input
          type="text"
          :name="name"
          class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          :placeholder="placeholder"
          v-model="value"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
  import { computed } from 'vue';

  const props = defineProps(['modelValue', 'name', 'label', 'description', 'placeholder']);
  const emit = defineEmits(['update:modelValue']);

  const value = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit('update:modelValue', value);
    },
  });
</script>
