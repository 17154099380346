// Sample of configuring perspective views // https://bl.ocks.org/texodus/803de90736a3641ad91c5c7a1b49d0a7
<template>
  <div class="h-full">
    <perspective-viewer theme="Material Light" style="width: 100%; height: 100%; resize: vertical" class="perspective-viewer-material" />
  </div>
</template>
<script setup>
  // TODO Support Refresh

  import { ref, watch, computed, onMounted } from 'vue';
  import { perspectiveTables } from '@/store/perspectivetables';
  const props = defineProps(['tablename']);

  async function initialize() {
    const el = document.getElementsByTagName('perspective-viewer')[0];
    //const testTable = await perspectiveTables.getTable('lastticks');
    const perspectiveTable = await perspectiveTables.getTable(props.tablename);
    el.load(perspectiveTable);
    el.toggleConfig();
  }

  onMounted(async () => {
    await initialize();
  });
</script>
